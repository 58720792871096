import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AttentionModal from '../AttentionModal';
import Transition from '../../components/ModalTransition';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {showWelcome} from '../../coresim/reducers/settings';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHighlightContext } from '../../providers/highlightProvider';

const StudentWelcomeModal = ({simulation, teamIndex, showWelcome, closeModal}) => {
  const highlights = useHighlightContext();
  console.log(highlights);

  let date = moment().dayOfYear(4);

  let currentQuarter = simulation.quarters[simulation.quarter.counter]
  let currentMinicase = undefined;

  if (currentQuarter) {
    currentMinicase = currentQuarter.minicase;
  }

  const quarter = simulation.quarter.counter;

  return (
    <Dialog
      style={{
        margin: 'auto',
      }}
      open={showWelcome}
      TransitionComponent={Transition}
      disableBackdropClick={false}
      hideBackdrop={false}
      onClose={closeModal}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'>

      <AttentionModal
        handleClose={closeModal}
        data={currentMinicase}
        quarter={quarter}
        sim={simulation}
        teamIndex={teamIndex}
        date={date}/>

    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    simulation: state.coresim,
    teamIndex: state.teamIndex.teamIndex,
    showWelcome: state.settings.showWelcome,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => dispatch(showWelcome(false)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentWelcomeModal);

StudentWelcomeModal.propTypes = {
  simulation: PropTypes.object.isRequired,
  teamIndex: PropTypes.number,
  showWelcome: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}
