import React from 'react';
import {
  Book as BookIcon,
  People as PeopleIcon,
  BusinessCenter as BusinessCenterIcon,
  Note as NoteIcon,
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  LibraryBooks as LibraryBooksIcon,
  HowToVote as SubmitBallotIcon,
  EmojiEvents as TrophyIcon,
  AccountBalance as AccountBalanceIcon,
  TableChart as TableChartIcon,
} from "@material-ui/icons";
import PropTypes from 'prop-types';
import {Badge, makeStyles, useTheme, useMediaQuery} from '@material-ui/core';
import {showWelcome, showAnnouncements, showCalendar, showCurriculum} from '../coresim/reducers/settings';
import store from '../coresim/';

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: '#fdd835',
    color: 'black',
  }
}));

const CurriculumBadge = (activeModuleIds) => ({children}) =>
  <Badge badgeContent={activeModuleIds.length} color='error'>
    {children}
  </Badge>

CurriculumBadge.propTypes = {
  children: PropTypes.node.isRequired,
  activeModuleIds: PropTypes.array.isRequired,
}

const GoldBadge = ({children}) => {
  const classes = useStyles();
  return (
    <Badge
      badgeContent={'New'}
      classes={{badge: classes.badge}}
    >
      {children}
    </Badge>
  );
};

GoldBadge.propTypes = {
  children: PropTypes.node.isRequired,
};

export default (sim, teamIndex, activeModuleIds) => {
  const result = sim.teams[teamIndex].decisionHistory
    .findIndex(
      (decision) => decision.marketResearch > 1
    );

  const hasResearch = result !== -1;
  const recentResearch = result === sim.teams[teamIndex].decisionHistory.length - 1;

  //find out if user is on mobile
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm')) && Boolean(sim.bbCourse);
  const hasZoom = Boolean(sim.teams[teamIndex].zoom);

  return [
    {
      label: 'Dashboard',
      Icon: BookIcon,
      to: '/',
    },
    {
      label: 'Mini Case',
      Icon: BusinessCenterIcon,
      to: '/case/details',
    },
    {
      label: 'Quarterly Decisions',
      Icon: SubmitBallotIcon,
      to: '/vitals',
    },
    {
      label: 'Rankings',
      Icon: TrophyIcon,
      to: '/leaderboard',
      display: sim.quarter.counter !== 0
    },
    {
      label: 'Balance Sheet',
      Icon: AccountBalanceIcon,
      to: '/balancesheet',
      display: sim.quarter.counter !== 0
    },
    {
      label: 'Market Research',
      Icon: PeopleIcon,
      to: '/research',
      display: hasResearch,
      badge: GoldBadge,
      displayBadge: recentResearch,
    },
    {
      label: 'Historical Data',
      Icon: TableChartIcon,
      to: '/vitals/history'
    },
    {
      label: 'Executive Summary',
      Icon: AssignmentIcon,
      action: () =>
        store.dispatch(
          showWelcome(true)
        ),
    },
    {
      label: 'View Announcements',
      Icon: NoteIcon,
      display: matches,
      action: () =>
        store.dispatch(
          showAnnouncements(true)
        )
    },
    {
      label: 'View Schedule',
      Icon: CalendarTodayIcon,
      display: matches,
      action: () =>
        store.dispatch(
          showCalendar(true)
        )
    },
    {
      id: 'menu-curriculum',
      label: 'View Curriculum',
      Icon: LibraryBooksIcon,
      displayBadge: activeModuleIds.length > 0,
      badge: CurriculumBadge(activeModuleIds),
      display: matches,
      action: () => {
        store.dispatch(
          showCurriculum(true)
        )
      }
    },
  ];
};
