import React, { useState, useEffect, useRef, useCallback } from 'react';
import Calendar from '@material-ui/icons/Today';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Block';

import {
  Button,
  FormControl,
  FormControlLabel,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const useIconStyles = makeStyles((theme) => ({
  icon: (colorPicker) => {

    const colors = colorPicker ? colorPicker(theme) : {};

    return ({
      color: colors.color,
      fontSize: '1.2rem !important',
      '&:hover': {
        color: colors.hover,
        cursor: 'pointer',
      },
      '&.disabled': {
        color: '#ccc',
      },
    })
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(.5),
    borderRadius: 5,
    minWidth: 'unset'
  },
}));

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    cursor: 'inherit',
  },
  label: {
    fontWeight: 'bold',
    margin: 0,
  },
  labelStart: {
    margin: 0,
  },

  editDate: {
    padding: 3,
  },
  safariWrapper: {
    // width isn't set correctly with safari and the scrolling time input, need to set directly
    width: 328,
  }
}));

const NoValue = () => (
  <Typography variant="body2">
    <em>No Value</em>
  </Typography>
)

const FormatValue = ({value}) => {
  return (
    <Typography variant="body2">
      {moment(value).format('lll')}
    </Typography>
  );
}

FormatValue.propTypes = {
  value: PropTypes.number
}

const DisplayValue = ({value}) => (
  value === undefined ? <NoValue /> : <FormatValue value={value} />
);

DisplayValue.propTypes = {
  value: PropTypes.number
}

const EditValue = ({value, onChange, inputRef}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!value) {
      value = new Date();
      onChange && onChange(value);
    }
  }, []);

  // HACK ALERT: This is a little tricky, but here's what is going on:
  // We are exploiting the difference (if any) between
  // the moment.tz default timezone and the local() timezone.
  // react-datepicker does not look at moment.tz, but rather
  // relies on the detected local timezone (as far as I can tell,
  // this corresponds to local(), and probably moment.tz.guess(), though
  // it's not certain). We are essentially faking the input time by shifting
  // the appropriate offset which is found by pretending the non-local date
  // is local and re-parsing it into moment.tz default. It is a function so
  // that if the moment.tz default timezone ever changes, we are always
  // calculating with the current timezone.
  // Beware that this diff needs to be taken relative to _value_ so that
  // daylight sayings, timezone calendars, etc, are taken in to account
  // for the particular datetime.
  // Thus: we have created a moment.tz aware control
  const timeDiff = useCallback(
    () => moment(moment(value).format('lll')) - moment(value),
    [value]
  );

  const convertedValue = moment(moment(value) + timeDiff()).toDate();
  const handleChange = (value) => {
    if (onChange) {
      onChange(moment(moment(value) - timeDiff()).toDate());
    }
  }

  return (
    <DatePicker
      ref={inputRef}
      className={classes.editDate}
      calendarClassName={classes.safariWrapper}
      selected={convertedValue}
      onChange={handleChange}
      showTimeInput={false}
      showTimeSelect={true}
      dateFormat="P p"
      timeIntervals={15}
    />
  )
};

EditValue.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.any.isRequired
};

const CustomIconButtonHOC = (Icon, colorPicker) => {
  const CustomIconButton = ({onClick, disabled}) => {
    const classes = useIconStyles(colorPicker);

    const clickCallback = useCallback((e) => {
      setTimeout(onClick, 50);
    })

    return (
      <Button
        className={classes.iconButton}
        onClick={clickCallback}
        disabled={disabled}
      >
        <Icon
          className={classnames(classes.icon, disabled && 'disabled' )}
        />
      </Button>
    );
  };

  CustomIconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  return CustomIconButton;
};


const CalendarButton = CustomIconButtonHOC(Calendar,
  (theme) => ({
    color: theme.palette.primary.dark,
    hover: theme.palette.primary.main,
  })
);

const OkButton = CustomIconButtonHOC(Done,
  (theme) => ({
    color: theme.palette.ok.dark,
    hover: theme.palette.ok.main,
  })
);

const ClearButton = CustomIconButtonHOC(Clear,
  (theme) => ({
    color: theme.palette.error.dark,
    hover: theme.palette.error.main,
  })
);

const EditButtons = ({onOk, onClear}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <OkButton onClick={onOk} />
      <ClearButton onClick={onClear} />
    </Box>
  );
};

EditButtons.propTypes = {
  onOk: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

const EditControl = ({value, onChange, onOk, onClear, disabled}) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.setFocus();
    }
  }, [ref.current]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      ml={1}>
      <EditValue value={value} onChange={onChange} inputRef={ref} />
      <EditButtons
        onOk={onOk}
        onClear={onClear}
        disabled={disabled}
      />
    </Box>
  )
};

EditControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onOk: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

const DisplayControl = ({value, disabled, onEdit}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      ml={1}
    >
      <DisplayValue value={value} />
      <CalendarButton
        onClick={onEdit}
        disabled={disabled}
      />
    </Box>
  );
};

DisplayControl.propTypes = {
  value: PropTypes.number,
  onEdit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const DateTimeControl = ({
  value,
  onChange,
  isEdit,
  onEditChange,
  disabled
}) => {
  const [selecting, setSelecting] = useState(isEdit === undefined ? false : isEdit);
  const ref = useRef();

  const handleToggle = (setToggle) => {
    isEdit !== undefined ?
      onEditChange && onEditChange(setToggle) : setSelecting(setToggle);
  }

  useEffect(() => {
    if (isEdit !== undefined) {
      setSelecting(isEdit);
    }
  }, [isEdit]);

  useEffect(() => {
    if (selecting && ref.current) {
      ref.current.setFocus();
    }
  }, [selecting, ref.current]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      ml={1}
    >
      {
        selecting ?
          <EditControl
            value={value}
            onChange={onChange}
            onOk={
              () => {
                handleToggle(false);
              }
            }
            onClear={
              () => {
                onChange && onChange();
                handleToggle(false);
              }
            }
            disabled={disabled}
          /> :
          <DisplayControl
            value={value}
            onEdit={() => {
              handleToggle(true);
            }}
            disabled={disabled}
          />
      }
    </Box>
  )
}

DateTimeControl.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  onEditChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

const DateTimePicker = ({ label, className, ...remaining}) => {
  const classes = useStyles();
  return (
    <FormControl className={className}>
      <Box display="flex" alignItems="flex-end">
        <FormControlLabel
          label={label}
          labelPlacement="start"
          classes={{
            root: classes.root,
            label: classes.label,
            labelPlacementStart: classes.labelStart
          }}
          control={
            <DateTimeControl {...remaining} />
          }
        />
      </Box>
    </FormControl>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
};

export default DateTimePicker;
