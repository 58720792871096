import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import ConfigOption from './ConfigOption';
import data from '../data/priceVolume';
import { Tabs, Tab, Box, Button, makeStyles, IconButton, Tooltip, Typography } from '@material-ui/core';
import {Auth} from 'aws-amplify';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const useStyles = makeStyles((theme) => ({
  logoutButton: {
    float: 'right',
    [theme.breakpoints.down('xs')] : {
      float: 'none'
    }
  },
  zoomButton: {
    [theme.breakpoints.down('xs')] :{
      textAlign: 'center'
    }
  }
}))

const typeInfo = [
  data.quantity,
  data.undecided,
  data.quality
];

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const TabList = typeInfo.map((value, i) =>
  <Tab style={{fontWeight: 600}}
    key={i} label={value.title} {...a11yProps(i)} />
);

const PriceVolume = ({coresim, teamIndex, updateConfig, handleLogout}) => {
  const [activeConfig, setActiveConfig] = useState(1);
  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedConfig = coresim.config[activeConfig];

    try {
      updateConfig(selectedConfig,
        coresim.id,
        coresim.teams[teamIndex].id);
      toast.success('Success!');
    } catch(e) {
      toast.error('Error selecting option');
    }
  }

  const handleChange = (event, newValue) => {
    setActiveConfig(Number(newValue));
  };

  const hasImage = coresim.config[activeConfig].widgetId;

  return (

    <Box p={2}>
      <Box style={{textAlign: 'center'}}>
        <Button
          className={classes.logoutButton}
          variant="contained"
          color="primary"
          onClick={async () => {
            await Auth.signOut();
            handleLogout();
          }}
          type="submit">Logout</Button>
      </Box>
      { coresim.config &&
    <React.Fragment>
      <Tabs
        value={activeConfig}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons="off"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="select configuration"
      >
        {TabList}
      </Tabs>
      <ConfigOption
        typeInfo={typeInfo[activeConfig]}
        image={hasImage ? `${process.env.REACT_APP_S3_IMAGES}/${coresim.config[activeConfig].widgetId}`: undefined}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
      }
    </Box>

  );
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
    teamIndex: state.teamIndex.teamIndex
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

PriceVolume.propTypes = {
  updateConfig: PropTypes.func.isRequired,
  coresim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number,
  handleLogout: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceVolume);
