import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Info from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#56503c',
    backgroundColor: '#feefb3',
    border: '.4px solid #56503c',
    borderRadius: 3,
  },
  infoIcon: {
    fontSize: '1.2rem !important',
    marginRight: theme.spacing(1),
  },
}));

const InfoBox = ({message}) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      my={.5}
      p={.5}
      className={classes.root}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Info className={classes.infoIcon} />
      <Typography variant="body1">
        {message}
      </Typography>
    </Box>
  );
}

InfoBox.propTypes = {
  message: PropTypes.string.isRequired,
};

export default InfoBox;
