import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import percentIncrease from '../helpers/percentIncrease';
import PropTypes from 'prop-types';


const PercentDiff = (props) => {
  const useStyles = makeStyles(() => ({
    up: {
      color: 'green',
    },
    down: {
      color: 'red',
    },
  }));

  const classes = useStyles();

  const absDiff = percentIncrease(props.previous, props.latest);

  const direction = props.latest >= props.previous ? classes.up : classes.down;


  if (absDiff === undefined || !isFinite(absDiff)) {
    return '';
  }

  const tagLine = props.latest >= props.previous ? `Up ${absDiff.toFixed(1)}%` : `Down ${absDiff.toFixed(1)}%`;

  return (
    <Typography className={direction}>{tagLine}</Typography>
  );
};

PercentDiff.propTypes = {
  previous: PropTypes.number,
  latest: PropTypes.number,
};

export default PercentDiff;
