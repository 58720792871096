import React from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import Field from '../Forms/Field';
import { useFormReducer } from '../../helpers/useFormReducer';
import { useArrayReducer } from '../../helpers/useArrayReducer';
import StackRoute from '../../routing/StackRoute';
import { useHistory, useLocation, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import MinicaseOutput from './MinicaseOutput';
import Group from '../Forms/Group';
import DeleteIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  item: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0,.05)',
    }
  }
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
  onChange: (e) =>updateHandler(name)(e.target.value),
});

const newOutput = {
  outputMetric: '',
  outputQuantity: undefined,
};

const OutputsAdmin = ({outputs, outputsHandlers, disabled}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const handleSave = (delegateHandler) => (value) => {
    delegateHandler(value);
    history.push(match.url);
  }

  return (
    <Switch>
      <StackRoute
        path={`${match.url}/outputs/create/`}
        label='Create Output'
      >
        <Typography variant="h4">
        Create Output
        </Typography>
        <MinicaseOutput
          initialOutput={newOutput}
          disabled={disabled}
          onSave={handleSave(outputsHandlers.add)}
        />
      </StackRoute>

      {outputs && outputs.map((o, i) => (
        <StackRoute
          key={i}
          path={`${match.url}/outputs/${i}/`}
          label='Edit Output'
        >
          <Typography variant="h4">
            Edit Output
          </Typography>
          <MinicaseOutput
            key={i}
            initialOutput={o}
            disabled={disabled}
            onSave={handleSave(outputsHandlers.replace(i))}
          />
        </StackRoute>
      ))
      }
      <Redirect path={`${match.url}/`} to={match.url} />
    </Switch>
  )
}

OutputsAdmin.propTypes = {
  outputs: PropTypes.array.isRequired, 
  outputsHandlers: PropTypes.array.isRequired, 
  disabled: PropTypes.bool.isRequired
}



//title, description, outputs, make default
const MinicaseOption = ({initialOption, disabled, onSave}) => {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const [form, updateHandler] = useFormReducer(initialOption,
    (init) => ({...init}));

  const [outputs, outputsHandlers] = useArrayReducer(
    initialOption.optionOutputs,
    (init) => [...init]
  );

  const handleSave = (e) => {
    e.preventDefault();
    if (onSave) {
      onSave({
        ...form,
        optionOutputs: outputs
      });
    }
  };

  return (
    <React.Fragment>
      <OutputsAdmin
        outputs={outputs}
        outputsHandlers={outputsHandlers}
        disabled={disabled}
      />
      <ValidatorForm onSubmit={handleSave}>

        <Group stack>
          <Field
            {...getProps('optionTitle', form, updateHandler)}
            label="Title"
            disabled={disabled}
          />
          <Field
            {...getProps('optionDescription', form, updateHandler)}
            label="Description"
            disabled={disabled}
            multiline
            rows={4}
            fullWidth
          />
          <Field
            {...getProps('optionResult', form, updateHandler)}
            label="Summary Debrief"
            disabled={disabled}
            multiline
            rows={4}
            fullWidth
          />
        </Group>
        <Group
          stack
          title="Output"
        >
          {
            <List>
              {outputs.map((o,i) =>
                <ListItem
                  classes={{container: classes.item}}
                  key={i}
                >
                  <ListItemText
                    primary={o.outputMetric}
                    secondary={o.outputQuantity}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => history.push(`${location.pathname}/outputs/${i}`)}
                      edge="end"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => outputsHandlers.remove(i)}
                      edge="end"
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>

          }
          <Button
            style={{alignSelf: 'flex-start'}}
            variant="text"
            color="primary"
            onClick={() => history.push(`${location.pathname}/outputs/create`) }
            disabled={disabled}
          >
          + Add
          </Button>
        </Group>

        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          type="submit"
        >
        Save
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

MinicaseOption.propTypes = {
  initialOption: PropTypes.object.isRequired, 
  disabled: PropTypes.bool.isRequired, 
  onSave: PropTypes.func.isRequired
}

export default MinicaseOption;
