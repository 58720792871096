import React from 'react';
import {makeStyles, Grid, Typography, CardMedia} from '@material-ui/core';
import graphNumberFormat from '../../helpers/graphNumberFormat';
import leaderboardBackground from '../../assets/leaderboardBackground.svg';
import firstPlaceMedal from '../../assets/firstPlaceMedal.svg';
import secondPlaceMedal from '../../assets/secondPlaceMedal.svg';
import thirdPlaceMedal from '../../assets/thirdPlaceMedal.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  statCard: {
    width: '90%',
    height: 'auto',
    margin: '5px',
    borderRadius: '1em 0 1em 0',
    backgroundColor: 'rgb(255, 255, 255)'
  },
  statStat: {
    fontSize: '.8rem',
    textAlign: 'center',
    paddingRight: '.2rem'
  },
  statName: {
    borderRadius: '0 0 1rem 0',
    fontSize: '.8rem', 
    margin: '.2rem'
  },
  standSecond: {
    height: '17rem',
    borderRadius: '1rem 0 0 0 ',
    backgroundColor: 'rgba(187, 246, 163, .9)',
    [theme.breakpoints.down('xs')]: {
      height: '15rem'
    }
  },
  standFirst: {
    height: '20rem',
    borderRadius: '1rem 0 0 0 ',
    backgroundColor: 'rgba(130, 194, 217, .9)',
    [theme.breakpoints.down('xs')]: {
      height: '15rem'
    }
  },
  standThird: {
    height: '15rem',
    borderRadius: '1rem 0 0 0 ',
    backgroundColor: 'rgba(232, 215, 108, .9)',
  },
  cardOne: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  cardTwo: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  cardThree: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
}))

const StatCard = (props) => {
  const classes = useStyles();
  const backgroundValue = props.label === 'stock' ? {backgroundColor: 'rgb(139, 235, 252)'} : {backgroundColor: 'rgb(198, 234, 169)'};

  return (
    <Grid item>
      <Grid container direction='row' alignItems='center' justify='center' className={classes.statCard}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p className={classes.statStat}>
            {graphNumberFormat(props.worth, 2)}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p className={classes.statName} style={backgroundValue}>
            {props.label.toUpperCase()}
          </p>
        </Grid>
      </Grid>
    </Grid> 
  )
};

StatCard.propTypes = {
  label: PropTypes.string.isRequired,
  worth: PropTypes.number.isRequired
}

const StandingsCard = (props) => {

  const classes = useStyles();
  const images = [firstPlaceMedal, secondPlaceMedal, thirdPlaceMedal]
  const podiums = [ classes.standFirst, classes.standSecond, classes.standThird]
  return (
    <React.Fragment>
      {props.team !== undefined ? 
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p style={{fontSize: '5rem', fontWeight: 'bolder'}}>{`${props.idx + 1}`}</p>
            </Grid>
          </Grid>
          <Grid container direction='column' alignItems='center' justify='space-between' className={podiums[props.idx]}>
            <Grid item>
              <Typography style={{fontWeight: 'bolder', margin: '20px auto'}}>{props.team.vitalsHistory.businessAs || props.team.teamName}</Typography>
              <img 
                src={images[props.idx]}
                alt=''
                width='50'
                height='50'
              />
            </Grid>
            <Grid item >
              <Grid container direction='row' alignItems='center' justify='center'>
                <StatCard label={'stock'} worth={props.team.vitalsHistory.stock}/>
                <StatCard label={'value'} worth={props.team.vitalsHistory.valuation}/>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment> 
        : null }
    </React.Fragment> 
  )
}

StandingsCard.propTypes = {
  team: PropTypes.object,
  idx: PropTypes.number.isRequired
}

const LeadBoard = (props) => {
  const classes = useStyles();
  
  return (
    <CardMedia image={leaderboardBackground}>
      <Grid container direction="row" spacing={2} alignItems='flex-end' justify='space-evenly' style={{paddingBottom: '35px'}}>
        <Grid item className={classes.cardOne} xs={12} sm={6} md={4}>
          <Grid container alignItems='center' justify='center'>
            <Grid item >
              <StandingsCard idx={1} team={props.orderedTeams[1]}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.cardTwo} xs={12} sm={12} md={4}>
          <Grid container alignItems='center' justify='center'>
            <Grid item >
              <StandingsCard idx={0} team={props.orderedTeams[0]}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.cardThree} xs={12} sm={6} md={4}>
          <Grid container alignItems='center' justify='center'>
            <Grid item >
              <StandingsCard idx={2} team={props.orderedTeams[2]}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardMedia>
  )
};

LeadBoard.propTypes = {
  orderedTeams: PropTypes.array.isRequired,
};

export default LeadBoard;