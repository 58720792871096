import loans from '../../config/loans';

//TODO: Remove this! We should never init to this, this is bogus data
export const initState = {
  demandIndex: [0.4, 0.5, 0.25, 0.85, 0.35, 0.6, 0.3, 0.75],
  marketSize: 100000,
  initialCash: 300000,
  initialInventory: 30000,
  initialCapacity: 30000,
  initialBoardSentiment: 0.55,
  initialPublicSentiment: 0.55,
  initialSalespeople: 2,
  salesQuarterlySalaray: 15000,
  salesHireCost: 30000,
  salesFireCost: 15000,
  salesAdvertisingOutput: 30000,
  industryPrice: 30,
  industryDefects: 16,
  industryDefectDeviance: 12,
  qaPerProductDev: 0.2,
  qaPerManufacturingExpense: 0.1,
  expenseLevelledCost: 20,
  expenseLevellingPoint: 7000, // TODO: revisit this
  qaSensitivity: 2,
  combinedQASensitivity: 1,
  productDevelopmentSensitivity: 0.75, // obsolesence in 3 quarters
  expectedProductDevPerQuarter: 20000,
  marketCapitalizationRate: 1.08,
  stocks: 100000,
  initialStockPrice: 21.8,
  initialValuation: 2180000,
  industryDividentYeild: 0.025, // 2.5% is the average dividend yeild for consumer goods in the S&P
  loanTypes: loans,
  plantWorth: 500000,
};

//TODO: remove this. this is never valid, must always init with a config
export const initVitals = {
  cash: initState.initialCash,
  lastQuarterDefects: 0,
  lastQuarterDemand: 0,
  productionCost: 0,
  inventory: initState.initialInventory,
  capacity: initState.initialCapacity,
  stock: initState.initialStockPrice,
  salespeople: initState.initialSalespeople,
  liabilitiesBankLoan: 0,
  loanPaymentDue: 0,
  loanPaymentApplied: 0,
  missedLoanPayment: false,
  loanLateAmount: 0,
  loanIndex: undefined,
  loanQuarters: 0,
  interestRate: undefined,
  interest: 0,
  expenses: 0,
  revenue: 0,
  boardSentiment: initState.initialBoardSentiment,
  publicSentiment: initState.initialPublicSentiment,
  publicSentimentModifier: 0,
  qaSentiment: 0.5,
  lostSales: 0,
  valuation: initState.initialValuation,
  advertisingHistory: [],
  productDevHistory: [],
  businessAs: '',
};

export function createConfiguration(options) {
  let {
    name,
    initialCash
  } = options;
  return {
    ...initState,
    initialCash: initialCash,
    name: name
  }
}

export default function configuration(state = initState) {
  return state;
}
