const percentageIncrease = (previous, latest) => {
  if (previous === undefined || latest === undefined) {
    return undefined;
  }

  if (previous * latest <= 0) {
    return undefined;
  }

  return (100 * (latest - previous)) / previous;
};

export default percentageIncrease;
