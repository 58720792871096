import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Link from "@material-ui/core/Link";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  externalLink: {
    display: "flex",
    alignItems: "center"
  },
  openInNew: {
    color: grey[600],
    marginRight: theme.spacing(1)
  }
}));

export default function ViewInBlackboard(props) {
  const classes = useStyles();
  return <Link classes={{root: classes.externalLink}} href={props.externalUrl}
    color="textSecondary" target="_blank" rel="noopener noreferrer" variant="caption">
    <OpenInNewIcon classes={{root: classes.openInNew}} style={{fontSize: '1.25rem'}}/>View In Blackboard
  </Link>
}

ViewInBlackboard.propTypes = {
  externalUrl: PropTypes.string.isRequired,
}
