import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {cachedAPIGet} from '../../../../helpers/cachedAPIRequests';
import ModuleList from './ModuleList';
import ActiveModule from './ActiveModule';

const Curriculum = (props) => {
  const [modules, setModules] = useState([]);
  const [activeModule, setActiveModule] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let abortObj = {aborted: false};
    // only try to load in curriculum if the course id is set
    if (props.courseId && props.courseId.length > 0) {
      onLoad(abortObj);
    } else {
      setLoading(false)
    }
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  async function onLoad(abortObj) {
    try {
      let modules;
      switch (props.activeModules.length) {
        // we don't need to show all modules
        case 0:
          break;
        // if there is only one, call the single module endpoint
        case 1: {
          const module = await cachedAPIGet('blackboard',
            `/courses/${props.courseId}/modules/${props.activeModules[0]}`)
          modules = [module]
          break;
        }
        default: {
          const init = {
            queryStringParameters: {
              filterIds: props.activeModules.join(',')
            }
          }
          modules = await cachedAPIGet('blackboard', `/courses/${props.courseId}/modules`, init);
          break;
        }
      }

      if (modules && (!abortObj || !abortObj.aborted)) {
        setModules(modules);
      }
    } catch (e) {
      toast.error('Unable to retrieve curriculum from Blackboard' + e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      {props.title}
      {
        loading ? <Box textAlign="center"><CircularProgress /></Box> :
          activeModule ?
            <ActiveModule setActiveModule={setActiveModule} activeModule={activeModule} courseId={props.courseId}/> :
            <ModuleList modules={modules} setActiveModule={setActiveModule}/>
      }
    </Box>
  )
}

Curriculum.defaultProps = {
  activeModules: []
}

Curriculum.propTypes = {
  courseId: PropTypes.string,
  activeModules: PropTypes.array,
  title: PropTypes.element,
}

export default Curriculum;
