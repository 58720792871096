import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {cachedAPIGet} from '../../../../helpers/cachedAPIRequests';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ViewInBlackboard from '../ViewInBlackboard';
import dompurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    width: '100%',
    overflowWrap: 'break-word',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  activeModuleTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  backLink: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  viewInBb: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const ActiveModule = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Box>
        <Link onClick={() => props.setActiveModule(null)} underline='none' classes={{root: classes.backLink}}>
          <KeyboardArrowLeft/> <Box alignSelf='center'>Back to Curriculum List</Box>
        </Link>
        <Typography align='center' variant='h4' classes={{root: classes.activeModuleTitle}}>
          {props.activeModule.title}
        </Typography>
        <SingleModule moduleId={props.activeModule.id} courseId={props.courseId}/>
      </Box>
      <Box display='flex' justifyContent='flex-end' className={classes.viewInBb}>
        <ViewInBlackboard externalUrl={props.activeModule.externalUrl}/>
      </Box>
    </div>
  )
}

ActiveModule.propTypes = {
  courseId: PropTypes.string.isRequired,
  setActiveModule: PropTypes.func.isRequired,
  activeModule: PropTypes.object.isRequired
}

const SingleModule = (props) => {
  const classes = useStyles();
  const [expandedPanelId, setExpandedPanelId] = useState('');
  const [moduleContents, setModuleContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let abortObj = {aborted: false};
    onLoad(abortObj);
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  async function onLoad(abortObj) {
    try {
      // this means modules will pull from blackboard for 3 days -- if they update module contents often, this
      // might become a problem
      const modules = await cachedAPIGet(
        'blackboard',
        `/courses/${props.courseId}/modules/${props.moduleId}/contents`
      );
      if (!abortObj || !abortObj.aborted) {
        setModuleContents(modules);
      }
    } catch (e) {
      toast.error('Unable to retrieve curriculum from Blackboard');
    } finally {
      setLoading(false);
    }
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanelId(isExpanded ? panel : '');
  };

  return (
    <div className={classes.root}>
      {loading ? <Box textAlign="center"><CircularProgress /></Box> : moduleContents.map((contents) =>
        <ModuleContent
          handleChange={handleChange}
          contents={contents}
          expandedPanel={expandedPanelId}
          key={contents.id}
          classes={classes}/>)}
    </div>
  );
}

SingleModule.propTypes = {
  courseId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired
}

const ModuleContent = (props) => {
  return (
    <ExpansionPanel expanded={props.expandedPanel === props.contents.id} onChange={props.handleChange(props.contents.id)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Typography variant="h6">{props.contents.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div
          dangerouslySetInnerHTML={{__html: dompurify.sanitize(props.contents.body)}}
          className={props.classes.content}/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

ModuleContent.propTypes = {
  expandedPanel: PropTypes.string,
  contents: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default ActiveModule;
