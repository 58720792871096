import {useContext} from 'react';
import breadcrumbContext from './BreadcrumbContext';
import { useHistory } from 'react-router';

const useBreadcrumbActions = () => {
  const breadcrumbs = useContext(breadcrumbContext);
  const history = useHistory();

  const pop = () => {
    const parent = breadcrumbs[breadcrumbs.length - 2] ||
      breadcrumbs[breadcrumbs.length - 1];

    if (history[history.length - 2] === parent.to) {
      history.goBack();
    } else {
      history.push(parent.to);
    }
  };

  return {
    pop
  };
};

export default useBreadcrumbActions;
