import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import UserForm from './UserForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const EditUser = ({onReload}) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialUser, setInitialUser] = useState();
  const [error, setError] = useState();
  let { id } = useParams();
  const breadcrumbActions = useBreadcrumbActions();

  useEffect(() => {
    let aborted = false;
    const load = async () => {
      try {
        const result = await API.get('mongo', `/users/${id}`);
        if (!aborted) {
          setInitialUser(result[0]);
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setError("User not found");
        } else {
          throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    load();
    return () => aborted = true;
  }, [id]);

  const onSubmit = async (form) => {
    setSaving(true);
    try {
      await API.put('mongo', `/users/${id}`, {body: form});
      toast.success('Successfuly edited user');
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Edit User
      </Typography>
      {
        error
      }
      { // TODO: loading screen
        !loading && !error &&
        <UserForm
          onSubmit={onSubmit}
          initialValue={initialUser}
          disabled={saving}
          editing
        />
      }
    </Box>
  );
}

EditUser.propTypes = {
  onReload: PropTypes.string.isRequired
}

export default EditUser;
