import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectionContext from './SelectionContext';
import useSelectionContext from './useSelectionContext';
import { useArrayReducer } from '../../helpers/useArrayReducer';

const SelectionContextProvider = ({
  children,
  selection,
  onSelectionChanged
}) => {
  const [selectItems, selectItemsHandlers] =
    useArrayReducer(selection || [], (init) => [...init]);

  useEffect(() => {
    onSelectionChanged && onSelectionChanged(selectItems);
  }, [selectItems])

  return (
    <SelectionContext.Provider value={[
      selectItems.map((i) => i._id),
      selectItemsHandlers,
    ]}>
      {children}
    </SelectionContext.Provider>
  );
};

SelectionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  selection: PropTypes.array,
  onSelectionChanged: PropTypes.func,
};

export default SelectionContextProvider;
export {SelectionContext, useSelectionContext};
