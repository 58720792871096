import {Tooltip, TableSortLabel} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import React from 'react';
import renderCell from './renderCell';
import {getHandledActionEvents} from '../../helpers/getActionEvents';

const renderHeader = (
  getSortChangeHandler,
  headerClass,
  iconClass,
  activeClass,
  sortField,
  sortAscending
) => {
  return (col, cellIndex) => {
    const contents = col.isSortable ? (
      <Tooltip
        title="Sort"
        placement={col.numeric ? 'bottom-end' : 'bottom-start'}
        enterDelay={300}
      >
        <TableSortLabel
          classes={{
            active: activeClass,
            icon: iconClass,
            root: headerClass,
          }}
          active={sortField === col.name}
          IconComponent={ArrowDropDown}
          direction={sortAscending ? 'asc' : 'desc'}
          {...getHandledActionEvents(
            getSortChangeHandler(col.name, col.sortDirection || false)
          )}
        >
          {col.label}
        </TableSortLabel>
      </Tooltip>
    ) : (
      <span className={headerClass}>{col.label}</span>
    );
    return renderCell(col)(contents, cellIndex, col.name);
  };
};

export default renderHeader;
