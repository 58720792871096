import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import SimulationForm from './SimulationForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import moment from 'moment';
import 'moment-timezone';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const defaultWidget = 'Fitness Tracker';
const defaultTimezone = moment.tz.guess();

const getInitialSimulation = () => ({
  id: uuidv4(), // TODO: remove id field and use _id
  name: '',
  widget: defaultWidget,
  quarter: {
    counter: 0
  },
  quarters: undefined,
  timezone: defaultTimezone,
  config: [undefined, undefined, undefined],
  teams: [],
  chairs: [],
  bbCourse: {id: undefined, name: undefined, curriculum: undefined},
  simStarted: false
});

const CreateSimulation = ({onReload}) => {
  const [loading, setLoading] = useState(false);
  const [initialSimulation, setInitialSimulation] =
    useState(getInitialSimulation());
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);

    form.config.map((conf, i) => {
      if (conf.widgetImage && conf.widgetImage.length > 0) {
        API.post('mongo', `/util/photo`, {
          body: {
            img: conf.widgetImage[0],
            name: conf.widgetId
          }
        })
      }
      // setting to empty array to no longer store base64 string
      conf.widgetImage = []
    })

    let body = {
      ...form
    }

    try {
      await API.post('mongo', '/sims', {body});
      await lockIn(form);
      toast.success('Success!');
      setInitialSimulation(getInitialSimulation());
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  }

  const lockIn = async (form) => {
    try {
      form.quarters.map(async (event, i) => {
        if (typeof(event.start) === 'undefined') {
          console.log('Not a proper date.')
        } else {
          let newStart = new Date(event.start).toISOString()
          let newObj = {
            start: new Date(event.start),
            end: new Date(event.end),
            coresimId: form.id,
            quarter: i + 1,
            dueDate: new Date(newStart)
          }
          const res = await API.post('scheduler', `/ticks`, {body: newObj});
          console.log('This is the execution ID we need to persist to unschedule: ', res)
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create Simulation
      </Typography>
      <SimulationForm
        onSubmit={onSubmit}
        lockIn={lockIn}
        initialValue={initialSimulation}
        disabled={loading}
      />
    </Box>
  );
}

CreateSimulation.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default CreateSimulation;
