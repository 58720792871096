import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const ConfirmModal=({handleConfirm, handleCancel, message}) => {
  return (
    <div>

      <DialogTitle id="responsive-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm Submission
        </Button>
      </DialogActions>

    </div>
  );
}

ConfirmModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string
}
export default ConfirmModal;
