import logistic from './base/logistic';
import sum from './base/sum';

/**
 *
 * This is a function given by https://www.desmos.com/calculator/loyrxr1ceu
 * to determine the actual defect rate for the product. Rates are raw
 * numbers without units.
 *
 * @param {number} deviance  - What are the bounds for deviation in the expected rate (+/- how many)
 *
 */
const actualDefectRate = (deviance) =>
  /**
   * @param {number} industryDefectRate - The industry standard defect rate
   */
  (industryDefectRate) =>
    /**
     * @param {number} requiredQA - The amount of QA needed
     */
    (requiredQA) =>
      /**
       * @param {number} qaQuarters - An array of previous quarters QA expenditures, with oldest first
       */
      (qaQuarters) =>
        requiredQA === 0 ?
          0 :
          -2 *
              deviance *
              logistic(4 / requiredQA)(sum(qaQuarters) - requiredQA) +
            deviance +
            industryDefectRate;

export default actualDefectRate;
