import React from "react";
import PropTypes from "prop-types";

const NumberCurrencyHoc = (Component, options = { dollarOnly: false }) => {
  const CurrencyField = ({ ...remaining }) => {
    return (
      <Component
        {...remaining}
        currency={true}
        dollarOnly={options.dollarOnly}
      />
    );
  };

  CurrencyField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    onNumberChange: PropTypes.func,
  };

  return CurrencyField;
};

export default NumberCurrencyHoc;
