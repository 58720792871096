import React, { useState, useCallback } from 'react';
import {Button, Box} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CommentIcon from '@material-ui/icons/Comment';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ContactSupport from '@material-ui/icons/ContactSupportTwoTone';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import BusinessValuation from '../components/BusinessValuation';
import {
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';
import getBalanceSheet from '../helpers/getBalanceSheet';

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
  },
  link: {
    textDecoration: 'none'
  },
  expandedItem: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  }
}));

const ExpandItem = ({primary, secondary, icon, children, visible}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  return visible ? (
    <Box>
      <ListItem
        button
        onClick={() => setExpanded(!expanded)}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={secondary} />
        {children &&
        (expanded ? <ExpandLess /> : <ExpandMore />)}

      </ListItem>
      {expanded && children &&
        <Box className={classes.expandedItem}>
          {children}
        </Box>
      }
    </Box>
  ) : null;
};

ExpandItem.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  icon: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired
}

// pass open/close in as props
const AttentionModal = (props) => {

  const classes = useStyles();

  const quarter = props.sim.teams[props.teamIndex].vitalsHistory.length - 1;
  const decHist = props.sim.teams[props.teamIndex].decisionHistory;
  const vitHist = props.sim.teams[props.teamIndex].vitalsHistory;
  const newName = vitHist.filter(vital => vital.businessAs);
  const simOver = props.sim.quarter.counter === props.sim.quarters.length;
  const getQtrBalanceSheet = useCallback(
    getBalanceSheet(decHist, vitHist), [decHist, vitHist]
  );

  let balanceSheet = getQtrBalanceSheet(quarter);

  let currentQtrValues = {
    ...balanceSheet,
    lastSelectedMinicaseTitle : decHist.length > 0 ? decHist[props.sim.quarter.counter - 1 ].selectedMinicase && decHist[props.sim.quarter.counter - 1 ].selectedMinicase.title : '',
    get lastSelectedMinicase() {return this.lastSelectedMinicaseTitle ? props.sim.quarters[props.sim.quarter.counter  - 1].minicase.options.find(mCase => mCase.optionTitle === this.lastSelectedMinicaseTitle) : ''},
    cashAmount: decHist.length > 0 ? decHist[props.sim.quarter.counter - 1].cashAmount : 0,
    cashJustification: decHist.length > 0 ? decHist[props.sim.quarter.counter - 1].cashJustification : '',
  }

  props.date.add(1, 'y')
  const qtrFirstMonth = props.quarter * 3;

  return (
    <div>
      <IconButton
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          padding: '0px',
        }}
        onClick={props.handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent
        id='alert-dialog-slide-title'
        style={{textAlign: 'center'}}
        dividers={true}>
        {simOver ? 
          <Typography variant="h4">
          Congratulations the sim is over.
          </Typography> :
          <Typography variant="h4">
            {`Welcome to Quarter ${props.sim.quarter.counter + 1}!`}
          </Typography>
        }
      </DialogContent>

      <DialogTitle>
        {`${(newName.length > 0 && newName[newName.length - 1].businessAs) || props.sim.teams[props.teamIndex].teamName}`}
        <Typography variant="body2">
          {props.date.clone().add(qtrFirstMonth, 'month').format('MMM Do, YYYY')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {simOver ? 
          <DialogContentText
            component="div"
            style={{color: 'black'}}
            id='alert-dialog-slide-description'>
            <Typography variant="body1">
           Let us reflect on the decisions we made througout this simulation. What was your reasoning for making the decsions that either propelled your company to the top or made you go bankrupt.
            </Typography>
          </DialogContentText> 
          :
          <DialogContentText
            component="div"
            style={{color: 'black'}}
            id='alert-dialog-slide-description'>
            <Typography variant="body1">
            Today, your executive team is currently planning the major business operations for
            the upcoming quarter (
              {props.date.clone().add(qtrFirstMonth, 'month').add(1, 'y').format('MMM YYYY')}, {
                props.date.clone().add(qtrFirstMonth + 1, 'month').add(1, 'y').format('MMM YYYY')} and {
                props.date.clone().add(qtrFirstMonth + 2, 'month').add(1, 'y').format('MMM YYYY')
              }
            ). Be prepared to anticipate the demand for the season, keeping in mind that
            any units produced or staff hired will not be available until the following quarter.
            </Typography>
          </DialogContentText>}
        <DialogContentText
          component="div"
          id='alert-dialog-slide-description'>
          <List
            component="div"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Q{(props.quarter + 1)} Executive Summary
              </ListSubheader>
            }
            className={classes.list}
          >
            <ExpandItem
              component="div"
              primary="Emerging Situation"
              secondary={`minicase: ${props.data && props.data.miniCase}`}
              icon={<NotificationImportantIcon style={{color: '#fb7a00'}} />}
              visible={Boolean(props.data)}
            >
              <Typography
                component="div"
                style={{maxHeight: 150, overflowY: 'auto'}}>
                {props.data && props.data.description}
              </Typography>
              <Link
                className={classes.link}
                to='/case/details'
                onClick={() => props.handleClose()}
              >
                <Button
                  color="secondary">
                  Respond to Minicase
                </Button>
              </Link>
            </ExpandItem>
            <ExpandItem
              component="div"
              primary="Balance Sheet"
              icon={<AccountBalanceIcon style={{color: 'darkgreen'}} />}
              visible={Boolean(props.quarter)}
            >
              <BusinessValuation
                stockCurrent={currentQtrValues.stockPrice}
                valueCurrent={currentQtrValues.valuation}
                netFlowCurrent={currentQtrValues.netCashFlow}
              />
              <Link
                className={classes.link}
                to='/balancesheet'
                onClick={() => props.handleClose()}
              >
                <Button
                  color="secondary">
                View Full Report
                </Button>
              </Link>
            </ExpandItem>

            {!props.sim.completed && props.sim.quarters[props.quarter].article &&
            <ExpandItem
              component="div"
              primary="News"
              secondary={props.sim.quarters[props.quarter].article.headline}
              icon={<CommentIcon style={{color: '#0c5460'}} />}
              visible={Boolean(props.sim.quarters[props.quarter].article)}
            >
              <Box maxHeight={150} style={{overflowY: 'auto', wordWrap: 'break-word'}}>
                <ReactMarkdown source={props.sim.quarters[props.quarter] &&
                  props.sim.quarters[props.quarter].article &&
                  props.sim.quarters[props.quarter].article.story.toString('markdown')} />
              </Box>
            </ExpandItem>
            }

            {currentQtrValues.lastSelectedMinicase && currentQtrValues.lastSelectedMinicase.optionResult &&
            <ExpandItem
              primary="Debriefing"
              secondary={
                <ReactMarkdown source={`The result of last quarters minicase option selected. You chose ${currentQtrValues.lastSelectedMinicaseTitle} `}
                />}
              icon={<ContactSupport style={{color: '#bfb876'}} />}
              visible={true}
            >
              <Box maxHeight={150} style={{overflowY: 'auto'}}>
                <ReactMarkdown source={`${currentQtrValues.lastSelectedMinicase.optionResult}`} />
              </Box>
            </ExpandItem>
            }

            {currentQtrValues.cashJustification &&
            <ExpandItem
              primary="Additional Investment"
              secondary={
                <ReactMarkdown source={`You received an additional ${currentQtrValues.cashAmount} of investment money.`}
                />}
              icon={<AttachMoneyIcon style={{color: '#8bc34a'}} />}
              visible={true}
            >
              <Box maxHeight={150} style={{overflowY: 'auto'}}>
                <ReactMarkdown source={`The memo reads:  ${currentQtrValues.cashJustification} `} />
              </Box>
            </ExpandItem>
            }

          </List>
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{display: 'flex', justifyContent: 'center', fontSize: '1.5rem'}}>
        <Button
          style={{margin: '15px auto'}}
          onClick={() => {
            props.handleClose();
          }
          }
          variant='text'
          color='primary'>
            Close Report
        </Button>
      </DialogActions>
    </div>
  );
};

export default AttentionModal;

AttentionModal.propTypes = {
  handleClose: PropTypes.func,
  data: PropTypes.object,
  quarter: PropTypes.number,
  date: PropTypes.object,
  sim: PropTypes.object,
  teamIndex: PropTypes.number,
};
