import React, { useState } from "react";
import PropTypes from "prop-types";

import { API } from "aws-amplify";

import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

export default function UnsubmitDecision({ simId, teamId, quarter, onClose }) {
  const [loading, setLoading] = useState(false);

  const handleUnsubmitDecisions = async () => {
    try {
      setLoading(true);

      await API.put(
        "state",
        `/simulations/${simId}/teams/${teamId}/decisions/${quarter}`,
        {
          body: {
            decisionsComplete: false,
          },
        }
      );

      onClose && onClose(true); // `true` to reload admin state
      toast.success("Success!");
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title="Allow this team to resubmit their decision for this quarter">
      <div>
        <Button
          onClick={handleUnsubmitDecisions}
          color="secondary"
          variant="contained"
          style={{ width: "100%" }}
          disabled={loading}
        >
          Unsubmit Decisions
        </Button>
      </div>
    </Tooltip>
  );
}

UnsubmitDecision.propTypes = {
  simId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  quarter: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};
