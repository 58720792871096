import identity from './identity';

/**
 * This call type is for real value function definitions
 *
 * @callback realFunc
 * @param {number} - The dependent variable
 * @returns {number} - The function value
 */

/**
 *
 * Composes a series of real-valued functions. Parameters are process
 * in a L to R fashion (e.g. compose(first, second) yeilds first(second(x)).
 *
 * @param {realFunc} required - The outermost function to compose
 * @param {realFunc} - The inner functions for composition
 */

const compose = (first, ...rest) => {
  let result = identity;

  for (let i = rest.length - 1; i >= 0; i--) {
    const lastResult = result;
    result = (...params) => rest[i](lastResult(...params));
  }

  return (...params) => first(result(...params));
};

export default compose;
