import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MiniCaseReport from './MiniCaseReport';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  centering: {
    display: 'inline-block',
    margin: 'auto'
  },
}));

const MiniCaseTabs = ({current, teamIndex, sim}) => {
  const classes = useStyles();
  const currentQuarter = sim.quarter.counter - 1;
  const [value, setValue] = React.useState(currentQuarter);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color='default'>
        <Tabs
          value={value}
          className={current ? classes.centering : ''}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          aria-label="simple tabs example"
        >
          {!current ? sim.teams[teamIndex].decisionHistory.map((decision, index) =>(
            <Tab key={index} label={`Q${index + 1} MiniCase Answers`} {...a11yProps(index)}/>
          )) : <Tab label={`Current Submitted MiniCase Answers`} {...a11yProps(0)}/>
          }

        </Tabs>
      </AppBar>
      {!current ? sim.teams[teamIndex].decisionHistory.map((decision, index) =>(
        <TabPanel key={index} value={value} index={index}>
          <MiniCaseReport quarterMinicase={sim.quarters[index]} quarterDecisions={sim.teams[teamIndex].decisionHistory[index]} />
        </TabPanel>
      )
      )
        :
        <TabPanel value={0} index={0}>
          <MiniCaseReport quarterMinicase={sim.quarters[sim.quarter.counter]} quarterDecisions={sim.teams[teamIndex].decisions} />
        </TabPanel>
      }
    </div>
  );
}

MiniCaseTabs.propTypes = {
  sim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  current: PropTypes.bool

}

export default MiniCaseTabs;
