import {Dialog} from '@material-ui/core';
import React from 'react';
import CalendarList from '../../components/Integrations/Blackboard/Calendar/CalendarList';
import { showCalendar } from '../../coresim/reducers/settings';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import Transition from '../../components/ModalTransition';
import ModalTitle from '../../components/ModalTitle';


const StudentCalendarModal = ({showCalendar, simulation, closeModal}) => {
  return (
    <Dialog aria-labelledby="course-schedule-modal"
      open={showCalendar}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      onClose={closeModal}>
      <CalendarList title={<ModalTitle text='Schedule' closeModal={closeModal}/>}
        courseId={simulation && simulation.bbCourse ? simulation.bbCourse.id : null}/>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    showCalendar: state.settings.showCalendar,
    simulation: state.coresim
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => dispatch(showCalendar(false)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentCalendarModal);

StudentCalendarModal.propTypes = {
  showCalendar: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  simulation: PropTypes.object,
}
