import okanoganLogo from "../../assets/branding/Okanogan Logo.png";
import omakLogo from "../../assets/branding/Omak Logo.png";
import hoquiamLogo from "../../assets/branding/Hoquiam Logo.png";

const simBranding = {
  // Keyed by Sim ID. This is the `_id` field, not `id`.
  // Logo may either be empty, a string, or an array of strings.
  "65e1f2e85db2d9000858733e": {
    logo: [okanoganLogo, omakLogo],
  },
  "661578befd67030008802132": {
    logo: hoquiamLogo,
  },
};

export default simBranding;
