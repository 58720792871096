import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryTheme, VictoryPortal, VictoryContainer} from 'victory';
import PercentDiff from '../../components/PercentDiff';
import PropTypes from 'prop-types';
import formatter from '../../helpers/graphNumberFormat';

const graphWidth = '80%';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    margin: 'auto',
  },
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
  },
  noDataTitle: {
    color: '#aeaeae',
    position: 'absolute',
    top: '25%',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      top: '15%',
    },
  },
}));

const ProfitLoss = (props) => {
  const classes = useStyles();
  const vitalsHistory = props.coresim[props.teamIndex].vitalsHistory;
  const plData = [];

  vitalsHistory.map((income, i) => {
    if (i === 0) {
      return '';
    }
    plData.push({
      x: `Q${i}`,
      y: income.revenue.toFixed() - income.expenses.toFixed(),
    });
    return '';
  });

  const latest = vitalsHistory[vitalsHistory.length - 1].revenue - vitalsHistory[vitalsHistory.length - 1].expenses;
  const previous = vitalsHistory.length > 1 ? vitalsHistory[vitalsHistory.length - 2].revenue - vitalsHistory[vitalsHistory.length - 2].expenses : latest;


  const minY = Math.min.apply(Math, plData.map((data) => {
    return data.y;
  }));
  const maxY = Math.max.apply(Math, plData.map((y) => {
    return y.y;
  }));

  const curVal = new Intl.NumberFormat(undefined, {style: 'currency', currency: 'USD'}).format(latest);

  return (
    (plData.length === 0 ?
      <div className={(classes.divStyle, classes.class)} style={{position: 'relative'}}>

        <Typography className={classes.titleStyle}>Profit Loss</Typography>
        <Typography align='center' className={classes.noDataTitle}>
          Charts Available after Q1
        </Typography>

        <svg style={{transform: 'rotate(90deg)', width: '100%', height: 'auto', color: 'rgba(0,0,0,.1)'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M2,5H10V2H12V22H10V18H6V15H10V13H4V10H10V8H2V5M14,5H17V8H14V5M14,10H19V13H14V10M14,15H22V18H14V15Z" />
        </svg>
      </div> :
      <div className={classnames(classes.divStyle, classes.class)}>
        <div>
          <Typography className={classes.titleStyle}>{`Profit & Loss`}</Typography>
          <Typography >{curVal}</Typography>
          <PercentDiff previous={previous} latest={latest} />

          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={30}
            containerComponent={<VictoryContainer maxWidth={graphWidth} />}
          >
            <VictoryPortal>
              <VictoryAxis
                tickValues={plData.map((x, i) => i)}
                tickFormat={plData.map((x) => x.x)}

              />
            </VictoryPortal>
            <VictoryAxis
              dependentAxis
              tickFormat={(x) => (formatter(x, 0))}
            />

            <VictoryBar
              barRatio={1}
              style={
                {
                  data: {fill: '#ff9900'},
                  labels: {
                    fill: '#0a0a0a',
                  },
                }}
              data={plData}
              domain={{x: [0, plData.length], y: [plData.length === 1 ? 0 : minY, maxY]}}
              labels={({datum}) => formatter(datum.y, 1)}
              labelComponent={<VictoryLabel labelPlacement='parallel' renderInPortal />}
              x='x'
              y='y'
            />
          </VictoryChart>
        </div>
      </div>
    )
  );
};

ProfitLoss.propTypes = {
  coresim: PropTypes.array,
  classes: PropTypes.object,
  teamIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim.teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitLoss);
