import React, {Component} from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Auth, API} from 'aws-amplify';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getPreload} from './coresim/state/index';
import {bindActionCreators} from 'redux';
import {showWelcome, showQEnding, showPriceVolume} from './coresim/reducers/settings';
import {startOver} from './coresim/reducers/decisions';
import {resetState} from './coresim/reducers';
import {getTeamIndexAction} from './coresim/reducers/teamIndex';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingModal from './components/LoadingModal';
import Dialog from '@material-ui/core/Dialog';
import LogRocket from 'logrocket';
import ErrorPage from './containers/Error';
import {version} from '../package.json';
import moment from 'moment';
import Transition from './components/ModalTransition';
import {clearDefaultCache} from './helpers/cachedAPIRequests';
import LogoutPage from './containers/Logout';
import UserContextProvider from './providers/userProvider';
import RoleBasedRoutesSelector from './RoleBasedRoutesSelector';
import AuthRoutes from './AuthRoutes';
import {beforeFirstQuarter} from './helpers/canSubmitDecisions';

const initialState = {
  isAuthenticated: undefined,
  session: {},
  username: '',
  user: {},
  simulation: [],
  teamIndex: null,
  error: false,
  errorData: '',
  loading: true,
  loadingText: '',
  currentMinicase: {title: '', description: ''},
  miniNavDisabled: false,
  counter: 0,
  date: ''
};

const getTeamIndex = (user, simulation) => {
  let teamIndex;
  for(let i = 0; i < simulation.teams.length && !teamIndex; i++) {
    for(let u = 0; u < simulation.teams[i].members.length && !teamIndex; u++) {
      if (simulation.teams[i].members[u]._id === user._id) {
        teamIndex = i;
      }
    }
  }

  return teamIndex;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  handleEnableMiniNavRightChevron = (active) => {
    this.setState({miniNavDisabled: active});
  };

  handleClickOpen = (text) => {
    this.setState({loading: true, loadingText: text});
  };

  async componentDidMount() {
    // This log below is for us, to check that the deployed version online is up to date and that previous version was not cached by service worker
    // eslint-disable-next-line no-console
    console.log(`WBW V${version} `)
    // eslint-disable-next-line no-constant-condition
    if (false) {
      LogRocket.init('evdhkf/wbw');
    }
    await this.loadUser();
  }

  onSimError = (user) => (e) => {
    const capitalizeName = user.firstName.replace(/^./, str => str.toUpperCase());
    let message = '';

    switch (true) {
      case user.role === 'student' || user.role === 'advisor':
        message = `Hello ${capitalizeName}! Congratulations on successfully accessing your WBW account. Once your chosen WBW program starts, you will be all set to begin making decisions in the simulated marketplace. Until then, good luck and see you at camp!`;
        break;
      case user.role === 'chair':
        message = `Hello ${capitalizeName}, we noticed you have not been assigned to a sim as a chair of the week yet. Please reach out to admin.`
        break;
      default:
        message = 'Your assigned role is not supported.'
    }

    this.setState({
      error: true,
      errorData: message,
      loading: false});
    return;
  };

  closeSocket = () => {};
  establishWebSocket = (user) => {
    let refreshSocket = false;
    let ws = new WebSocket(process.env.REACT_APP_WS_ENDPOINT);
    ws.onopen = () => {
      refreshSocket = true;

      this.closeSocket = () => {
        refreshSocket = false;
        this.closeSocket = () => {};

        try {
          ws && ws.close();
        } finally {
          ws = null;
        }
      }

      if (user.role === 'student' || user.role === 'advisor') {

        API.get('mongo', `/users/simulation/${user._id}`)
          .then( async (simulations) => {

            if (!simulations || !simulations.length){
              throw new Error('no simulation found');
            }

            const sortSim = (first, second) => (second.lastModifiedDate || 0)
              - (first.lastModifiedDate || 0);

            simulations.sort(sortSim);

            const teamIndex = getTeamIndex(user, simulations[0]);

            const body = {
              route: 'sub',
              data: {
                token: (await Auth.currentSession()).getIdToken().getJwtToken(),
                simId: simulations[0].id,
                teamId: simulations[0].teams[teamIndex].id,
              },
            };

            ws.send(JSON.stringify(body));
          })
          .catch((err) => {
            console.error('Simulation error: ', err);
            this.onSimError(user)(err);
          });
      } else if (user.role === 'chair') {
        API.get('mongo', `/chairs/simulation/${user._id}`)
          .then(async (simulations) => {

            if (!simulations || !simulations.length){
              throw new Error('no simulation found');
            }
            const sortSim = (first, second) => (second.lastModifiedDate || 0)
              - (first.lastModifiedDate || 0);

            simulations.sort(sortSim)
            const body = {
              route: 'sub',
              data: {
                token: (await Auth.currentSession()).getIdToken().getJwtToken(),
                simId: simulations[0].id,
                teamId: -1,
              },
            };
            ws.send(JSON.stringify(body));
          })
          .catch((err) => {
            console.error('Simulation error: ', err);
            this.onSimError(user)(err);
          });
      }
    };

    ws.onclose = () => {
      ws = null; // cleanup last socket

      if (refreshSocket) {
        // Set an interval to continue trying to reconnect
        // periodically until we succeed.
        setTimeout(() => this.establishWebSocket(user), 5000);
      }
    };

    ws.onmessage = (message) => {
      if (user.role === 'admin') {
        // eslint-disable-next-line no-console
        console.error('Admins should not be subscribed to update events');
        return;
      }

      const data = JSON.parse(message.data);

      if (data.type === 'error') {
        toast.error('An error has occurred');
      }

      if (data.type === 'refresh' || data.type === 'subscribed') {
        if (user.role === 'student' || user.role === 'advisor') {
          this.loadSim(user);
        } else if (user.role === 'chair') {
          this.loadChairSim(user);
        }
      }
    };
  }

  // load in simulation for the "Chair of the Week" user
  loadChairSim = async (user) => {
    let simulation;
    const sortSim = (first, second) => (second.lastModifiedDate || 0)
    - (first.lastModifiedDate || 0);

    try {
      const simulations = await API.get('mongo', `/chairs/simulation/${user._id}`);
      if (!simulations || !simulations.length) {
        throw new Error('no simulation found');
      }
      simulations.sort(sortSim)
      simulation = simulations[0];
    } catch (e) {
      this.onSimError(user)(e);
    }
    // calculate which index in the teams array inside the sim object is yours
    let teamIndex = 0;

    // console.log('Team index:', teamIndex)

    // some logic to check whether or not to open a minicase alert

    let currentMinicase;

    if (simulation.completed || simulation.quarters[simulation.quarter.counter] === undefined) {
      this.props.showQEnding(true);
    } else if (simulation.quarters[simulation.quarter.counter] === undefined && this.state.user.role === 'chair') {
      //do nothing
    } else {
      currentMinicase = simulation.quarters[simulation.quarter.counter].minicase;
      this.setState({currentMinicase: currentMinicase});
    }

    this.setState({simulation: simulation, teamIndex: teamIndex, counter: simulation.quarter.counter});

    // quarter is persisted, so let's check our persisted state against current state
    if (simulation.quarter.counter !== this.props.quarter && this.state.user.role !== 'admin') {
      this.props.startOver();
    }

    this.props.getPreload(simulation);
    this.props.getTeamIndexAction(teamIndex);
    this.setState({loading: false});

  }

  loadSim = async (user) => {
    let simulation;
    const sortSim = (first, second) => (second.lastModifiedDate || 0)
    - (first.lastModifiedDate || 0);

    try {
      const simulations = await API.get('mongo', `/users/simulation/${user._id}`);

      if (!simulations || !simulations.length){
        throw new Error('no simulation found');
      }

      simulations.sort(sortSim)
      simulation = simulations[0];
      // check to make sure the first quarter has actually started
      if (beforeFirstQuarter(simulation.quarter.counter, simulation.quarters[0])) {
        this.setState({
          error: true,
          errorData: 'The first quarter has not started yet - we will see you '+
            `${moment(simulation.quarters[0].start).fromNow()}!`,
          loading: false});
        return;
      }
    } catch (e) {
      this.onSimError(user)(e);
    }

    // calculate which index in the teams array inside the sim object is yours
    let teamIndex = getTeamIndex(user, simulation);

    if (teamIndex === undefined) {
      //theoretically impossible, but just in case
      // eslint-disable-next-line no-console
      console.error('No team found');
      toast.error('An error occurred finding your team, contact an administrator');
      Auth.signOut();
      this.handleLogout();
      return;
    }

    if (simulation.teams[teamIndex].configuration.name === undefined) {
      this.props.showPriceVolume(true);
    } else {
      this.props.showPriceVolume(false);
    }

    // some logic to check whether or not to open a minicase alert
    let currentMinicase;
    if (simulation.completed || simulation.quarters[simulation.quarter.counter] === undefined) {
      this.props.showQEnding(true);
      this.props.showWelcome(false);
    } else {
      currentMinicase = simulation.quarters[simulation.quarter.counter].minicase;
      this.setState({currentMinicase: currentMinicase});
    }

    let date = moment().add(1, 'y').dayOfYear(4);
    this.setState({simulation: simulation, teamIndex: teamIndex, counter: simulation.quarter.counter, date: date});

    // quarter is persisted, so let's check our persisted state against current state
    if (simulation.quarter.counter !== this.props.quarter && this.state.user.role !== 'admin') {
      this.props.startOver();
    }

    if (currentMinicase !== null && simulation.teams[teamIndex].decisions.decisionsComplete !== true &&
      simulation.quarter.counter !== this.props.quarter &&
      this.state.user.role !== 'admin' && !simulation.completed ) {
      this.props.showWelcome(true);
    }

    this.props.getPreload(simulation);
    this.props.getTeamIndexAction(teamIndex);
    this.setState({loading: false});
  }

  loadUser = async () => {
    this.setState({loading: true});
    try {
      const session = await Auth.currentSession();
      this.setState({username: session.idToken.payload.sub});
      const username = session.idToken.payload.sub;
      const userInfo = await API.get('mongo', `/users/${username}`);
      // eslint-disable-next-line no-constant-condition
      if (false) {
        await LogRocket.identify(session.idToken.payload.sub, {
          name: `${userInfo[0].firstName} ${userInfo[0].lastName}`,
          email: `${userInfo[0].email}`,
        });
      }

      if (userInfo[0] === undefined) {
        this.setState({loading: false});
        return;
      }

      this.setState({user: userInfo[0], isAuthenticated: true});

      if (userInfo[0].role === 'admin') {
        this.setState({loading: false});
      } else {
        this.establishWebSocket(userInfo[0]);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('App e: ', e);


      if (e !== 'No current user') {
        toast.error('an error occurred.');
      }

      this.handleLogout();
    }
  }

  handleLogout = () => {
    this.closeSocket();
    this.setState({...initialState, loading: false});
    this.props.resetState();
    clearDefaultCache();
  }

  onLogin = async () => {
    await this.loadUser();
  }

  handleRetry = () => {
    this.setState({error: false})
    this.onLogin();
  }

  render() {
    const {settings} = this.props;
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      handleLogout: this.handleLogout,
      teamIndex: this.state.teamIndex,
      sim: this.state.simulation,
      handleClickOpen: this.handleClickOpen,
      handleClose: this.handleClose,
      handleEnableMiniNavRightChevron: this.handleEnableMiniNavRightChevron,
      miniNavDisabled: this.state.miniNavDisabled,
      updateConfig: this.updateConfig,
      onLogin: this.onLogin,
    };


    return (
      <MuiThemeProvider theme={settings.theme}>
        <CssBaseline />
        <UserContextProvider user={this.state.user}>
          <Router>
            <Switch>
              <Route
                path='/logout'
                render={props =>
                  this.state.loading ? this.setState({loading: false}): (
                    <LogoutPage {...props} handleLogout={this.handleLogout}/>)}
                props={childProps}
                exact
              />
              {(() => { // https://react-cn.github.io/react/tips/if-else-in-JSX.html
                if (this.state.error) {
                  return <ErrorPage
                    onLogin={this.handleRetry}
                    handleLogout={this.handleLogout}
                    childProps={childProps}
                    errorData={this.state.errorData}/>
                } else if (!this.state.loading) {
                  return this.state.isAuthenticated ?
                    <RoleBasedRoutesSelector childProps={childProps} role={this.state.user.role}/> :
                    <AuthRoutes childProps={childProps}/>
                } else {
                  // don't return any routes if all the data hasn't come in yet
                  return null
                }
              })()}
            </Switch>

            <Dialog
              style={{
                margin: 'auto',
              }}
              open={this.state.loading}
              TransitionComponent={Transition}
              keepMounted
              disableEscapeKeyDown={true}
              fullScreen={true}
              fullWidth={true}
              disableBackdropClick={true}
              hideBackdrop={false}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'>
              <LoadingModal handleLogout={this.handleLogout} />
            </Dialog>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />

            <Link to="/setup/quantity">
              <div id="init"></div>
            </Link>
          </Router>
        </UserContextProvider>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPreload: (data) => getPreload(data),
      getTeamIndexAction: (index) => getTeamIndexAction(index),
      startOver: (data) => startOver(data),
      showWelcome,
      showQEnding,
      showPriceVolume,
      resetState,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    quarter: state.coresim.quarter.counter
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
  getPreload: PropTypes.func.isRequired,
  startOver: PropTypes.func.isRequired,
  getTeamIndexAction: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    theme: PropTypes.any,
  }),
  quarter: PropTypes.number.isRequired,
  showWelcome: PropTypes.func.isRequired,
  showQEnding: PropTypes.func.isRequired,
  showPriceVolume: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
};
