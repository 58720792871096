import {combineReducers} from 'redux';
import stepCounterReducer, {increment} from '../reducers/stepCounter';
import combineReducerArray, {
  getModifyAction,
} from './combineReducerArray';
import team, {getTeamTickAction} from './team';
import configuration from './configuration';

const TICK = 'root/TICK';
const PRELOAD = 'root/PRELOAD';
const teamReducerPrefix = 'team';

const combineTeamArrayReducer = combineReducerArray(teamReducerPrefix)(team);

const combined = combineReducers({
  quarter: stepCounterReducer,
  configuration,
  teams: combineTeamArrayReducer,
});

export default (state = getDefaultState(), action) => {
  // export default (state = []], action) => {
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case TICK:
      return {
        ...state,
        quarter: stepCounterReducer(state.quarter, increment()),
        teams: state.teams.map((v) => team(v, getTeamTickAction())),
      };
    case PRELOAD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return combined(state, action);
  }
};


const getDefaultState = () => {
  const state = combined(undefined, {});
  return state;
};

export function getTickAction() {
  return {
    type: TICK,
  };
}

export function getPreload(data) {
  return {
    type: PRELOAD,
    payload: data,
  };
}

export function getTeamAction(teamId, nestedAction) {
  return getModifyAction(teamReducerPrefix)(teamId)(nestedAction);
}
