import React from "react";
import {Box, Grid, Typography, Avatar, Divider} from '@material-ui/core';
import PropTypes from 'prop-types';
import ViewInBlackboard from '../ViewInBlackboard';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    margin: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    }
  },
  avatar: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  externalLink: {
    display: "flex",
    alignItems: "center"
  }
}));

const AnnouncementItem = (props) => {
  const classes = useStyles();

  return (<Box mt={3}>
    <Grid container justify="space-around" className={classes.item}>
      <Grid item>
        <Avatar className={classes.avatar}>{props.item.creator.firstName.charAt(0)}{props.item.creator.lastName.charAt(0)}</Avatar>
      </Grid>
      <Grid item xs={10}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography variant="body1" style={{fontWeight: "bold"}}>
              {props.item.creator.firstName} {props.item.creator.lastName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {moment(props.item.created).format('M/D/YYYY, h:mm A')}
            </Typography>
          </Grid>
        </Grid>
        <Box my={1}>
          <Typography variant="body2" color="textSecondary">
            {props.item.title}
          </Typography>
        </Box>
        <Divider variant="fullWidth"/>
        <Grid container justify="flex-end" className={classes.actionButtons}>
          <ViewInBlackboard externalUrl={props.item.externalUrl}/>
        </Grid>
      </Grid>
    </Grid>
  </Box>)
}

AnnouncementItem.propTypes = {
  item: PropTypes.shape({
    creator: PropTypes.object.isRequired,
    created: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    externalUrl: PropTypes.string.isRequired,
  }),
};

export default AnnouncementItem
