import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    borderRadius: '3px',
    paddingBottom: 40,
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingRight: 10,
    paddingTop: 10,
  },
  title: {fontSize: 18, paddingTop: 10, paddingBottom: 30},
  header: {fontWeight: 'bold', paddingBottom: 10, fontSize: 18},
};

const SentimentCard = (props) => {
  let description;

  let boardFeeling;
  if (props.score > 0.85) {
    boardFeeling = 'Your board thinks you are the best!';
  } else if (props.score > 0.7 && props.score <= 0.85) {
    boardFeeling =
      'The board is very pleased with your work thus far. It is not easy running a company, but you are pulling it off with aplomb.';
  } else if (props.score > 0.5 && props.score <= 0.7) {
    boardFeeling =
      'You are not doing a bad job, but you are not exactly excelling. They would like to see higher growth and higher shareholder dividend returns, or they may have to take action down the road.';
  } else if (props.score > 0.25 && props.score <= 0.5) {
    boardFeeling =
      'You are in the hot seat. If things do not change soon, you can expect the board to begin succession planning.';
  } else if (props.score <= 0.25) {
    boardFeeling =
      'If you do not drastically improve your performance  next quarter, you will be fired.';
  }

  let publicFeeling;
  if (props.score > 0.85) {
    publicFeeling = 'Your product is the best thing since sliced bread!';
  } else if (props.score > 0.7 && props.score <= 0.85) {
    publicFeeling =
      'You are a leader in the industry and others look to copy what you are doing.';
  } else if (props.score > 0.5 && props.score <= 0.7) {
    publicFeeling =
      'Your product is better than your competitors, and customer satisfaction is relatively high. While you are not the leader in your industry, you certainly are not a bottom feeder.';
  } else if (props.score > 0.25 && props.score <= 0.5) {
    publicFeeling =
      'Mediocrity is your middle name, and the public is not responding well to your products. Your employees are not excited to work for a mediocre company, and sales are sluggish.';
  } else if (props.score <= 0.25) {
    publicFeeling =
      'Your product is receiving incredibly poor reviews from the public with rumors about your company folding.';
  }

  props.type === 'board' ?
    (description = boardFeeling) :
    (description = publicFeeling);
  return (
    <div style={styles.class} className="block">
      <div style={styles.flex}>
        <h4 style={styles.title}>{props.title}</h4>
        <img
          alt="Sentiment"
          src={props.image}
          height="90"
          width="80"
        />
      </div>
      <p style={styles.header}>
        {props.header} ({Math.trunc(props.score * 100)})
      </p>
      <p style={{marginRight: 14}}>{description}</p>
    </div>
  );
};

export default SentimentCard;

SentimentCard.propTypes = {
  score: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.string,
  image: PropTypes.string,
};
