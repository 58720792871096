import React, {createContext, useContext, useReducer} from 'react'

const initialState = {userCsv: null, parsedUserCsv: null, errorCsv: null, overrideTeam: false, preexistingTeams: []}

export const actionTypes = {
  ADD_GROUPED_TEAM_USERS: 2,
  ADD_ERROR_CSV: 3,
  CLEAR: 4,
  ADD_USER_CSV: 5,
  SET_TEAM_OVERRIDE: 6,
  SET_PREEXISTING_TEAMS: 7,
}

/**
 * action is:
 * {type: one of the action types, payload: unstructured object to complete said action}
 *
 */

const bulkUploadReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_ERROR_CSV:
      return {...state, errorCsv: action.payload}
    case actionTypes.ADD_GROUPED_TEAM_USERS:
      return {...state, groupedUsers: action.payload}
    case actionTypes.ADD_USER_CSV:
      return {...state, userCsv: action.payload}
    case actionTypes.SET_TEAM_OVERRIDE:
      return {...state, overrideTeam: action.payload}
    case actionTypes.SET_PREEXISTING_TEAMS:
      return {...state, preexistingTeams: action.payload}
    case actionTypes.CLEAR:
      return initialState
    default:
      return state
  }
}

const BulkUploadContext = createContext(null)

export const BulkUploadProvider = ({children}) => {
  const [state, dispatch] = useReducer(bulkUploadReducer, initialState)
  return <BulkUploadContext.Provider value={{state, dispatch}}>{children}</BulkUploadContext.Provider>
}

export const useBulkUploadProvider = () => {
  const context = useContext(BulkUploadContext);
  if (context === undefined) {
    throw new Error('useBulkUploadProvider must be used within BulkUploadProvider')
  }
  return context
}
