import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import ViewSim from "../../chair/ViewSim";
import { useParams } from "react-router";
import {toast} from 'react-toastify';

function AdminViewSim () {
  const [loading, setLoading] = useState(true);
  const [coresim, setCoresim] = useState({});
  const {id} = useParams();

  const load = async (abortObj) => {
    try {
      const result = await API.get('mongo', `/sims/${id}`);
      if (!abortObj || !abortObj.aborted) {
        setCoresim(result)
      }

    } catch (e) {
      if (e.response && e.response.status === 404) {
        toast.error("Simulation not found");
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let abortObj = {aborted: false};
    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  return(
    <div>
      {loading ? null : (

        <ViewSim coresim={coresim} quarter={coresim.quarter.counter} admin={true} reloadAdmin={load} />
      )}
    </div>
  )
}

export default AdminViewSim;
