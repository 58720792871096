import React, {useState} from 'react';
import ConfigurationList from './ConfigurationList';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateConfiguration from './CreateConfiguration';
import EditConfiguration from './EditConfiguration';
import StackPanel from '../../routing/StackPanel';

const ConfigurationAdmin = () => {
  const [reload, setReload] = useState(new Date())
  const match = useRouteMatch();

  const onReload = () => {
    setReload(new Date())
  }

  return (
    <AdminPanel label='Configurations' >
      <ConfigurationList reload={reload} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create Configuration'
        >
          <StackPanel>
            <CreateConfiguration onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit Configuration'
        >
          <StackPanel>
            <EditConfiguration onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  )
}

export default ConfigurationAdmin;
