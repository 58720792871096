import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import StockPrice from '../Graphs/StockPrice';
import PublicSentiment from '../Graphs/PublicSentiment';
import BoardSentiment from '../Graphs/BoardSentiment';
import ProfitLoss from '../Graphs/ProfitLoss';
import Factory from '../Graphs/Factory';
import NewsArticle from '../Graphs/NewsArticle';
import { makeStyles, Grid } from "@material-ui/core";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1280},
    items: 3
  },
  desktop: {
    breakpoint: { max: 1279, min: 1025 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 775 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 774, min: 600 },
    items: 1
  },
  inbetween: {
    breakpoint: {max: 599, min: 500 },
    items: 2
  },
  atEnd: {
    breakpoint: {max: 499, min: 0 },
    items: 1
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  graphStyle: {
    margin: 'auto',
    position: 'relative',
    height: '100%',
    overflow: 'inherit',
  },
  buttonGroup: {

  }
}));

const HomeCarousel = (props) => {
  const classes = useStyles();

  let hasStory = false;
  if (!props.coresim.completed && props.coresim.quarters[props.quarter].article){
    hasStory = true;
  }

  const CustomButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <Grid item className={classes.buttonGroup} container justify='space-between'>
        <Grid item style={{display: 'inline-block'}}>
          <KeyboardArrowLeft style={{fontSize: '3rem', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer'}} className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}/>
          <span style={{cursor: 'pointer'}} onClick={() => previous()}>Prev</span>
        </Grid>
        <Grid item style={{display: 'inline-block'}} >
          <span style={{cursor: 'pointer'}} onClick={() => next()}>Next</span>
          <KeyboardArrowRight style={{fontSize: '3rem', display: 'inline-block', verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => next()} />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Carousel
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroup/>}
        className={classes.graphStyle}
        infinite={true}
        responsive={responsive}>

        { hasStory &&
            <NewsArticle data={props.quarters[props.quarter].article}/>
        }
        <StockPrice teamIndex={props.teamIndex} />
        <ProfitLoss teamIndex={props.teamIndex} />
        <PublicSentiment teamIndex={props.teamIndex} />
        <BoardSentiment teamIndex={props.teamIndex} />
        <Factory teamIndex={props.teamIndex} />
      </Carousel>
    </div>
  );
};

HomeCarousel.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  goToSlide: PropTypes.func,
  teamIndex: PropTypes.number.isRequired,
  coresim: PropTypes.array.isRequired,
  quarter: PropTypes.number.isRequired,
  quarters: PropTypes.array
}

export default HomeCarousel;
