const getValue = (value) => value || 0;

const getBalanceSheet = (decHist, vitHist) => (quarter) => {
  if (quarter < 1 || quarter > decHist.length) {
    return undefined;
  }

  const vitals = vitHist[quarter];
  const decisions = decHist[quarter - 1];
  const minicaseOutputs = {};

  if (decisions && decisions.selectedMinicase &&
    decisions.selectedMinicase.outputs) {
    for(let output of decisions.selectedMinicase.outputs) {
      minicaseOutputs[output.outputMetric] = output.outputQuantity;
    }
  }

  return (
    {
      cash: getValue(vitals.cash),
      inventory: getValue(vitals.inventoryValuation),
      property: getValue(vitals.propertyValuation),
      acctRecv: getValue(vitals.revenue),
      loanLiability: getValue(vitals.liabilitiesBankLoan),
      loanPayment: getValue(vitals.loanPaymentApplied),
      prodDev: getValue(decisions.productDevelopment) +
        getValue(minicaseOutputs.productDevelopment),
      marketResearch: getValue(decisions.marketResearch) +
        getValue(minicaseOutputs.marketResearch),
      advertising: getValue(decisions.advertising) +
        getValue(minicaseOutputs.advertising),
      qualityManagement: getValue(decisions.qualityManagement) +
        getValue(minicaseOutputs.qualityManagement),
      dividends: getValue(decisions.dividends) +
        getValue(minicaseOutputs.dividends),
      otherExpenses: getValue(minicaseOutputs.otherExpenses),
      personnel: getValue(vitals.staffCost),
      productionCost: getValue(vitals.productionCost),
      stockPrice: getValue(vitals.stock),
      valuation: getValue(vitals.valuation),
      totExpenses: getValue(vitals.expenses),
      get totAssets() {return this.cash + this.inventory + this.property},
      get totLiabilities() {return this.totExpenses + this.loanLiability},
      get netCashFlow() {return this.acctRecv - this.totExpenses}
    }
  );
};

export default getBalanceSheet;
