import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BalanceSheetReport from './BalanceSheetReport';
import CurrentDecisions from './CurrentDecisions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  centering: {
    display: 'inline-block',
    margin: 'auto'
  },
}));

const BalanceSheetTabs = (props) => {
  const classes = useStyles();
  const currentQuarter = props.sim.quarter.counter - 1;
  const [value, setValue] = React.useState(currentQuarter);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(Math.min(props.sim.quarters.length - 1, currentQuarter))
  }, [currentQuarter]
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color='default'>
        <Tabs
          value={value}
          className={props.current ? classes.centering : ''}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          aria-label="simple tabs example"
        >
          {!props.current ? props.sim.teams[props.teamIndex].decisionHistory.map((decision, index) =>(
            <Tab key={index} label={`Quarter ${index + 1}`} {...a11yProps(index)}/>
          ))
            :
            <Tab label={`Current Submitted Decisions`} {...a11yProps(0)}/>
          }

        </Tabs>
      </AppBar>
      {!props.current ? props.sim.teams[props.teamIndex].decisionHistory.map((decision, index) =>(
        <TabPanel key={index} value={value} index={index}>
          <BalanceSheetReport quarter={index} {...props}/>
        </TabPanel>
      ))
        :
        <TabPanel value={0} index={0}>
          <CurrentDecisions quarter={props.sim.quarter.counter + 1} {...props}/>
        </TabPanel>
      }
    </div>
  );
}

BalanceSheetTabs.propTypes = {
  sim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  current: PropTypes.bool
}

export default BalanceSheetTabs;
