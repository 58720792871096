const priceVolumeData = {
  quantity: {
    title: 'Quantity',
    overview: 'This is the overview of the quantity option - it\'s not a very good option, but it does give you a lot of flexibility moving forward in making your own decisions.',
    pros: [
      'You can pick and choose future upgrades as you wish, creating a highly customized product.',
      'You start with more available money for producing additional units, as well as a cheaper per unit manufacturing cost.',
      'Easier to serve international markets.',
      'In general, with more money available at the start, you are free to spend more on advertising, market research, plant capacity, etc.',
    ],
    cons: [
      'The starting consumer demand for your product will be lower, considering your lack of features that competitors provide.',
      'The price you can charge on your product is limited, considering the features and consumer perception of your product. Your margins per sale are smaller.',
      'Your intellectual property is less valuable, and your company is seen as less prestigious.',
      'Your competition has significantly better products.',
    ],
  },
  quality: {
    title: 'Quality',
    overview: 'As the old saying goes, you need to spend money to make money. In a landscape where competition is fierce, you are choosing to invest heavily in research and development to create a product that stands out from the competition. However, this up-front investment does constrain your future actions to a certain degree.',
    pros: [
      'Your product already includes some of the most in-demand features on the market, including a heart-rate sensor and early morning vibrating alarm clock. ',
      'Your product starts off higher demand from the market, due to the reputation and hype that you have created from research and development, as well as marketing efforts. ',
      'You are potentially able to get away with charging a high price for your product.',
    ],
    cons: [
      'After investing so much money into developing your product, you have less money to spend on upgrading your product and creating additional units - you need to turn a profit, and quick. Otherwise, you may be forced into going deeper into debt.',
      'You have spent a lot in developing your product, but some of the features chosen probably were not necessary to achieve high growth. "Nice to haves", not "need to haves".',
      'You have accumulated a bit of debt, having spent a lot of time into building a product and not fully committing to selling it.',
    ],
  },
  undecided: {
    title: 'Standard',
    overview: 'Goldilocks is your business persona - you have taken practical, pragmatic moves in developing your product but have made some rather poor marketing decisions as your product does not have a killer feature to speak of.',
    pros: [
      'You have the essentials of a product, including a heart rate monitor, but have spent a lot of money on advertising so far that has not yielded much success. Your new VP of Marketing is confident they can turn the ship around, but it will require a lot of investment in advertising outreach.',
      'You have not accumulated any debt, which is nice.',
      'Easier to serve international markets.',
      'In general, with more money available at the start, you are free to spend more on advertising, market research, plant capacity, etc.',
    ],
    cons: [
      'The starting consumer demand for your product will be lower, considering your lack of features that competitors provide.',
      'The price you can charge on your product is limited, considering the features and consumer perception of your product. Your margins per sale are smaller.',
      'Your company is seen as so-so, with some in the tech press describing your firm as "running on the treadmill of mediocrity". ',
      'Your competition has better products, with more hype. Some of your competition has inferior products, but a higher margin for each sale.',
    ],
  },
};

export default priceVolumeData;
