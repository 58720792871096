import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {increment, decrement} from '../../coresim/reducers/stepCounter';
import {withStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MiniCaseAnswers from './MiniCaseAnswer';
import {useUserContext} from '../../providers/userProvider';
import {simSubmitState} from '../../helpers/canSubmitDecisions';
import WarningBox from '../WarningBox';
import {
  Divider,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Tooltip,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  grid: {
    margin: '.5rem auto ',
    minWidth: '100%',
  },
  card: {
    background: 'white',
    color: 'black',
    border: '2px solid e6e6e6',
    padding: 10,
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    borderRadius: '3px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    margin: 'auto',
  },
  title: {
    fontSize: 18,
    padding: '5px 15px',
    backgroundColor: '#ffe066',
    width: '40vw',
    height: 'auto',
  },
  header: {fontWeight: 'bold', fontSize: 18, marginBottom: '1rem'},
  selectedCard: {
    background: '#e6e6e6',
  },
};

const MiniCase = (props) => {
  const user = useUserContext();
  const {classes} = props;
  const currentMinicase = !props.sim.completed && props.sim.quarters[props.sim.quarter.counter].minicase;
  const team = props.sim.teams[props.teamIndex];
  const teamDecision = team.decisions.selectedMinicase.title;
  const submitState = simSubmitState({user, activeQuarter: props.activeQuarter, completed: props.completed});
  return (
    <React.Fragment>
      {currentMinicase ? (
        <React.Fragment>
          {!submitState.canSubmit() &&
          <WarningBox>
            {submitState.closedReason()}
          </WarningBox>
          }
          <Grid item xs={12} sm={12} md={12} className={classes.grid}>
            <Box component='div' className={classes.flex}>
              <Typography variant='h3' className={classes.header}>
                {currentMinicase.miniCase}
              </Typography>
              <Divider light style={{width: '10vw'}} />
              <br />
              <Typography variant='body1'>
                {currentMinicase.description}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} className={classes.grid}>
            <Box
              component='div'
              className={classes.flex}
              style={{ margin: 'auto' }}
            >
              {!teamDecision && (
                <Typography variant='h4' className={classes.title}>
                  Choose the Best Answer
                </Typography>
              )}
              {currentMinicase.options.map((v, i) => (
                <MiniCaseAnswers
                  rightchevrondisable={props.handleEnableMiniNavRightChevron}
                  key={i}
                  teamDecision={teamDecision}
                  decision={v}
                  classes={classes}
                  disabled={!submitState.canSubmit()}
                />
              ))}
            </Box>
          </Grid>
        </React.Fragment>
      ) : (
        <p style={{ fontSize: 24 }}>There is no minicase assigned.</p>
      )}

      <Typography variant='h4' gutterBottom style={{ marginTop: '3rem' }}>
        Previous Mini-Cases
      </Typography>
      {props.sim.quarters.map((quarter, i) => {
        // Only show previous minicases
        if (i >= props.sim.quarter.counter) return null;

        const minicase = quarter.minicase;
        if (!minicase) return null; // No mini-case exists for this quarter

        const quarterDecision = team.decisionHistory[i];
        const complete = quarterDecision && quarterDecision.minicaseComplete;

        const selected = quarterDecision && quarterDecision.selectedMinicase;
        const justification =
          quarterDecision && quarterDecision.minicaseJustification;

        return (
          <ExpansionPanel key={i}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>
                Q{i + 1}: {minicase.miniCase}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div>
                <Typography variant='body1'>{minicase.description}</Typography>

                <br />

                <Grid container spacing={2} alignItems='stretch'>
                  {minicase.options.map((option, j) => (
                    <Grid item key={j} md>
                      <Paper
                        className={classes.card}
                        style={{
                          flexDirection: 'column',
                          height: '100%',
                          background:
                            selected.title === option.optionTitle
                              ? '#e6e6e6'
                              : 'white',
                        }}
                      >
                        <Typography variant='body1' gutterBottom>
                          {option.optionTitle}
                        </Typography>
                        <Typography variant='caption'>
                          {option.optionDescription}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>

                <br />
                <br />

                {complete ? (
                  <>
                    <Typography variant='body1'>
                      You chose{' '}
                      <Tooltip title={selected.content}>
                        <b>{selected.title}</b>
                      </Tooltip>
                    </Typography>
                    <Typography variant='caption'>
                      &quot;{justification}&quot;
                    </Typography>
                  </>
                ) : (
                  <Typography variant='body1'>
                    You did not complete this minicase.
                  </Typography>
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    stepCounter: state.stepCounter,
    activeQuarter: state.coresim.quarters[state.coresim.quarter.counter],
    completed: state.coresim.completed
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      increment: () => increment(),
      decrement: () => decrement(),
    },
    dispatch
  );
};

const homeStyled = withStyles(styles)(MiniCase);

export default connect(mapStateToProps, mapDispatchToProps)(homeStyled);

MiniCase.propTypes = {
  classes: PropTypes.object,
  sim: PropTypes.object,
  handleEnableMiniNavRightChevron: PropTypes.func,
  activeQuarter: PropTypes.shape({end: PropTypes.number}),
  completed: PropTypes.bool,
  teamIndex: PropTypes.number.isRequired
};
