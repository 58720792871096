import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, Typography, Grid, Divider} from '@material-ui/core';
import PropTypes from 'prop-types';
import formatCurrency from '../../helpers/formatCurrency';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    margin: 'auto'
  },
  topBottomBorder: {
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  tally: {
    width: '100%',
    minWidth: '6rem',
    textAlign: 'right',
    margin: '.5rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  subheader: {
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '10px 0'
    }
  },
  rowHeading: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  rowTitle: {
    paddingLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
    }
  },
  tally: {
    width: '100%',
    minWidth: '6rem',
    textAlign: 'right',
    margin: '.5rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  row: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: '10px'
    }
  },
  titleStyle: {
    fontWeight: 'bold',
  },
}));

const Row = ({title, quantity, currency}) => {
  const classes = useStyles();
  return(
    <div>
      <Grid container className={classes.row} direction='row' alignItems='center'>
        <Grid className={classes.rowTitle} item xs={12} sm={12} md='auto' lg='auto' style={{display: 'flex'}}>
          <Typography className={classes.titleStyle}>{title}</Typography>
        </Grid>
        <Grid item xs='auto' sm='auto' md='auto' lg='auto' style={{display: 'flex'}}>
          {currency ? <Typography className={classes.tally}>{formatCurrency(quantity)}</Typography>
            :
            <Typography className={classes.tally}>{quantity}</Typography>
          }
        </Grid>
      </Grid>
      <Divider/>
    </div>
  )
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  currency: PropTypes.bool.isRequired
}

const CurrentDecisions = (props) => {
  const classes = useStyles();

  const decisions = props.sim.teams[props.teamIndex].decisions;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Divider/>
        <Row title='Added Plant Capacity' quantity={decisions.addedPlantCapacity} currency={true}/>
        <Row title='Advertising' quantity={decisions.advertising} currency={true}/>
        <Row title='Change Name' quantity={decisions.changeName} currency={true}/>
        <Row title='Dividends' quantity={decisions.dividends} currency={true}/>
        <Row title='Loans Paid' quantity={decisions.loansPaid} currency={true}/>
        <Row title='Loans Taken' quantity={decisions.loansTaken} currency={true}/>
        <Row title='Market Research' quantity={decisions.marketResearch} currency={true}/>
        <Row title='Price' quantity={decisions.price} currency={true}/>
        <Row title='Product Development' quantity={decisions.productDevelopment} currency={true}/>
        <Row title='Quality Management' quantity={decisions.qualityManagement} currency={true}/>
        <Row title='Salespeople' quantity={decisions.salespeople} currency={false}/>
        <Row title='Units Produced' quantity={decisions.unitsProduced} currency={false}/>

      </CardContent>
    </Card>
  );
};

CurrentDecisions.propTypes = {
  sim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  quarter: PropTypes.number.isRequired
}

export default CurrentDecisions;
