
export const quotes = [
  {
    author: 'Henry Ford',
    quote: 'Whether you think you can, or you think you can\'t--you\'re right.”',
  },
  {
    author: 'Bill Gates',
    quote: 'Technology is unlocking the innate compassion we have for our fellow human beings',
  },
  {
    author: 'Muhammad Ali',
    quote: 'I hated every minute of training, but I said, \'Don\'t quit. Suffer now and live the rest of your life as a champion.',
  },
  {
    author: 'Maya Angelou',
    quote: 'Nothing will work unless you do.',
  },
  {
    quote: 'If you can\'t measure it, you can\'t improve it.',
    author: 'Peter Drucker',
  },
  {
    quote: 'We may encounter many defeats, but we must not be defeated',
    author: 'Maya Angelou',
  },
  {
    quote: 'Done is better than perfect.',
    author: 'Mark Zuckerberg',
  },
  {
    quote: 'Do what you can, with what you have, wherever you are.',
    author: 'Teddy Roosevelt',
  },
  {
    quote: 'To see what is right, and not do it, is a lack of courage.',
    author: 'Confucius',
  },
  {
    quote: 'The only way to do great work is to love what you do. If you haven\'t found it yet, keep looking. Don\'t settle.',
    author: 'Steve Jobs',
  },
  {
    quote: 'There are no limits to what you can accomplish, except the limits you place on your own thinking.',
    author: 'Brian Tracy',
  },
  {
    quote: 'Success is going from failure to failure without a loss of enthusiasm.',
    author: 'Winston Churchill',
  },
  {
    quote: 'What you do speaks so loudly, that I cannot hear what you say.',
    author: 'Ralph Waldo Emerson',
  },
  {
    quote: 'There is only one success - to spend your life in your own way.',
    author: 'Christopher Morley',
  },
  {
    quote: 'Don\'t count the days - make the days count.',
    author: 'Muhammad Ali',
  },
  {
    quote: 'You must not only aim your bow right, but draw your bow with all your might.',
    author: 'Henry David Thoreau',
  },
  {
    quote: 'Everything you have ever wanted, is on the other side of FEAR.',
    author: 'George Addair',
  },
  {
    quote: 'A year from now, you may wish you started today.',
    author: 'Karen Lamb',
  },
  {
    quote: 'It is never too late to be what you might have been.',
    author: 'George Eliot',
  },
  {
    quote: 'If there is no struggle, there is no progress.',
    author: 'Frederick Douglass',
  },
  {
    quote: 'What we fear most, is usually what we most need to do.',
    author: 'Tim Ferriss',
  },
  {
    quote: 'The harder I work, the luckier I get.',
    author: 'Gary Player',
  },
  {
    quote: 'Someday is not a day of the week.',
    author: 'Denise Brennan-Nelson',
  },
  {
    quote: 'If you can\'t outplay them, outwork them.',
    author: 'Ben Hogan',
  },
  {
    quote: 'If you have everything under control, you aren\'t moving fast enough.',
    author: 'Mario Andretti',
  },
  {
    quote: 'Action is the foundational key to all success.',
    author: 'Pablo Picasso',
  },
  {
    quote: 'Try not. Do. Or do not. There is no try.',
    author: 'Master Yoda',
  },
  {
    quote: 'Don\'t wish it was easier, wish you were better.',
    author: 'Jim Rohn',
  },
  {
    quote: 'Hard work beats talent when talent doesn\'t work hard.',
    author: 'Tim Notke',
  },
  {
    quote: 'It\'s hard to beat a person who never gives up.',
    author: 'Babe Ruth',
  },
  {
    quote: 'Never say never. Limits, like fears, are often just an illusion.',
    author: 'Michael Jordan',
  },
  {
    quote: 'You have to expect things of yourself before you do them.',
    author: 'Michael Jordan',
  },
  {
    quote: 'Winning isn\'t everything, but wanting to win is.',
    author: 'Vince Lombardi',
  },
  {
    quote: 'Most people never run far enough on their first wind to find out they\'ve got a second.',
    author: 'William James',
  },
  {
    quote: 'I\'ve failed over and over and over again in my life. And that is why I succeed.',
    author: 'Michael Jordan',
  },
  {
    quote: 'Nobody who ever gave their best regretted it.',
    author: 'George Halas',
  },
  {
    quote: 'Without self-discipline, success is impossible. Period.',
    author: 'Lou Holtz',
  },
  {
    quote: 'Make each day your masterpiece.',
    author: 'John Wooden',
  },
  {
    quote: 'If you aren\'t going all the way, why go at all?',
    author: 'Joe Namath',
  },
  {
    quote: 'Do not let what you cannot do interfere with what you can do.',
    author: 'John Wooden',
  },
  {
    quote: 'A champion is someone who get\'s up when they can\'t.',
    author: 'Jack Dempsey',
  },
  {
    quote: 'Winners never quit, and quitters never win.',
    author: 'Vince Lombardi',
  },
  {
    quote: 'It isn\'t the mountains ahead to climb that wear you out - it\'s the pebble in your shoes.',
    author: 'Muhammad Ali',
  },
  {
    quote: 'We are what we repeatedly do. Therefore, excellence is not an act - but a habit.',
    author: 'Socrates',
  },
  {
    quote: 'You\'re never a loser until you quit trying.',
    author: 'Mike Ditka',
  },
  {
    quote: 'Those who are not courageous enough to take risks will accomplish nothing.',
    author: 'Muhammad Ali',
  },
  {
    quote: 'To give anything but your best is to sacrifice a gift.',
    author: 'Steve Prefontaine',
  },
  {
    quote: 'Run when you can, walk if you have to, crawl when you must; just never give up.',
    author: 'Dean Karnazes',
  },
  {
    quote: 'When I race, my mind is filled with doubts: who will finish second? Who will finish third?',
    author: 'Noureddine Morceli',
  },
];

