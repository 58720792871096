import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {justification, minicaseComplete} from '../../coresim/reducers/decisions';
import {bindActionCreators} from 'redux';
import {getPreload} from '../../coresim/state/index';
import {connect} from 'react-redux';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import {TextField, Divider, Button, Dialog} from '@material-ui/core';
import PropTypes from 'prop-types';
import Progress from '../../components/Progress';
import ConfirmModal from '../../components/ConfirmModal';
import Transition from '../../components/ModalTransition';

const styles = {
  grid: {
    margin: '.5rem auto',
  },
  card: {
    background: 'white',
    color: 'black',
    border: '2px solid grey',
    padding: 10,

    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',

    borderRadius: '3px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    margin: 'auto',
  },
  title: {
    fontSize: 18,
    padding: '5px 15px',
    backgroundColor: '#ffe066',
    width: '40vw',
    height: 'auto',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: '1rem',
  },
  list: {
    display: 'block',
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: '40px',
  },
  li: {
    borderRadius: '50%',
    border: '1px solid black',
    width: '3vw',
    height: '3vw',
    position: 'relative',
    float: 'left',
    marginRight: '3vw',
  },
  selectedCard: {
    background: '#e6e6e6',
  },
  submit: {
    color: 'white',
  },
};

const Justification = (props) => {
  const {classes} = props;
  const [loading, setLoading] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const minicaseAnswered = props.decisions.selectedMinicase.title;
  const justificationAnswered = props.decisions.minicaseJustification;
  const teamSubmitted = props.sim.teams[props.teamIndex].decisions.selectedMinicase.title && props.sim.teams[props.teamIndex].decisions.minicaseJustification.length > 0;

  const checkForPreviousAnswers = (minicaseAnswered, justificationAnswered) => {

    if (minicaseAnswered && justificationAnswered.length > 0) {
      setIsAnswered(true);
    } else {
      setIsAnswered(false);
    }
  }

  useEffect(() => {

    checkForPreviousAnswers(minicaseAnswered, justificationAnswered);

  },[justificationAnswered])

  const handleChange = (event) => {
    props.justification(event.target.value);
  };

  const confirmSubmit = () => {
    setOpenDialog(!openDialog);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const updatedTeams = props.sim.teams.slice();
    const teamId = updatedTeams[props.teamIndex].id;
    const quarter = props.sim.quarter.counter;

    let trimJustification = props.decisions.minicaseJustification.trim();
    
    const body = {
      minicaseComplete: true,
      selectedMinicase: props.decisions.selectedMinicase,
      minicaseJustification: trimJustification,
    };

    updatedTeams[props.teamIndex].decisions = body;

    try {
      await API.put('state',
        `/simulations/${props.sim.id}/teams/${teamId}/decisions/${quarter}`,
        {body}
      );

      toast.success('Success!');
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
    props.history.push('/');
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} className={classes.grid}>
        <Box component='div' className={classes.flex}>
          <Typography variant='body2' className={classes.header}>
            Justify Your Response
          </Typography>
          <Divider light style={{width: '10vw'}} />
          <br />
          <Typography variant='body2'>
            In a few paragraphs explain why your team made this decision.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.grid}>
        <Box
          component='div'
          className={classes.flex}
          style={{margin: 'auto'}}>
          <Typography variant='h4' className={classes.title}>
            Explain your Answer
          </Typography>
          <TextField
            fullWidth={true}
            multiline={true}
            rows='4'
            variant='outlined'
            name='justification'
            value={props.sim.teams[props.teamIndex].decisions.minicaseJustification || props.decisions.minicaseJustification}
            style={{margin: '1rem auto'}}
            placeholder='Explain here...'
            onChange={handleChange}
          />
        </Box>
        <Button
          onClick={confirmSubmit}
          fullWidth
          type="submit"
          variant="contained"
          className={classes.submit}
          color="primary"
          disabled={loading || teamSubmitted || !isAnswered}
        >
          Submit to Instructor
          {loading && <Progress variant="overlay" size='1.5em' />}
        </Button>
      </Grid>
      <Dialog
        style={{
          margin: 'auto',
        }}
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <ConfirmModal handleConfirm={(event) => handleSubmit(event)} handleCancel={confirmSubmit} message={`Please double check your submission. You will not be able to re-submit.`}/>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    quarter: state.coresim.quarter.counter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      justification: (units) => justification(units),
      minicaseComplete: (units) => minicaseComplete(units),
      getPreload: (data) => getPreload(data),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Justification));

Justification.propTypes = {
  classes: PropTypes.object,
  decisions: PropTypes.object,
  justification: PropTypes.func,
  minicaseComplete: PropTypes.func,
  history: PropTypes.object,
  sim: PropTypes.object,
  teamIndex: PropTypes.number,
  getPreload: PropTypes.func,
};
