import React, {useState, useEffect, useMemo, useCallback} from 'react';
import tableHoc from '../../components/Tables/tableHoc';
import {API} from 'aws-amplify';
import { Box, Button, Typography, Grid, makeStyles, Dialog } from '@material-ui/core';
import IconButton from '../../components/widgets/IconButton';
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import selectableTableHoc from '../../components/Tables/selectableTableHoc';
import { useSelectionContext } from '../../providers/selectionProvider';
import { useBreadcrumbContext, useBreadcrumbActions } from '../../providers/breadcrumbProvider';
import Transition from '../../components/ModalTransition';
import DeleteModal from '../../components/DeleteModal';

const useStyles = makeStyles((theme) => ({
  buttonList: {
    '& .MuiButton-root + .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const header = () => [
  {
    isSortable: true,
    label: 'Last Name',
    name: 'lastName',
  },
  {
    isSortable: true,
    label: 'First Name',
    name: 'firstName',
  },
  {
    isSortable: false,
    label: 'e-mail',
    name: 'email',
  },
  {
    isSortable: true,
    label: 'Last Modified',
    name: 'lastModifiedDate',
  },
  {
    isSortable: true,
    label: 'Created',
    name: 'createdDate',
  },
  {
    isSortable: true,
    label: 'Role',
    name: 'role',
  },
  {
    label: ''
  }
];

const rowDefinition = (deleteUser, editUser) => (row, index) => [
  row.lastName,
  row.firstName,
  row.email,
  row.lastModifiedDate ? new Date(row.lastModifiedDate).toLocaleString() : "",
  row.createdDate ? new Date(row.createdDate).toLocaleString() : "",
  row.role,
  <Grid key="actions" container justify="flex-end">
    {editUser &&
      <Grid item>
        <IconButton size="small"
          variant="text"
          color="primary"
          icon={CreateIcon}
          onClick= { (e) => {
            e.preventDefault();
            editUser(row.id);
          }}
        >
          edit
        </IconButton>
      </Grid>
    }
    {deleteUser &&
      <Grid item>
        <IconButton
          size="small"
          variant="text"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            deleteUser(row);
          }}
          icon={DeleteIcon}
        >
          delete
        </IconButton>
      </Grid>
    }
  </Grid>
];

const UserList = ({reload, isSelect, bulkUpload, filter}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const match = useRouteMatch();
  const selection = useSelectionContext();
  const breadcrumbActions = useBreadcrumbActions();
  const [deleting, setDeleting] = useState();

  const classes = useStyles();

  useEffect(() => {
    const abortObj = {
      aborted: false
    };

    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }

  }, [reload]);

  useEffect(() => {
    if (!users || !users.length || !isSelect) {
      return;
    }

    const usersMap = new Map(
      users.map((u) => [
        u._id,
        u
      ])
    );

    const updatedSelectionItems =
      selection[0].reduce((newSelection, value) => {
        usersMap.has(value) && newSelection.push(usersMap.get(value));
        return newSelection;
      }, []);

    selection[1].init(updatedSelectionItems);
  }, [users, isSelect]);

  const load = async (abortObj) => {
    try {
      const users = await API.get('mongo', '/users');
      if (!abortObj || !abortObj.aborted)
      {
        const filteredUsers = filter ? users.filter(filter) : users;
        setUsers(filteredUsers.filter((u) => u.active !== false));
      }
    } catch (e) {
      toast.error('Error retrieving users.');
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async (user) => {
    try {
      setLoading(true);

      await API.put('mongo', `/users/${user.id}/deactivate`, {});

      toast.success(`User (email: ${user.email}) successfully deleted.`);
    } catch(e) {
      toast.error(`Error deleting user (email: ${user.email})`);
    } finally {
      setDeleting()
      load();
    }

  }, []);

  const handleDelete = useCallback((row) => {
    setDeleting(row);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting();
  }, []);

  const onEdit = useCallback((id) => {
    history.push(`${match.url}/${id}`);
  }, []);

  useEffect(() => {
    let abortObj = {aborted: false};
    load(abortObj);

    return () => {
      abortObj.aborted = true;
    }
  }, []);

  const Table = useMemo(() => isSelect ?
    selectableTableHoc(rowDefinition(handleDelete, onEdit), header)
    :

    tableHoc(rowDefinition(handleDelete, onEdit), header),
  [handleDelete, onEdit, isSelect, handleDelete, handleCancelDelete]);

  const breadcrumbs = useBreadcrumbContext();
  const label = breadcrumbs[breadcrumbs.length -1].label;

  const selectionProps = useMemo(() => {
    if (!isSelect) {
      return {};
    }

    return {
      selected: selection[0],
      onSelectAdd: selection[1].add,
      onSelectRemove: selection[1].findAndRemove,
      onSelectAll: selection[1].init,
    };
  }, [selection, isSelect]);


  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        variant="h4">
        {isSelect && 'Select '}{label}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {isSelect &&
            <Typography variant="body2">
              <em>
                {selection[0].length} item(s) selected
              </em>
            </Typography>
          }
        </Box>
        <Box
          className={classes.buttonList}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          py={2}
        >
          { isSelect &&
            <Button
              variant="contained"
              color="secondary"
              onClick={
                () => breadcrumbActions.pop()
              }
            >
              done
            </Button>
          }
          { bulkUpload &&
            <Button
              variant="contained"
              color="secondary"
              onClick={
                () => history.push(`/admin/bulk-upload`)
              }
            >
              bulk upload
            </Button>
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={
              () => history.push(`${match.url}/create`)
            }
          >
            + new
          </Button>
        </Box>
      </Box>

      <Table data={users}
        sortField='lastName'
        sortAscending
        loading={loading}
        {...selectionProps}
      />

      <Dialog
        style={{
          margin: 'auto',
        }}
        open={Boolean(deleting)}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <DeleteModal handleConfirm={() => handleConfirmDelete(deleting)} handleCancel={handleCancelDelete} what={`User "${(deleting && deleting.firstName) || ''}"`}/>
      </Dialog>
    </Box>
  );
};

UserList.propTypes = {
  reload: PropTypes.string.isRequired,
  isSelect: PropTypes.bool,
  filter: PropTypes.func,
  bulkUpload: PropTypes.bool, // if true, then the bulk upload button will be visible
}

export default UserList;
