/**
 * A function given by https://www.desmos.com/calculator/g0rhoslbtw
 * to determine the expenses for a given number of units produced.
 * Expenses are high at low values and taper off, eventually approaching
 * a levelled cost per unit. Graphically, this is a linear asymptote.
 * @param {number} levellingPoint - The point at which the graph becomes mostly linear
 */
const expenses = (levellingPoint) =>
  /**
   * @param {number} levelledcost - The cost per unit as units go to infinity
   */
  (levelledCost) =>
    /**
     * @param {number} units - The number of units produced
     * @returns {number} - the cumulative expsense for the number of units
     */
    (units) =>
      (levelledCost *
        Math.pow(
          (4 / (levellingPoint * levellingPoint)) *
            Math.pow(units + 0.5 * levellingPoint, 2) -
            1,
          0.5
        )) /
      (2 / levellingPoint);

export default expenses;
