import SettingsIcon from '@material-ui/icons/Settings';
import BookIcon from '@material-ui/icons/Book';
import GroupWork from '@material-ui/icons/GroupWork';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default [
  {
    label: 'Home',
    Icon: SettingsIcon,
    to: '/',
  },
  {
    label: 'Configurations',
    Icon: BookIcon,
    to: '/admin/configurations',
  },
  {
    label: 'Simulations',
    Icon: BookIcon,
    to: '/admin/simulations',
  },
  {
    label: 'Teams',
    Icon: BookIcon,
    to: '/admin/teams'
  },
  {
    label: 'Users',
    Icon: GroupWork,
    to: '/admin/users'
  },
  {
    label: 'Bulk Upload',
    Icon: CloudUploadIcon,
    to: '/admin/bulk-upload'
  },
  {
    label: 'Mini-cases',
    Icon: BookIcon,
    to: '/admin/mini'
  },
  {
    label: 'News Articles',
    Icon: BookIcon,
    to: '/admin/news'
  }
];
