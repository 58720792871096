import React, { useEffect, useMemo } from 'react';
import {ValidatorForm} from "react-material-ui-form-validator";
import PropTypes from 'prop-types';
import formatNumber from './formatNumber';
import uuidv4 from 'uuid/v4';

const numberBoundsHoc = (Component, {maxValue, minValue}) => {
  // invocationId is necessary since ValidatorForm.addValidationRule
  // is badly scoped (although theywill be overwritten appropriately,
  // the closure will point to the last invocation). Since our rules are
  // Component dependent (references minValue/maxValue), we need to
  // ensure unique rule names.
  const invocationId = uuidv4();
  const maxValueRule = `maxValue-${invocationId}`;
  const minValueRule = `minValue-${invocationId}`;

  const NumberBounds = ({validators, errorMessages, ...remaining}) => {
    useEffect(() =>{
      ValidatorForm.addValidationRule(maxValueRule, (value) => {
        return isNaN(value) || value === '' ||  Number(value) <= maxValue
      });

      ValidatorForm.addValidationRule(minValueRule, (value) => {
        return isNaN(value) || value === '' | Number(value) >= minValue
      });
    }, []);

    const calculatedValidators = useMemo(() => {
      const result = validators ? [...validators] : [];
      if (maxValue !== undefined) {
        result.unshift(maxValueRule);
      }

      if (minValue !== undefined) {
        result.unshift(minValueRule);
      }

      return result;
    }, [validators]);

    const calculatedMessages = useMemo(() => {
      const result = errorMessages ? [...errorMessages] : [];
      if (maxValue !== undefined) {
        result.unshift(`Cannot be > '${formatNumber(maxValue)}'`);
      }

      if (minValue !== undefined) {
        result.unshift(`Cannot be < '${formatNumber(minValue)}'`);
      }

      return result;
    }, [errorMessages]);

    return (
      <Component
        {...remaining}
        errorMessages={calculatedMessages}
        validators={calculatedValidators}
      />
    );
  }

  NumberBounds.propTypes = {
    validators: PropTypes.arrayOf(PropTypes.string.isRequired),
    errorMessages: PropTypes.arrayOf(PropTypes.string),
  }

  return NumberBounds;
};


export default numberBoundsHoc;
