import compose from './base/compose';
import exp from './base/exp';
import identity from './base/identity';

/**
 * Specifies the amount to bend sentiment
 * based off of poor QA. This prevents
 * Sentiment from approaching 1 when feature sentiment
 * is very high, but QA remains poor.
 */
const depressQA = (qaSensitivity) => (qa) => (x) =>
  ((1 / qaSensitivity) * Math.pow(x, 2)) / (x / qaSensitivity - (qa - 0.5));

/**
 * Function based from https://www.desmos.com/calculator/b3hnc03on7
 * to combine QA and feature Sentiment. At a QA value of .5, the function
 * behaves linearly with feature. At a QA value of 1, it behaves as a square root,
 * at a value of 0, as x^2 (but dampened). The function is dampened depending on
 * the value of qaSensitivity. Dampening is not applied for values of qa >=.5
 *
 * @param {number} qaSensitivity - how much dampening to apply. Should be a number x, 0 < x <= 2. 1 is an "average" result, resulting in a sentiment cap of .44 when QA is 0
 * */
const combineQAFeatureSentiment = (qaSensitivity) =>
  /**
   * Combine QA and feature sentiment.
   *
   * @param {number} qa - The qa sentiment from 0 to 1
   * @param {number} feature - The product dev, or feature sentiment from 0 to 1
   * @returns {number} - The combined sentiment value from 0 to 1
   */
  (qa, feature) =>
    compose(
      qa < 0.5 ? depressQA(qaSensitivity)(qa) : identity, // don't dampen when qa >=.5
      exp(qa * qa - 2.5 * qa + 2)
    )(feature);

export default combineQAFeatureSentiment;
