const ADD = 'ADD';
const REMOVE = 'REMOVE';

const addType = (prefix) => `${prefix}/${ADD}`;
const removeType = (prefix) => `${prefix}/${REMOVE}`;
const modifyType = (prefix) => (index) => (type) => `${prefix}[${index}]/${type}`;

// matches actions like: 'some_prefix[5]/someaction'
const modifyRegex = (prefix) => new RegExp(`^${prefix}\\[(\\d+)\\]/`);

export default (prefix) => (itemReducer) => {
  const ADD_TYPE = addType(prefix);
  const REMOVE_TYPE = removeType(prefix);
  const MODIFY_REGEX = modifyRegex(prefix);

  return function(state = [], action) {
    if (action === undefined) {
      return state;
    }
    switch (action.type) {
      case ADD_TYPE:
        return [...state, itemReducer(action.payload)];
      case REMOVE_TYPE:
        return state.filter((value, index) => index !== action.payload);
      default:
        break;
    }

    const match = action.type && action.type.match(MODIFY_REGEX);
    if (match) {
      const index = Number(match[1]);
      const newAction = {...action, type: action.type.replace(match[0], '')};
      const newItemState = itemReducer(state[index], newAction);
      return state.map((value, i) => (i === index ? newItemState : value));
    }

    return state;
  };
};

export const getAddAction = (prefix) => {
  const type = addType(prefix);
  return (payload) => ({
    type,
    payload,
  });
};

export const getRemoveAction = (prefix) => {
  const type = removeType(prefix);
  return (payload) => ({
    type,
    payload,
  });
};

export const getModifyAction = (prefix) => (index) => (action) => {
  const type = modifyType(prefix)(index)(action.type);
  return {
    type,
    payload: action.payload,
  };
};
