import * as React from 'react';
import PropTypes from 'prop-types';
import HighlightContext from './HighlightContext';
import useHighlightContext from './useHighlightContext';

const HighlightContextProvider = ({children, highlights}) => {
  return (
    <HighlightContext.Provider value={highlights}>
      {children}
    </HighlightContext.Provider>
  );
};

HighlightContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string),
};

export default HighlightContextProvider;
export {HighlightContext, useHighlightContext};
