export default [
  // {
  //   type: 'Economic Forecast',
  //   description: "Fund a report that details next quarter's macroeconomic demand. Who knows, maybe there is a recession coming.",
  //   payperiod: 4,
  //   term: 4,
  //   interest: 0,
  //   interestPenalty: .1225,
  //   servicingRate: .25,
  //   maxLoanToValuation: .2,
  //   icon: 'Balloon',
  // },
  // {
  //   type: 'Keeping Your Enemy Close',
  //   description: "To maintain your competitive advantage, it's important to understand what your competition is doing. Purchase this report to see a record of the decisions that were made last quarter by one of your competitors.",
  //   payperiod: 1,
  //   term: 3,
  //   interest: .0425,
  //   interestPenalty: .1225,
  //   servicingRate: .05,
  //   maxLoanToValuation: .2,
  //   icon: 'Clock',
  // },
  {
    type: "Knowledge is Power",
    description: 'To truly understand what leads to success in your industry, you decide to purchase this report that gives you all of the decisions that your competitors made last quarter.',
    price: 10000,
    payperiod: 1,
    term: 6,
    interest: .0725,
    interestPenalty: .1925,
    servicingRate: .1,
    maxLoanToValuation: .4,
    icon: 'Clock',
  },
];
  