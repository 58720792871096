import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import qualityPlaceholder from '../../assets/quality-config-placeholder.svg';
import standardPlaceholder from '../../assets/standard-config-placeholder.svg';
import quantityPlaceholder from '../../assets/quantity-config-placeholder.svg';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import {useUserContext} from '../../providers/userProvider';
const useStyles = makeStyles(() => ({
  tooltip: {zIndex: 10000}
}))

const ConfigOption = ({typeInfo, image, handleSubmit}) => {

  let tempImage = image !== undefined ? image : typeInfo.title === 'Quantity' ? quantityPlaceholder : typeInfo.title === 'Quality' ? qualityPlaceholder : standardPlaceholder;

  let staticImage = <img style={{height: 'auto', width: '100%'}} src={tempImage} alt={`${typeInfo.title} Product`}></img>;

  const classes = useStyles();
  const user = useUserContext();
  return (
    <Box pt={2}>

      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
        <h2>Price Volume Decision</h2>
        {user.role === 'student' ?
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            type="submit">Select {typeInfo.title}</Button> :
          <Tooltip
            title='Only students are allowed to select a configuration.'
            placement="top"
            classes={{popper: classes.tooltip}}
            arrow>
            <span> {/* https://material-ui.com/components/tooltips/#disabled-elements */}
              <Button
                variant="contained"
                color="primary"
                disabled>Select {typeInfo.title}</Button>
            </span>
          </Tooltip>
        }
      </div>
      <p style={{marginTop: 20}}>Welcome to the WBW! One of the most important business decisions you will make in manufacturing a physical product is to determine whether to chase quantity or quality. When making a smartphone, for example, you can go the premium route towards Apple iPhones, Google Pixels, or Samsung Galaxies. You can also purchase an Android smartphone for $30 at a Walmart, which has most of the same features as those premium items, with compromises taken on performance, appeal and overall quality.</p>
      <p style={{marginTop: 20}}>Based on your decision, you will start with a different initial configuration. If you pick Quality, you will start with less cash on hand but a higher amount of money spent on product development and a larger amount of money able to take out in loans. Choosing quantity, you will start with plenty of cash but not a very well developed product. Undecided will result in a wishy-washy state where you spend some money on R&amp;D, but not too much.</p>
      <Grid container spacing={3}>
        <div className="root-flex" style={{paddingTop: 20}}>
          <Grid item xs={12}>
            <div className="block" style={{padding: 10, margin: 10}}>
              <div>
                <h2>{typeInfo.title}</h2>
              </div>
              <div style={{textAlign: 'center'}}>
                {staticImage}
              </div>
              <div>
                <h2>Overview</h2>
                <p>{typeInfo.overview}</p>

              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="block" style={{padding: 10, margin: 10}}>
              <h2>Pros</h2>
              <ul style={{marginLeft: 20}}>
                {typeInfo.pros.map((opt, index) => (
                  <li key={index}>{opt}</li>
                ))}

              </ul>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="block" style={{padding: 10, margin: 10}}>
              <h2>Cons</h2>
              <ul style={{marginLeft: 20}}>
                {typeInfo.cons.map((opt, index) => (
                  <li key={index}>{opt}</li>
                ))}
              </ul>
            </div>
          </Grid>
        </div>
      </Grid>
    </Box>
  );
};

ConfigOption.propTypes = {
  typeInfo: PropTypes.object.isRequired,
  image: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default ConfigOption;
