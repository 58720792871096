import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const defaultErrorMessage = 'Error';

const getArrayMenuItems = (items) =>
  items.map((item, index) => (
    <MenuItem key={index} value={index}>
      {item}
    </MenuItem>
  ));

const getFormattedText = (text) => (
  <span style={{ paddingRight: 4 }}>{text}</span>
);

/**
 * Generate select list items from an object mapping of {selectValueToSet: selectValueLabel}
 */
const getIndexMenuItems = (items) => {
  function* generator() {
    for (const prop in items) {
      if (!Object.prototype.hasOwnProperty.call(items, prop)) {
        continue; // prevent prototype pollution
      }

      yield (
        <MenuItem key={prop} value={prop}>
          {getFormattedText(items[prop])}
        </MenuItem>
      );
    }
  }

  return [...generator()];
};

const getMenuItems = (items) => {
  if (!items) {
    return [];
  }

  return Array.isArray(items)
    ? getArrayMenuItems(items)
    : getIndexMenuItems(items);
};

const SelectField = (props) => {
  const {
    id,
    label,
    error,
    disabled,
    fullWidth,
    helperText,
    items,
    onChange,
    placeholder,
    value,
    ...remaining
  } = props;

  return (
    <Box py={1}>
      <FormControl
        variant="outlined"
        margin="dense"
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
      >
        {label && // HACK: Need to figure out why label doesn't display
          <InputLabel id={`label-${id}`}
            style={{backgroundColor: 'unset', padding: '0 4px'}}
          >
            {label}
          </InputLabel>
        }
        <Select
          label={label}
          id={id}
          labelId={label ? `label-${id}` : undefined}
          value={value === undefined ? '' : value}
          onChange={onChange}
          displayEmpty
          {...remaining}>
          {placeholder && (
            <MenuItem value="" disabled>
              {getFormattedText(placeholder)}
            </MenuItem>
          )}
          {getMenuItems(items)}
        </Select>
        {(helperText || error) && (
          <FormHelperText>
            {helperText || defaultErrorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  items: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default SelectField;
