import React from 'react';
import { Route, useRouteMatch } from 'react-router';
import {
  Box,
  makeStyles,
  Breadcrumbs,
  Link,
  Typography } from '@material-ui/core';
import BreadcrumbContextProvider, { useBreadcrumbContext } from '../providers/breadcrumbProvider';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles( (theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#fafafa',
    zIndex: 1
  },
  nested: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 3, 3, 3)
  }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const StackBreadcrumb = () => {
  const breadcrumbContext = useBreadcrumbContext();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter color="inherit" to='/'>
        Admin Home
      </LinkRouter>
      {breadcrumbContext.map((value, index) => {
        const last = index === breadcrumbContext.length - 1;

        return last ? (
          <Typography color="textPrimary" key={value.to}>
            {value.label}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={value.to} key={value.to}>
            {value.label}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>

  )
};

const StackBreadcrumbProvider = ({label, children}) => {
  const breadcrumbContext = useBreadcrumbContext() || [];
  const match = useRouteMatch();

  return (
    <BreadcrumbContextProvider
      breadcrumb={
        [
          ...breadcrumbContext,
          {
            label,
            to: match.url,
          }
        ]
      }>
      <StackBreadcrumb label={label} />
      {children}
    </BreadcrumbContextProvider>
  );
};

StackBreadcrumbProvider.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

const StackRoute = ({children, label, ...remaining}) => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <Route {...remaining}>
      <Box className={classes.root}>
        <Box className={classes.nested}>
          <StackBreadcrumbProvider
            root={match.url}
            children={children}
            label={label} />
        </Box>
      </Box>
    </Route>
  )
};

StackRoute.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default StackRoute;
