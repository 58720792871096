import React, { useState, useMemo } from 'react';
import numberFilterHoc from '../../helpers/numberFilterHoc';
import { useFormReducer } from '../../helpers/useFormReducer';
import { Button } from '@material-ui/core';
import SelectField from '../Forms/SelectField';
import Field from '../Forms/Field';
import Group from '../Forms/Group';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

const metricOptions = [
  'unitsProduced',
  'unitsModification',
  'percentageCapacityModification',
  'percentageSentimentModification',
  'addedPlantCapacity',
  'price',
  'qualityManagement',
  'advertising',
  'salespeople',
  'productDevelopment',
  'marketResearch',
  'dividends',
  'loansTaken',
  'loansPaid',
  'otherExpenses',
];

const NumberField = numberFilterHoc(Field,
  {
    allowNegative: true,
  });

const getProps = (name, form) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
});

const MinicaseOutput = ({initialOutput, disabled, onSave}) => {
  const initialIndex = useMemo(() => {
    const index = metricOptions.findIndex(
      (v) => initialOutput && v === initialOutput.outputMetric);
    return index === -1 ? undefined : index;
  }, [initialOutput])
  const [selected, setSelected] = useState(initialIndex);
  const [form, updateHandler] = useFormReducer(
    initialOutput,
    (init) => ({...init})
  );
  const [selectError, setSelectError] = useState();

  const validateForm = () => {
    if (selected === undefined) {
      setSelectError('You must select a value');
      return false;
    }

    return true;
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (validateForm() && onSave) {
      onSave({
        ...form,
        outputMetric: metricOptions[selected]
      });
    }
  };

  return (
    <ValidatorForm onSubmit={handleSave}>
      <Group stack>
        <SelectField
          id="outputMetric"
          name="outputMetric"
          onChange={(e) => {
            setSelected(e.target.value);
            setSelectError();
          }}
          items={metricOptions}
          placeholder="Select Metric"
          disabled={disabled}
          value={selected}
          error={Boolean(selectError)}
          helperText={selectError}
        />
        <NumberField
          {...getProps('outputQuantity', form, updateHandler)}
          label='Quantity'
          onNumberChange={updateHandler('outputQuantity')}
        />
      </Group>

      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        type="submit"
      >
        Save
      </Button>
    </ValidatorForm>
  );
};

MinicaseOutput.propTypes = {
  initialOutput: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
}

export default MinicaseOutput;
