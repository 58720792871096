import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import makeStyles from '@material-ui/core/styles/makeStyles';
import mobileNavItems from '../menus/studentMobileNav';
import StudentIntegrationModal from '../containers/modal/StudentIntegrationModal';
import PropTypes from 'prop-types';
import store from '../coresim';

const useStyles = makeStyles((theme) => ({
  navContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  navItemRoot: {
    color: 'white',
    padding: '6px 12px 8px',
    margin: '0 10px',
    '&.Mui-selected': {
      color: 'white',
      paddingTop: 16,
      borderBottom: `2px solid white`
    },
  },
  navItemLabel: {
    display: 'none',
  }
}))

// footer navigation switch that shows up only for student views in mobile width viewports
const StudentMobileNav = () => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState('sim');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState(`${location.pathname}/${location.key}`);
  const state = store.getState();
  const sim = state.coresim;
  const teamIndex = state.teamIndex.teamIndex;
  useEffect(() => {

    // if path has changed, that means that sidebar has been clicked -- close modal and reset current path
    if (currentPath !== `${location.pathname}/${location.key}`) {
      setModalOpen(false);
      setCurrentPath(location.pathname);
      setValue('sim');
    }
  }, [location]);

  // If the new value is 'sim', close the modal. Otherwise, set the new modal content.
  const handleNavBarChange = (event, newValue) => {

    // Only change the indicator and close modal if it is not zoom
    if (newValue !== 'zoom'){
      setValue(newValue);
      let modalOpen = newValue !== 'sim';
      setModalOpen(modalOpen)
    }

  }

  return <Hidden 
    smUp 
    implementation='css'>
    <AppBar 
      position='fixed' 
      color='primary' 
      style={{top: "auto", bottom: 0}} 
      component='div'
    >
      <BottomNavigation 
        classes={{root:classes.navContainer}} 
        value={value} 
        onChange={handleNavBarChange} 
        style={{width: 'auto', height: 'auto'}}
      >
        {mobileNavItems(sim, teamIndex).map((navItem, i) => {
          let Icon = navItem.Icon;
          return <BottomNavigationAction
            id={navItem.id}
            classes={{root:classes.navItemRoot, label: classes.navItemLabel}}
            value={navItem.to}
            onClick={navItem.to === 'zoom' ? () => {
              navItem.action()
            } : null}
            icon={navItem.displayBadge ?
              <Badge badgeContent={navItem.badgeValue} color='error'><Icon /></Badge> : <Icon />}
            key={i}
          />
        })}
      </BottomNavigation>
    </AppBar>
    <StudentIntegrationModal 
      handleClose={() => setModalOpen(false)} 
      modalOpen={modalOpen} 
      activeComponentKey={value}/>
  </Hidden>
}

StudentMobileNav.propTypes = {
  childProps: PropTypes.object.isRequired
}

export default StudentMobileNav;
