const SUBMIT = 'decision/SUBMIT';

export const initState = {
  unitsProduced: 0,
  addedPlantCapacity: 0,
  qualityManagement: 0,
  price: 0,
  advertising: 0,
  salespeople: 0,
  // + or - to signify hiring firing
  productDevelopment: 0,
  marketResearch: 0,
  dividends: 0,
  loansPaid: 0,
  loansTaken: 0,
  loansIndex: 0,
  loanConfirmed: false,
  loanPaidConfirmed: false,
  otherExpenses: 0,
  minicaseJustification: '',
  cashJustification: '',
  cashAmount: 0,
  selectedMinicase: {outputs: []},
  minicaseComplete: false,
  decisionsComplete: false,
  businessAs: '',
  changeName: 0
};

export default function decisionsReducer(state = initState, action) {
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case SUBMIT: {
      const decisions = initState;

      for (const prop in action.payload) {
        if (!Object.prototype.hasOwnProperty.call(action.payload, prop)) {
          continue;
        }

        const value = action.payload[prop];

        if (value === null || value === undefined || value === '') {
          decisions[prop] = 0;
        } else if (typeof value === 'boolean' || !Number.isFinite(value)) {
          decisions[prop] = action.payload[prop];
        } else {
          decisions[prop] = Number(value);
        }
      }

      return decisions;
    }
    default:
      return state;
  }
}

export function submit(decisions) {
  return {
    type: SUBMIT,
    payload: decisions,
  };
}
