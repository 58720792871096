/**
 upload needs validate:
  - Invalid Email
  - Team Already Exists
  - Missing X Column
  - Role x is invalid.
  - Role is specified as student or advisor and Team field is empty.
  - Role is specified as chair but the Team field is not empty.
 **/

import Papa from "papaparse";

const REGEX_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const VALID_ROLES = ['student', 'advisor', 'chair']
const TEAM_REQUIRED_ROLES = ['student', 'advisor']
const TEAM_REQUIRED_EMPTY_ROLES = ['chair']
const REQUIRED_COLUMNS = ['Role', 'Email', 'First Name', 'Last Name', 'Team']
const ERROR_COLUMNS = ['Role', 'Email', 'First Name', 'Last Name', 'Team', 'Errors']

export class MissingColumnsError extends Error {
  constructor(msg = 'CSV is missing required fields', fields) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(msg)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MissingColumnsError)
    }

    this.name = 'MissingColumnsError'
    // Custom debugging information
    this.msg = msg
    this.fields = fields
  }
}

/**
 * validatorFuncMap is a map of {csv key: func (csv value, fullRow)}. The validator function itself should return null or a string of validation errors.
 *  rows should be normalized first! column names lowercase, empty rows w/ spaces stripped
 */
const validatorFuncMap = {
  'email': (value) => {
    if (!value) {
      return 'Email required'
    }
    return REGEX_PATTERN.test(value) ? null : 'Invalid email string.'
  },
  'first name': (value) => !!value ? null : 'First Name is required',
  'last name': (value) => !!value ? null : 'Last Name is required',
  'role': (value, fullRow, existingUserObj) => {
    if (!value) {
      return 'Role is required'
    }
    if (existingUserObj && existingUserObj.role !== value) {
      return `This user already exists in WBW and has a different role than marked here. Existing users must have the same role they were created with, which is: ${existingUserObj.role}`
    }
    if (VALID_ROLES.indexOf(value) === -1) {
      return `Role must be one of: ${VALID_ROLES.join(', ')}`
    }
    if (TEAM_REQUIRED_ROLES.indexOf(value) > -1 && !fullRow['team']) {
      return 'If role is student or advisor, Team must be filled out'
    }
    if (TEAM_REQUIRED_EMPTY_ROLES.indexOf(value) > -1 && fullRow['team']) {
      return 'If role is chair, Team must be empty'
    }
    return null
  },
  'team': (value, sanitizedRow, existingUser, existingTeam) =>
    !existingTeam ?
      null :
      `A team already exists with the name ${value}. Either change the name or delete the old team.`
}

const runValidators = (sanitizedRowObj, existingUserObj, existingTeamObj) => {
  let validationErrors = [];
  for (const [validationKey, validationFunc] of Object.entries(validatorFuncMap)) {
    const error = validationFunc(sanitizedRowObj[validationKey], sanitizedRowObj, existingUserObj, existingTeamObj)
    if (error) {
      validationErrors.push(error)
    }
  }
  return validationErrors.length > 0 ? validationErrors.join(', \n') : null
}

/**
 * all keys become lowercase, all leading and trailing whitespace will be trimmed
 * @param rowObj - row object in papaparse structure {columnKey: columnVal}
 * @returns sanitized row, in same papaparse structure
 */
const sanitizeRow = (rowObj) => {
  let sanitized = {};
  for (const [key, value] of Object.entries(rowObj)) {
    // console.debug(`key: ${key} | value: ${value}`)
    sanitized[key.toLowerCase().trim()] = value.trim()
  }
  return sanitized;
}

// make sure that the csv has all the headers we need
const findMissingHeaders = columns => {
  let missingFields = [];
  REQUIRED_COLUMNS.forEach(requiredCol => {
    if (columns.indexOf(requiredCol) === -1) {
      missingFields.push(requiredCol)
    }
  })
  return missingFields
}

/**
 * `parseUserCsv` will actually read the csv string and throw errors if it cannot parse the csv or if there
 *  are missing columns. *Required columns are: Role, Email, First Name, Last Name, Team*
 *
 *
 * @param userCsvString: CSV file contents of users. Needs
 * @throws MissingColumnsError, Error. MissingColumnsError if any missing columns exist, a normal Error if the CSV
 *   parsing lib returns any errors in the actual parsing
 * @return parsed user csv with validated headers, in the following format:
 *    [
 *      {Role: '<role>', Email: '<email>', 'First Name': '<first name>', 'Last Name': '<last name>', Team: '<team>'},
 *      {Role: '<role>', Email: '<email>', 'First Name': '<first name>', 'Last Name': '<last name>', Team: '<team>'},
 *      ...
 *    ]
 */
const parseUserCsv = userCsvString => {
  const parsed = Papa.parse(userCsvString, {
    header: true,
    skipEmptyLines: true,
    transformHeader: headerVal => headerVal.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')
  })
  if (parsed.errors.length) {
    throw new Error(`Unable to parse csv, the following errors occurred: \n${'\n'.join(parsed.errors)}`)
  }
  const missingHeaders = findMissingHeaders(parsed.meta.fields)
  if (missingHeaders.length > 0) {
    throw new MissingColumnsError("Missing required fields", missingHeaders);
  }
  return parsed.data
}


/**
 * Transform papa parsed user array into an object grouped by teams & chair users.
 * @param parsedCsvRows: parsed rows, in the format:
 *    [
 *     {"First Name":"x","Last Name":"x","Email":"xx@un-loop.org","Role":"advisor","Team":"Team D"},
 *     {"First Name":"y","Last Name":"y","Email":"yy@un-loop.org","Role":"student","Team":"Team D"},
 *     {"First Name":"z","Last Name":"z","Email":"zz@wbw.org","Role":"student","Team":"Team D"},
 *     {"First Name":"q","Last Name":"q","Email":"qq@un-loop.org","Role":"chair","Team":""},
 *     {"First Name":"e","Last Name":"e","Email":"ee@un-loop.org","Role":"student","Team":"Team E"},
 *     {"First Name":"l","Last Name":"e","Email":"le@wbw.org","Role":"advisor","Team":"Team E"},
 *     {"First Name":"Lauri","Last Name":"Sylvaine","Email":"ls@wbw.org","Role":"advisor","Team":"Team C"},
 *     {"First Name":"Karena","Last Name":"Kouji","Email":"kk@wbw.org","Role":"student","Team":"Team C"},
 *     {"First Name":"Aino","Last Name":"Ogechi","Email":"ainochair@wbw.org","Role":"chair","Team":""}
 *    ]
 *
 * @return all users with teams grouped by their team, and chair users
 *  {
 *    teamUsers: {
 *      "Team C": [
 *        {firstName:"Lauri",lastName:"Sylvaine",email:"ls@wbw.org",role:"advisor"},
 *        {firstName:"Karena",lastName:"Kouji",email:"kk@wbw.org",role:"student"}
 *      ],
 *      "Team D": [
 *        {firstName:"x",lastName:"x",email:"xx@un-loop.org",role:"advisor"},
 *        {firstName:"y",lastName:"y",email:"yy@un-loop.org",role:"student"},
 *        {firstName:"z",lastName:"z",email:"zz@wbw.org",role:"student"}
 *      ],
 *      "Team E": [
 *        {firstName:"e",lastName:"e",email:"ee@un-loop.org",role:"student"},
 *        {firstName:"l",lastName:"e",email:"le@wbw.org",role:"advisor"},
 *      ]
 *    },
 *    chairUsers: [
 *      {firstName:"q",lastName:"q",email:"qq@un-loop.org",role:"chair"},
 *      {firstName:"Aino",lastName:"Ogechi",email:"ainochair@wbw.org",role:"chair"}
 *    ]
 *  }
 */
export const groupUploadByTeams = (parsedCsvRows) => {
  const chairKey = 'CHAIR_PSEUDO_TEAM'
  const teamUsers = parsedCsvRows.reduce((finalObj, csvRow) => {
   let team = csvRow.Team || chairKey; // chairs don't have a team
   delete csvRow.Team

   const camelcaseUserFields = (row) => {
     const camelcase = {};
     camelcase.firstName = row['First Name']
     camelcase.lastName = row['Last Name']
     camelcase.email = row['Email']
     camelcase.role = row['Role']
     camelcase.userExists = row['UserExists'] || false
     return camelcase
   }
   const camelcaseRow = camelcaseUserFields(csvRow)
   if (team in finalObj) {
     finalObj[team].push(camelcaseRow)
   } else {
     finalObj[team] = [camelcaseRow]
   }
   return finalObj
  }, {})
  // pop chair users out of the team users array, since they aren't actually team users
  const chairUsers = chairKey in teamUsers ? [...teamUsers[chairKey]] : [];
  delete teamUsers[chairKey];
  return {teamUsers, chairUsers}
}

/**
 * iterates over every user row from the parsed user csv, runs validators and adds additional attributes to the
 * user object. It will add UserExists and Errors:
 *  - UserExists: boolean. if the user is already in the db
 *  - Errors: Concatenated string of all errors on that row.
 *
 * exported just for testing smh
 *
 * @param userRows: array of user objects parsed from the CSV. This array *will* be modified
 *                  (not sure if i should just make a new one tbh)
 * @param existingUsers: array of users that are already in the db
 * @param existingTeams: array of teams that are already in the db
 * @return {boolean}: if there were any errors in the whole document, will return true
 */
export const validateUserRows = (userRows, existingUsers, existingTeams) => {
  let hasErrors = false

  // using normal for loop, most performant and there may be hundreds of rows
  for (let i = 0; i < userRows.length; i++) {
    const userRow = userRows[i]
    const sanitized = sanitizeRow(userRow)

    // determine if they already exist in the system. If they do, we will need to run additional validations
    // and mark them as pre-existing
    let existingUser = null

    const existingUserArray = existingUsers.filter((u) => u.email === userRow.Email)
    const userExists = existingUserArray.length > 0
    userRows[i].UserExists = userExists

    if (userExists) {
      existingUser = existingUserArray[0]
    }

    // do the same for team name
    let existingTeam = null
    const existingTeamArray = existingTeams.filter(t => t.teamName === userRow.Team)
    if (existingTeamArray.length > 0) {
      console.log(`Team "${userRow.Team}" exists`)
      existingTeam = existingTeamArray[0]
    }

    const errorString = runValidators(sanitized, existingUser, existingTeam);
    if (errorString) {
      hasErrors = true
      userRows[i].Errors = errorString
    }
  }

  const hasDuplicates = checkDuplicates(userRows)

  return hasDuplicates || hasErrors
}

export const checkDuplicates = (userRows) => {
  let emailCounts = userRows.reduce((valueCounter, userRow) => {
    valueCounter[userRow.Email] = ++valueCounter[userRow.Email] || 0
    return valueCounter
  }, {})

  const duplicateRows = userRows.filter(userRow => emailCounts[userRow.Email])

  if (!duplicateRows.length) {
    return false
  }

  for (let i = 0; i < duplicateRows.length; i++) {
    const dupe = duplicateRows[i]
    if (dupe.Errors) {
      dupe.Errors = dupe.Errors + ', \nThis email is duplicated in this spreadsheet. Please remove one row.'
    } else {
      dupe.Errors = 'This email is duplicated in this spreadsheet. Please remove one row.'
    }
  }

  return true
}

/**
 * will throw an error if cannot validate
 * @param csvString
 * @param existingActiveUsers
 * @param existingActiveTeams
 *
 * @return [hasErrors, errorCsv, userRows, tooManyRows]:
 *  hasErrors: boolean, true if any validation errors came p
 *  errorCsv: string, csv of invalid rows
 *  userRows: array of objects, parsed CSV
 *  rowLength: integer, number of rows. if the row length is greater than 100, no other validations will occur
 */
const bulkUploadParseAndValidate = (csvString, existingActiveUsers, existingActiveTeams) => {
  let userRows = parseUserCsv(csvString)
  if (userRows.length > 100) {
    return [true, null, userRows, userRows.length]
  }
  const hasErrors = validateUserRows(userRows, existingActiveUsers, existingActiveTeams)
  const errorCsv = hasErrors ? Papa.unparse(userRows, {header: true, columns: ERROR_COLUMNS}) : null
  return [hasErrors, errorCsv, userRows, userRows.length]
}

export default bulkUploadParseAndValidate;
