// Add events here as they are supported
const supportedEvents = {
  onClick: () => true,
  onKeyUp: (e) => {
    const keyCode = e.keyCode;
    return keyCode === 13 || keyCode === 32; // space or enter
  },
  onTouchEnd: () => true,
};

const getFilter = (filter) => (handler) => (e) => {
  if (filter(e)) {
    handler(e);
  }
};

const markHandled = (event) => {
  event.preventDefault();
  event.bubbles = false;
};

const getActionEvents = (handled) => (callback) => {
  const handler = (e) => {
    if (handled) {
      markHandled(e);
    }

    if (callback) {
      callback(e);
    }
  };

  const events = {};
  for (const supported in supportedEvents) {
    if (!Object.prototype.hasOwnProperty.call(supportedEvents, supported)) {
      continue; // prevent prototype pollution
    }

    events[supported] = getFilter(supportedEvents[supported])(handler);
  }

  return events;
};

export default getActionEvents;

export const getHandledActionEvents = getActionEvents(true);

export const getUnhandledActionEvents = getActionEvents(false);
