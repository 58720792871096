import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import logo from '../assets/wbw-logo.png';
import {Auth} from 'aws-amplify';
import Progress from '../components/Progress';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) =>( {
  title: {
    textAlign: 'center'
  },
  container: {
    margin: '10% auto'
  },
  buttonStyle: {
    margin: '1rem'
  }
}))

const LogoutPage = ( props) => {
  const classes = useStyles()

  useEffect(() => {
    let mounted = true;
    const logout = async () => {
      try {
        await Auth.signOut();
        props.handleLogout();
      } catch (e) {
        toast.error(e.message.toString());
      } finally {
        props.history.push('/');
      }
    }
    if (mounted) {
      setTimeout(logout, 2000);
    }
    return () => mounted = false;
  }, [])

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid className={classes.title} item xs={12}>
        <img src={logo} alt='washington business week logo'/>
      </Grid>
      <Grid className={classes.title} item xs={12}>
        <Box color="#333">
          <Progress color="inherit">
            <Typography variant='h6'>Please wait, logging you out.</Typography>
          </Progress>
        </Box>
      </Grid>
    </Grid>
  );
};

LogoutPage.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default LogoutPage;
