import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
  }
}));

const StackPanel = (props) => {
  const classes = useStyles();

  return (
    <Box {...props} className={classes.root} />
  );
};

export default StackPanel;
