import React from 'react';
import PercentDiff from './PercentDiff';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import formatter from '../helpers/graphNumberFormat';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => (
  {
    class: {
      background: 'white',
      color: 'black',
      paddingLeft: 10,
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
      border: 'none',
      borderRadius: '3px',
      height: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '70px'
    },
    imageDivStyle: {
      paddingRight: 10,
      paddingTop: 14,
      width: '50px',
      height: '50px',
    },
    title: {
      fontSize: '1rem',
    },
    value: {
      fontSize: '1rem',
    },
    percetnDiff: {
      fontSize: '1rem',
    },
    imageStyle: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        width: '100%',
        minWidth: '15px',
        height: 'auto',
      },
    },
  }));

const valueFormat = (value, isCurrency) => {
  if (isCurrency) {
    return [formatter(value, 2), new Intl.NumberFormat(undefined, {style: 'currency', currency: 'USD'}).format(value)];
  }

  return [new Intl.NumberFormat(undefined).format(value), new Intl.NumberFormat(undefined).format(value)];
};

const SmallDashboardCard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.class}>
      <Tooltip title={valueFormat(props.amount.toFixed(props.fixedNumberLength), props.isCurrency)[1]}>
        <div className={classes.flex}>
          <div className={classes.imageDivStyle}>
            <img alt='Profile' src={props.image} className={classes.imageStyle} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingRight: 15,
              textAlign: 'right',
            }}>
            <p className={classes.title}>{props.title}</p>

            <p className={classes.value} >{valueFormat(props.amount.toFixed(props.fixedNumberLength), props.isCurrency)[0]}</p>

            <PercentDiff className={classes.percetnDiff} previous={props.previous} latest={props.amount}/>
          </div>
        </div>
      </Tooltip>
    </div>

  );
};

SmallDashboardCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.number,
  previous: PropTypes.number,
  fixedNumberLength: PropTypes.number,
  isCurrency: PropTypes.bool,
};

export default SmallDashboardCard;
