const initState = {
  teamIndex: null,
};

const NAME_INDEX = 'core/get-index';

export default (state = initState, action) => {
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case NAME_INDEX:
      return {
        ...state,
        teamIndex: action.payload,
      };
    default:
      return state;
  }
};

export function getTeamIndexAction(index) {
  return {
    type: NAME_INDEX,
    payload: index,
  };
}
