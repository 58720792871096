import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Transition from '../../components/ModalTransition';
import EndingModal from '../Ending';
import {QEndingDismissed} from '../../coresim/reducers/settings';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import { DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const StudentQEndingModal = ({quarter, showQEnding, qEndingDismissed, closeModal}) => {
  return (<Dialog
    style={{
      margin: 'auto',
    }}
    open={showQEnding && !qEndingDismissed}
    TransitionComponent={Transition}
    keepMounted
    fullScreen={true}
    fullWidth={true}
    disableBackdropClick={true}
    hideBackdrop={false}
    onClose={closeModal}
    aria-labelledby='alert-dialog-slide-title'
    aria-describedby='alert-dialog-slide-description'>
    <DialogContent style={{textAlign: 'center', paddingTop: 100}}>
      <IconButton
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          padding: '0px',
        }}
        onClick={closeModal}>
        <CloseIcon />
      </IconButton>
    </DialogContent>

    <EndingModal quarter={quarter} handleClose={closeModal}/>
  </Dialog>)
}

const mapStateToProps = (state) => {
  return {
    quarter: state.coresim.quarter.counter,
    showQEnding: state.settings.showQEnding,
    qEndingDismissed: state.settings.qEndingDismissed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => {
        dispatch(QEndingDismissed(true));
      },
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentQEndingModal);

StudentQEndingModal.propTypes = {
  quarter: PropTypes.number.isRequired,
  showQEnding: PropTypes.bool.isRequired,
  qEndingDismissed: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}
