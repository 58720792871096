import {Dialog} from '@material-ui/core';
import React from 'react';
import AnnouncementList from '../../components/Integrations/Blackboard/Announcements/AnnouncementList';
import { showAnnouncements } from '../../coresim/reducers/settings';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import Transition from '../../components/ModalTransition';
import ModalTitle from '../../components/ModalTitle';


const StudentAnnouncementsModal = ({showAnnouncements, simulation, closeModal}) => {
  return (
    <Dialog aria-labelledby="course-announcements-modal"
      open={showAnnouncements}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      onClose={closeModal}>
      <AnnouncementList title={<ModalTitle text='Announcements' closeModal={closeModal}/>}
        courseId={simulation && simulation.bbCourse ? simulation.bbCourse.id : null}/>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    showAnnouncements: state.settings.showAnnouncements,
    simulation: state.coresim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => dispatch(showAnnouncements(false)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAnnouncementsModal);

StudentAnnouncementsModal.propTypes = {
  showAnnouncements: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  simulation: PropTypes.object,
}
