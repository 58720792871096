import React from "react";
import TextField from "@material-ui/core/TextField";
import { ValidatorComponent } from "react-form-validator-core";
import formatCurrency from "../../helpers/formatCurrency";

export default class FormattedTextValidator extends ValidatorComponent {
  constructor(props) {
    super(props);

    this.ref = props.inputRef || React.createRef();
    this.cursor = null;
    this.decimal = props.decimal || (props.dollarOnly ? 0 : 2);
  }

  truncateDecimals = (text) => {
    if (this.decimal === 0) return text.replace(/\.\d*$/, "");
    const regex = new RegExp(`(\\.\\d{0,${this.decimal}})\\d*$`);
    return text.replace(regex, "$1");
  };

  formatTruncateCurrency = (text) => {
    if (!text || text === "-") return text || ""; // Don't format
    if (text === ".") text = "0."; // Format as zero with decimal
    if (isNaN(text)) return "";

    return this.truncateDecimals(formatCurrency(text));
  };

  componentDidUpdate(prevProps) {
    if (!this.cursor) return;

    let prevTail = this.formatTruncateCurrency(prevProps.value).substring(
      this.cursor - 1
    );
    if (prevTail.indexOf(".") === -1 && this.decimal !== 0) {
      prevTail = prevTail.substring(0, prevTail.length - 1);
    }
    let newPos = this.formatTruncateCurrency(this.props.value).lastIndexOf(
      prevTail
    );
    if (newPos === -1) {
      newPos = this.formatTruncateCurrency(this.props.value).indexOf(".");
    }
    if (prevTail === "" || newPos === -1) {
      newPos = this.formatTruncateCurrency(this.props.value).length;
    }
    this.ref.current.selectionStart = newPos;
    this.ref.current.selectionEnd = newPos;
  }

  render() {
    const { error, helperText, currency, value, onChange, ...rest } =
      this.props;
    const { isValid } = this.state;

    let handleChange = onChange;
    let renderValue = value;
    if ([undefined, null, ""].includes(renderValue)) {
      renderValue = undefined;
    } else if (renderValue !== "-") {
      if (renderValue === ".") renderValue = "0."; // Format as zero with decimal
      if (isNaN(renderValue)) renderValue = "";

      if (currency === true) {
        // Render as currency
        renderValue = this.formatTruncateCurrency(renderValue);
      } else {
        // Render as number (with thousands separator)
        renderValue = new Intl.NumberFormat("en-US", {
          useGrouping: true,
        }).format(renderValue);
      }
    }

    handleChange = (e) => {
      this.cursor = e.target.selectionStart;

      // Strip render-specific characters (e.g. thousands separator commas and '$')
      e.target.value = e.target.value.replace(/[^0-9.-]/g, "");
      e.target.value = this.truncateDecimals(e.target.value, this.decimal);

      onChange && onChange(e);
    };

    return (
      <TextField
        {...rest}
        error={!isValid || error}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
        value={renderValue}
        onChange={handleChange}
        inputRef={this.ref}
      />
    );
  }
}
