import React, {Component} from 'react';
import {Paper, Button, Dialog} from '@material-ui/core';
import {
  unitsProduced,
  addedPlantCapacity,
  qualityManagement,
  price,
  advertising,
  salespeople,
  productDevelopment,
  marketResearch,
  dividends,
  loansPaid,
  loansTaken,
  loanConfirmed,
  decisionsComplete,
  otherExpenses,
  nameChange,
  businessAs
} from '../../coresim/reducers/decisions';
import {toast} from 'react-toastify';
import {getTickAction, getTeamAction, getPreload} from '../../coresim/state/index';
import {API} from 'aws-amplify';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Progress from '../../components/Progress';
import Tooltip from '@material-ui/core/Tooltip';
import {simSubmitState} from '../../helpers/canSubmitDecisions';
import ConfirmModal from '../../components/ConfirmModal';
import Transition from '../../components/ModalTransition';

class DecisionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openDialog: false,
    };
  }

  confirmSubmit = (event) => {
    event.preventDefault();
    this.setState({openDialog: !this.state.openDialog})
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({loading: true});
    const updatedTeamData = this.props.coresim.teams.slice();
    const teamId = updatedTeamData[this.props.teamIndex].id;

    const body = {
      unitsProduced: this.props.decisions.unitsProduced,
      addedPlantCapacity: this.props.decisions.addedPlantCapacity,
      qualityManagement: this.props.decisions.qualityManagement,
      price: this.props.decisions.price,
      advertising: this.props.decisions.advertising,
      salespeople: this.props.decisions.salespeople,
      productDevelopment: this.props.decisions.productDevelopment,
      marketResearch: this.props.decisions.marketResearch,
      dividends: this.props.decisions.dividends,
      loansTaken: this.props.decisions.loansTaken,
      loansIndex: this.props.decisions.loansIndex,
      loanConfirmed: this.props.decisions.loanConfirmed,
      loansPaid: this.props.decisions.loansPaid,
      loanPaidConfirmed: this.props.decisions.loanPaidConfirmed,
      otherExpenses: this.props.decisions.otherExpenses,
      factoryCapacityFactor: this.props.decisions.factoryCapacityFactor,
      factoryDefectFactor: this.props.decisions.factoryDefectFactor,
      decisionsComplete: true,
      changeName: this.props.decisions.changeName
    };

    if (this.props.decisions.businessAs) {
      // HACK: This is to allow admins to set businessAs and
      // not have student submissions override if they did not set a value
      // otherwise, students submitting decisions after the admin DBA
      // selection would wipe the DBA selection
      body.businessAs = this.props.decisions.businessAs;
    }

    const quarter = this.props.coresim.quarter.counter;

    try {
      await API.put('state',
        `/simulations/${
          this.props.coresim.id
        }/teams/${teamId}/decisions/${quarter}`,
        {body}
      );
      toast.success('Success!');
    } catch (e) {
      toast.error(e.toString());
      this.setState({loading: false});
    }
    this.props.history.push('/');
  };

  render() {
    const vitalsHistory = this.props.coresim.teams[this.props.teamIndex].vitalsHistory;
    const cashOnHand = vitalsHistory[vitalsHistory.length - 1].cash +
   (this.props.decisions.loanConfirmed ?
     Number(this.props.decisions.loansTaken) : 0);
    const cashExceeded = cashOnHand < this.props.expenses;
    const enteringLoan = this.props.decisions.loansIndex &&
      !this.props.decisions.loanConfirmed;
    const submitted = this.props.coresim.teams[this.props.teamIndex].decisions.decisionsComplete;
    const submittableState = simSubmitState({
      user: this.props.user,
      activeQuarter: this.props.coresim.quarters[this.props.coresim.quarter.counter],
      decisionsSubmitted: submitted
    });
    return (
      <form onSubmit={this.confirmSubmit}>
        <Paper style={{padding: 16}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {
              !submittableState.canSubmit() ?
                <Tooltip title={submittableState.closedReason()}>
                  <span>
                    <Button fullWidth type="submit" variant="contained" color="primary" disabled>Submit</Button>
                  </span>
                </Tooltip> :
                <Button
                  onClick={this.handleOpenDialog}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    cashExceeded ||
                    enteringLoan ||
                    this.state.loading === true}
                >Submit
                  {this.state.loading && <Progress variant="overlay" size='1.5em' />}
                </Button>
            }
            <Dialog
              style={{
                margin: 'auto',
              }}
              open={this.state.openDialog}
              TransitionComponent={Transition}
              keepMounted
              disableEscapeKeyDown={true}
              fullScreen={false}
              fullWidth={false}
              disableBackdropClick={true}
              hideBackdrop={false}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'>
              <ConfirmModal handleConfirm={(event) => this.handleSubmit(event)} handleCancel={this.confirmSubmit} message={`Please double check your submission. You will not be able to re-submit.`}/>
            </Dialog>
          </div>
        </Paper>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    expenses: state.expenses,
    coresim: state.coresim,
    teamIndex: state.teamIndex.teamIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      unitsProduced: (units) => unitsProduced(units),
      addedPlantCapacity: (units) => addedPlantCapacity(units),
      qualityManagement: (units) => qualityManagement(units),
      price: (units) => price(units),
      advertising: (units) => advertising(units),
      salespeople: (units) => salespeople(units),
      productDevelopment: (units) => productDevelopment(units),
      marketResearch: (units) => marketResearch(units),
      dividends: (units) => dividends(units),
      loansPaid: (units) => loansPaid(units),
      loansTaken: (units) => loansTaken(units),
      loanConfirmed: (value) => loanConfirmed(value),
      decisionsComplete: (units) => decisionsComplete(units),
      getTickAction: (decisions) => getTickAction(decisions),
      getTeamAction: (teamId, nestedAction) => getTeamAction(teamId, nestedAction),
      otherExpenses: (units) => otherExpenses(units),
      getPreload: (data) => getPreload(data),
      nameChange: (units) => nameChange(units),
      businessAs: (data) => businessAs(data)
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DecisionsForm);

DecisionsForm.propTypes = {
  history: PropTypes.object,
  coresim: PropTypes.object,
  expenses: PropTypes.number,
  decisions: PropTypes.object,
  getTeamAction: PropTypes.func,
  teamIndex: PropTypes.number,
  getPreload: PropTypes.func,
  user: PropTypes.object.isRequired,
};
