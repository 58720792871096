/**
 * The identity function
 *
 * @param x - the dependent variable
 * @returns {number}
 *
 */

const identity = (x) => x;

export default identity;
