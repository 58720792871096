import logistic from './base/logistic';

const rate = 1.5349; // The constant needed to peg the graph midpoint at expectedPrice

/**
 * Function given by https://www.desmos.com/calculator/8kptsyazjw
 * to determine the sales volume for the given price.
 *
 * @param {number} maxVolume - The maximum sales available as matter of market size
 */
const priceVolume = (maxVolume) =>
  /**
   * @param {number} expectedPrice - The expected price of the product (given sentiment)
   */
  (expectedPrice) =>
    /**
     * @param {number} x - The actual price of the product
     * @returns {number} - a number from 0 to maxVolume
     */
    (x) =>
      expectedPrice === 0 ?
        0 :
        Math.floor(
          2 *
              maxVolume *
              (logistic(1)(1 / (logistic(rate / expectedPrice)(x) - 0.5) - 2) -
                0.5)
        );

export default priceVolume;
