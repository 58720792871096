import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box,Grid, Button, Typography, makeStyles} from '@material-ui/core';
import formatCurrency from '../../helpers/formatCurrency';
import { connect } from "react-redux";
import { addedPlantCapacity } from "../../coresim/reducers/decisions";
import { bindActionCreators } from "redux";
import factory from "../../config/factoryCapacity"

const useStyles = makeStyles((theme) => ({
  infoTip: {
    margin: theme.spacing(0, .5),
    padding: theme.spacing(.2, .6),
    borderRadius: 5,
    color: 'white',
    cursor: 'help',
  },
  positiveTip: {
    backgroundColor: 'green',
  },
  negativeTip: {
    backgroundColor: 'red',
  },
  valueLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(.5),
  },
  plantBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: 'rgba(250, 0, 250, 0.08)',
    },
  },
  overviewRow: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  },
  overviewGridItem: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  plantRow: {
    [theme.breakpoints.up('md')]: {
      '& div:not(:last-child)': {
        marginRight: theme.spacing(1)
      }
    }
  },
  confirmButton: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    },
  }
}));

const Option = ({ plant, onSelect }) => {
  const classes = useStyles();
  const Icon = plant.icon;

  return (
    <Box
      p={1}
      border="1px solid #ccc"
      borderRadius={5}
      style={{ marginBottom: 10 }}

      className={classes.plantBox}
      onClick={onSelect}
    >
      <Grid container className={classes.overviewRow}>
        <Grid item xs={2} md={12} justify='center' className={classes.overviewGridItem}>
          <Icon />
        </Grid>
        <Grid item xs={6} md={12} className={classes.overviewGridItem}>
          <Typography variant="h6">{plant.type}</Typography>
        </Grid>
        <Grid item xs md={12} className={classes.overviewGridItem}>
          <Button color="secondary">More Info</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

Option.propTypes = {
  plant: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

const OptionDetails = ({
  confirmed,
  plant,
  onCancel,
  onSave,
  value,
  cash,
  addedPlantCapacity,
}) => {
  const classes = useStyles();
  const Icon = plant.icon;
  const hasError = Boolean(value && Number(value) > cash);
  return (
    <Box maxWidth={{xs: '100%', md: 400}} mt={2} display="flex" flexDirection="column" className={classes.plantRow}>
      <Box display="flex" flexDirection="row">
        <Icon />
        <Typography component="header" variant="h6">
          {plant.type}
        </Typography>
      </Box>
      <Typography variant="body1">
        {plant.description}
      </Typography>

      {confirmed ?
        <Typography variant="body2">
          Amount Taken: {formatCurrency(value)}
        </Typography> : null
      }

      <Box
        pt={2}
        display="flex"
        flexDirection={{xs: "column-reverse", md: "row"}}
        justifyContent="space-evenly"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            addedPlantCapacity({addedPlantCapacity: 0, factoryCapacityFactor: 1, factoryDefectFactor: 1});
            onCancel && onCancel()
          }}
        >
          Cancel
        </Button>
        {!confirmed &&
          <Button
            variant="contained"
            color="primary"
            disabled={hasError}
            className={classes.confirmButton}
            onClick={() => {
              addedPlantCapacity({addedPlantCapacity: plant.price, factoryCapacityFactor: plant.productionCapacityFactor, factoryDefectFactor: plant.defectRateFactor})
              onSave && onSave() }}
          >
            Purchase Upgrade ${plant.price}
          </Button>
        }
      </Box>

    </Box>
  );
};


OptionDetails.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  plant: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  maxOption: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  inputRef: PropTypes.object.isRequired,
  cash: PropTypes.number,
  addedPlantCapacity: PropTypes.func.isRequired,
};

const MakeOptionDecision = ({
  coresim,
  decisions,
  teamIndex,
  handleUpdate,
  handleChange,
  addedPlantCapacity,
}) => {
  const vitalsHistory = coresim.teams[teamIndex].vitalsHistory;
  const valuation = vitalsHistory[vitalsHistory.length - 1].valuation;
  const cash = vitalsHistory[vitalsHistory.length - 1].cash;
  // const config = coresim.teams[teamIndex].configuration;
  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    let aborted = false;
    if (ref.current && Number.isFinite(decisions.factoryIndex) && !aborted) {
      ref.current.focus();
    }
    return () => aborted = true;
  }, [ref.current, decisions.factoryIndex]);

  return (
    !Number.isFinite(decisions.factoryIndex) ?
      <Box className={classes.plantRow} display="flex" style={{ marginTop: 10 }} flexWrap='wrap' flexDirection="row" justifyContent="end">
        {
          factory.map((plant, i) =>
            <Option
              plant={plant}
              key={i}
              onSelect={() => handleUpdate({name: 'factoryIndex', value: i})}
            />)
        }
      </Box> :
      <OptionDetails
        maxOption={
          Math.max(0, valuation) * factory[decisions.factoryIndex].maxOptionToValuation
        }
        plant={factory[decisions.factoryIndex]}
        onCancel={() => {
          handleUpdate({name: 'factoryIndex', value: undefined});
          handleUpdate({name: 'factoryConfirmed', value: false});
        }}
        handleChange={handleChange}
        onSave={() => handleUpdate({ name: 'factoryConfirmed', value: true })}
        value={decisions.addedPlantCapacity}
        confirmed={decisions.factoryConfirmed}
        inputRef={ref}
        cash={cash}
        addedPlantCapacity={addedPlantCapacity}
      />

  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addedPlantCapacity: (units) => addedPlantCapacity(units),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeOptionDecision);

MakeOptionDecision.propTypes = {
  coresim: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  addedPlantCapacity: PropTypes.func.isRequired
};
