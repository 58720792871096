import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Warning from '@material-ui/icons/Warning';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#d32f2f',
    borderRadius: 4,
    color: 'white',
    width: '100%'
  },
  warningContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warningIcon: {
    fontSize: '1.5rem',
    verticalAlign: 'bottom'
  }
}));

const WarningBox = ({children}) => {
  const classes = useStyles();
  return <Box p={2} mb={1} className={classes.root}>
    <Typography component='div'>
      <Grid container>
        <Grid item xs={2} sm={1} className={classes.warningContainer}>
          <Warning className={classes.warningIcon}/>
        </Grid>
        <Grid item xs={10} sm={11}>
          {children}
        </Grid>
      </Grid>
    </Typography>
  </Box>
}

WarningBox.propTypes = {
  children: PropTypes.node
}

export default WarningBox;
