import React from 'react';
import Row from './BusinessReportRow';
import PropTypes from 'prop-types';
import {Typography, Divider, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  subheader: {
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '10px 0'
    }
  },
}))

const BusinessValuation = ({stockLast, stockCurrent, valueLast, valueCurrent, netFlowLast, netFlowCurrent}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        component="div" className={classes.subheader}>Account Summary</Typography>
      <Divider/>
      <Row title='Stock Value' last={stockLast} current={stockCurrent}/>
      <Row title='Business Valuation' last={valueLast} current={valueCurrent}/>
      <Row title='Net Cash Flow' last={netFlowLast} current={netFlowCurrent}/>
    </div>
  )
};

BusinessValuation.propTypes = {
  stockLast: PropTypes.number,
  stockCurrent: PropTypes.number.isRequired,
  valueLast: PropTypes.number,
  valueCurrent: PropTypes.number.isRequired,
  netFlowLast: PropTypes.number,
  netFlowCurrent: PropTypes.number.isRequired,
}

export default BusinessValuation;