import React from 'react';
import Leaderboard from '../components/Leaderboard';
import PropTypes from 'prop-types';

const Ending = ({coresim}) => {

  return (
    <div>
      <div style={{marginBottom: 250, textAlign: 'center'}}>
        <h2>The Leaderboard</h2>
        <div style={{marginTop: '25px', paddingLeft: '10%', paddingRight: '10%'}}>
          {coresim ? <Leaderboard coresim={coresim}/> : <Leaderboard />}
        </div>


      </div>
    </div>
  );
};

export default Ending;

Ending.propTypes = {
  coresim: PropTypes.object,
};
