/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'us-west-2',
	aws_cognito_region: 'us-west-2',
	aws_user_pools_id: 'us-west-2_cq0bxBlsQ',
	aws_user_pools_web_client_id: '3ln3vv6lgi6c08a64qio9ull3o',
	oauth: {},
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
