import {TableRow} from '@material-ui/core';
import React from 'react';
import renderCell from './renderCell';

const maxString = 180;

const renderRow = (row, cols, cellBodyStyle) => (rowData, rowIndex) => (
  <TableRow key={rowData._id}>
    {row(rowData, rowIndex).map((cell, cellIndex) => {
      let smallCell;
      if (typeof(cell) === 'string' && cell.length > maxString) {
        smallCell = `${cell.substring(0, maxString)}...`;
      } else {
        smallCell = cell;
      }
      return (
        renderCell(cols ? cols[cellIndex] : {}, cellBodyStyle)(
          smallCell,
          cellIndex,
          cols ?
            `${rowData.id}:${cols[cellIndex].name}` :
            `${rowData.id}:${cellIndex}`
        )
      );
    }

    )}
  </TableRow>
);

export default renderRow;
