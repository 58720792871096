import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import StackRoute from '../routing/StackRoute';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    position: (root) => root ? 'relative' : 'unset',
    overflow: 'hidden',
    height: `calc(100% + ${theme.spacing(3)}px)`,
    width: `calc(100% + ${theme.spacing(6)}px)`,
    margin: theme.spacing(0, -3, -3, -3)
  }
}));

const AdminPanel = ({children, label, root}) => {
  const classes = useStyles(root);
  const match = useRouteMatch();

  return (
    <Box className={classes.root}>
      <StackRoute path={`${match.url}`} label={label}>
        {children}
      </StackRoute>

    </Box>
  );
};

AdminPanel.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  root: PropTypes.bool,
};

AdminPanel.defaultValues = {
  root: true,
}

export default AdminPanel;
