import React, {useState, useRef, useEffect} from 'react';
import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import formatCurrency from '../../helpers/formatCurrency';
import DecisionTooltip from './DecisionTooltip';
import {CheckCircle, Warning} from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles( (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  statusIcon: {
    fontSize: '1.2rem !important',
    paddingRight: 4,
    verticalAlign: 'bottom',
  },
  statusBox: {
    padding: theme.spacing(2),
    border: '.4px solid #ccc',
    backgroundColor: '#eaeaea',
    borderRadius: 5,
    marginRight: theme.spacing(2),
  },
  warningIcon: {
    color: 'red',
  },
  checkIcon: {
    color: 'green',
  },
}));

const NoPayment = () => (
  <Typography variant="body1">
    No payment due
  </Typography>
);

const CustomPayment = ({
  handleChange,
  value,
  onCommit,
  onCancel,
  decisions,
  quarter,
  vitals,
  expenses,
  payoff,
  onToolTipToggle,
  inputRef,
  Component
}) => {
  const classes = useStyles();
  const error = (Number(value) > payoff) &&
    'You cannot pay more than the loan payoff amount!';

  return (
    <Box
      display="flex"
      flexDirection="column">
      <DecisionTooltip
        disableHoverListener
        placement="top-start"
        quarter={quarter}
        vitals={vitals}
        decisions={decisions}
        expenses={expenses}
        onOpen={() =>
          onToolTipToggle && onToolTipToggle(true)}
        onClose={() => onToolTipToggle &&
              onToolTipToggle(false)}
      >
        <Component
          id="loansPaid"
          name="loansPaid"
          label="Loan Payment"
          onNumberChange={(value) => handleChange('loansPaid')(value)}
          value={value}
          error={Boolean(error)}
          helperText={error || ''}
          inputRef={inputRef}
        />
      </DecisionTooltip>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={!value || Boolean(error)}
        onClick={() => onCommit && onCommit()}
      >
            Pay Loan
      </Button>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={() => onCancel && onCancel()}
      >
            Cancel
      </Button>

    </Box>
  );
};

CustomPayment.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  onCommit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  decisions: PropTypes.object.isRequired,
  quarter: PropTypes.number.isRequired,
  vitals: PropTypes.object.isRequired,
  expenses: PropTypes.object.isRequired,
  payoff: PropTypes.number.isRequired,
  onToolTipToggle: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired,
}

const PaymentOptions = ({onCustom, onMinimum, onPayoff}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="stretch"
      height="100%"
    >
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        onClick={onCustom}
      >
          Pay Other Amount
      </Button>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={onMinimum}>
          Pay Minimum
      </Button>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={onPayoff}>
          Payoff Loan
      </Button>
    </Box>
  );
};

PaymentOptions.propTypes = {
  onCustom: PropTypes.func.isRequired, 
  onMinimum: PropTypes.func.isRequired, 
  onPayoff: PropTypes.func.isRequired
}

const PaymentDue = ({
  liability,
  minimumDue,
  decisions,
  quarter,
  vitals,
  expenses,
  onToolTipToggle,
  handleChange,
  handleUpdate,
  Component
}) => {
  const [enterCustom, setEnterCustom] = useState(false);
  const ref = useRef();

  useEffect(() => {
    let abort = false;
    if (enterCustom && !abort && ref.current) {
      ref.current.focus();
    }

    return () => abort = true;
  },
  [ref.current, enterCustom]
  );

  const payoff = vitals.payoff;

  return (
    decisions.loanPaidConfirmed ?
      <PaymentConfirmed
        paid={decisions.loansPaid}
        minimumDue={minimumDue}
        onEdit={
          () => {
            handleUpdate({name: 'loanPaidConfirmed', value: false});
            handleChange('loansPaid')(undefined);
          }
        }
      /> :
      enterCustom ?
        <CustomPayment
          quarter={quarter}
          vitals={vitals}
          decisions={decisions}
          expenses={expenses}
          onToolTipToggle={onToolTipToggle}
          onCancel={() => {
            setEnterCustom(false);
            handleChange('loansPaid')(undefined);
          }}
          handleChange={handleChange}
          value={decisions.loansPaid}
          minimumDue={minimumDue}
          payoff={payoff}
          onCommit={
            () => handleUpdate({name: 'loanPaidConfirmed', value: true})
          }
          inputRef={ref}
          Component={Component}
        /> :
        <PaymentOptions
          onCustom={() => {
            setEnterCustom(true);
          }}
          onMinimum={
            () => {
              handleChange('loansPaid')(minimumDue);
              handleUpdate({name: 'loanPaidConfirmed', value: true});
            }
          }
          onPayoff={
            () => {
              handleChange('loansPaid')(payoff);
              handleUpdate({name: 'loanPaidConfirmed', value: true});
            }
          }
        />
  );
};

PaymentDue.propTypes = {
  liability: PropTypes.number.isRequired,
  minimumDue: PropTypes.number.isRequired,
  decisions: PropTypes.object.isRequired,
  quarter: PropTypes.number.isRequired,
  vitals: PropTypes.object.isRequired,
  expenses: PropTypes.number.isRequired,
  onToolTipToggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired
}

const MinPaymentMet = () => {
  const classes = useStyles();
  return (
    <Typography variant="body1">
      <CheckCircle
        className={classnames(classes.statusIcon, classes.checkIcon)}
      />
      Minimum payment satisfied
    </Typography>
  );
};

const MinPaymentShort = () => {
  const classes = useStyles();
  return (
    <Typography variant="body">
      <Warning
        className={classnames(classes.statusIcon, classes.warningIcon)}
      />
      Minimum payment short
    </Typography>
  );
};

const PaymentStatus = ({minimumDue, paid}) => {
  const minPaid = minimumDue <= paid;

  return (
    minPaid ?
      <MinPaymentMet /> : <MinPaymentShort />
  );
};

PaymentStatus.propTypes = {
  minimumDue: PropTypes.number.isRequired, 
  paid: PropTypes.number.isRequired
}

const PaymentConfirmed = ({minimumDue, paid, onEdit}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.statusBox}
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
    >
      <Typography variant="h5">
        {`Paying ${formatCurrency(paid)}`}
      </Typography>
      <PaymentStatus minimumDue={minimumDue} paid={paid} />
      <Button
        className={classes.button}
        color="primary"
        variant="text"
        onClick={onEdit}
      >
          Pay Other Amount
      </Button>
    </Box>
  );
};

PaymentConfirmed.propTypes = {
  minimumDue: PropTypes.number.isRequired, 
  paid: PropTypes.number.isRequired, 
  onEdit: PropTypes.func.isRequired
}

const LoanPayment = ({
  coresim,
  decisions,
  teamIndex,
  handleChange,
  expenses,
  onToolTipToggle,
  handleUpdate,
  Component
}) => {
  const vitalsHistory = coresim.teams[teamIndex].vitalsHistory;
  const latest = vitalsHistory[vitalsHistory.length - 1];
  const vitals = coresim.teams[teamIndex].vitalsHistory[coresim.teams[teamIndex].vitalsHistory.length - 1];
  const quarter = coresim.quarter.counter;

  return latest.liabilitiesBankLoan ?
    <PaymentDue
      liability={latest.liabilitiesBankLoan}
      minimumDue={latest.loanPaymentDue}
      handleChange={handleChange}
      handleUpdate={handleUpdate}
      quarter={quarter}
      vitals={vitals}
      decisions={decisions}
      expenses={expenses}
      onToolTipToggle={onToolTipToggle}
      Component={Component}
    /> : <NoPayment />;
};

LoanPayment.propTypes = {
  coresim: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  expenses: PropTypes.number.isRequired,
  onToolTipToggle: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired
}

export default LoanPayment;
