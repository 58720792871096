import React, {useState} from 'react';
import businessVariableData from '../data/businessVariableData';
import SimInputCard from '../../components/SimInputCard';
import DecisionsForm from './DecisionsForm';
import SimStatusCard from './SimStatusCard';
import {Box} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {useUserContext} from '../../providers/userProvider';

const SimulationVitals = (props) => {
  const latest = props.coresim.teams[props.teamIndex].vitalsHistory[props.coresim.teams[props.teamIndex].vitalsHistory.length - 1];
  const [toolTipShown, setToolTipShow] = useState(false);
  const user = useUserContext();
  return (
    <Box component='div'>
      <ValidatorForm onSubmit={()=>{return '';}}>
        <SimStatusCard
          showError={!toolTipShown}
          latest={latest}
          quarterNum={props.coresim.quarter.counter}
          expendatures={props.expenses}
          decisions={props.decisions}
          loanBalanceDue={latest.liabilitiesBankLoan}
          activeQuarter={props.coresim.quarters[props.coresim.quarter.counter]}
          completed={props.coresim.completed}
          user={user}
          decisionsSubmitted={props.decisionsSubmitted}
        />
        <br />
        {businessVariableData.map((business, i) => (
          <SimInputCard
            key={i}
            index={i}
            icon='business'
            businessData={business}
            teamIndex={props.teamIndex}
            onToolTipToggle={setToolTipShow}
          />
        ))}
      </ValidatorForm>
      <DecisionsForm
        history={props.history}
        teamIndex={props.teamIndex}
        user={user}
        childProps={props} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    coresim: state.coresim,
    expenses: state.expenses,
    decisionsSubmitted: state.coresim.teams[state.teamIndex.teamIndex].decisions.decisionsComplete
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationVitals);

SimulationVitals.propTypes = {
  teamIndex: PropTypes.number,
  sim: PropTypes.object,
  history: PropTypes.object,
  coresim: PropTypes.object,
  expenses: PropTypes.number,
  decisions: PropTypes.object,
  user: PropTypes.object,
  decisionsSubmitted: PropTypes.bool
};
