import logistic from './base/logistic';

/**
 * Function, given by https://www.desmos.com/calculator/xmqhuw5ge4,
 * to determine QA sentiment as a value from 0 to 1
 * with a result of .5 at the expected defect rate and increasing
 * sentiment when defect rates decrease. Furthermore, the sentiment
 * is scaled proportionally about the midpoint such that an actual
 * defect rate that is half the expected rate yeilds an increase
 * of sentiment that is exactly the sentiment *decrease* experienced
 * by a a defecte rate that is twice the expected rate.
 *
 * The amount that is increased is determined by sensitivity, which
 * should be kepth within the boundaries of 1 to 4.
 * Experimentally, a sensitivity of 2 is an average result that will
 * result in a 30 point shift from midpoint on a halving / doubling
 *
 * @param {number} sensitivity - A number between 1 and 4 to determine how sensitive the sentiment is, with a sensitivity of 2 being an average result that will result in a 30 point shift from midpoint on a halving / doubling
 *
 */
const qaSentiment = (sensitivity) =>
  /**
   * @param {number} expectedDefects - The defected expected for the product this quarter
   */
  (expectedDefects) =>
    /**
     * @param {number} actualDefects - The actual product defects this quarter
     * @returns {number} - A number between 0 and 1 to indicate sentiment, with 0 being poor, .5 being average, and 1 being excellent
     */
    (actualDefects) =>
      1 -
      logistic(sensitivity / Math.pow(expectedDefects * actualDefects, 0.5))(
        actualDefects - expectedDefects
      );

export default qaSentiment;
