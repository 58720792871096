import React, {useState, useEffect, useMemo, useCallback} from 'react';
import tableHoc from '../../components/Tables/tableHoc';
import {API} from 'aws-amplify';
import { Box, Button, Typography, Grid, makeStyles, Dialog } from '@material-ui/core';
import IconButton from '../../components/widgets/IconButton';
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { useSelectionContext } from '../../providers/selectionProvider';
import selectableTableHoc from '../../components/Tables/selectableTableHoc';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';
import Transition from '../../components/ModalTransition';
import DeleteModal from '../../components/DeleteModal';

const useStyles = makeStyles((theme) => ({
  buttonList: {
    '& .MuiButton-root + .MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const header = () => [
  {
    isSortable: true,
    label: 'Team Name',
    name: 'teamName',
  },
  {
    isSortable: true,
    label: 'Last Modified',
    name: 'lastModifiedDate',
  },
  {
    isSortable: true,
    label: 'Created',
    name: 'createdDate',
  },
  {
    label: ''
  }
];

const rowDefinition = (deleteTeam, editTeam) => (row, index) => [
  row.teamName,
  row.lastModifiedDate ? new Date(row.lastModifiedDate).toLocaleString() : "",
  row.createdDate ? new Date(row.createdDate).toLocaleString() : "",
  <Grid key="actions" container justify="flex-end">
    {editTeam &&
      <Grid item>
        <IconButton size="small"
          variant="text"
          color="primary"
          icon={CreateIcon}
          onClick= { (e) => {
            e.preventDefault();
            editTeam(row._id);
          }}
        >
          edit
        </IconButton>
      </Grid>
    }
    {deleteTeam &&
      <Grid item>
        <IconButton
          size="small"
          variant="text"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            deleteTeam(row);
          }}
          icon={DeleteIcon}
        >
          delete
        </IconButton>
      </Grid>}
  </Grid>
];

const TeamList = ({reload, isSelect}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const selection = useSelectionContext();
  const breadcrumbActions = useBreadcrumbActions();
  const [deleting, setDeleting] = useState();

  const classes = useStyles();

  useEffect(() => {
    const abortObj = {
      aborted: false
    };

    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }

  }, [reload]);

  useEffect(() => {
    if (!teams || !teams.length || !isSelect) {
      return;
    }

    const teamsMap = new Map(
      teams.map((t) => [
        t._id,
        t
      ])
    );

    const updatedSelectionItems =
      selection[0].reduce((newSelection, value) => {
        teamsMap.has(value) && newSelection.push(teamsMap.get(value));
        return newSelection;
      }, []);

    selection[1].init(updatedSelectionItems);
  }, [teams, isSelect]);

  const load = async (abortObj) => {
    try {
      const teams = await API.get('mongo', '/teams/');
      if (!abortObj || !abortObj.aborted)
      {
        setTeams(teams.filter((t) => t.active !== false));
      }
    } catch (e) {
      toast.error('Error retrieving teams.');
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async (id, zoom) => {
    try {
      setLoading(true);

      if (zoom && zoom.meetingId && zoom.meetingId.length > 0) {
        try {
          await API.del('zoom', `/meeting/${zoom.meetingId}`);
        } catch (err) {
          // swallow this error because in prod the zoom rooms aren't there - maybe they are being manually deleted?
          console.error("unable to delete zoom room: ", err.toString());
        }
      }

      await API.put('mongo', `/teams/${id}`,
        {
          body: {
            zoom: null,
            active: false
          }
        }
      );
      toast.success('team deleted');
    } catch(e) {
      toast.error('Error deleting team');
    } finally {
      setDeleting()
      load();
    }

  }, []);

  const handleDelete = useCallback((row) => {
    setDeleting(row);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting();
  }, []);

  const onEdit = useCallback((id) => {
    history.push(`${match.url}/${id}`);
  }, []);

  const Table = useMemo(() => isSelect ?

    selectableTableHoc(rowDefinition(handleDelete, onEdit, deleting), header)
    :
    tableHoc(rowDefinition(handleDelete, onEdit, isSelect, deleting), header),
  [handleDelete, onEdit, isSelect, handleDelete, handleCancelDelete]);

  const selectionProps = useMemo(() => {
    if (!isSelect) {
      return {};
    }

    return {
      selected: selection[0],
      onSelectAdd: selection[1].add,
      onSelectRemove: selection[1].findAndRemove,
      onSelectAll: selection[1].init,
    };
  }, [selection, isSelect]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        variant="h4">
        {isSelect && 'Select '}Teams
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {isSelect &&
            <Typography variant="body2">
              <em>
                {selection[0].length} item(s) selected
              </em>
            </Typography>
          }
        </Box>
        <Box
          className={classes.buttonList}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          py={2}
        >
          {isSelect &&
            <Button
              variant="contained"
              color="secondary"
              onClick={
                () => breadcrumbActions.pop()
              }
            >
              done
            </Button>
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={
              () => history.push(`${match.url}/create`)
            }
          >
            + new
          </Button>
        </Box>
      </Box>

      <Table data={teams}
        sortField='name'
        sortAscending
        loading={loading}
        {...selectionProps}
      />
      <Dialog
        style={{
          margin: 'auto',
        }}
        open={Boolean(deleting)}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <DeleteModal handleConfirm={() => handleConfirmDelete(deleting._id, deleting.zoom)} handleCancel={handleCancelDelete} what={`Team "${(deleting && (deleting.name || deleting.teamName)) || ''}"`}/>
      </Dialog>
    </Box>

  );
};

TeamList.propTypes = {
  reload: PropTypes.string.isRequired,
  isSelect: PropTypes.bool,
}

export default TeamList;
