import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Setting from './containers/Setting';
import Admin from './admin/Admin';
import Login from './containers/Login';
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import Sandbox from './containers/Sandbox';
import PropTypes from 'prop-types';
import AdminMinicase from './admin/Minicase/';
import AdminNews from './admin/News/';
import AdminUser from './admin/User/';
import AdminTeam from './admin/Team/';
import AdminSim from './admin/Simulation/';
import AdminBulkUpload from './admin/BulkUpload';
import AdminConfiguration from './admin/Configuration';

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({component: C, props: cProps, ...rest}) => {
  return (<Route
    {...rest}
    render={(props) =>
      cProps.isAuthenticated === true ?
        <MainLayout childProps={cProps}>
          <C {...props} {...cProps} />
        </MainLayout> :
        <Login {...props} {...cProps} />
    }
  />
  );
};


const EmptyRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const Routes = ({childProps}) => (
  <Switch>
    <DashboardRoute exact path='/' component={Admin} props={childProps} />

    <DashboardRoute
      path="/admin/configurations"
      component={AdminConfiguration}
      props={childProps}
    />
    <DashboardRoute
      path='/admin/simulations'
      component={AdminSim}
      props={childProps}
    />

    <DashboardRoute
      path='/admin/teams'
      component={AdminTeam}
      props={childProps}
    />
    <DashboardRoute
      path='/admin/users'
      component={AdminUser}
      props={{...childProps, bulkUpload: true}}
    />
    <DashboardRoute
      path='/admin/mini'
      component={AdminMinicase}
      props={childProps}
    />
    <DashboardRoute
      path='/admin/news'
      component={AdminNews}
      props={childProps}
    />
    <DashboardRoute
      path='/admin/bulk-upload'
      component={AdminBulkUpload}
      props={childProps}
    />
    <DashboardRoute
      path='/login'
      component={Login}
      props={childProps}
      exact />

    <DashboardRoute
      path='/setting'
      component={Setting}
      exact
      props={childProps}
    />
    <DashboardRoute
      path='/sandbox'
      component={Sandbox}
      exact
      props={childProps}
    />

    <EmptyRoute component={NotFound} />
  </Switch>
);

DashboardRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object,
};

EmptyRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object,
};

Routes.propTypes = {
  childProps: PropTypes.object,
};

export default Routes;

Routes.propTypes = {
  childProps: PropTypes.object
}

DashboardRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object
}

EmptyRoute.propTypes = {
  component: PropTypes.elementType
}
