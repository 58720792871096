import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import TeamForm from './TeamForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';
import {initVitals} from '../../coresim/state/configuration';
import decisionsReducer from '../../coresim/state/team/decisions';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const getInitialTeam = () => ({
  id: uuidv4(), // TODO: remove id field and use _id
  teamName: '',
  members: [],
  vitalsHistory: [initVitals], // fixme WHY IS THIS IN THE FRONTEND
  decisions: decisionsReducer(), // fixme WHY IS THIS IN THE FRONTEND
});

const CreateTeam = ({onReload}) => {
  const [loading, setLoading] = useState(false);
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);
    try {
      await API.post('mongo', '/teams', {body: form});
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error('Unable to create team - either Zoom room creation has failed or team could not be saved.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create Team
      </Typography>
      <TeamForm
        onSubmit={onSubmit}
        initialValue={getInitialTeam()}
        disabled={loading}
      />
    </Box>
  );
}

CreateTeam.propTypes = {
  onReload: PropTypes.string.isRequired
}

export default CreateTeam;
