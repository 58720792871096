import {TableCell} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const AsyncTableCell = ({content, ...remaining}) => {
  const [renderContent, setRenderContent] = useState('Loading...');

  useEffect(() => {
    let abort = false;
    if (content instanceof Promise) {
      content.then((result) => !abort && setRenderContent(result));

      return () => {
        abort = true;
      };
    }


    return;
  }, [content]);

  return (
    <TableCell {...remaining}>
      {content instanceof Promise ? renderContent : content}
      {/* {typeof(content) === 'object' ? renderContent : content} */}
    </TableCell>

  );
};

AsyncTableCell.propTypes = {
  content: PropTypes.any.isRequired
};

const renderCell = (col, cellBodyStyle) => (cell, cellIndex, key) => {
  const contents = typeof cell === 'function' ? cell(cellIndex) : cell;

  return (
    <AsyncTableCell
      key={key}
      classes={{body: cellBodyStyle}}
      align={col.numeric ? 'right' : 'left'}
      padding={col.disablePadding ? 'none' : 'default'}
      sortDirection={col.sortDirection}
      content={contents}
    />
  );
};

export default renderCell;
