import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {cachedAPIGet} from '../../helpers/cachedAPIRequests';
import highlights from '../../providers/highlights';

import SelectFlexBox from '../Forms/SelectFlexBox';
import SelectField from '../Forms/SelectField'
import ClearButton from '../Forms/ClearButton';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 300,
    [theme.breakpoints.down('sm')] : {
      marginBottom: theme.spacing(2),
    }
  },
  mappingRow: {
    minHeight: theme.spacing(8),
  },
  highlightTitle: {
    fontStyle: 'italic',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
    [theme.breakpoints.down('sm')] : {
      marginTop: theme.spacing(2),
      width: '100%',
    }
  },
  highlightButton: {
    height: 'fit-content',
  },
  activeMapping: {
    [theme.breakpoints.up('md')]: {
      minWidth: 300,
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')] : {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}));

const BBCurriculum = ({course, disabled, onSave}) => {
  const classes = useStyles();
  const [modules, setModules] = useState([]);
  const [curriculum, setCurriculum] = useState(course.curriculum ? course.curriculum : {});

  const load = async (abortObj) => {
    try {
      const courseModules = await cachedAPIGet('blackboard', `/courses/${course.id}/modules`, null, 3600);
      if (!abortObj || !abortObj.aborted) {
        setModules(courseModules);
      }
    } catch (e) {
      toast.error('Unable to retrieve course modules from Blackboard.')
    }
  }

  useEffect(() => {
    let abortObj = {aborted: false};
    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  const updateCurriculumMapping = (highlightTag, moduleId) => {
    curriculum[highlightTag] = moduleId;
    setCurriculum(curriculum);
    onSave({id: course.id, name: course.name, curriculum: curriculum})
  }

  const removeCurriculumMapping = (highlightTag) => {
    delete curriculum[highlightTag];
    setCurriculum(curriculum);
    onSave({id: course.id, name: course.name, curriculum: curriculum});
  }

  // create map of {moduleId: moduleTitle} to pass to SelectField
  const moduleMap = useMemo(() =>
    modules.reduce((moduleMap, module) => { return {...moduleMap, [module.id]: module.title}}, {}),
  [modules]);
  return (
    <Box width={1}>
      <Typography variant='subtitle1'>
        Map Blackboard Modules to Simulation Highlights
      </Typography>
      <Divider/>
      {
        Object.entries(highlights).map(([highlightName, highlight]) => {
          return (
            <div key={highlightName}>
              <Divider/>
              <SingleHighlightModuleMapping
                highlight={highlight}
                moduleId={curriculum[highlight.tag]}
                moduleMap={moduleMap}
                classes={classes}
                disabled={disabled}
                updateMapping={updateCurriculumMapping}
                removeMapping={removeCurriculumMapping}/>
            </div>
          )
        })
      }
    </Box>
  )
}

BBCurriculum.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    curriculum: PropTypes.object,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

/* displays single highlight to bb module name if it has already been selected */
const DisplaySingleMapping = ({highlightTag, moduleName, onRemove, disabled, className, ...remaining}) =>
  (
    <Box display='flex' flexDirection='row' className={className} {...remaining}>
      <Typography>{moduleName}</Typography>
      <ClearButton
        onRemove={() => onRemove(highlightTag)}
        disabled={disabled}
        tooltipTitle='Remove Curriculum Mapping'/>
    </Box>
  );

DisplaySingleMapping.propTypes = {
  highlightTag: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

const SingleHighlightModuleMapping = ({
  highlight,
  moduleId,
  updateMapping,
  moduleMap,
  classes,
  removeMapping,
  disabled}) =>
  (
    <SelectFlexBox width={1} className={classes.mappingRow}>
      <Typography className={classes.highlightTitle} component='div'>{highlight.title} </Typography>
      {moduleId && moduleMap[moduleId] ?
        <DisplaySingleMapping
          className={classes.activeMapping}
          highlightTag={highlight.tag}
          moduleName={moduleMap[moduleId]}
          disabled={disabled}
          onRemove={(highlightTag) => {
            removeMapping(highlightTag);
          }}
        /> :
        <SelectField
          id={highlight.tag}
          items={moduleMap}
          className={classes.select}
          placeholder='Select Module'
          value={moduleId}
          disabled={disabled}
          onChange={(e) => {
            updateMapping(highlight.tag, e.target.value);
          }} />}
    </SelectFlexBox>
  )

SingleHighlightModuleMapping.propTypes = {
  highlight: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  moduleId: PropTypes.string,
  moduleMap: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    activeMapping: PropTypes.string,
    select: PropTypes.string,
    highlightTitle: PropTypes.string,
    mappingRow: PropTypes.string
  }),
  updateMapping: PropTypes.func.isRequired,
  removeMapping: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default BBCurriculum;
