import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import dompurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(2),
  }
}));

const ModuleList = (props) => {
  const classes = useStyles();
  return(
    <Box>
      {props.modules.length > 0 ? props.modules.map((module, i) => {
        return <Card key={module.id} classes={{root: classes.item}}>
          <CardActionArea onClick={() => {
            props.setActiveModule(module)
          }}>
            <CardContent>
              <Typography variant='h6'>{module.title}</Typography>
              <Typography variant='caption'>
                <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(module.body)}}/>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      }) : <p>There is no curriculum content from Blackboard at this time.</p>}
    </Box>
  )
}

ModuleList.propTypes = {
  modules: PropTypes.array.isRequired,
  setActiveModule: PropTypes.func.isRequired
}

export default ModuleList;
