import React, {
  useState,
  useEffect,
  useMemo
} from 'react';
import {
  Switch,
  Box,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SelectField from '../Forms/SelectField';
import {toast} from 'react-toastify';
import {API} from 'aws-amplify';
import PropTypes from 'prop-types';
import ClearButton from '../Forms/ClearButton';
import SelectFlexBox from '../Forms/SelectFlexBox';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: '300px'
  }
}));

/* displays course if exists or is selected */
const DisplayCourse = ({course, onRemove, disabled}) => {
  return (<Box display="flex" flexDirection="row">
    <Typography variant="body1">{course.name}</Typography>
    <ClearButton
      onRemove={onRemove}
      disabled={disabled}
      tooltipTitle='Remove Course'/>
  </Box>);
}

DisplayCourse.propTypes = {
  course: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}


/* select course from list of courses */
const SelectCourse = ({onSave, disabled}) => {
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState();

  const load = async (abortObj) => {
    try {
      const courses = await API.get('blackboard', '/courses');
      if (!abortObj || !abortObj.aborted) {
        setCourses(courses);
      }
    } catch (e) {
      toast.error(e.toString())
    }
  }

  useEffect(() => {
    let abortObj = {aborted: false};
    load(abortObj);

    return () => {
      abortObj.aborted = true;
    }
  }, []);

  const courseNames = useMemo(() => courses.map((c) => c.name), [courses])

  const classes = useStyles();
  return (
    <SelectFlexBox width={1}>
      <SelectField
        id="selectCourse"
        className={classes.select}
        label="Select Course"
        items={courseNames}
        disabled={disabled}
        value={selected}
        onChange={ (e) => setSelected(e.target.value)}/>
      <Button
        color="primary"
        variant="contained"
        disabled={selected === undefined}
        onClick={ () => onSave(courses[selected]) }>
        Select
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setSelected(undefined)}>
        Cancel
      </Button>
    </SelectFlexBox>
  )
}

SelectCourse.propTypes = {
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const BBCourse = ({course, onRemove, onSave, disabled}) => {
  const [integrated, setIntegrated] = useState(Boolean(course && course.id));

  const handleChange = () => {
    if (integrated) {
      onRemove(); //if bb toggle is going to be false, clear selection
    }
    setIntegrated(!integrated);
  }

  return (
    <Box width={3/4}>
      <Switch checked={integrated} onChange={handleChange} name="bbIntegrated" disabled={disabled}/>
      {integrated &&
        <Box>
          {course && course.id ?
            <DisplayCourse
              course={course}
              onRemove={onRemove}
              disabled={disabled}/> :
            <SelectCourse onSave={onSave} disabled={disabled}/>
          }
        </Box>}
    </Box>
  )
}

BBCourse.propTypes = {
  course: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default BBCourse;
