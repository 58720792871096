import React from 'react';
import PropTypes from 'prop-types';
import AdminRoutes from './AdminRoutes';
import ChairRoutes from './ChairRoutes';
import Routes from './Routes';

/**
 * RoutesSelector will render the correct routes based on what role the user has.
 */
const RoleBasedRoutesSelector = ({role, childProps}) => {
  return <React.Fragment>
    {{
      'student': <Routes childProps={childProps}/>,
      'advisor': <Routes childProps={childProps}/>,
      'chair': <ChairRoutes childProps={childProps}/>,
      'admin': <AdminRoutes childProps={childProps}/>
    }[role]}
  </React.Fragment>
}

RoleBasedRoutesSelector.propTypes = {
  isAuthenticated: PropTypes.bool,
  role: PropTypes.oneOf(['student', 'advisor', 'chair', 'admin']), // defined in UserForm.js
  childProps: PropTypes.object,
}

export default RoleBasedRoutesSelector;
