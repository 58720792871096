function stableSort(array, sortField, cmp) {
  const stabilizedThis = array.map((el, index) => ({el, index}));
  stabilizedThis.sort((a, b) => {
    const order = cmp(a.el[sortField], b.el[sortField]);
    if (order !== 0) {
      return order;
    }
    return a.index - b.index; // sort by index when otherwise equal
  });
  return stabilizedThis.map((el) => el.el);
}

export default stableSort;
