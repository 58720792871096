import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import CheckIcon from '@material-ui/icons/Check';
import Progress from '../components/Progress';
import BlockIcon from "@material-ui/icons/Block"
import Slide from "@material-ui/core/Slide"
import BalanceSheetTabs from "../containers/Graphs/BalanceSheetTabs"
import {bindActionCreators} from "redux";
import { API } from "aws-amplify";
import moment from "moment";
import NextQuarter from "../admin/NextQuarter";
import PropTypes from "prop-types";
import formatCurrency from "../helpers/formatCurrency";
import formatNumber from "../helpers/formatNumber";
import MiniCaseTabs from "./MiniCaseTabs";
import {toast} from "react-toastify";
import SetDBA from "./SetDBA";
import {Box, IconButton, Typography} from "@material-ui/core";
import UnsubmitDecision from "./UnsubmitDecision";
import Ending from "../containers/Ending";

const refreshTimeout = 30000;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function ViewSim (props) {
  const { coresim, reloadAdmin } = props;
  const [handle, setHandle] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState({open: false, current: false});
  const [openCashModal, setOpenCashModal] = useState(false);
  const [openCaseModal, setOpenCaseModal] = useState({open: false, current: false});
  const [openDBAModal, setOpenDBAModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [cashMessage, setCashMessage] = useState("");

  let qtrs = props.coresim.quarters;
  let currentQuarter;

  useEffect( () => {
    let aborted = false;
    if (handle) {
      clearInterval(handle);
    }

    if (reloadAdmin) {
      const h = setInterval(() => {
        if (!aborted && reloadAdmin) {
          reloadAdmin();
        }
      }, refreshTimeout);

      setHandle(h);
      return () => {
        aborted = true;
        clearInterval(h);
      };
    }

    return () => aborted = true;
  }, [coresim, reloadAdmin]);

  const handleModalClose = (reload) => {
    setOpenModal({open: false, current: false});
    reload && reloadAdmin && reloadAdmin();
  };

  const handleCashModalClose = () => {
    setOpenCashModal(false);
  };

  const handleDBAClose = (reload) => {
    setOpenDBAModal(false);
    reload && reloadAdmin && reloadAdmin();
  };

  const handleCaseModalClose = () => {
    setOpenCaseModal({open: false, current: false});
  };

  const handleChange = (event) => {
    setCashAmount(event.target.value);
  };

  const handleMessageChange = (event) => {
    setCashMessage(event.target.value);
  };

  const updateSimulationCash = async (index, cash) => {
    setLoading(true);
    const teams = props.coresim.teams.slice();
    const teamId = teams[index] && teams[index].id;

    const body = {
      cashJustification: cashMessage,
      cashAmount: parseInt(cashAmount, 10),
    };

    try {
      await API.put('state',
        `/simulations/${props.coresim.id}/teams/${teamId}/decisions/${currentQuarter}`,
        {body}
      );
      handleCashModalClose();
      setCashMessage("");
      setCashAmount(0);
      toast.success('Success!');
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  };


  if (props.coresim.quarter.counter < props.coresim.quarters.length) {
    currentQuarter = props.coresim.quarter.counter;
  } else { currentQuarter = props.coresim.quarters.length - 1}
  return(
    <div>
      <NextQuarter coresim={props.coresim} quarter={props.coresim.quarter.counter} {...props} />
      <br />
      <h3>
        Simulation: {props.coresim.name}
      </h3>
      <p>Q{currentQuarter + 1} started at {moment(qtrs[currentQuarter].start).format('MMMM Do YYYY, h:mm:ss a')} and ends at {moment(qtrs[currentQuarter].end).format('MMMM Do YYYY, h:mm:ss a')}</p>
      <div style={{display: 'flex', flexDirection: 'column', alignContent: 'space-between'}}>
        {coresim.teams.map((team, i) => {
          let vitals = team.vitalsHistory[team.vitalsHistory.length - 1];
          let dba = team.decisions.businessAs || vitals.businessAs;

          return(
            <div key={i} style={{marginTop: 20, padding: 10, display: 'flex', justifyContent: 'space-between'}} className="block">

              <div style={{display: 'flex', flexDirection: 'column', alignContent: 'space-between'}}>
                <React.Fragment>
                  <h3>{team.teamName}</h3>
                  <br/>
                  {dba !== '' &&
                  <div>
                    <h3>Doing Business As:</h3>
                    <h3>{dba}</h3>
                  </div>
                  }
                  <br />
                  {team.configuration.description !== '' &&
                  <div>
                    <h3>{team.configuration.description}</h3>
                  </div>
                  }
                  <br />
                  <div style={{display: 'flex', justifyContent: 'start', paddingRight: 5}}>
                    <p>
                      {!team.decisions.decisionsComplete ? 'Decisions Not Submitted' : 'Decisions Submitted'}
                    </p>
                    <span>
                      {!team.decisions.decisionsComplete ? <BlockIcon style={{color: 'red', paddingBottom: 12}} /> :

                        <IconButton onClick={() => {
                          setModalIndex(i)
                          setOpenModal({open: true, current: true})
                        }} style={{ alignItems: 'end', padding: 0}}>
                          <CheckIcon style={{color: 'green', paddingBottom: 12}}/>
                          <Typography style={{fontSize: 'small'}}>VIEW</Typography>
                        </IconButton>
                        
                      }
                    </span>
                  </div>
                  <br/>
                  <div style={{display: 'flex', justifyContent: 'start'}}>
                    <p>



                      {!qtrs[currentQuarter].minicase ? 'No minicase this quarter.' : !team.decisions.minicaseComplete ? 'Minicase Not Submitted' : 'Minicase Submitted'}
                    </p>
                    <span>
                      {!team.decisions.minicaseComplete ? <BlockIcon style={{color: 'red', paddingBottom: 12}} /> :
                        <IconButton onClick={() => {
                          setModalIndex(i)
                          setOpenCaseModal({open: true, current: true})
                        }} style={{ alignItems: 'end', padding: 0}}>
                          <CheckIcon style={{color: 'green', paddingBottom: 12}}/>
                          <Typography style={{fontSize: 'small'}}>VIEW</Typography>
                        </IconButton>
                      }
                    </span>
                  </div>
                  <br />
                </React.Fragment>

                <h3>Key Vitals</h3>
                <div style={{fontSize: 16}}>
                  <p>Cash: {vitals.cash === undefined ? 'Error :(' : formatCurrency(vitals.cash)}</p>
                  <p>Units: {vitals.inventory === undefined ? 'Error :(' : formatNumber(vitals.inventory)}</p>
                  <p>Stock: {vitals.stock === undefined ? 'Error :(' : formatCurrency(vitals.stock)}</p>
                </div>

              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignContent: 'space-between', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                  <h3>
                    Team Members
                  </h3>
                  {team.members.map((member, i) => {
                    return(
                      <div key={i} style={{}}>
                        {member.firstName} {member.lastName} - {member.email}
                      </div>
                    )
                  })}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>

                  {coresim.quarter.counter > 0 ? (
                    <div style={{display: 'flex', flexGrow: 1}}>
                      <Button style={{margin: '5px'}} color="primary" variant="contained" onClick={() => {
                        setModalIndex(i)
                        setOpenModal({open: true})
                      }}>View Balance Sheet</Button>
                      <Button style={{margin: '5px'}} color="primary" variant="contained" onClick={() => {
                        setModalIndex(i)
                        setOpenCaseModal({open: true})
                      }}>View MiniCase</Button>
                      {props.admin ? (
                        <Button style={{margin: '5px'}} color="secondary" variant="contained" onClick={() => {
                          setModalIndex(i)
                          setOpenCashModal(true)
                        }}>Add Cash</Button>
                      ) : null}

                    </div>
                  ) : null}
                  {
                    props.admin &&
                        <Button style={{margin: '5px'}} color="secondary" variant="contained" onClick={() => {
                          setModalIndex(i)
                          setOpenDBAModal(true)
                        }}>Set Name</Button>
                  }
                </div>
              </div>

            </div>
          )
        })}
      </div>
      {props.coresim.quarter.counter !== 0 && (
        <div style={{marginTop: 50, marginBottom: -200}}>
          <Ending coresim={props.coresim} />
        </div>
      )}
      <SetDBA
        open={openDBAModal}
        onClose={handleDBAClose}
        simId={coresim.id}
        quarter={currentQuarter}
        teamId={coresim.teams[modalIndex] && coresim.teams[modalIndex].id}
        teamName={coresim.teams[modalIndex] ? coresim.teams[modalIndex].decisions.businessAs ||
           coresim.teams[modalIndex].vitalsHistory[coresim.teams[modalIndex].vitalsHistory.length - 1].businessAs ||
           coresim.teams[modalIndex].teamName : ''
        }
      />
      <Dialog
        style={{
          margin: 'auto',
        }}
        open={openModal.open}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        hideBackdrop={false}
        onClose={handleModalClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <BalanceSheetTabs current={openModal.current} teamIndex={modalIndex} sim={props.coresim} />
        <Box style={{padding: 24, paddingTop: 0}}>
          <UnsubmitDecision
            simId={coresim.id}
            quarter={currentQuarter}
            teamId={coresim.teams[modalIndex] && coresim.teams[modalIndex].id}
            onClose={handleModalClose}
          />
        </Box>
      </Dialog>

      <Dialog
        style={{
          margin: 'auto',
        }}
        open={openCaseModal.open}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        hideBackdrop={false}
        onClose={handleCaseModalClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <MiniCaseTabs current={openCaseModal.current} teamIndex={modalIndex} sim={props.coresim} />
      </Dialog>

      <Dialog
        style={{
          margin: 'auto',
        }}
        open={openCashModal}
        TransitionComponent={Transition}
        disableBackdropClick={false}
        hideBackdrop={false}
        onClose={handleCashModalClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >

        <div style={{width: 300, padding: 15}}>
          <h2>Enter cash amount</h2>
          <p>This cash infusion is for {coresim.teams[modalIndex] && coresim.teams[modalIndex].teamName}</p>
          <TextField
            id="money"
            label="Dollars"
            value={cashAmount}
            onChange={handleChange}
            variant="filled"
            type="number"
          />
          <TextField
            id="message"
            label="Message"
            value={cashMessage}
            onChange={handleMessageChange}
            variant="filled"
            type="string"
          />
          <Button disabled={loading} onClick={() => updateSimulationCash(modalIndex, cashAmount)}>Send Money{loading && <Progress variant="overlay" size='1.5em' />}</Button>
        </div>
      </Dialog>


    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    everything: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {}, dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSim);

ViewSim.propTypes = {
  simulationId: PropTypes.string,
  teams: PropTypes.object,
  coresim: PropTypes.object,
  reloadAdmin: PropTypes.func,
  everything: PropTypes.object,
  admin: PropTypes.bool
}
