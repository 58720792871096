/**
 *
 * This is a logistic function generator (a sigmoid, or s-curve), given at
 * https://en.wikipedia.org/wiki/Logistic_function. Values are capped
 * from 0 to 1 and should be scaled exterior to this function.
 * Similarly, the midpoint is fixed at origin. If another midpoint
 * is needed, use function composition.
 *
 * @param {number} rate - The steepness of the curve
 * @return {generated} - the generated logistic function
 */
const logistic = (rate) => {
  /**
   * Generated logistic function.
   *
   * @function generated
   * @param {number} x - the dependent variable
   * @return {number}
   */
  const generated = (x) => 1 / (1 + Math.exp(-rate * x));

  return generated;
};

export default logistic;
