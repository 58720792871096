import classnames from 'classnames';
import React, {useState, useEffect} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Checkbox} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selectMinicase} from '../../coresim/reducers/decisions';
import PropTypes from 'prop-types';

const MiniCaseAnswers = (props) => {
  const {classes, decision, onSelected, rightchevrondisable, selectMinicase, disabled, ...rest} = props;
  const [isSelected, setIsSelected] = useState(false);
  const disableCheck = props.teamDecision ? true : false;

  useEffect(() => {
    if (!props.decisions.selectedMinicase.title) {
      props.rightchevrondisable(true);
    }
  }, []);


  const selectedCase = !props.teamDecision ? 
    props.decision.optionTitle === props.decisions.selectedMinicase.title ?
      true : false
    :
    props.decision.optionTitle === props.teamDecision;

  return (
    <Grid item xs={12} md={12} className={classes.grid} {...rest}>
      <Box
        component='div'
        className={classnames(
          classes.card,
          selectedCase && classes.selectedCard
        )}
        style={{margin: 'auto'}}>
        <Box component='div' className={classes.flex}>
          <FormControlLabel
            value='end'
            control={
              <Checkbox
                checked={selectedCase}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                disabled={disabled || disableCheck}
                color='primary'
                onChange={() => {
                  rightchevrondisable(false);
                  setIsSelected(!isSelected);
                  selectMinicase({
                    title: decision.optionTitle,
                    content: decision.optionDescription,
                    outputs: decision.optionOutputs,
                  });
                  if (onSelected) {
                    onSelected();
                  }
                }}
              />
            }
            label={
              <Grid item xs={12}>
                <Typography
                  gutterBottom={true}
                  className={classes.header}
                  variant='h3'>
                  {decision.optionTitle}
                </Typography>
                <Typography
                  paragraph={true}
                  variant='body2'
                  style={{fontSize: '12px'}}>
                  {decision.optionDescription}
                </Typography>
              </Grid>
            }
            labelPlacement='end'
          />
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectMinicase: (units) => selectMinicase(units),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCaseAnswers);

MiniCaseAnswers.propTypes = {
  classes: PropTypes.object,
  decision: PropTypes.object,
  selectMinicase: PropTypes.func,
  decisions: PropTypes.object,
  onSelected: PropTypes.func,
  rightchevrondisable: PropTypes.func,
  disabled: PropTypes.bool,
  teamDecision: PropTypes.string
};

MiniCaseAnswers.defaultProps = {
  disabled: false,
}
