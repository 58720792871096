import React, {useState, useEffect, useMemo, useCallback} from 'react';
import tableHoc from '../../components/Tables/tableHoc';
import {API} from 'aws-amplify';
import { toast } from 'react-toastify';
import { Box, Button, Typography, Grid, Dialog } from '@material-ui/core';
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from '../../components/widgets/IconButton';
import types from './ConfigurationTypes';
import Transition from '../../components/ModalTransition';
import DeleteModal from '../../components/DeleteModal';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

const header = () => [
  {
    isSortable: true,
    label: 'Name',
    name: 'name',
  },
  {
    isSortable: true,
    label: 'Type',
    name: 'type',
  },
  {
    isSortable: true,
    label: 'Last Modified',
    name: 'lastModifiedDate',
  },
  {
    isSortable: true,
    label: 'Created',
    name: 'createdDate',
  },
  {
    label: ''
  }
];

const rowDefinition = (deleteConfig, editConfig) => (row, index) => [
  row.name,
  !isNaN(row.type) ? types[row.type] : 'None',
  row.lastModifiedDate ? new Date(row.lastModifiedDate).toLocaleString() : "",
  row.createdDate ? new Date(row.createdDate).toLocaleString() : "",
  <Grid key="actions" container justify="flex-end">
    {editConfig &&
      <Grid item>
        <IconButton size="small"
          loading={false}
          variant="text"
          color="primary"
          icon={CreateIcon}
          onClick= { (e) => {
            e.preventDefault();
            editConfig(row._id);
          }}
        >
          edit
        </IconButton>
      </Grid>
    }
    {deleteConfig &&
      <Grid item>
        <IconButton
          size="small"
          variant="text"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            deleteConfig(row);
          }}
          icon={DeleteIcon}
        >
          delete
        </IconButton>
      </Grid>}
  </Grid>
];

const ConfigurationList = ({reload}) => {
  const history = useHistory();
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const match = useRouteMatch();
  const [deleting, setDeleting] = useState();

  const load = async (abortObj) => {
    try {
      const configurations = await API.get('mongo', '/configurations');
      if (!abortObj || !abortObj.aborted) {
        setConfigurations(configurations.filter((c) => c.active !== false));
      }
    } catch (e) {
      toast.error('Error retrieving configurations.');
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async (id) => {
    try {
      setLoading(true);
      await API.put('mongo', `/configurations/${id}`,
        {
          body: {
            active: false
          }
        }
      );
      toast.success('configuration deleted');
    } catch(e) {
      toast.error('Error deleting configuration');
    } finally {
      setDeleting()
      load();
    }

  }, []);

  const handleDelete = useCallback((row) => {
    setDeleting(row);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting();
  }, []);

  const onEdit = useCallback((id) => {
    history.push(`${match.url}/${id}`);
  }, []);

  useEffect(() => {
    let abortObj = {aborted: false};
    load(abortObj);

    return () => {
      abortObj.aborted = true;
    }
  }, [reload]);

  const Table = useMemo(() =>
    tableHoc(rowDefinition(handleDelete, onEdit), header)
  , [handleDelete, onEdit])

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        variant="h4">
            Configurations
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        py={2}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={
            () => history.push(`${match.url}/create`)
          }
        >
          + new
        </Button>
      </Box>
      <Table data={configurations}
        sortField='name'
        sortAscending
        loading={loading}
      />
      <Dialog
        style={{
          margin: 'auto',
        }}
        open={Boolean(deleting)}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <DeleteModal handleConfirm={() => handleConfirmDelete(deleting._id)} handleCancel={handleCancelDelete} what={`Configuration "${(deleting && deleting.name) || ''}"`}/>
      </Dialog>
    </Box>

  );
};

ConfigurationList.propTypes = {
  reload: PropTypes.string.isRequired
}
export default ConfigurationList;
