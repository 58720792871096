import React, {useEffect, useRef} from 'react';
import {headShake} from 'react-animations';
import {withStyles} from '@material-ui/core';


const styles = {
  headShake: {
    animationName: headShake,
    animationDuration: '1s',
  },
};

const shakeHoc = (Component) => {
  class ShakeWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {shouldShake: false};
    }

    shake() {
      this.setState({shouldShake: true}, () => {
        setTimeout(() => this.setState({shouldShake: false}), 1000);
      });
    }

    render() {
      const {classes, ...remaining} = this.props;
      return (
        <Component className={this.state.shouldShake ? classes.headShake : ''} {...remaining} />
      );
    }
  }
  return withStyles(styles)(ShakeWrapper);
};

export const shakeOnCondition = (Component) => {
  const ShakeableComponent = shakeHoc(Component);

  const ConditionalShake = ({condition, ...remaining}) => {
    const ref = useRef();
    useEffect(
      () => {
        Boolean(condition) && ref.current && ref.current.shake();
      },
      [condition]
    );

    return (
      <ShakeableComponent ref={ref} {...remaining} />
    );
  };

  return ConditionalShake;
};

export default shakeHoc;
