import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 'auto',
  },
  class: {
    background: 'white',
    color: 'black',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    paddingTop: 10,
    width: 'fit-content',
  },
  storyStyle: {
    maxHeight: '1px',
    maxWidth: '100%',
    wordWrap: 'break-word'
  }
}))

const NewsArticle = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.class} container spacing={2}>
      <Grid  item style={{width: 'fit-content'}}>
        <img src={`${process.env.REACT_APP_S3_IMAGES}/${props.data._id}`} className={classes.image} width={250} height="auto"/>
      </Grid>
      <Grid className={classes.titleStyle} item >
        <ReactMarkdown source={props.data.headline}/>
      </Grid>
      <Grid item className={classes.storyStyle}>
        <Grid item>
          <ReactMarkdown source={props.data.story.toString('markdown')}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewsArticle;

NewsArticle.propTypes = {
  data: PropTypes.object.isRequired,
}
