export const adminRoutes = [
  {
    route: '/admin/teams',
    title: 'Search All Teams',
  },
  {
    route: '/admin/teams/create',
    title: 'Create New Team'
  },
  {
    route: '/admin/users',
    title: 'Search All Users'
  },
  {
    route: '/admin/users/create',
    title: 'Create New User'
  },
  {
    route: '/admin/mini/create',
    title: 'Create New Minicase',
  },
  {
    route: '/admin/simulations/create',
    title: 'Create New Sim'
  },
  {
    route: '/admin/simulations',
    title: 'Search All Sims'
  },
  {
    route: '/admin/bulk-upload',
    title: 'Bulk Upload Users'
  }
]
