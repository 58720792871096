import React, { useMemo, useState } from 'react';
import {
  Button,
  makeStyles} from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import SelectField from '../Forms/SelectField';

const roles = [
  'advisor',
  'admin',
  'student',
  'chair'
];

const useStyles = makeStyles(() => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
  onChange: (e) => updateHandler && updateHandler(name)(e.target.value),
});

const UserForm = ({onSubmit, initialValue, disabled, editing}) => {
  const initialIndex = useMemo(() => {
    const index = roles.findIndex(
      (v) => initialValue && v === initialValue.role);
    return index === -1 ? undefined : index;
  }, [initialValue]);
  const [selected, setSelected] = useState(initialIndex);
  const [selectError, setSelectError] = useState();

  const validateForm = () => {
    if (selected === undefined) {
      setSelectError('You must select a value');
      return false;
    }

    return true;
  };

  const classes = useStyles();

  const [form, updateHandler] =
    useFormReducer(initialValue,
      (init) => ({
        ...init,
      })
    );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && onSubmit) {
      onSubmit({
        ...form,
        role: roles[selected]
      });
    }
  }

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <fieldset disabled={disabled} className={classes.fieldset}>
        <Group stack>
          <Field
            {...getProps('firstName', form, updateHandler)}
            label="First Name"
            disabled={disabled}
            autoFocus
          />
          <Field
            {...getProps('lastName', form, updateHandler)}
            label="Last Name"
            disabled={disabled}
          />
          <Field
            {...getProps('email', form, updateHandler)}
            label="email"
            disabled={disabled || editing}
          />
          <SelectField
            id="role"
            name="role"
            onChange={(e) => {
              setSelected(e.target.value);
              setSelectError();
            }}
            items={roles}
            placeholder="Select Role"
            disabled={disabled || editing}
            value={selected}
            error={Boolean(selectError)}
            helperText={selectError}
          />
        </Group>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          Save
        </Button>
      </fieldset>
    </ValidatorForm>
  )
}

UserForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  editing: PropTypes.bool
};

export default UserForm;
