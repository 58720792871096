import React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ClearButton from '../Forms/ClearButton';
import PropTypes from 'prop-types';
import UserRoutes from '../User';
import { useRouteMatch, useHistory, Switch, Route } from 'react-router';
import SelectionContextProvider from '../../providers/selectionProvider';
import BuildIcon from '@material-ui/icons/Build';

const NoUsers = () => (
  <Typography>
    <em>No users selected</em>
  </Typography>
);

const UserDisplay = ({user, onRemove, disabled}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <Typography
        variant="body1">
        {`${user.lastName}, ${user.firstName}`}
      </Typography>
      <ClearButton
        onRemove={onRemove}
        disabled={disabled}
        tooltipTitle='Remove User'/>
    </Box>
  );
};

UserDisplay.propTypes = {
  user: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

const UsersDisplay = ({users, onRemoveUser, disabled}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    {
      users.map((user, i) => (
        <UserDisplay
          key={i}
          user={user}
          onRemove={() => onRemoveUser(i)}
          disabled={disabled}
        />
      ))
    }
  </Box>
);

UsersDisplay.propTypes = {
  users: PropTypes.array.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

const UsersDisplayList = ({users, onRemoveUser, disabled}) => {
  return (
    <Box py={1}>
      {
        users && users.length ?
          <UsersDisplay
            users={users}
            onRemoveUser={onRemoveUser}
            disabled={disabled}
          /> : <NoUsers />
      }
    </Box>
  );
};

UsersDisplayList.propTypes = {
  users: PropTypes.array.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

const Users = ({users, onUsersChanged, onRemove, disabled}) => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Box>
      <UsersDisplayList
        users={users}
        onRemoveUser={onRemove}
        disabled={disabled}
      />
      <Switch>
        <Route
          path={`${match.url}/users`}
        >
          <SelectionContextProvider
            selection={users}
            onSelectionChanged={onUsersChanged}
          >
            <UserRoutes
              filter={(user) => user.role === 'student' || user.role === 'advisor' }
              isSelect />
          </SelectionContextProvider>
        </Route>
      </Switch>
      <Button
        variant="text"
        color="primary"
        onClick={() => history.push(`${match.url}/users`)}
        disabled={disabled}
      >
        <BuildIcon style={{marginRight: '5px'}}/> Manage 
      </Button>
    </Box>
  );
};

Users.propTypes = {
  users: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onUsersChanged: PropTypes.func.isRequired,
};

export default Users;
