import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _orderby from 'lodash.orderby';
import types from '../admin/Configuration/ConfigurationTypes';
import ExtensionIcon from '@material-ui/icons/Extension';
import { Box } from '@material-ui/core';
import formatCurrency from '../helpers/formatCurrency';
import formatNumber from '../helpers/formatNumber';
import formatPercent from '../helpers/formatPercent';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4, 4),
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }   
  },
  table: {
    minWidth: 650,
  },
  strategy: {
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '1.2rem !important',
    marginRight: 2,
  },
}));

function DenseTable(props) {
  const classes = useStyles();

  let path = window.location.pathname.split('/');
  let teamArrays = [];

  if (path[1] === 'vitals') {
    props.coresim.teams.map((team, index) => {
      if (props.coresim.teams[index] === props.coresim.teams[props.teamIndex]) {
        teamArrays.push(team)
      }
      return '';
    })
  } else {
    props.coresim.teams.map((team, index) => {
      teamArrays.push({
        ...team,
        valuation: team.vitalsHistory[team.vitalsHistory.length - 1].valuation})
      return '';
    })
  }

  const mappedArrays = _orderby(teamArrays, ['valuation'], ['desc']);

  let purchasedQuarters = [];

  props.coresim.teams[props.teamIndex].decisionHistory.map((quarter, i) => {
    if (quarter.marketResearch === 10000) {
      purchasedQuarters.push(i)
    }
    return '';
  })

  return (
    <div className={classes.root}>
      {props.coresim.teams[props.teamIndex].decisionHistory.length < 1 ? (
        <h2>You have not yet submitted any decisions! Check back here next quarter.</h2>
      ) : (
        <div>
          {mappedArrays.map((team, i) => {
            const isSelf = props.coresim.teams[props.teamIndex]._id === team._id;
            const displayName = team.vitalsHistory !== undefined ? team.vitalsHistory.reduce((name, v) => v.businessAs || name, team.teamName) : team.teamName;
            return(
              <React.Fragment key={i}>
                <h2>Team {displayName}
                </h2>

                {
                  team.configuration.type !== undefined &&
                  <Box display="flex" className={classes.strategy}>
                    <ExtensionIcon className={classes.icon} />
                    {types[team.configuration.type]}
                  </Box>
                }

                <Paper className={classes.paper}>
                  <h3>Decisions</h3>
                  <Table
                    className={classes.table}
                    size='small'
                    aria-label={`Team ${displayName}`}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Quarter</TableCell>
                        <TableCell>Units Produced</TableCell>
                        <TableCell align='right'>Total Quality Management</TableCell>
                        <TableCell align='right'>Unit Price</TableCell>
                        <TableCell align='right'>Advertising &amp; Sales</TableCell>
                        <TableCell align='right'>Sales Hiring</TableCell>
                        <TableCell align='right'>Product Development</TableCell>
                        <TableCell align='right'>Market Research</TableCell>
                        <TableCell align='right'>Dividends</TableCell>
                        <TableCell align='right'>Loan Addition</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {team.decisionHistory.map((row, i) => {
                        return (
                          <React.Fragment  key={i}>
                            {path[1] === 'vitals' || isSelf ||
                            (path[1] === 'research' && purchasedQuarters.includes(i)) ? (
                                <TableRow>
                                  <TableCell component='th' scope='row'>
                                    Q{i + 1}
                                    {row.quarter}
                                  </TableCell>
                                  <TableCell align='right'>{formatNumber(row.unitsProduced)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.qualityManagement)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.price)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.advertising)}</TableCell>
                                  <TableCell align='right'>{formatNumber(row.salespeople)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.productDevelopment)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.marketResearch)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.dividends)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.loans)}</TableCell>
                                </TableRow>
                              ): null }
                          </React.Fragment>
                        );
                      })
                      }
                    </TableBody>
                  </Table>
                </Paper>
                <Paper className={classes.paper}>
                  <h3>Vitals</h3>
                  <Table
                    className={classes.table}
                    size='small'
                    aria-label={`Team ${displayName}`}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Quarter</TableCell>
                        <Tooltip placement='top' title="The amount of cash the company has available">
                          <TableCell>Cash on Hand</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="Product ready to sell">
                          <TableCell align='right'>Inventory</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="Product sold">
                          <TableCell align='right'>Units Sold</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="How much product did the public demand?">
                          <TableCell align='right'>Demand</TableCell>
                        </Tooltip>
                        {/* <Tooltip placement='top' title="If your inventory did not meet public demand, you've lost some sales">
                          <TableCell align='right'>Sales Lost</TableCell>
                        </Tooltip> */}
                        <Tooltip placement='top' title="The number of defective units">
                          <TableCell align='right'>Defective Units</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="The maxmium number of units your plants can produce">
                          <TableCell align='right'>Plant Capacity</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="The outstanding loan balance to be paid">
                          <TableCell align='right'>Loan Liabilities</TableCell>
                        </Tooltip>
                        <Tooltip placement='top' title="How the public feels about your product overall">
                          <TableCell align='right'>Public Sentiment</TableCell>
                        </Tooltip>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {team.vitalsHistory.map((row, i) => {
                        return (
                          <React.Fragment  key={i}>
                            {path[1] === 'vitals' || isSelf ||
                              (path[1] === 'research' && purchasedQuarters.includes(i - 1)) ? (
                                <TableRow>
                                  <TableCell component='th' scope='row'>
                                    Q{i + 1}
                                    {row.quarter}
                                  </TableCell>
                                  <TableCell align='right'>{formatCurrency(row.cash)}</TableCell>
                                  <TableCell align='right'>{formatNumber(row.inventory)}</TableCell>
                                  <TableCell align='right'>{formatNumber(row.lastQuarterDemand - row.lostSales)}</TableCell>
                                  <TableCell align='right'>{formatNumber(row.lastQuarterDemand)}</TableCell>
                                  {/* <TableCell align='right'>{formatNumber(row.lostSales)}</TableCell> */}
                                  <TableCell align='right'>{formatNumber(row.lastQuarterDefects)}</TableCell>
                                  <TableCell align='right'>{formatNumber(row.capacity)}</TableCell>
                                  <TableCell align='right'>{formatCurrency(row.liabilitiesBankLoan)}</TableCell>
                                  <TableCell align='right'>{formatPercent(row.publicSentiment)}</TableCell>

                                </TableRow>
                              ) : null
                            }
                          </React.Fragment>
                        )}
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </React.Fragment>
            )
          })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
    teamIndex: state.teamIndex.teamIndex
  };
};

export default connect(mapStateToProps)(DenseTable);

DenseTable.propTypes = {
  coresim: PropTypes.object,
  teamIndex: PropTypes.number,
  teams: PropTypes.object,
};
