import React from 'react';
import {Button, Tooltip, makeStyles } from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import SelectField from '../Forms/SelectField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import numberFilterHoc from '../../helpers/numberFilterHoc';
import numberBoundsHoc from '../../helpers/numberBoundsHoc';
import ValueSlider from '../../components/widgets/ValueSlider';
import types from './ConfigurationTypes';

const useStyles = makeStyles(() => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
  overflowText: {
    '& > label': {
      width: '200px',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle'
    }
  }
}));

const decimals = (decimal, minValue, maxValue) => {
  let Component = numberFilterHoc(
    Field,
    {
      allowNegative: false,
      decimal,
    }
  );

  if (minValue !== undefined || maxValue !== undefined) {
    Component = numberBoundsHoc(Component, {
      minValue, maxValue
    });
  }

  return Component;
};

const qaSensitivity = [
  {
    value: 1,
    label: 'low',

  },
  {
    value: 2,
    label: 'med',

  },
  {
    value: 4,
    label: 'high'
  },
];

const qaEffect = [
  {
    value: .5,
    label: 'low',

  },
  {
    value: 1,
    label: 'med',

  },
  {
    value: 2,
    label: 'high'
  },
];

// TODO: Change this to be obsolences / quarters
const pdEffect = [
  {
    value: .5,
    label: 'low',

  },
  {
    value: .75,
    label: 'med',

  },
  {
    value: 1.25,
    label: 'high'
  },
];

const initialValueFields = [
  {
    name: 'initialCash',
    label: 'Initial Cash',
    helpText: 'Currency value for team\'s initial cash on hand.',
    Component: decimals(2, 0, 10000000)
  },
  {
    name: 'initialInventory',
    label: 'Initial Inventory',
    helpText: 'The team\'s initial product inventory quantity.',
    Component: decimals(0, 0, 1000000)
  },
  {
    name: 'initialCapacity',
    label: 'Initial Capacity',
    helpText: 'The team\'s initial manufacturing capacity per quarter.',
    Component: decimals(0, 0, 10000000)
  },
  {
    name: 'initialBoardSentiment',
    label: 'Initial Board Sentiment',
    helpText: 'Starting board sentiment, as a value between 0 (bad) and 1 (good).',
    Component: decimals(2, 0, 1)
  },
  {
    name: 'initialPublicSentiment',
    label: 'Initial Public Sentiment',
    helpText: 'Starting public product sentiment, as a value between 0 (bad) and 1 (good).',
    Component: decimals(2, 0, 1),
  },
  {
    name: 'stocks',
    label: 'Issued Stocks',
    helpText: 'Used in calculation of stock price as: valuation / # Stocks.',
    Component: decimals(0, 100)
  }
];

const industryFields = [
  {
    name: 'marketSize',
    label: 'Market Size',
    helpText: 'The market size. This is how many people are available to buy a product.',
    Component: decimals(0, 0, 100000000)
  },
  {
    name: 'industryPrice',
    label: 'Industry Price',
    helpText: 'The industry standard price of the product. Half the market (scaled by demand), will buy the product at this price.',
    Component: decimals(2, 1, 10000),
  },
  {
    name: 'industryDefects',
    label: 'Industry Defects',
    helpText: 'This is the expected defects per thousand units. Deviation from this will affect public perception.',
    Component: decimals(1, 1, 500),
  },
  {
    name: 'industryDefectDeviance',
    label: 'Industry Defect Deviance',
    helpText: 'By how much can the defect rate deviate? This should be less than industryDefects.',
    Component: decimals(1, 1, 50),
    validators: [[
      'Defect deviance must be less than industry defects',
      'DefectDevianceValidator',
      (form) => (value) => {
        return form.industryDefects >= value;
      },
    ]]
  },
  {
    name: 'qaPerProductDev',
    label: 'QA Per Product Development',
    helpText: 'A number expressing the proportional amount that needs to be spent on QA per dollar spent on product development. Deviation from this amount will affect product defects. Example: a value of .1 indicates that 1 dollar should be spent in QA for every 10 dollars spent on product development.',
    Component: decimals(2, .01, 2),
  },
  {
    name: 'qaPerManufacturingExpense',
    label: 'QA per Manufacturing Expense',
    helpText: 'A number expressing the proportional amount that needs to be spent on QA per dollar spent manufacturing goods. Example: a value of .1 indicates that 1 dollar should be spent in QA for every 10 dollars spent manufacturing. Naturally, the more units produced, the more needs to be spent on QA.',
    Component: decimals(3, .01, 2),
  },
  {
    name: 'expectedProductDevPerQuarter',
    label: 'Expected Product Development each Quarter',
    helpText: 'A number expressing the product development needed every quarter to keep up with public sentiment',
    Component: decimals(0),
  },
  {
    name: 'expenseLevelledCost',
    label: 'Ideal Manufacturing Cost',
    helpText: 'How much does it cost to produce a unit. The first unit produced is much more expensive, the second slightly less expensive, and so on with the cost approaching the dollar amount given here.',
    Component: decimals(2, .1, 10000),
  },
  {
    name: 'expenseLevellingPoint',
    label: 'Mfg. Cost Inflection',
    helpText: 'How many units need to be produced before the cost/unit approaches the Ideal Manufacturing Cost.',
    Component: decimals(0, 1000, 100000)
  },
  {
    name: 'qaSensitivity',
    label: 'QA Sensitivity',
    helpText: 'How quickly QA sentiment changes with product issues.',
    Component: ValueSlider,
    isSlider: true,
    items: qaSensitivity
  },
  {
    name: 'combinedQASensitivity',
    label: 'QA Effect on Sentiment',
    helpText: 'QA sentiment serves as a dampener on product feature sentiment. This determines how much QA perception will dampen or inflate product sentiment to determine overall public perception.',
    Component: ValueSlider,
    isSlider: true,
    items: qaEffect
  },
  {
    name: 'productDevelopmentSensitivity',
    label: 'Product Development Sensitivity',
    helpText: 'How quickly product development sentiment changes with product development deviation from expectation.',
    Component: ValueSlider,
    isSlider: true,
    items: pdEffect
  },
  {
    name: 'industryDividentYeild',
    label: 'Industry Dividend Yield',
    helpText: 'The dividend yield for the industry, as a percentage. 2.5% is the average for consumer goods in the S&P 500. This is used to calculate board sentiment.',
    Component: decimals(3, .001, .25)
  }
];

const salesFields = [
  {
    name: 'initialSalespeople',
    label: 'Initial Salespeople',
    helpText: 'How many salespeople do teams start with?',
    Component: decimals(0, 0, 25)
  },
  {
    name: 'salesHireCost',
    label: 'Hiring Cost',
    helpText: 'How much to hire a salesperson?',
    Component: decimals(0, 0, 100000)
  },
  {
    name: 'salesFireCost',
    label: 'Firing Cost',
    helpText: 'How much to fire a salesperson?',
    Component: decimals(0, 0, 100000)
  },
  {
    name: 'salesQuarterlySalaray', // TODO: fix typo
    label: 'Quarterly Salary',
    helpText: 'How much are salespeople paid per quarter?',
    Component: decimals(0, 0, 100000)
  },
  {
    name: 'salesAdvertisingOutput',
    label: 'Advertising Productivity',
    helpText: 'How much advertising (in dollars) do salespeople generate per quarter?',
    Component: decimals(0, 1, 1000000)
  },
];

const getProps = (name, form, updateHandler, validators = []) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required', ...validators.map((v) => v[1])],
  errorMessages: ['field is required', ...validators.map((v) => v[0])],
  autoComplete: "off",
  value: form[name],
  onNumberChange: updateHandler(name),
});

const getSliderProps = (name, items, form, updateHandler) => ({
  id: name,
  name,
  autoComplete: 'off',
  defaultValue: form[name],
  value: form[name],
  items,
  onChangeValue: (v) => updateHandler(name)(v)
});

const SectionMap = ({title, items, form, updateHandler, disabled}) => {
  const classes = useStyles();
  return (
    <Group title={title} items={items}>
      {
        items.map( (f, i) => {
          if (f.validators) {
            f.validators.forEach((v) => {
              ValidatorForm.addValidationRule(v[1], v[2](form));
            });
          }

          const Component = f.Component || Field;
          const componentProps = f.isSlider ?
            getSliderProps(f.name, f.items, form, updateHandler) :
            getProps(f.name, form, updateHandler, f.validators);

          return (
            <Tooltip key={i} title={f.helpText}>
              <Component
                className={classes.overflowText}
                {...componentProps}
                label={f.label}
                disabled={disabled}
              />
            </Tooltip>
          );
        }
        )
      }
    </Group>
  );
};

SectionMap.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  form: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const ConfigurationForm = ({onSubmit, initialValue, disabled}) => {
  const classes = useStyles();
  const [form, updateHandler] =
    useFormReducer(initialValue,
      (init) => ({...init})
    );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit(form);
    }
  }

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
    >
      <fieldset disabled={disabled} className={classes.fieldset}>
        <Group stack>
          <Field
            {...getProps('name', form, updateHandler)}
            label="Name"
            onChange={(e) =>updateHandler('name')(e.target.value)}
            autoFocus />
          <Field
            {...getProps('description', form, updateHandler)}
            label="Description"
            onChange={(e) =>updateHandler('description')(e.target.value)}
            fullWidth
          />
          <SelectField
            {...getProps('type', form, updateHandler)}
            onChange={(e) => updateHandler('type')(e.target.value)}
            items={types}
            placeholder="Select Type"
            disabled={disabled}
          />
        </Group>
        <SectionMap
          title="Initial Values"
          items={initialValueFields}
          form={form}
          updateHandler={updateHandler}
          disabled={disabled}
        />
        <SectionMap
          title="Industry Settings"
          items={industryFields}
          form={form}
          updateHandler={updateHandler}
          disabled={disabled}
        />
        <SectionMap
          title="Salespeople"
          items={salesFields}
          form={form}
          updateHandler={updateHandler}
          disabled={disabled}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          Save
        </Button>
      </fieldset>
    </ValidatorForm>
  )
};

ConfigurationForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default ConfigurationForm;
