import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import HomeCarousel from './simulation/HomeCarousel';
import {withStyles} from '@material-ui/styles';
import MediaQuery from 'react-responsive';
import SmallDashboardCard from '../components/SmallDashboardCard';
import Checks from '../components/Checkbox';
import SentimentCard from '../components/SentimentCard';
import SimOverCard from '../components/SimOverCard';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

const styles = () => ({
  root: {
    marginBottom: 20,
  },
  graphStyle: {
    width: '100%',
    margin: 'auto',
  },
});

const Home = (props) => {
  const {classes} = props;
  const icon = require('../assets/inventory.png');
  const gears = require('../assets/gears.png');
  const production = require('../assets/production.png');
  const sold = require('../assets/sold.png');
  const up = require('../assets/green-thumb.png');
  const down = require('../assets/red-thumb.png');
  const latest = props.coresim.teams[props.teamIndex].vitalsHistory[props.coresim.teams[props.teamIndex].vitalsHistory.length - 1];
  const previous = props.coresim.teams[props.teamIndex].vitalsHistory[props.coresim.teams[props.teamIndex].vitalsHistory.length - 2] || latest;

  const totIncomeLatest = latest.revenue - latest.expenses;
  const totIncomePrevious = previous.revenue - previous.expenses;

  return (
    <Box className={classes.root}>
      <MediaQuery maxWidth={768}>
        {(matches) => {
          if (matches) {
            return (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title="Units on Hand"
                      amount={latest.inventory}
                      previous={previous.inventory}
                      image={icon}
                      fixedNumberLength={0}
                      isCurrency={false}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title="Units Sold"
                      amount={latest.lastQuarterDemand - latest.lostSales}
                      previous={previous.lastQuarterDemand - previous.lostSales}
                      image={sold}
                      fixedNumberLength={0}
                      isCurrency={false}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title="Total Income"
                      amount={totIncomeLatest}
                      previous={totIncomePrevious}
                      image={gears}
                      fixedNumberLength={2}
                      isCurrency={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title="Cash on Hand"
                      amount={latest.cash}
                      previous={previous.cash}
                      image={production}
                      fixedNumberLength={2}
                      isCurrency={true}
                    />
                  </Grid>
                </Grid>
                <br />
                <HomeCarousel
                  className={classes.graphStyle}
                  teamIndex={props.teamIndex}
                  coresim={props.coresim}
                  quarter={props.sim.quarter.counter}
                  quarters={props.sim.quarters}
                />
                <br />
                {!props.coresim.completed ? <Checks /> :
                  <SimOverCard/>}
                <br />
                <SentimentCard
                  title='BOARD SENTIMENT'
                  header={latest.boardSentiment > 0.50 ? 'Happy' : 'Unhappy'}
                  score={latest.boardSentiment}
                  description={latest.boardFeeling}
                  image={latest.boardSentiment > 0.50 ? up : down}
                  type="board"
                />
                <br />
                <SentimentCard
                  title='PUBLIC SENTIMENT'
                  header={latest.publicSentiment > 0.50 ? 'Happy' : 'Unhappy'}
                  score={latest.publicSentiment}
                  image={latest.publicSentiment > 0.50 ? up : down}
                  type="public"
                />
              </div>
            );
          } else {
            return (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title='Units Sold'
                      amount={latest.lastQuarterDemand - latest.lostSales}
                      previous={previous.lastQuarterDemand - previous.lostSales}
                      image={sold}
                      fixedNumberLength={0}
                      isCurrency={false}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title='Total Income'
                      amount={totIncomeLatest}
                      previous={totIncomePrevious}
                      image={sold}
                      fixedNumberLength={2}
                      isCurrency={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title='Units On Hand'
                      amount={latest.inventory}
                      previous={previous.inventory}
                      image={gears}
                      fixedNumberLength={0}
                      isCurrency={false}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <SmallDashboardCard
                      title='Cash on Hand'
                      amount={latest.cash}
                      previous={previous.cash}
                      image={production}
                      fixedNumberLength={2}
                      isCurrency={true}
                    />
                  </Grid>
                </Grid>
                <br />
                <HomeCarousel
                  className={classes.graphStyle}
                  teamIndex={props.teamIndex}
                  coresim={props.sim}
                  quarter={props.sim.quarter.counter}
                  quarters={props.sim.quarters}
                />
                <br /><br />
                <div style={{display: 'flex', margin: -5, alignItems: 'stretch', flexWrap: 'wrap'}}>
                  <div style={{padding: 5, flexBasis: '33.33%'}}>
                    {!props.coresim.completed ? <Checks /> :
                      <SimOverCard/>}
                  </div>
                  <div style={{padding: 5, flexBasis: '33.33%'}}>
                    <SentimentCard
                      title='BOARD SENTIMENT'
                      header={latest.boardSentiment > 0.50 ? 'Happy' : 'Unhappy'}
                      score={latest.boardSentiment}
                      description={latest.boardFeeling}
                      image={latest.boardSentiment > 0.50 ? up : down}
                      type="board"
                    />
                  </div>
                  <div style={{padding: 5, flexBasis: '33.33%'}}>
                    <SentimentCard
                      title='PUBLIC SENTIMENT'
                      header={latest.publicSentiment > 0.50 ? 'Happy' : 'Unhappy'}
                      score={latest.publicSentiment}
                      image={latest.publicSentiment > 0.50 ? up : down}
                      type="public"
                    />
                  </div>
                </div>
              </div>
            );
          }
        }}
      </MediaQuery>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

const homeStyled = withStyles(styles)(Home);

Home.propTypes = {
  classes: PropTypes.object,
  coresim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  sim: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(homeStyled);
