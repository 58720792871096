import * as React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbContext from './BreadcrumbContext';
import useBreadcrumbContext from './useBreadcrumbContext';
import useBreadcrumbActions from './useBreadcrumbActions';

const BreadcrumbContextProvider = ({children, breadcrumb}) => {
  return (
    <BreadcrumbContext.Provider value={breadcrumb}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

BreadcrumbContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string
  })),
};

export default BreadcrumbContextProvider;
export {BreadcrumbContext, useBreadcrumbContext, useBreadcrumbActions};
