/**
 *
 *  A shift function generator. A shift function
 * will shift output to the "right" by the amount, offset
 *
 * @param offset - how much to shift right
 *
 */

const shift = (offset) =>
  /**
  *
  * Generated shift function
  *
  * @param {number} x - the dependent variable
  * @returns {number}
  *
  */
  (x) => x - offset;

export default shift;
