import Field from '../../admin/Forms/Field';
import {useUserContext} from '../../providers/userProvider';
import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {simSubmitState} from '../../helpers/canSubmitDecisions';

// DecisionsField is only enabled if you are in an active quarter and you are not an advisor
const DecisionsField = ({activeQuarter, completed, decisionsSubmitted, ...props}) => {
  const user = useUserContext();
  if (simSubmitState({user, activeQuarter, completed, decisionsSubmitted}).canSubmit()) {
    return <Field {...props}/>
  }
  return <Field {...props} disabled/>
}

DecisionsField.propTypes = {
  activeQuarter: PropTypes.shape({end: PropTypes.number}),
  completed: PropTypes.bool,
  decisionsSubmitted: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    // since quarters is always a populated array on creation of the sim, i don't *think* this will ever get an
    // indexing issue.
    activeQuarter: state.coresim.quarters[state.coresim.quarter.counter],
    completed: state.coresim.completed,
    decisionsSubmitted: state.coresim.teams[state.teamIndex.teamIndex].decisions.decisionsComplete
  };
};

export default connect(mapStateToProps, null)(DecisionsField);
