import React, { useState } from 'react';
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Typography, Paper, Grid} from '@material-ui/core';

import {useBulkUploadProvider} from "../../providers/bulkUploadProvider";
import {useBreadcrumbActions} from "../../providers/breadcrumbProvider";
import Progress from "../../components/Progress";
import {API} from "aws-amplify";
import {toast} from 'react-toastify';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  subHead: {
    paddingBottom: theme.spacing(2),
  },
  blurb: {
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%'
  },
  ul: {
    display: 'block',
    listStyleType: 'disc',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    paddingInlineStart: '40px'
  },
  existingUsersUl: {
    display: 'block',
    listStyleType: 'disc',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    paddingInlineStart: '40px'
  },
  submittingButton: {
    display: 'flex',
    alignItems: 'center',
  },
  submittingText: {
    paddingRight: '1em'
  },
  confirm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }}),
);

const ConfirmFile = () => {
  const classes = useStyles();
  const history = useHistory();
  const {state} = useBulkUploadProvider();
  const {groupedUsers, userCsv} = state;
  const breadcrumbActions = useBreadcrumbActions();
  const [loading, setLoading] = useState(false);

  if (!groupedUsers) {
    breadcrumbActions.pop()
    return (
      <Box>
        <Progress variant="overlay" size='1.5em' />
      </Box>
    )
  }

  const queueBulkUpload = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await API.post('mongo', '/bulk-upload', {
        body: {
          overrideTeamValidation: state.overrideTeam,
          csvFile: {contents: userCsv.text, name: userCsv.name},
          groupedTeamData: groupedUsers
        }
      })

      toast.success(<>
        <p>User Spreadsheet uploaded!</p>
        <p>Expect updates on processing via email.</p>
      </>)

      setLoading(false)
      history.push('/admin/users')

    } catch (err) {
      setLoading(false)

      toast.error(<>
        <p>User spreadsheet unable to post!</p>
        <p>Please contact support with this error:</p>
        <p>{err.toString()}</p>
      </>)
    }
  }

  return (
    <Box>
      <Typography variant="h4" className={classes.header}>
        Confirmation
      </Typography>
      <Typography variant="body1" className={classes.blurb}>
        The following teams and users will be created. If the users are marked with an asterisk (*), they are already in the system and will be removed from their old team to be added to this one.
      </Typography>
      {state.overrideTeam && (
        <Typography variant="body1" className={classes.blurb}>
          You selected the override team validation flag. If the team already exists, then users will be added to that existing team.
        </Typography>
      )}
      <ChairUsersPanel classes={classes} chairUsers={groupedUsers.chairUsers} />
      <TeamUsersSection classes={classes} />
      <Button
        variant="contained"
        color="secondary"
        onClick={queueBulkUpload}
        className={classes.confirm}
        disabled={loading}
      >
        {loading ? (
          <div className={classes.submittingButton}>
            <div className={classes.submittingText}>Submitting</div>
            <CircularProgress size='1rem'/>
          </div>
        ) : 'Confirm & Process'}
      </Button>
    </Box>
  )
}

const ChairUsersPanel = ({chairUsers, classes}) => (
  chairUsers && chairUsers.length ? (
    <section>
      <Typography variant="h4">
        Chairs
      </Typography>
      <Typography variant="body1" className={classes.ul} component="ul">
        {chairUsers.map((chair, index) =>
          <li key={index}>{`${chair.firstName} ${chair.lastName}, ${chair.email}`}</li>)}
      </Typography>
    </section>
  ) : null
)

const TeamUserCard = ({classes, teamName, users}) => {
  const {state} = useBulkUploadProvider();
  const {preexistingTeams, overrideTeam} = state;

  let existingTeamWithName;
  const existingTeamWithNameList = preexistingTeams.filter(t => t.teamName === teamName)

  let usersToBeAdded = users;
  if (existingTeamWithNameList && existingTeamWithNameList.length && overrideTeam) {
    existingTeamWithName = existingTeamWithNameList[0]
    usersToBeAdded = users.reduce((list, user) => {
      const existingTeamUserIndex = existingTeamWithName.members.findIndex(m => m.email === user.email)
      if (existingTeamUserIndex === -1) {
        list.push(user)
      }
      return list
    }, [])
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper className={classes.paper}>
        <Typography variant='h5'>{teamName} {existingTeamWithName && '(Already Exists)'}</Typography>
        {usersToBeAdded.length === 0 && (
          <Typography variant="body1" className={classes.header}>All users in upload already on team.</Typography>
        )}
        <Typography variant="body1" className={classes.ul} component="ul">
          {
            usersToBeAdded.map((user, index) =>
              <li key={index}>{`${user.firstName} ${user.lastName}, ${user.email} (${user.role[0]})${user.userExists ? '*' : ''}`}</li>
            )
          }
        </Typography>
        {existingTeamWithName && (
          <>
            <Typography variant="body1">
              Existing Users in {teamName}:
            </Typography>
            <Typography variant="body1" className={classes.existingUsersUl} component="ul">
              {
                existingTeamWithName.members.map((user, index) =>
                  <li key={index}>{`${user.firstName} ${user.lastName}, ${user.email} (${user.role[0]})`}</li>
                )
              }
            </Typography>
          </>
        )}
      </Paper>
    </Grid>
  )
}

const TeamUsersSection = ({classes}) => {
  const {state} = useBulkUploadProvider();
  const {groupedUsers: {teamUsers}} = state;
  return (
    teamUsers ? (
      <section>
        <Typography variant="h4" className={classes.subHead}>Teams and their Users (Advisors and Students)</Typography>
        <Grid container spacing={3}>
          {
            Object.entries(teamUsers).map((team, index) => {
              const [teamName, users] = team;
              return (
                <TeamUserCard teamName={teamName} users={users} classes={classes} key={index}/>
              )
            }
          )}
        </Grid>
      </section>
    ) : null
  )
}

export default ConfirmFile;
