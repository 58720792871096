import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, AppBar, Tab} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import formatCurrency from '../helpers/formatCurrency';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& .MuiTabs-scroller': {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left'
      }
    }
  },
  centering: {
    '& .MuiTabs-flexContainer': {
      display: 'inline-flex',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block'
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }
}));

const SimTabs = ({idx, coresim, teamIndex}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currTeamStats = coresim.teams && coresim.teams[teamIndex];
  const {vitalsHistory} = currTeamStats
  const newName = vitalsHistory !== undefined ? vitalsHistory.filter(vital => vital.businessAs) : undefined;

  const latest = currTeamStats.vitalsHistory && currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1];

  let sentimentality = ``;

  switch (true) {
    case (latest.qaSentiment < 0.25) :
      sentimentality = `QA perception is awful!`
      break;
    case (latest.qaSentiment >= 0.25 && latest.qaSentiment < 0.5) :
      sentimentality = `QA perception is poor.`
      break;
    case (latest.qaSentiment >= 0.5 && latest.qaSentiment < 0.75) :
      sentimentality = `QA perception is OK.`
      break;
    case (latest.qaSentiment >= 0.75 && latest.qaSentiment <= 1) :
      sentimentality = `QA perception is good!`
      break;
    default:
      sentimentality = `out of range`
  }

  const tabControl = [
    currTeamStats.vitalsHistory.length - 1 === 0 ? undefined :
      [
        {
          label: sentimentality,
        },
        {
          label: 'Last Quarter Defects',
          data: currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].lastQuarterDefects
        }
      ],
    [
      {
        label: 'Production Capacity',
        data: currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].capacity
      }
      // Lost sales was removed in https://github.com/WashingtonBusinessWeek/wbw-frontend/pull/639
    ],
    [
      {
        label: 'Plant Capacity',
        data: currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].capacity
      }
    ],
    [
      {
        label: 'Industry Price',
        data: formatCurrency(currTeamStats.configuration.industryPrice)
      },
      {
        label: 'Current Sales Price',
        data: currTeamStats.vitalsHistory.length > 1 ? formatCurrency(currTeamStats.decisionHistory[currTeamStats.decisionHistory.length -1].price) : formatCurrency(currTeamStats.configuration.industryPrice)
      }
    ],
    [
      {
        label: 'Sales People Output',
        data: formatCurrency((currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].salespeople * currTeamStats.configuration.salesAdvertisingOutput))
      }
    ],
    [
      {
        label: 'Current Sales Force',
        data: currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].salespeople
      },
      {
        label: 'Cost to Hire',
        data: formatCurrency(currTeamStats.configuration.salesHireCost)
      },
      {
        label: 'Cost to Fire',
        data: formatCurrency(currTeamStats.configuration.salesFireCost)
      },
      {
        label: 'Quarterly Salary',
        data: formatCurrency(currTeamStats.configuration.salesQuarterlySalaray)
      }
    ],
    [
      {
        label: 'Expected', data:  currTeamStats.configuration.expectedProductDevPerQuarter
      }
    ],
    undefined,
    [
      {
        label: 'Expected Dividend Yield',
        data:  currTeamStats.configuration.industryDividentYeild
      }
    ],
    undefined,
    currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length -1].liabilitiesBankLoan === 0 ? undefined :
      [
        {
          label: 'Minimum Loan Payment Due',
          data: formatCurrency(currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].loanPaymentDue)
        },
        {
          label: 'Amount to Pay Off Loan',
          data: currTeamStats.vitalsHistory.length > 1 ? formatCurrency(currTeamStats.vitalsHistory[currTeamStats.vitalsHistory.length - 1].payoff) : 0
        }
      ],
    newName.length === 0 ? undefined :
      [
        {
          label: 'Doing Business As',
          data: newName[newName.length - 1].businessAs
        }
      ]

  ]

  const tabbackgroundcolor = ['#9c27b0', '#f11e41', '#f17f1e', '#33f11e' ]

  return (
    <div className={classes.root}>

      <React.Fragment>
        {tabControl[idx] &&
        <AppBar position="static" color="default">
          <Tabs
            className={classes.centering}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabControl[idx].map((tab, index) => (
              <Tab style={{margin: '5px', fontSize: '0.8rem'}} key={index}
                label={
                  <React.Fragment>
                    <div style={{backgroundColor: tabbackgroundcolor[index], borderRadius: '2rem', width: '100%', height: '0.5rem'}}></div>
                    <p style={{padding: '5px'}}>
                      <span style={{color: '#999999', fontWeight: 'bolder'}}>{tab.label}</span>
                      <span style={{fontWeight: 'bolder', marginLeft: '10px'}}>{tab.data}</span>
                    </p>
                  </React.Fragment>}
              />

            ))}
          </Tabs>
        </AppBar>
        }
      </React.Fragment>
    </div>
  )
}

SimTabs.propTypes = {
  idx: PropTypes.number.isRequired,
  coresim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired
}

export default SimTabs;
