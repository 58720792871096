import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import formatCurrency from '../../helpers/formatCurrency';
import PropTypes from 'prop-types';
const imgMiddle = require('../../assets/middle.png');
const imgQuality = require('../../assets/quality.png');
const imgQuantity = require('../../assets/quantity.png');

const useStyles = makeStyles((theme) => ({
  divStyle: {
    margin: 'auto',
    marginTop: '30px',
  },
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
  },
  noDataTitle: {
    color: '#aeaeae',
    position: 'absolute',
    top: '25%',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      top: '15%',
    },
  },
}));

const Factory = (props) => {
  const classes = useStyles();
  const vitalsHistory = props.coresim[props.teamIndex].vitalsHistory;
  const decisionHistory = props.coresim[props.teamIndex].decisionHistory;
  let lastQuarterUnits;

  decisionHistory.length < 1 ? lastQuarterUnits = 0 : lastQuarterUnits = decisionHistory[decisionHistory.length - 1].unitsProduced;
  const latest = vitalsHistory[vitalsHistory.length - 1];
  const stockData = [];

  vitalsHistory.map((vital, i) => {
    stockData.push({x: `Q${i}`, y: parseInt(vital.stock, 10)});
    return '';
  });

  // this is an MVP, and the images will need to be changed in the next few weeks for copyright/IP - though I'm pretty sure they were free images available for use. Will check.
  let img;

  if (latest.capacity <= 30000) {
    img = imgQuality;
  } else if (latest.capacity <= 60000) {
    img = imgQuantity;
  } else {
    img = imgMiddle;
  }

  return (
    <div className={(classes.divStyle, classes.class)}>
      <Typography className={classes.titleStyle}>Manufacturing Capacity</Typography>
      <img src={img} alt='Production Factory Representation' />
      <Typography>Current Capacity: {latest.capacity}</Typography>
      <Typography>Last Quarter Production Costs: {formatCurrency(latest.productionCost)}</Typography>
      <Typography>Last Quarter Defects: {latest.lastQuarterDefects}</Typography>
      {lastQuarterUnits === 0 ? null : (
        <Typography>Price Per Unit: {formatCurrency(latest.productionCost / lastQuarterUnits)}</Typography>
      )}
    </div>
  );
};

Factory.propTypes = {
  coresim: PropTypes.array,
  classes: PropTypes.object,
  teamIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim.teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Factory);
