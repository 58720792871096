import React from 'react';
import DecisionTooltip from './DecisionTooltip';
import PropTypes from 'prop-types';

const DefaultAction = ({
  coresim,
  teamIndex,
  businessData,
  decisions,
  onToolTipToggle,
  handleChange,
  expenses,
  Component
}) => {
  const quarter = coresim.quarter.counter;
  const vitals = coresim.teams[teamIndex].vitalsHistory[coresim.teams[teamIndex].vitalsHistory.length - 1];
  return (
    <DecisionTooltip
      disableHoverListener
      placement="top-start"
      quarter={quarter}
      vitals={vitals}
      decisions={decisions}
      expenses={expenses}
      onOpen={() =>
        onToolTipToggle && onToolTipToggle(true)}
      onClose={() => onToolTipToggle &&
              onToolTipToggle(false)}
      leaveDelay={300}
    >
      <span>
        <Component
          id={businessData.task}
          name={businessData.task}
          label={businessData.title}
          value={parseInt(decisions[businessData.task])}
          onNumberChange={handleChange(businessData.task)}
          disabled={businessData.disabled}
        />
      </span>
    </DecisionTooltip>
  );
};

DefaultAction.propTypes = {
  coresim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  businessData: PropTypes.object.isRequired,
  expenses: PropTypes.number.isRequired,
  decisions: PropTypes.object.isRequired,
  onToolTipToggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired,
  ref: PropTypes.node
};

export default DefaultAction;
