import React, {useState} from 'react';
import MiniCaseList from './MiniCaseList';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateMinicase from './CreateMinicase';
import EditMinicase from './EditMinicase';
import StackPanel from '../../routing/StackPanel';

const MinicaseAdmin = () => {
  const [reload, setReload] = useState(new Date());
  const match = useRouteMatch();

  const onReload = () => {
    setReload(new Date());
  }

  return (
    <AdminPanel label='Minicases' >
      <MiniCaseList reload={reload} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create Minicase'
        >
          <StackPanel>
            <CreateMinicase onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit Minicase'
        >
          <StackPanel>
            <EditMinicase onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  )
}

export default MinicaseAdmin;
