import { useReducer } from 'react';

const ADD = 'array/add';
const REMOVE = 'array/remove';
const FIND_AND_REMOVE = 'array/findAndRemove';
const REPLACE = 'array/replace';
const RESET = 'array/reset';
const POP = 'array/pop';
const INIT = 'array/init';

export function useArrayReducer(
  initializerArgs,
  initializer
) {
  const [array, arrayDispach] = useReducer(
    (state, action) => {
      switch (action.type) {
        case ADD:
          return [
            ...state,
            action.payload
          ];
        case REMOVE: {
          if (typeof action.payload !== 'number') {
            return state;
          }

          const newState = [...state];
          newState.splice(action.payload, 1);
          return newState;
        }
        case FIND_AND_REMOVE: {
          const index = state.findIndex((v) => v._id === action.payload._id);
          const newState = [...state];
          if (index === -1) {
            return newState;
          }

          newState.splice(index, 1);
          return newState;
        }
        case POP: {
          const newState = [...state];
          newState.pop();
          return newState;
        }
        case REPLACE: {
          if (!action.payload || typeof action.payload.index !== 'number') {
            return state;
          }

          const newState = [...state];
          newState.splice(action.payload.index, 1, action.payload.value);
          return newState;
        }
        case INIT: {
          return Array.isArray(action.payload) ? [...action.payload] : [];
        }
        case RESET:
          return initializer(initializerArgs);
        default:
          return state;
      }
    }, initializerArgs, initializer
  );

  const getAddHandler = (value) => {
    arrayDispach({
      payload: value,
      type: ADD
    });
  };

  const getRemoveHandler = (index) => {
    arrayDispach({
      payload: index,
      type: REMOVE
    });
  };

  const getFindAndRemoveHandler = (item) => {
    arrayDispach({
      payload: item,
      type: FIND_AND_REMOVE,
    });
  };

  const getReplaceHandler = (index) => (value) => {
    arrayDispach({
      payload: { index, value },
      type: REPLACE
    });
  };

  const getResetHandler = () => {
    arrayDispach({
      type:RESET,
    });
  };

  const getPopHandler = () => {
    arrayDispach({
      type:POP,
    });
  };

  const getInitHandler = (init) => {
    arrayDispach({
      payload: init,
      type: INIT,
    });
  };

  return [array, {
    add: getAddHandler,
    remove: getRemoveHandler,
    findAndRemove: getFindAndRemoveHandler,
    replace: getReplaceHandler,
    reset: getResetHandler,
    push: getAddHandler,
    pop: getPopHandler,
    init: getInitHandler,
  }];
}
