import React from 'react';
import formatCurrency from '../../helpers/formatCurrency';
import formatNumber from '../../helpers/formatNumber';
import Warning from '@material-ui/icons/Warning';
import {shakeOnCondition} from '../../helpers/shakeHoc';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Box, withStyles, Tooltip, Typography, Link} from '@material-ui/core';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'white',
    minWidth: 200,
    padding: 0,
  },
}))(Tooltip);


const Value = ({label, value}) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box py={.4} px={1} flexBasis="50%" flexGrow={0} flexShrink={0}>
        {label}
      </Box>
      <Box py={.4} px={1} flexBasis="50%" flexGrow={0} flexShrink={0} textAlign="right">
        {value}
      </Box>
    </Box>
  );
};

Value.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const Status = ({classes, vitals, decisions, quarter, expenses}) => {
  const cashOnHand = vitals.cash +
  (decisions.loanConfirmed ? Number(decisions.loansTaken) : 0);
  const error = cashOnHand < expenses;
  
  const Base = ({className}) => {
    return (
      <Box className={classes.root}>
        <Box className={classnames(className, classes.tooltip)}>
          <Typography variant="h5" style={{backgroundColor: '#555', padding: 4}}>
            {`Q${quarter+1} At a Glance`}
          </Typography>
          {error &&
          <Box
            p={.5}
            style={{backgroundColor: '#d32f2f', color: 'white', width: '100%'}}
          >
            <Warning style={{fontSize: '1rem', verticalAlign: 'bottom'}} />
            &nbsp;Available cash exceeded!

            {vitals.liabilitiesBankLoan === 0 ? <Link href='#loansTaken'  style={{marginLeft: '2rem', color: 'white', cursor: 'pointer'}}>Loan options...</Link> : <Typography>Change Decisions.</Typography>}
          </Box>
          }
          <Value
            label='cash avail.'
            value={formatCurrency(cashOnHand - expenses)} />
          <Value label='inventory' value={formatNumber(vitals.inventory)} />
          <Value
            label='sold'
            value={formatNumber(vitals.lastQuarterDemand)} />
          {/* Lost sales was removed in https://github.com/WashingtonBusinessWeek/wbw-frontend/pull/639 */}
        </Box>
      </Box>
    )
  };

  const Shakeable = shakeOnCondition(Base);
  return <Shakeable condition={error} />;
};

Status.propTypes = {
  classes: PropTypes.object,
  vitals: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  quarter: PropTypes.number.isRequired,
  expenses: PropTypes.number.isRequired,
  className: PropTypes.object
}

const StyledStatus = withStyles( () => ({
  root: {
    backgroundColor: 'rgba(1,0,0,0)',
    paddingTop: 5,
    paddingBottom: 5,
  },
  tooltip: {
    '& > div + div': {
      borderTop: '.4px solid #777',
    },
    'display': 'flex',
    'flexDirection': 'column',
    'padding': 0,
    'backgroundColor': '#333',
  },
})
)(Status);


const DecisionTooltip = ({
  vitals,
  decisions,
  quarter,
  expenses,
  ...remaining}) => {
  return (
    <HtmlTooltip
      title={
        <StyledStatus
          vitals={vitals}
          decisions={decisions}
          quarter={quarter}
          expenses={expenses}
        />
      }
      interactive
      {...remaining}
    />
  );
};

DecisionTooltip.propTypes = {
  vitals: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  quarter: PropTypes.number.isRequired,
  expenses: PropTypes.number.isRequired
}

export default DecisionTooltip;
