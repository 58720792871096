import React, {useState, useEffect, useMemo, useCallback} from 'react';
import tableHoc from '../../components/Tables/tableHoc';
import {API} from 'aws-amplify';
import { Box, Button, Typography, Grid, Tooltip, IconButton, Dialog } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import CopyIcon from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import ViewIcon from '@material-ui/icons/ImportContacts';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import handleInvites from '../../helpers/sendInvites';
import Transition from '../../components/ModalTransition';
import DeleteModal from '../../components/DeleteModal';

const header = () => [
  {
    isSortable: true,
    label: 'Name',
    name: 'name',
  },
  {
    isSortable: true,
    label: 'Last Modified',
    name: 'lastModifiedDate',
  },
  {
    isSortable: true,
    label: 'Created',
    name: 'createdDate',
  },
  {
    label: ''
  }
];

const SimIcon = ({linkFn, btnColor, label, children}) => (
  <Grid item>
    <Tooltip title={label} aria-label={label}>
      <IconButton
        color={btnColor}
        onClick= {(e) => {
          e.preventDefault();
          linkFn();
        }}>{children}</IconButton>
    </Tooltip>
  </Grid>
);

SimIcon.propTypes = {
  linkFn: PropTypes.func.isRequired,
  btnColor: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string
}

const rowDefinition = (viewSim, deleteSim, editSim, startSim, cloneSim) => (row, index) => [
  row.name,
  row.lastModifiedDate ? new Date(row.lastModifiedDate).toLocaleString() : "",
  row.createdDate ? new Date(row.createdDate).toLocaleString() : "",
  <Grid key="actions" container justify="flex-end">
    {viewSim &&
      <SimIcon linkFn={() => viewSim(row._id)} btnColor="primary" label="View"><ViewIcon/></SimIcon>}
    {editSim &&
      <SimIcon linkFn={() => editSim(row._id)} btnColor="primary" label="Edit"><CreateIcon/></SimIcon>}
    {deleteSim &&
      <SimIcon linkFn={() => deleteSim(row)} btnColor="secondary" label="Delete"><DeleteIcon/></SimIcon>}
    {startSim &&
      <SimIcon linkFn={() => startSim(row._id)} btnColor="secondary"label="Send Invite"><EmailIcon/></SimIcon>}
    {cloneSim &&
      <SimIcon linkFn={() => cloneSim(row._id)} btnColor="secondary" label="Clone"><CopyIcon/></SimIcon>}
  </Grid>
];

const SimulationList = ({reload}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [sims, setSims] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    const abortObj = {
      aborted: false
    };

    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }

  }, [reload]);

  const load = async (abortObj) => {
    try {
      const sims = await API.get('mongo', '/sims');
      if (!abortObj || !abortObj.aborted)
      {
        setSims(sims.filter((s) => s.active !== false));
      }
    } catch (e) {
      toast.error('Error retrieving simulations.');
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async (id) => {
    try {
      setLoading(true);
      await API.put('mongo', `/sims/${id}`,
        {
          body: {
            active: false
          }
        }
      );
      toast.success('simulation deleted');
    } catch(e) {
      toast.error('Error deleting simulation');
    } finally {
      setDeleting();
      load();
    }

  }, []);

  const startSim = useCallback(async (id) => {
    let simList = {};
    try {
      setLoading(true);
      simList = await API.get('mongo', `/sims/${id}`);
    } catch(e) {
      toast.error('Error getting simulation for invite');
    }

    let body = { simStarted: true };
    const task1 = handleInvites(simList.teams, simList.chairs, simList, simList.quarters);
    const task2 = API.put('mongo', `/sims/${id}`, {body});

    try{
      await Promise.all([task1, task2]);
    } catch(e) {
      toast.error('Error staring sim.')
    }
    load();
  }, []);

  const handleDelete = useCallback((row) => {
    setDeleting(row);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting();
  }, []);

  const onEdit = useCallback((id) => {
    history.push(`${match.url}/${id}`);
  }, []);

  const onView = useCallback((id) => {
    history.push(`${match.url}/${id}/view`);
  }, []);

  const onClone = useCallback((id) => {
    history.push(`${match.url}/${id}/clone`);
  }, []);

  const Table = useMemo(() =>
    tableHoc(rowDefinition(onView, handleDelete, onEdit, startSim, onClone), header),
  [handleDelete, onEdit, startSim]);

  return (
    <Box
      display="flex"
      flexDirection="column">

      <Typography
        variant="h4">
            Simulations
      </Typography>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        py={2}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={
            () => history.push(`${match.url}/create`)
          }
        >
          + New
        </Button>
      </Box>

      <Table
        data={sims}
        sortField='city'
        sortAscending
        loading={loading}
      />

      <Dialog
        style={{
          margin: 'auto',
        }}
        open={Boolean(deleting)}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <DeleteModal handleConfirm={() => handleConfirmDelete(deleting._id)} handleCancel={handleCancelDelete} what={`Simulation "${(deleting && deleting.name) || ''}"`}/>
      </Dialog>
    </Box>

  );
};

SimulationList.propTypes = {
  reload: PropTypes.object.isRequired
}

export default SimulationList;
