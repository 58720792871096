import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {connect} from 'react-redux';
import adminMenu from '../menus/admin';
import mainMenu from '../menus/main';
import chairMenu from "../menus/chair";
import MenuLink from '../components/widgets/MenuLink';
import moment from 'moment';
import {useCurriculumContext} from '../providers/curriculumProvider';
import {useUserContext} from '../providers/userProvider';
import { Box, Tooltip } from '@material-ui/core';
import simBrandingData from '../containers/data/simBrandingData';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 1,
    },
  },
  appBar: {
    zIndex: theme.zIndex.appBar + 1,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth + 240,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  toolbarStyle: {
    justifyContent: 'space-between'
  }
}));

function ResponsiveDrawer(props) {
  const user = useUserContext();
  const activeCurriculumIds = useCurriculumContext();
  const {container, coresim, teamIndex} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const date = moment().add(1, 'y').dayOfYear(4);
  const qtrFirstMonth = props.coresim.quarter.counter * 3;
  let routeItems;

  if (user.role === 'chair') {
    routeItems = chairMenu(coresim, teamIndex);
  } else if (user.role === 'admin') {
    routeItems = adminMenu;
  } else {
    routeItems = mainMenu(coresim, teamIndex, activeCurriculumIds);
  }

  const simBranding = simBrandingData[coresim._id];
  const simLogos = simBranding && simBranding.logo ? [simBranding.logo].flat() : null;

  const drawer = (hideOnClick) => (
    <div>
      <div className={classes.toolbar} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: 20, maxWidth: drawerWidth - (20 * 2), flexWrap: "wrap"}}>
        <Box style={{flexGrow: 2, display: "flex", gap: "8px"}}>
          {simLogos ? (simLogos.map((logo) => 
            <img key={logo} src={logo} style={{ maxHeight: "64px", objectFit: "contain", padding: "0.5rem", paddingLeft: "0" }} />
          )
          ) : (
            <Typography variant="h6" noWrap>
              WBW
            </Typography>
          )}
        </Box>

        { simLogos ? null: (
          <div style={{flexGrow: 1}}>
            <Tooltip title="Logout">
              <IconButton
                onClick={async () => {
                  await Auth.signOut();
                  props.childProps.handleLogout();
                }}
                style={{float: "right", paddingRight: 0}}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <Divider />
      <React.Fragment>
        {
          routeItems
            .map((entry, i) =>
              hideOnClick ? <MenuLink key={i} entry={entry} onClick={handleDrawerToggle}/> :
                <MenuLink key={i} entry={entry} />
            )
        }
      </React.Fragment>

      {simLogos ?
        (<>
          <Divider />
          <MenuLink
            entry={{
              Icon: LogoutIcon,
              label: "Logout",
              action: async () => {
                await Auth.signOut();
                props.childProps.handleLogout();
              }
            }}
          />
        </>)
        : null
      }
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarStyle}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {user.role !== 'admin' ? (
              <div style={{color: 'white', fontSize: 14, verticalAlign: 'middle', marginRight: '.8rem'}}>
                {date.clone().add(qtrFirstMonth, 'month').format('MMM Do YYYY')}
              </div>) : null}
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {(user.role !== 'admin' && !props.coresim.completed)  ? (
              <div style={{color: 'white', fontSize: 24, verticalAlign: 'middle'}}>
         Q{props.coresim.quarter.counter + 1}
              </div>): null}
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer(true)}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer(false)}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
  admin: PropTypes.bool,
  childProps: PropTypes.object,
  coresim: PropTypes.object,
  teamIndex: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
    teamIndex: state.teamIndex.teamIndex,
  };
};

export default connect(mapStateToProps)(ResponsiveDrawer);
