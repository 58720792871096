import {Dialog, Box} from '@material-ui/core';
import React from 'react';
import Curriculum from '../../components/Integrations/Blackboard/Curriculum/Curriculum';
import {showCurriculum} from '../../coresim/reducers/settings'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import Transition from '../../components/ModalTransition';
import ModalTitle from '../../components/ModalTitle';
import {useCurriculumContext} from '../../providers/curriculumProvider';


const StudentCurriculumModal = ({
  showCurriculum,
  simulation,
  closeModal,
  editField
}) => {
  const activeCurriculumIds = useCurriculumContext();
  const handleExited = (e) => {
    if (editField) {
      editField.focus();
    }
  }

  return (
    <Dialog aria-labelledby="course-announcements-modal"
      open={showCurriculum}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
      onClose={closeModal}
      onExited={handleExited}>
      <Box m={3}>
        <Curriculum
          courseId={simulation && simulation.bbCourse ? simulation.bbCourse.id : null}
          title={<ModalTitle text='Curriculum' closeModal={closeModal}/>}
          activeModules={activeCurriculumIds}/>
      </Box>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    showCurriculum: state.settings.showCurriculum,
    simulation: state.coresim,
    editField: state.settings.editField,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => dispatch(showCurriculum(false)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentCurriculumModal);

StudentCurriculumModal.propTypes = {
  showCurriculum: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  simulation: PropTypes.object,
  editField: PropTypes.object,
}
