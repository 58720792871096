import logistic from './base/logistic';
import compose from './base/compose';
import sum from './base/sum';
import shift from './base/shift';
import scaleArray from './base/scaleArray';

const advertisingSum = ([current, last, secondLast, ...remaining]) =>
  sum([
    1.5 * current,
    last ? last : 0,
    secondLast ? 0.5 * secondLast : 0,
    ...scaleArray(0.25)(remaining),
  ]);

/**
 * Function, given by https://www.desmos.com/calculator/tbddmdluy3,
 * to determine the product development sentiment as a value from 0 to 1
 * with a result of .5 at the product developement and increasing
 * sentiment when there is more development or advertising. Furthermore,
 * sentiment is scaled equivalently about the midpoint.
 *
 * The amount that is increased is determined by sensitivity, which
 * should be kepth within the boundaries of 1 to 4.
 * Experimentally, a sensitivity of 1 is an average result that will
 * result in a 30 point shift from midpoint on a halving / doubling
 *
 * @param {number} sensitivity - Should be a factor of the expected quarter product development (k * dev). Factor should be between 0 and 2 to determine how sensitive the sentiment is. A factor of 1 leads to obsolesence in about 2 quarters, .5 in 4 quarters
 *
 */
const productDevelopmentSentiment = (sensitivity) =>
  /**
   * @param {number} expectedProductDev - the cumulative product developement expected
   */
  (expectedProductDev) =>
    /**
     * @param {Array<number>} advertisingQuarters - the advertising history (in order)
     */
    (advertisingQuarters) =>
      /**
       * @param {Array<number>} productDevQuarters - the product development history (in order)
       */
      (productDevQuarters) => {
        return compose(
          logistic(sensitivity),
          shift(
            expectedProductDev - advertisingSum(advertisingQuarters.reverse())
          )
        )(sum(productDevQuarters));
      };

export default productDevelopmentSentiment;
