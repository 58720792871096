import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  flex: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    '& > *': {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }
}));

const Group = ({children, title, stack, ...remaining}) => {
  const classes = useStyles();

  return (
    <Paper {...remaining}>
      <Box
        p={4}
        my={4}
      >
        {title &&
          <Typography variant="h6">
            {title}
          </Typography>
        }
        <Box
          className={classes.flex}
          display="flex"
          flexDirection={stack ? "column" : "row"}
          flexWrap={stack ? "nowrap" : "wrap"}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
};

Group.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  stack: PropTypes.bool,
};

export default Group;
