import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {quotes} from '../containers/data/pareto-quotes';
import {Typography, Box, Button, makeStyles} from '@material-ui/core';
import Progress from './Progress';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';

const randomIndex = Math.floor(Math.random() * Math.floor(quotes.length));

const useStyles = makeStyles((theme) =>( {
  buttonStyle: {
    margin: 'auto'
  }
}))

const LoadingModal = ({handleLogout}) => {

  const classes = useStyles();
  return (
    <React.Fragment>
      <DialogContent style={{overflow: 'visible', textAlign: 'center', paddingTop: 100}}>
        <img
          src={require('../assets/wbw-logo.png')}
          alt=''
          width='150'
          height='150'
        />
      </DialogContent>
      <div style={{marginBottom: 250}}>
        <DialogTitle
          style={{textAlign: 'center', fontWeight: 'bold'}}
          id='alert-dialog-slide-title'>

          <br/>
          <Box color="#333">
            <Progress color="inherit">
              <Typography variant='h6'>Now Loading</Typography>
            </Progress>
          </Box>

        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{textAlign: 'center', color: 'black'}}
            id='alert-dialog-slide-description'>
            {quotes[randomIndex].quote}
          </DialogContentText>
          <DialogContentText
            style={{textAlign: 'center', color: 'black'}}
          >
            {quotes[randomIndex].author}
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{textAlign: 'center'}}>
          <Button
            className={classes.buttonStyle}
            variant='contained'
            onClick={async () => {
              await Auth.signOut();
              handleLogout();
            }} color="primary">
          Logout
          </Button>
        </DialogContent>
      </div>
    </React.Fragment>
  );
};

LoadingModal.propTypes = {
  handleLogout: PropTypes.func.isRequired
}

export default LoadingModal;
