import React, {useState} from 'react';
import UserList from './UserList';
import UploadFile from '../BulkUpload/UploadFile';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import StackPanel from '../../routing/StackPanel';
import PropTypes from 'prop-types';

const UserAdmin = ({isSelect, title, filter, bulkUpload}) => {
  const [reload, setReload] = useState(new Date());
  const match = useRouteMatch();

  const onReload = () => {
    setReload(new Date());
  };

  return (
    <AdminPanel root={!isSelect} label={title || 'Users'}>
      <UserList reload={reload} isSelect={isSelect} filter={filter} bulkUpload={bulkUpload} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create User'
        >
          <StackPanel>
            <CreateUser onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute path={`${match.url}/bulk-upload`}
                    label='Bulk Upload'>
          <StackPanel>
            <UploadFile />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit User'
        >
          <StackPanel>
            <EditUser onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  );
};

UserAdmin.propTypes = {
  isSelect: PropTypes.bool.isRequired,
  filter: PropTypes.func,
  title: PropTypes.string,
  bulkUpload: PropTypes.bool
};

export default UserAdmin;
