import React, {useState} from 'react';
import SimulationList from './SimulationList';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateSimulation from './CreateSimulation';
import EditSimulation from './EditSimulation';
import CloneSimulation from './CloneSimulation';
import StackPanel from '../../routing/StackPanel';
import AdminViewSim from './AdminViewSim';

const SimulationAdmin = () => {
  const [reload, setReload] = useState(new Date());
  const match = useRouteMatch();

  const onReload = () => {
    setReload(new Date());
  }

  return (
    <AdminPanel label='Simulations' >
      <SimulationList reload={reload} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create Simulation'
        >
          <StackPanel>
            <CreateSimulation onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id/view`}
          label='View Simulation'
        >
          <StackPanel>
            <AdminViewSim />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id/clone`}
          label='Clone Simulation'
        >
          <StackPanel>
            <CloneSimulation onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit Simulation'
        >
          <StackPanel>
            <EditSimulation onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  )
}

export default SimulationAdmin;
