import React from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/wbw-logo.png'

const styles = {
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    borderRadius: '3px',
    paddingBottom: 40,
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingRight: 10,
    paddingTop: 10,
  },
  title: {fontSize: 18, paddingTop: 10, paddingBottom: 30},
  header: {fontWeight: 'bold', paddingBottom: 10, fontSize: 18},
};

const SimOverCard = (props) => {
  
 
  return (
    <div style={styles.class} className="block">
      <div style={styles.flex}>
        <h4 style={styles.title}>CONGRATULATIONS</h4>
        <img
          alt="Sentiment"
          src={logo}
          height="80"
          width="80"
        />
      </div>
      <p style={styles.header}>
        THE SIMULATION IS OVER
      </p>
      <p style={{marginRight: 14}}>Time to sit back and enjoy the benefits of your decisions.</p>
    </div>
  );
};

export default SimOverCard;

SimOverCard.propTypes = {
  score: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.string,
  image: PropTypes.string,
};
