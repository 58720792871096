import * as React from "react";
import classnames from "classnames"
import { Button, Fade, makeStyles } from "@material-ui/core";
import Progress from "../Progress";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(.25),
    fontSize: "small"
  },
  container: {
    display: "inline-block",
    position: "relative"
  }
}));

const IconButton = (props) => {
  let {
    loading,
    className,
    icon: Icon,
    children,
    ...remainder} = props;
  const classes = useStyles();
  className = classnames(className, classes.container);

  return <div className={className}>
    <Button {...remainder}>
      {children}
      {Icon && <Icon className={classes.icon}/>}
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
        <Progress size={16} variant="overlay" />
      </Fade>
    </Button>
  </div>
}

IconButton.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default IconButton;
