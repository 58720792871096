import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {makeStyles} from "@material-ui/core/styles";
import {connect} from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AnnouncementList from '../../components/Integrations/Blackboard/Announcements/AnnouncementList';
import CalendarList from '../../components/Integrations/Blackboard/Calendar/CalendarList';

import Curriculum from "../../components/Integrations/Blackboard/Curriculum/Curriculum"
import {useCurriculumContext} from '../../providers/curriculumProvider';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: 'relative',
    height: '100%',
    overflow: 'scroll',
    '&:focus': {
      outline: 'none'
    }
  },
  modalContainer: {
    padding: theme.spacing(2.5),
  }
}));

const IntegrationTitle = (props) => {
  return (
    <Typography variant="h5" style={{fontWeight: "bold", ...props.style}} gutterBottom>
      {props.text}
    </Typography>)
}

IntegrationTitle.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  gutterBottom: PropTypes.bool
}

const StudentIntegrationModal = (props) => {
  const activeCurriculumIds = useCurriculumContext();
  const classes = useStyles();

  useEffect(() => {
    if (props.activeComponentKey === 'sim' && props.editField) {
      props.editField.focus();
    }
  }, [props.activeComponentKey])

  const courseId = props.simulation && props.simulation.bbCourse ? props.simulation.bbCourse.id : null;
  return <Modal
    disableEnforceFocus
    open={props.modalOpen}
    onClose={props.handleClose}
    keepMounted
    disablePortal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    BackdropProps={{
      style: {
        top: '55px',
        bottom: '55px',
        backgroundColor: '#fafafa'
      }
    }}
    style={{top: "55px", bottom: '55px'}}
  >
    <div className={classnames(classes.modalContent)}>
      <div style={{ padding: 20 }}> {/*https://material-ui.com/components/grid/#negative-margin*/}
        {{
          'notifications': <Notifications courseId={courseId}/>,
          'curriculum': <Curriculum courseId={courseId}
            title={<IntegrationTitle text='Curriculum' gutterBottom/>}
            activeModules={activeCurriculumIds}
          />
        }[props.activeComponentKey]}
      </div>
    </div>
  </Modal>
}

const Notifications = ({courseId}) =>
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <AnnouncementList title={<IntegrationTitle text='Announcements'/>} courseId={courseId}/>
    </Grid>
    <Grid item xs={12}>
      <IntegrationTitle text='Schedule' style={{marginLeft: '15px', marginBottom: '10px'}}/>
      <CalendarList courseId={courseId}/>
    </Grid>
  </Grid>

Notifications.propTypes = {
  courseId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    simulation: state.coresim,
    editField: state.settings.editField,
  };
};

StudentIntegrationModal.propTypes = {
  modalOpen: PropTypes.bool,
  simulation: PropTypes.object,
  handleClose: PropTypes.func,
  editField: PropTypes.object,
  activeComponentKey: PropTypes.oneOf(['sim', 'curriculum', 'notifications'])
}

export default connect(mapStateToProps)(StudentIntegrationModal);
