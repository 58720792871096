import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import ConfigurationForm from './ConfigurationForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import { useParams } from 'react-router-dom';
import loans from '../../config/loans';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const EditConfiguration = ({onReload}) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialConfiguration, setInitialConfiguration] = useState();
  const [error, setError] = useState();
  const breadcrumbActions = useBreadcrumbActions();

  let { id } = useParams();

  useEffect(() => {
    let aborted = false;
    const load = async () => {
      try {
        const result = await API.get('mongo', `/configurations/${id}`);
        result.loanTypes = loans;
        if (!aborted) {
          setInitialConfiguration(result);
        }
      } catch(e) {
        if (e.response && e.response.status === 404) {
          setError("Simulation not found");
        } else {
          throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    load();
    return () => aborted = true;
  }, [id]);

  const onSubmit = async (form) => {
    setSaving(true);
    try {
      await API.put('mongo', `/configurations/${id}`, {body: form});
      toast.success('Success!');
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4">
        Edit Configuration
      </Typography>
      { // TODO: error screen
        error
      }
      { // TODO: loading screen
        !loading && !error &&
        <ConfigurationForm
          onSubmit={onSubmit}
          initialValue={initialConfiguration}
          disabled={saving}
        />
      }

    </Box>
  );
};

EditConfiguration.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default EditConfiguration;
