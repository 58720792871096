import businessVariableData from "../containers/data/businessVariableData";

const decisionFields =  businessVariableData.map((d) => d.task);

const answeringMinicase = (match) => match && match.url &&
  match.url.toLowerCase().startsWith('/case/');

const getLastVitals = (sim, teamIndex) => {
  const vitalsHistory = sim.teams[teamIndex].vitalsHistory;
  return vitalsHistory[vitalsHistory.length - 1];
};

const getLastDecisions = (sim, teamIndex) => {
  const decisionHistory = sim.teams[teamIndex].decisionHistory;
  return decisionHistory[decisionHistory.length - 1];
};

export default {
  AnswerMinicase: {
    title: 'Answering Minicase',
    tag: `highlight/answerMinicase`,
    test: (sim, settings, teamIndex, match) => {
      return answeringMinicase(match);
    },
    item: (sim, settings, teamIndex, match) => {
      const id = sim.quarters[sim.quarter].minicase &&
        sim.quarters[sim.quarter].minicase._id;

      if (!id || !answeringMinicase(match)) {
        return undefined;
      }

      return id;
    },
    itemTag: (id) => `highlight/answerMinicase/${id}`
  },
  AnswerDecision: {
    title: 'Answering a Decision',
    tag: 'highlight/answerDecision',
    test: (sim, settings) => {
      return settings.editField &&
      decisionFields.findIndex(
        (field) => field === settings.editField.name
      ) !== -1;
    },
    item: (sim, settings) => {
      return settings.editField &&
      decisionFields.find(
        (field) => field === settings.editField.name
      );
    },
    itemTag: (id) => `highlight/answerDecision/${id}`
  },
  quarter: {
    title: 'Playing Sim',
    tag: `highlight/quarter`,
    test: (sim, settings) => {
      return sim.quarters.length >= sim.quarter.counter + 1; // sim hasn't ended
    },
    item: (sim, settings) => {
      return sim.quarters.length >= sim.quarter.counter + 1 ?
        sim.quarter : ''
    },
    itemTag: (id) => `highlight/quarter/${id}`,
  },
  lastQuarter: {
    title: 'In Last Quarter',
    tag: `highlight/lastQuarter`,
    test: (sim) => {
      return sim.quarters.length === sim.quarter + 1;
    }
  },
  hasProfit: {
    title: 'You have profit!',
    tag: 'highlight/hasProfit',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.revenue > lastVitals.expenses;
    }
  },
  hasLoss: {
    title: 'You had a loss',
    tag: 'highlight/hasLoss',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.expenses > lastVitals.revenue;
    }
  },
  positivePublicSentiment: {
    title: 'Positive Public Sentiment',
    tag: 'highlight/positivePublicSentiment',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.publicSentiment > .5;
    }
  },
  negativePublicSentiment: {
    title: 'Negative Public Sentiment',
    tag: 'highlight/negativePublicSentiment',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.publicSentiment < .5;
    }
  },
  positiveBoardSentiment: {
    title: 'Positive Board Sentiment',
    tag: 'highlight/positiveBoardSentiment',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.boardSentiment > .5;
    }
  },
  negativeBoardSentiment: {
    title: 'Negative Board Sentiment',
    tag: 'highlight/negativeBoardSentiment',
    test: (sim, settings, teamIndex) => {
      const lastVitals = getLastVitals(sim, teamIndex);
      return lastVitals.boardSentiment < .5;
    }
  },
  didNotSubmitDecisions: {
    title: 'Decisions were not submitted',
    tag: 'highlight/didNotSubmitDecisions',
    test: (sim, settings, teamIndex) => {
      const lastDecisions = getLastDecisions(sim, teamIndex);
      return lastDecisions && !lastDecisions.decisionsComplete;
    }
  },
  didNotSubmitMinicase: {
    title: 'Minicase not completed',
    tag: 'highlight/didNotSubmitMinicase',
    test: (sim, settings, teamIndex) => {
      const lastDecisions = getLastDecisions(sim, teamIndex);
      return lastDecisions && !lastDecisions.minicaseComplete;
    }
  },
  // Lost sales was removed in https://github.com/WashingtonBusinessWeek/wbw-frontend/pull/639
  upcomingRecession: {
    title: 'Recession is Upcoming',
    tag: 'highlight/upcomingRecession',
    test: (sim) => {
      const quarter = sim.quarter.counter;

      return sim.quarters[quarter + 1] &&
      sim.quarters[quarter].demand > .4 &&
        sim.quarters[quarter + 1].demand < .4;
    }
  },
  upcomingDemand: {
    title: 'Demand is Upcoming',
    tag: 'highlight/upcomingDemand',
    test: (sim) => {
      const quarter = sim.quarter.counter;

      return sim.quarters[quarter + 1] &&
      sim.quarters[quarter].demand < .6 &&
        sim.quarters[quarter + 1].demand > .6;
    }
  }
};
