import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import NavigationCarousel from '../components/NavigationCarousel';
import NavBar from './NavBar';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight:'100vh',
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3),
    marginTop: theme.spacing(7),
    '&.has-student-nav': {
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(7)
      },
    },
    overflowX: 'hidden',
  },
  link: {
    color: 'blue',
    textDecoration: 'none',
  },
});
class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      alertOpen: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCaseRoute = this.handleCaseRoute.bind(this);
  }

  handleClickOpen = () => {
    this.setState({alertOpen: true});
  };

  handleClose = () => {
    this.setState({alertOpen: false});
  };

  handleCaseRoute = () => {
    window.location = '/case/details';
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth > 768) {
      this.setState({open: true});
    } else if (window.innerWidth < 768) {
      this.setState({open: false});
    }
  }

  handleToggleDrawer = () => {
    this.setState({open: !this.state.open});
  };

  render() {
    const {classes, children} = this.props;
    const routeKey = window.location.href;

    const routes = (route) => {
      if (route.includes('case')) {
        return route.includes('case');
      }
      if (route.includes('setup')) {
        return route.includes('setup');
      }
      return false;
    };

    const newRoute = routes(routeKey);
    return (
      <div className={classes.root}>
        <NavBar childProps={this.props.childProps} />
        <main
          className={classNames(classes.content, {'has-student-nav': this.props.hasStudentNav})}>
          {newRoute && !this.props.coresim.completed && (
            this.props.coresim.quarters[this.props.coresim.quarter.counter].minicase) ? (
              <div>
                <NavigationCarousel childProps={this.props.childProps}></NavigationCarousel>
              </div>
            ) : null}
          {children}
        </main>
      </div>
    );
  }
}

MainLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  childProps: PropTypes.object,
  coresim: PropTypes.object,
  hasStudentNav: PropTypes.bool,
};

MainLayout.defaultProps = {
  hasStudentNav: false,
}

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainLayout));
