const UNITS_PRODUCED = 'simulator/UNITS_PRODUCED';
const ADDED_PLANT_CAPACITY = 'simulator/ADDED_PLANT_CAPACITY';
const QUALITY_MANAGEMENT = 'simulator/QUALITY_MANAGEMENT';
const PRICE = 'simulator/PRICE';
const ADVERTISING = 'simulator/ADVERTISING';
const SALESPEOPLE = 'simulator/SALESPEOPLE';
const PRODUCT_DEVELOPMENT = 'simulator/PRODUCT_DEVELOPMENT';
const MARKET_RESEARCH = 'simulator/MARKET_RESEARCH';
const NAME_CHANGE = 'simulator/NAME_CHANGE';
const BUSINESS_AS = 'simulator/BUSINESS_AS';
const NAME_CHANGE_CONFIRMED = 'simulator/NAME_CHANGE_CONFIRMED';
const NAME_CHANGE_INDEX = 'simulator/NAME_CHANGE_INDEX';
const DIVIDENDS = 'simulator/DIVIDENDS';
const LOANS_TAKEN = 'simulator/LOANS_TAKEN';
const LOANS_PAID = 'simulator/LOANS_PAID';
const START_OVER = 'simulator/START_OVER';
const JUSTIFICATION = 'simulator/JUSTIFICATION';
const SELECT_MINICASE = 'simulator/SELECT_MINICASE';
const MINICASE_COMPLETE = 'simulator/MINICASE_COMPLETE';
const DECISIONS_COMPLETE = 'simulator/DECISIONS_COMPLETE';
const OTHER_EXPENSES = 'simulator/OTHER_EXPENSES';
const LOANS_INDEX = 'simulator/LOAN_INDEX';
const LOAN_CONFIRMED = 'simulator/LOAN_CONFIRM';
const LOAN_PAID_CONFIRMED = 'simulator/LOAN_PAID_CONFIRMED';
const RESEARCH_INDEX = 'simulator/RESEARCH_INDEX';
const RESEARCH_CONFIRMED = 'simulator/RESEARCH_CONFIRMED';
const RESEARCH_PAID_CONFIRMED = 'simulator/RESEARCH_PAID_CONFIRMED';
const RESEARCH_PAID = 'simulator/RESEARCH_PAID';
const FACTORY_INDEX = 'simulator/FACTORY_INDEX';
const FACTORY_CONFIRMED = 'simulator/FACTORY_CONFIRMED';
const FACTORY_PAID_CONFIRMED = 'simulator/FACTORY_PAID_CONFIRMED';
const FACTORY_PAID = 'simulator/FACTORY_PAID';

const initState = {
  unitsProduced: '',
  addedPlantCapacity: 0,
  qualityManagement: '',
  price: '',
  advertising: '',
  salespeople: '',
  // + or - to signify hiring firing
  productDevelopment: '',
  marketResearch: 0,

  changeName: 0,
  businessAs: '',
  nameChangeConfirmed: false,
  nameChangeIndex: '',

  dividends: '',
  loansTaken: '',
  loansIndex: '',
  loanConfirmed: false,
  loansPaid: '',
  loanPaidConfirmed: false,

  researchPaid: '',
  researchConfirmed: false,
  researchPaidConfirmed: false,
  researchIndex: '',

  factoryPaid: '',
  factoryCapacityFactor: 1,
  factoryDefectFactor: 1,
  factoryConfirmed: false,
  factoryPaidConfirmed: false,
  factoryIndex: '',
  
  otherExpenses: '', // other expenses usually come about because of a response to a mini-case
  minicaseComplete: false,
  decisionsComplete: false,
  minicaseJustification: '',
  selectedMinicase: {outputs: []},
};

export default function decisionsReducer(state = initState, action) {
  if (action === undefined) {
    return state;
  }

  const newState = {...state};
  switch (action.type) {
    case START_OVER:
      return initState;
    case UNITS_PRODUCED:
      newState.unitsProduced = action.payload;
      break;
    case ADDED_PLANT_CAPACITY:
      newState.addedPlantCapacity = action.payload.addedPlantCapacity;
      newState.factoryCapacityFactor = action.payload.factoryCapacityFactor;
      newState.factoryDefectFactor = action.payload.factoryDefectFactor;
      break;
    case QUALITY_MANAGEMENT:
      newState.qualityManagement = action.payload;
      break;
    case PRICE:
      newState.price = action.payload;
      break;
    case SALESPEOPLE:
      newState.salespeople = action.payload;
      break;
    case ADVERTISING:
      newState.advertising = action.payload;
      break;
    case PRODUCT_DEVELOPMENT:
      newState.productDevelopment = action.payload;
      break;
    case MARKET_RESEARCH:
      newState.marketResearch = action.payload;
      break;
    case BUSINESS_AS:
      newState.businessAs = action.payload;
      break;
    case NAME_CHANGE:
      newState.changeName = action.payload;
      break;
    case NAME_CHANGE_CONFIRMED:
      newState.nameChangeConfirmed = action.payload;
      break;
    case NAME_CHANGE_INDEX:
      newState.nameChangeIndex = action.payload;
      break;
    case DIVIDENDS:
      newState.dividends = action.payload;
      break;
    case LOANS_TAKEN:
      newState.loansTaken = action.payload;
      break;
    case LOANS_PAID:
      newState.loansPaid = action.payload;
      break;
    case LOANS_INDEX:
      newState.loansIndex = action.payload;
      break;
    case LOAN_CONFIRMED:
      newState.loanConfirmed = action.payload;
      break;
    case LOAN_PAID_CONFIRMED:
      newState.loanPaidConfirmed = action.payload;
      break;
    case RESEARCH_INDEX:
      newState.researchIndex = action.payload;
      break;
    case RESEARCH_PAID:
      newState.researchPaid = action.payload;
      break;
    case RESEARCH_CONFIRMED:
      newState.researchConfirmed = action.payload;
      break;
    case RESEARCH_PAID_CONFIRMED:
      newState.researchPaidConfirmed = action.payload;
      break;
    case FACTORY_INDEX:
      newState.factoryIndex = action.payload;
      break;
    case FACTORY_PAID:
      newState.factoryPaid = action.payload;
      break;
    case FACTORY_CONFIRMED:
      newState.factoryConfirmed = action.payload;
      break;
    case FACTORY_PAID_CONFIRMED:
      newState.factoryPaidConfirmed = action.payload;
      break;
    case JUSTIFICATION:
      newState.minicaseJustification = action.payload;
      break;
    case SELECT_MINICASE:
      newState.selectedMinicase = action.payload;
      break;
    case MINICASE_COMPLETE:
      newState.minicaseComplete = action.payload;
      break;
    case DECISIONS_COMPLETE:
      newState.decisionsComplete = action.payload;
      break;
    default:
      return state;
  }

  return newState;
}

export function startOver() {
  return {
    type: START_OVER,
  };
}

export function unitsProduced(newState) {
  return {
    type: UNITS_PRODUCED,
    payload: newState,
  };
}

export function addedPlantCapacity(newState) {
  return {
    type: ADDED_PLANT_CAPACITY,
    payload: newState,
  };
}

export function qualityManagement(newState) {
  return {
    type: QUALITY_MANAGEMENT,
    payload: newState,
  };
}

export function price(newState) {
  return {
    type: PRICE,
    payload: newState,
  };
}

export function salespeople(newState) {
  return {
    type: SALESPEOPLE,
    payload: newState,
  };
}

export function advertising(newState) {
  return {
    type: ADVERTISING,
    payload: newState,
  };
}

export function productDevelopment(newState) {
  return {
    type: PRODUCT_DEVELOPMENT,
    payload: newState,
  };
}

export function marketResearch(newState) {
  return {
    type: MARKET_RESEARCH,
    payload: newState,
  };
}

export function businessAs(newState) {
  return {
    type: BUSINESS_AS,
    payload: newState,
  };
}

export function nameChange(newState) {
  return {
    type: NAME_CHANGE,
    payload: newState,
  };
}

export function nameChangeConfirmed(newState) {
  return {
    type: NAME_CHANGE_CONFIRMED,
    payload: newState,
  };
}
export function nameChangeIndex(newState) {
  return {
    type: NAME_CHANGE_INDEX,
    payload: newState,
  };
}

export function dividends(newState) {
  return {
    type: DIVIDENDS,
    payload: newState,
  };
}

export function loansTaken(newState) {
  return {
    type: LOANS_TAKEN,
    payload: newState,
  };
}

export function loansIndex(newState) {
  return {
    type: LOANS_INDEX,
    payload: newState,
  };
}

export function loanConfirmed(newState) {
  return {
    type: LOAN_CONFIRMED,
    payload: newState,
  };
}
export function loanPaidConfirmed(newState) {
  return {
    type: LOAN_PAID_CONFIRMED,
    payload: newState,
  };
}

export function loansPaid(newState) {
  return {
    type: LOANS_PAID,
    payload: newState,
  };
}

export function researchIndex(newState) {
  return {
    type: RESEARCH_INDEX,
    payload: newState,
  };
}

export function researchConfirmed(newState) {
  return {
    type: RESEARCH_CONFIRMED,
    payload: newState,
  };
}
export function researchPaidConfirmed(newState) {
  return {
    type: RESEARCH_PAID_CONFIRMED,
    payload: newState,
  };
}

export function researchPaid(newState) {
  return {
    type: RESEARCH_PAID,
    payload: newState,
  };
}

export function factoryIndex(newState) {
  return {
    type: FACTORY_INDEX,
    payload: newState,
  };
}

export function factoryConfirmed(newState) {
  return {
    type: FACTORY_CONFIRMED,
    payload: newState,
  };
}
export function factoryPaidConfirmed(newState) {
  return {
    type: FACTORY_PAID_CONFIRMED,
    payload: newState,
  };
}

export function factoryPaid(newState) {
  return {
    type: FACTORY_PAID,
    payload: newState,
  };
}

export function justification(newState) {
  return {
    type: JUSTIFICATION,
    payload: newState,
  };
}

export function selectMinicase(newState) {
  return {
    type: SELECT_MINICASE,
    payload: newState,
  };
}

export function minicaseComplete(newState) {
  return {
    type: MINICASE_COMPLETE,
    payload: newState,
  };
}

export function decisionsComplete(newState) {
  return {
    type: DECISIONS_COMPLETE,
    payload: newState,
  };
}

export function otherExpenses(newState) {
  return {
    type: OTHER_EXPENSES,
    payload: newState,
  };
}
