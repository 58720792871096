import moment from 'moment';

// debugging function
// eslint-disable-next-line no-unused-vars
function logTimes(activeQuarter) {
  // eslint-disable-next-line no-console
  console.log('now', moment().format('MMMM Do YYYY, h:mm:ss a'),
    'activeQuarter', activeQuarter,
    'activeQuarter start', moment(activeQuarter.start).format('MMMM Do YYYY, h:mm:ss a'),
    'activeQuarter end', moment(activeQuarter.end).format('MMMM Do YYYY, h:mm:ss a'));
}

/**
 * helper function for determining and explaining whether or not decisions and minicases can be determined.
 *
 * @param user
 * @param activeQuarter
 * @param completed
 * @param decisionsSubmitted
 * @return {
 *  {
 *    isInActiveQuarter: (function(): boolean),
 *    hasCorrectAccess: (function(): boolean),
 *    closedReason: (function(): string),
 *    canSubmit: (function(): boolean)
 *  }}
 */
export function simSubmitState(
  {
    user,
    activeQuarter,
    completed=false,
    decisionsSubmitted=false
  }) {
  const hasCorrectAccess = () => (
    user && user.role === 'student'
  )

  const isInActiveQuarter = () => (
    // logTimes(activeQuarter)
    activeQuarter && activeQuarter.end ? moment().isBefore(activeQuarter.end) : true
  )

  return {
    /**
     * Determines if the users's role is one that allows submitting decisions
     * @return {boolean}
     */
    hasCorrectAccess,
    /**
     * Returns true if the current time is within the end date of the active quarter. If there is no end date assigned
     *   to the quarter, this will also return true
     *
     * @return {boolean}
     */
    isInActiveQuarter,
    /**
     * Checks if this user and simulation has the correct state to be able to submit decisions
     * @return {boolean}
     */
    canSubmit: () => {
      if (completed === undefined) {
        throw new Error("'completed' is a required parameter for this function")
      }
      return hasCorrectAccess() && isInActiveQuarter() && !completed && !decisionsSubmitted
    },
    /**
     *
     * @return {string}
     */
    closedReason: () => {
      if (completed) {
        return 'The simulation has completed. Decisions and minicase answers cannot be submitted.'
      }
      if (!hasCorrectAccess()) {
        return 'Only students can submit decisions or minicases, you have the role: ' + user.role
      }
      if (!isInActiveQuarter()) {
        return 'Outside of operating hours, cannot submit decisions or minicases.'
      }
      if (decisionsSubmitted) {
        return 'Decisions have already been submitted'
      }
      // eslint-disable-next-line no-console
      console.error('shouldn\'t call closedReason if the sim is submittable')
      return ''
    }
  }
}

/**
 * Tells you if current time is before the start date of the first quarter.
 *   If you aren't in the first quarter, this function returns false.
 *   If the first quarter has no start time defined, this function returns false.
 *
 *
 * @param quarterIndex index of current quarter
 * @param firstQuarter first quarter object
 * @return {boolean} true if there is a start time defined, the quarter index is 0,
 *                   and current time is before start time
 */
export function beforeFirstQuarter(quarterIndex, firstQuarter) {
  if (quarterIndex > 0) {
    return false
  }
  return firstQuarter.start !== undefined ? moment().isBefore(firstQuarter.start) : false
}
