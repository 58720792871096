import {API} from 'aws-amplify';
import { toast } from 'react-toastify';
import moment from 'moment';

const sender = process.env.REACT_APP_REPLY_TO;
const edgeEndpoint = process.env.REACT_APP_BASE_URL;


const handleInvites = async (teams, chairs, form, quarters) => {
  let emails = [];

  let hasStartDate = quarters[0].start ? `The first quarter begins on ${moment(quarters[0].start ).format('llll')}.`: '';

  for (let team of teams){
    // todo: add something about if you are an advisor or student?
    for (let member of team.members){
      emails.push(
        API.post('mongo', '/util/email', {body: {
          recipient: member.email,
          sender: sender,
          subject: 'A message from WBW',
          htmlBody: `<p>Dear ${member.firstName},</p>
          <p>Welcome to WBW! <br/>
          <p>Your team, the ${team.teamName} team, have been added to the Washington Business Week simulation, <a href=${edgeEndpoint}>${form.name}</a>.<br/>
          ${hasStartDate}</p>
          See you soon!</p><br/>
          Sincerely,<br/>
          Andrea Keikkala<br/>
          Executive Director<br/>
          Washington Business Week</p>
          <img src="https://1q1km3747n32j9390btn5y5s-wpengine.netdna-ssl.com/wp-content/uploads/2016/03/logo-transparent.png" style="width:115px; height: 115px;" alt="Washington Business Week">`,


          textBody: `Dear ${member.firstName},

          Welcome to WBW!

          Your team, the ${team.teamName} team, have been added to the Washinigton Business Week simulation,${form.name}.
          ${hasStartDate}

          ${edgeEndpoint}

          See you soon!


          Sincerely,
          Andrea Keikkala
          Executive Director
          Washington Business Week`,
        }} )
      );

    }
  }

  for (let chair of chairs){
    emails.push(
      API.post('mongo', '/util/email', {body: {
        recipient: chair.email,
        sender: sender,
        subject: 'A message from WBW ',

        htmlBody: `<p>Dear ${chair.firstName},</p>
          <p>Welcome to WBW! <br/>
          <p>You have been selected to be a Chair of the Week for the Washington Business Week simulation, <a href=${edgeEndpoint}>${form.name}</a>.<br/>
          ${hasStartDate}</p>.
          See you soon!</p><br/>
          Sincerely,<br/>
          Andrea Keikkala<br/>
          Executive Director<br/>
          Washington Business Week</p>
          <img src="https://1q1km3747n32j9390btn5y5s-wpengine.netdna-ssl.com/wp-content/uploads/2016/03/logo-transparent.png" style="width:115px; height: 115px;" alt="Washington Business Week">`,


        textBody: `Dear ${chair.firstName},

          Welcome to WBW!

          You have been selected to be a Chair of the Week for the Washington Business Week simulation, ${form.name}.
          ${hasStartDate}

          ${edgeEndpoint}

          See you soon!


          Sincerely,
          Andrea Keikkala
          Executive Director
          Washington Business Week`,

      }} )
    );

  }

  try {
    await Promise.all([...emails]).then((results) => {
      toast.success('All Emails sent.')
    })
  } catch (e) {
    toast.error('Error sending emails to team members or chairs')
  }

};

export default handleInvites;
