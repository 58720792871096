import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './containers/Home';
import MiniCase from './containers/simulation/MiniCase';
import SimulationVitals from './containers/simulation/SimulationVitals';
import Justification from './containers/simulation/Justification';
import Setting from './containers/Setting';
import Login from './containers/Login';
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import NextQuarter from './admin/NextQuarter';
import Table from './components/Table';
import PriceVolume from './containers/simulation/PriceVolume';
import Sandbox from './containers/Sandbox';
import ErrorPage from './containers/Error';
import Research from "./containers/Research"
import PropTypes from 'prop-types';
import Ending from './containers/Ending';
import BalanceSheetTabs from './containers/Graphs/BalanceSheetTabs';
import StudentAnnouncementsModal from './containers/modal/StudentAnnouncementsModal';
import StudentWelcomeModal from './containers/modal/StudentWelcomeModal';
import StudentQEndingModal from './containers/modal/StudentQEndingModal';
import StudentPriceVolumeModal from './containers/modal/StudentPriceVolumeModal';
import StudentCalendarModal from "./containers/modal/StudentCalendarModal"
import HighlightCurriculumContext from './providers/HighlightCurriculumContext';
import StudentCurriculumModal from "./containers/modal/StudentCurriculumModal"
import StudentMobileNav from './layouts/StudentMobileNav';

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({component: C, props: cProps, ...rest}) => {

  return (<Route
    {...rest}
    render={(props) =>
      <HighlightCurriculumContext>
        <MainLayout childProps={cProps} hasStudentNav={Boolean(cProps.sim.bbCourse)}>
          <StudentQEndingModal />
          <StudentWelcomeModal />
          <StudentPriceVolumeModal childProps={cProps}/>
          <StudentAnnouncementsModal />
          <StudentCalendarModal />
          <StudentCurriculumModal />
          {cProps.sim.bbCourse && <StudentMobileNav/>}
          <C {...props} {...cProps} />
        </MainLayout>
      </HighlightCurriculumContext>
    }
  />
  );
};

const EmptyRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const Routes = ({childProps}) => (
  <Switch>
    <DashboardRoute exact path='/' component={Home} props={childProps}/>
    <DashboardRoute
      path='/dashboard'
      component={Home}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/leaderboard'
      component={Ending}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/balancesheet'
      component={BalanceSheetTabs}
      props={childProps}
      exact
    />

    <DashboardRoute
      path="/"
      component={Login}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/case/details'
      component={MiniCase}
      props={childProps}
      exact
    />
    <DashboardRoute
      path='/case/input'
      component={MiniCase}
      props={childProps}
      exact
    />
    <DashboardRoute
      path='/case/justification'
      component={Justification}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/next'
      component={NextQuarter}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/vitals'
      component={SimulationVitals}
      props={childProps}
      exact
    />
    <DashboardRoute
      path='/vitals/history'
      component={Table}
      props={childProps}
      exact
    />

    <DashboardRoute
      path="/research"
      component={Research}
      props={childProps}
      exact
    />
    <DashboardRoute
      path='/vitals/history/:id'
      component={MiniCase}
      props={childProps}
      exact
    />

    <DashboardRoute
      path="/setup/:id"
      component={PriceVolume}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/login'
      component={Login}
      props={childProps}
      exact />

    <DashboardRoute
      path='/setting'
      component={Setting}
      exact
      props={childProps}
    />
    <DashboardRoute
      path='/sandbox'
      component={Sandbox}
      exact
      props={childProps}
    />

    <DashboardRoute
      path="/error"
      component={ErrorPage}
      exact
      props={childProps}
    />

    <EmptyRoute component={NotFound} />
  </Switch>
);

export default Routes;

DashboardRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object,
};

EmptyRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object,
};

Routes.propTypes = {
  childProps: PropTypes.object,
};
