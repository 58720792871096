import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import navRoutes from '../containers/miniNavRoutes/MiniNavRoutes';
import {Box} from '@material-ui/core';
import PropTypes from 'prop-types';

const AutoPlaySwipeableViews = SwipeableViews;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    flexGrow: 1,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    border: 'none',
    borderRadius: '3px',
    borderColor: 'rgb(17, 16, 16)',
    background: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
  },
  stepper: {
    position: 'relative',
    top: -32,
    backgroundColor: 'transparent',
  },
  buttonsContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    margin: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    margin: '0 -14px',
  },
  mainTitleStyle: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    overflow: 'hidden',
  },
  miniNavText: {
    fontSize: '1em',
    marginBottom: 40,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.7rem',
    },
  },
  miniNavMainText: {
    fontSize: '1.5em',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  playerStyle: {
    padding: '0px',
  },
  miniNavStyle: {
    height: '25px',
  },
  arrows: {
    '&svg.MuiSvgIcon': {
      fontSize: '3rem',
    },
  },
})
);

const NavigationCarousel = (props) => {
  const routeKey = window.location.href;

  const routes = (route) => {
    if (route.includes('case')) {
      return navRoutes.case;
    } else if (route.includes('teams')) {
      return navRoutes.teams;
    } else if (route.includes('users')) {
      return navRoutes.users;
    } else if (route.includes('simulations')) {
      return navRoutes.simulations;
    } else if (route.includes('minicases')) {
      return navRoutes.minicases;
    } else if (route.includes('vitals')) {
      return navRoutes.vitals;
    } else if (route.includes('setup')) {
      return navRoutes.setup;
    }
  };

  const newRoute = routes(routeKey);

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = newRoute.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box component='div' className={classes.root} >
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{margin: '0 auto'}}>
        {newRoute.map((step, index, arr) => {
          return (
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='space-between'
              className={classes.mainTitleStyle}
              key={index}
              wrap='nowrap'
              spacing={5}>
              <Grid item className={classes.miniNavStyle}>
                <React.Fragment>
                  {newRoute[activeStep].page !== 'Quantity' ? (

                    <Typography
                      className={classes.miniNavText}
                      style={{textAlign: 'left'}}>
                      {index === 0 ? 'Dashboard' : arr[index - 1].page}
                    </Typography>
                  ) : null}
                </React.Fragment>
              </Grid>

              <Grid item>
                <Typography className={classes.miniNavMainText}>
                  {Math.abs(activeStep - index) <= 2 ? step.page : null}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                className={classes.miniNavStyle}
                style={{textAlign: 'right'}}>
                <Typography className={classes.miniNavText}>
                  {index === arr.length - 1 ? null : arr[index + 1].page}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        )}
      </AutoPlaySwipeableViews>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttons}>
          <IconButton
            edge='end'
            onClick={handleBack}
            className={classes.button}>
            {theme.direction === 'rtl' ? (
              <Link to={`${newRoute[activeStep].next}`}>
                <KeyboardArrowRight style={{fontSize: '3rem'}} />
              </Link>
            ) : (<React.Fragment>
              {newRoute[activeStep].page === 'Quantity' ? null :
                (
                  <Link to={`${newRoute[activeStep].previous}`}>
                    <KeyboardArrowLeft style={{fontSize: '3rem'}} />
                  </Link>
                )
              }
            </React.Fragment>)}
          </IconButton>

          <IconButton
            edge='start'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1 || props.childProps.miniNavDisabled}
            className={classes.button}>
            {theme.direction === 'rtl' ? (
              <Link to={`${newRoute[activeStep].previous}`}>
                <KeyboardArrowLeft style={{fontSize: '3rem'}}/>
              </Link>
            ) : (
              <Link to={`${newRoute[activeStep].next}`}>
                <KeyboardArrowRight style={{fontSize: '3rem'}}/>
              </Link>
            )}
          </IconButton>
        </div>
      </div>
    </Box>
  );
};

NavigationCarousel.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default NavigationCarousel;
