import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const NumberFilterHoc = (Component, {allowNegative, decimal}) => {
  const regEx =
    new RegExp(`^${
      allowNegative ? '(-)?' :''
    }\\d*${
      decimal ? `(\\.\\d{0,${decimal}})?`: ''
    }$`);

  const NumberField = ({
    value,
    onChange,
    onNumberChange,
    ...remaining
  }) => {
    const [textValue, setTextValue] = useState(
      (value === null || isNaN(value)) ? '' : value.toString()
    );
    useEffect( () => {
      if (value !== undefined && value !== Number(textValue)) {
        setTextValue(
          (value === null || isNaN(value)) ? '' : value.toString()
        );
      }
    }, [value]);

    const filterText = (text) => {
      return !text || text === '-' || text === '.';
    }

    const processText = (text) => {
      const number = Number(text);

      onNumberChange && onNumberChange(number);
    }

    const handleChange = (e) => {
      const text = e.target.value;
      if (!text.match(regEx)) {
        return;
      }

      setTextValue(text);
      if (filterText(text)) {
        onNumberChange && onNumberChange(undefined);
      } else {
        processText(text);
      }

      onChange && onChange(e);
    }

    return (
      <Component
        {...remaining}
        onChange={handleChange}
        value={textValue}
        number={true}
      />
    )
  }

  NumberField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    onNumberChange: PropTypes.func,
  }

  return NumberField;
}

export default NumberFilterHoc;
