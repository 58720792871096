import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ViewSim from "./ViewSim";
import PropTypes from "prop-types";


// We probably need to set a 'advisors' category in the simulation to add them in. Can't be in 'teams' array, creates too many headaches.
// Need to create additional logic in the App.js to have the right API requests.
// Probably assign them inside the SimulationEdit experience


// Inside the upgrade user component, we probably want to add a simulation ID that we are applying to them? In addition to storing their data in the simulation itself.

function ChairHome (props) {
  return(
    <div>
      <h1>
          Chair of the Week HQ
      </h1>
      <ViewSim coresim={props.everything.coresim} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return{
    everything: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {}, dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChairHome);

ChairHome.propTypes = {
  everything: PropTypes.object
}
