import React from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link"
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  timeslot: {
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    borderRadius: '20px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    }
  }
}));


const CalendarItem = (props) => {
  const classes = useStyles();

  return (
    <Link href={props.item.externalUrl} underline="none" target="_blank" rel="noopener noreferrer" variant="caption">
      <Box classes={{root: classes.item}}>
        <Grid container justify="space-between">
          <Grid item xs={2} sm={1}>
            <Typography variant="h4" color="textSecondary">
              {moment(props.item.start).format('DD')}
            </Typography>
          </Grid>
          <Grid item xs={5} sm={8}>
            <Typography variant="caption" color="textSecondary" style={{fontWeight: "bold"}}>
              {moment(props.item.start).format('ddd, MMM YYYY')}
            </Typography><br/>
            <Typography variant="caption" color="textSecondary">
              {props.item.title}
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3}>
            {/* put timeslot range in grid so it stays centered even with long event descriptions */}
            <Grid container style={{height: "100%"}}>
              <Box classes={{root: classes.timeslot}} className='schedule-timeslot' width="100%">
                {moment(props.item.start).format('h:mmA') + ' - ' + moment(props.item.end).format('h:mmA')}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Link>)
}

CalendarItem.propTypes = {
  item: PropTypes.shape({
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    externalUrl: PropTypes.string.isRequired
  }),
};

export default CalendarItem
