// https://www.desmos.com/calculator/6qwdfdpxll

/**
 * Function, given by https://www.desmos.com/calculator/6qwdfdpxll,
 * to determine the factor that a products price is worth of the
 * base industry price, based on overall sentiment
 *
 * @param {*} x - The overall sentiment, a number from 0 to 1
 * @return {number} - The factor that the product is worth (as relates to base industry price)
 */
const sentimentToPrice = (x) => {
  const result = (Math.atanh(2 * x - 1) + 2) * x;

  // There is a small portion of the graph
  // that is negative starting around .018.
  // Truncate the graph to 0
  return result > 0 ? result : 0;
};
export default sentimentToPrice;
