import {Grid, IconButton, Typography} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import PropTypes from "prop-types"
import React from "react"

const ModalTitle = ({closeModal, text}) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4" style={{fontWeight: "bold"}}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

ModalTitle.propTypes = {
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
}

export default ModalTitle;
