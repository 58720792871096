import React, { useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, Typography, Grid, Divider, Box} from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Row from '../../components/BusinessReportRow';
import BusinessValuation from '../../components/BusinessValuation';
import getBalanceSheet from '../../helpers/getBalanceSheet';
import IconButton from '../../components/widgets/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    margin: 'auto'
  },
  topBottomBorder: {
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  tally: {
    width: '100%',
    minWidth: '6rem',
    textAlign: 'right',
    margin: '.5rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  subheader: {
    fontWeight: 'bolder',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '10px 0'
    }
  },
  rowHeading: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

const BalanceSheetReport = (props) => {
  const classes = useStyles();

  const quarter = props.quarter + 1;
  const decHist = props.sim.teams[props.teamIndex].decisionHistory;
  const vitHist = props.sim.teams[props.teamIndex].vitalsHistory;
  const newName = vitHist.filter(vital => vital.businessAs);
  const teamName = `${(newName.length > 0 && newName[newName.length - 1].businessAs) || props.sim.teams[props.teamIndex].teamName}, INC.`;

  const getQtrBalanceSheet = useCallback( (q) => {
    return getBalanceSheet(decHist, vitHist)(q)
  }, [decHist, vitHist]
  );

  let currentQtrValues = getQtrBalanceSheet(quarter);
  let lastQtrValues = getQtrBalanceSheet(quarter - 1);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `WBW, ${teamName} Balance Sheet - Quarter ${quarter}`,
    pageStyle: "margin: 2rem;"
  });

  return (
    <Card className={classes.root}>
      <Box display='flex' justifyContent='flex-end' margin='0.5rem;'>
        <IconButton
          size="small"
          variant="text"
          color="secondary"
          style={{float: 'right'}}
          onClick={ handlePrint }
          icon={PrintIcon}
        />
      </Box>
      <CardContent ref={printRef}>
        <Grid container alignItems='center' justify='center'>
          <Grid item className={classes.header}>
            <Typography className={classes.header}>{teamName}</Typography>
            <Typography className={classes.header}>CONSOLIDATED BALANCE SHEETS</Typography>
            <Typography className={classes.header}>{`QUARTER ${quarter}`}</Typography>
            <br/><br/>
          </Grid>
        </Grid><br/>
        <Typography style={{textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold'}}>ASSETS</Typography><br/>
        <Grid container className={classes.rowHeading} direction='row' alignItems='center'>
          <Grid className={classes.header} item style={{display: 'flex'}}>
            {quarter > 1 && <Typography style={{textAlign: 'center'}} className={classnames(classes.tally, classes.topBottomBorder)}>{`Q${quarter - 1}`}</Typography>}
            <Typography style={{textAlign: 'center'}} className={classnames(classes.tally, classes.topBottomBorder)}>{`Q${quarter}`}</Typography>
          </Grid>
        </Grid>
        <Typography className={classes.subheader}>Assets</Typography>
        <Divider/>
        <Row title='Cash' last={lastQtrValues && lastQtrValues.cash} current={currentQtrValues.cash}/>
        <Row title='Inventory' last={lastQtrValues && lastQtrValues.inventory} current={currentQtrValues.inventory}/>
        <Row title='Accounts Receivable' last={lastQtrValues && lastQtrValues.acctRecv} current={currentQtrValues.acctRecv}/>
        <Row title='Property' last={lastQtrValues && lastQtrValues.property} current={currentQtrValues.property}/><br/>
        <Divider/>
        <Row title='Total Assets' last={lastQtrValues && lastQtrValues.totAssets} current={currentQtrValues.totAssets}/>
        <Divider/><br/><br/>
        <Typography style={{textDecoration: 'underline', textAlign: 'center', fontWeight: 'bold'}}>LIABLILITIES AND STOCKHOLDERS EQUITY </Typography><br/>
        <Typography className={classes.subheader}>Expenses</Typography>
        <Divider/>
        <Row title='Product Development' last={lastQtrValues && lastQtrValues.prodDev} current={currentQtrValues.prodDev}/>
        <Row title='Market Research' last={lastQtrValues && lastQtrValues.marketResearch} current={currentQtrValues.marketResearch}/>
        <Row title='Advertising' last={lastQtrValues && lastQtrValues.advertising} current={currentQtrValues.advertising}/>
        <Row title='Quality Management' last={lastQtrValues && lastQtrValues.qualityManagement} current={currentQtrValues.qualityManagement}/>
        <Row title='Dividends' last={lastQtrValues && lastQtrValues.dividends} current={currentQtrValues.dividends}/>
        <Row title='Personnel' last={lastQtrValues && lastQtrValues.personnel} current={currentQtrValues.personnel}/>
        <Row title='Loan Payment' last={lastQtrValues && lastQtrValues.loanPayment} current={currentQtrValues.loanPayment}/>
        <Row title='Production Costs' last={lastQtrValues && lastQtrValues.productionCost} current={currentQtrValues.productionCost}/>
        <Row title='Other Expenses' last={lastQtrValues && lastQtrValues.otherExpenses} current={currentQtrValues.otherExpenses}/><br/>
        <Typography className={classes.subheader}>Liabilities</Typography>
        <Divider/>
        <Row title='Short Term Expenses' last={lastQtrValues && lastQtrValues.totExpenses} current={currentQtrValues.totExpenses}/>
        <Row title='Long Term Debt' last={lastQtrValues && lastQtrValues.loanLiability} current={currentQtrValues.loanLiability}/>
        <Divider/><br/>

        <Row title='Total Liabilites' last={lastQtrValues && lastQtrValues.totLiabilities} current={currentQtrValues.totLiabilities}/>
        <Divider/><br/><br/>

        <BusinessValuation
          stockLast={lastQtrValues && lastQtrValues.stockPrice}
          stockCurrent={currentQtrValues.stockPrice}
          valueLast={lastQtrValues && lastQtrValues.valuation}
          valueCurrent={currentQtrValues.valuation}
          netFlowLast={lastQtrValues && lastQtrValues.netCashFlow}
          netFlowCurrent={currentQtrValues.netCashFlow}
        />

      </CardContent>
    </Card>
  );
};

BalanceSheetReport.propTypes = {
  sim: PropTypes.object.isRequired,
  user: PropTypes.object,
  teamIndex: PropTypes.number.isRequired,
  quarter: PropTypes.number.isRequired
}

export default BalanceSheetReport;
