import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import MinicaseForm from './MinicaseForm';
import uuidv4 from 'uuid/v4';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const getInitialMinicase = () => ({
  id: uuidv4(), // TODO: remove id field and use _id
  miniCase: '',
  description: '',
  options: [],
});

const CreateMinicase = ({onReload}) => {
  const [loading, setLoading] = useState(false);
  const [initialMinicase, setInitialMinicase] = useState(getInitialMinicase());
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);
    try {
      await API.post('mongo', '/cases', {body: form});
      toast.success('Minicase created');
      setInitialMinicase(getInitialMinicase());
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create Minicase
      </Typography>
      <MinicaseForm
        onSubmit={onSubmit}
        initialValue={initialMinicase}
        disabled={loading}
      />
    </Box>
  );
}

CreateMinicase.propTypes = {
  history: PropTypes.object.isRequired,
  onReload: PropTypes.string.isRequired
};

export default CreateMinicase;
