import React, { useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, Typography, Grid, Divider, Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import IconButton from '../components/widgets/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    margin: 'auto'
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  body: {
    margin: '15px'
  }
}));

const MiniCaseReport = ({quarterMinicase, quarterDecisions}) => {
  const classes = useStyles();

  let title, description, options, selectedOption, justification, effects, debrief;

  if (quarterMinicase.minicase){
    title = quarterMinicase.minicase.miniCase;
    description = quarterMinicase.minicase.description;
    options = quarterMinicase.minicase.options.map((option, i) => (
      <Box key={i} style={{margin: '15px'}}>
        <Typography>{option.optionTitle}</Typography>
        <Typography style={{margin: '15px'}}>{option.optionDescription}</Typography>
      </Box>
    ));
    selectedOption = quarterDecisions.selectedMinicase.title || 'No Selection.';
    justification =  quarterDecisions.minicaseJustification || 'No Justification.'
    effects = quarterDecisions.selectedMinicase.title ? quarterDecisions.selectedMinicase.outputs.map((output, i) => (
      <Box key={i} style={{margin: '15px'}}>
        <Typography>{`Metric Affected: ${output.outputMetric}`}</Typography>
        <Typography>{`Metric Effect: ${output.outputQuantity}`}</Typography>
      </Box>
    )) 
      : 'No effect given';

    debrief = quarterDecisions.selectedMinicase.title ? quarterMinicase.minicase.options.find(mCase => mCase.optionTitle === quarterDecisions.selectedMinicase.title) : 'No debrief given';
  }

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `WBW Mini Case Report (${title})`,
    pageStyle: "margin: 2rem;"
  });
  
  return (
    <Card className={classes.root}>
      { quarterMinicase.minicase ?
        <>
          <Box display='flex' justifyContent='flex-end' margin='0.5rem;'>
            <IconButton
              size="small"
              variant="text"
              color="secondary"
              style={{float: 'right'}}
              onClick={ handlePrint }
              icon={PrintIcon}
            />
          </Box>
          <CardContent ref={printRef}>
            <Grid container alignItems='center' justify='center'>
              <Grid item className={classes.header}>
                <Typography className={classes.header}>MiniCase</Typography>
                <Typography className={classes.header}>{title}</Typography>
                <br/><br/>
              </Grid>
            </Grid><br/>
            <Divider/>
            <Grid container alignItems='center' justify='center'>
              <Grid item>

                <Typography>Description:</Typography>
                <Typography className={classes.body}>{description}</Typography><br/>

                <Typography>Minicase Options:</Typography>
                {options}<br/>

                <Typography>Option Selected:</Typography>
                <Typography className={classes.body}>{selectedOption}</Typography><br/>

                <Typography>Minicase Justification:</Typography>
                <Typography className={classes.body}>{justification}</Typography><br/>

                <Typography>Minicase Effects:</Typography>
                <Typography className={classes.body}>{effects}</Typography><br/>

                <Typography>Minicase Debrief:</Typography>
                <Typography className={classes.body}>{debrief.optionResult || 'No debrief given.'}</Typography><br/>

              </Grid>
            </Grid> 
          </CardContent>
        </>
        : 
        <CardContent>
          <Grid container alignItems='center' justify='center'>
            <Grid item className={classes.header}>
              <Typography className={classes.header}>No Minicase for this quarter</Typography>
              <br/><br/>
            </Grid>
          </Grid>
        </CardContent>
      }

    </Card>
  );
};

MiniCaseReport.propTypes = {
  quarterMinicase: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  quarterDecisions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default MiniCaseReport;
