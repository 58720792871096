import React, { useRef, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { editField } from '../../coresim/reducers/settings';
import NumberValidator from "./NumberValidator";
import { TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(() => ({
  field: {
    minWidth: 200,
  }
}));

const Field = ({
  className,
  editField,
  setEditField,
  name,
  onFocus,
  onBlur,
  ...remaining
}) => {
  const classes = useStyles();
  const ref = remaining.inputRef || useRef();

  const handleFocus = useCallback(
    (e) => {
      setEditField(e.target);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const isMenuCurriculum = (target) => target.parentNode &&
    target.parentNode.id === 'menu-curriculum';

  const isMobileNavLink = (target) => target.id &&
    target.id.startsWith('mobile-nav-');

  const handleBlur = useCallback(
    (e) => {
      let trimmed = e.target.value;
      if (typeof e.target.value.trim === "function") {
        trimmed = e.target.value.trim()
      }
      setNativeValue(ref.current, trimmed);
      ref.current.dispatchEvent(new Event('change', { bubbles: true }))
      if (editField && editField.name === e.target.name) {

        if (!e.relatedTarget || !(isMenuCurriculum(e.relatedTarget) ||
          isMobileNavLink(e.relatedTarget))) {
          setEditField();
        }
      }
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur, name, editField]
  );

  const InputValidator = (remaining.number || remaining.currency) ? NumberValidator : TextValidator;

  return (
    <Box py={1}>
      <InputValidator
        name={name}
        inputRef={ref}
        className={classnames(className, classes.field)}
        variant="outlined"
        margin="dense"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...remaining}
      />
    </Box>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  editField: PropTypes.func.isRequired,
  setEditField: PropTypes.func.isRequired,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setEditField: (field) => editField(field)
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  editField: state.settings.editField,
});

// setting value of element so that you can later trigger an onChange event
//https://github.com/facebook/react/issues/10135
function setNativeValue(element, value) {
  if (!value) {
    return
  }
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Field);
