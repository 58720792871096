import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import {API} from 'aws-amplify';
import { toast } from 'react-toastify';
import _orderby from 'lodash.orderby';
import SelectField from '../Forms/SelectField';
import ClearButton from '../Forms/ClearButton';
import SelectFlexBox from '../Forms/SelectFlexBox';
import PropTypes from 'prop-types';
import TeamRoutes from '../Team';
import { Route, useHistory, useRouteMatch, Switch } from 'react-router';
import SelectionContextProvider from '../../providers/selectionProvider';
import BuildIcon from '@material-ui/icons/Build';


const NoTeams = () => (
  <Typography>
    <em>No teams selected</em>
  </Typography>
);

const TeamDisplay = ({team, onRemove, disabled}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <Typography
        variant="body1">
        {team.teamName}
      </Typography>
      <ClearButton
        onRemove={onRemove}
        disabled={disabled}
        tooltipTitle='Remove Team'/>
    </Box>
  );
}

TeamDisplay.propTypes = {
  team: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const TeamsDisplay = ({teams, onRemoveTeam, disabled}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    {
      teams.map((team, i) => (
        <TeamDisplay
          key={i}
          team={team}
          onRemove={() => onRemoveTeam(i)}
          disabled={disabled}
        />
      ))
    }
  </Box>
);

TeamsDisplay.propTypes = {
  teams: PropTypes.array.isRequired,
  onRemoveTeam: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const TeamsList = ({teams, onRemoveTeam, disabled}) => {
  return (
    <Box py={1}>
      {
        teams && teams.length ?
          <TeamsDisplay
            teams={teams}
            onRemoveTeam={onRemoveTeam}
            disabled={disabled}
          /> : <NoTeams />
      }
    </Box>
  );
};

TeamsList.propTypes = {
  teams: PropTypes.array.isRequired,
  onRemoveTeam: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const SelectTeam = ({teams, onCancel, onSelected}) => {
  const [allTeams, setAllTeams] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    let aborted = false;
    async function load() {
      try {
        const loadedTeams = await API.get('mongo', '/teams');
        if (!aborted) {
          const newAllTeams =  _orderby(loadedTeams, ['teamName']);
          setAllTeams(newAllTeams);
        }
      } catch(e) {
        toast.error()
      }
    }

    load();

    return () => aborted = true;
  }, []);

  useEffect(() => {
    setSelected();
  }, [teams, allTeams]);

  const filteredTeams = useMemo( () => {
    const lookup = new Set(teams.map((t) => t._id));
    return allTeams.filter((v) => !lookup.has(v._id));
  }, [allTeams, teams]); // will be ordered, since allTeams is ordered

  const selectItems = useMemo( () => filteredTeams.map((t) => t.teamName),
    [filteredTeams]);

  return (
    <SelectFlexBox>
      <SelectField
        id="selectTeam"
        style={{minWidth: 150}}
        label="Select Team"
        items={selectItems}
        value={selected}
        onChange={ (e) => setSelected(e.target.value)}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={selected === undefined}
        onClick={ () => onSelected(filteredTeams[selected]) }
      >
        Select
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </SelectFlexBox>
  );
};

SelectTeam.propTypes = {
  teams: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired
}

const Teams = ({teams, onTeamsChanged, onRemove, disabled}) => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Box>
      <TeamsList
        teams={teams}
        onRemoveTeam={onRemove}
        disabled={disabled}
      />

      <Switch>
        <Route
          path={`${match.url}/teams`}
        >
          <SelectionContextProvider
            selection={teams}
            onSelectionChanged={onTeamsChanged}
          >
            <TeamRoutes isSelect />
          </SelectionContextProvider>
        </Route>
      </Switch>
      <Button
        variant="text"
        color="primary"
        onClick={() => history.push(`${match.url}/teams`)}
        disabled={disabled}
      >
        <BuildIcon style={{marginRight: '5px'}}/> Manage 
      </Button>

    </Box>
  );
};

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTeamsChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default Teams;
