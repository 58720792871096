import actualDefectRate from '../../formulas/actualDefectRate';
import unitsForExpense from '../../formulas/unitsForExpense';
import expenses from '../../formulas/expenses';
import productDevelopmentSentiment from '../../formulas/productDevelopmentSentiment';
import sum from '../../formulas/base/sum';
import expectedDefectRate from '../../formulas/expectedDefectRate';
import qaSentiment from '../../formulas/qaSentiment';
import combineQAFeatureSentiment from '../../formulas/combineQAFeatureSentiment';
import sentimentToPrice from '../../formulas/sentimentToPrice';
import priceVolume from '../../formulas/priceVolume';
import boardSentiment from '../../formulas/boardSentiment';

const TICK = 'vitals/tick';

const roundDownCent = (value) => Math.floor(value * 100) / 100;
const roundUpCent = (value) => Math.ceil(value * 100) / 100;

const discountFactor = (rate) => (payments) => { // calcluation from https://www.thebalance.com/loan-payment-calculations-315564
  const temp = Math.pow(1 + rate, payments);
  return (temp - 1) / (rate * temp);
};

export default (configuration) => {
  const computeDefectRate = actualDefectRate(
    configuration.industryDefectDeviance
  )(configuration.industryDefects);

  const computeExpectedDefectRate = expectedDefectRate(
    configuration.industryDefectDeviance
  )(configuration.industryDefects)(configuration.industryPrice);

  const computeQASentiment = qaSentiment(configuration.qaSensitivity);

  const computeProductDevelopmentSentiment = productDevelopmentSentiment(
    configuration.productDevelopmentSensitivity /
      configuration.expectedProductDevPerQuarter
  );

  const computeCombinedSentiment = combineQAFeatureSentiment(
    configuration.combinedQASensitivity
  );

  const computeUnitsForExpense = unitsForExpense(
    configuration.expenseLevellingPoint
  )(configuration.expenseLevelledCost);

  const computeExpense = expenses(configuration.expenseLevellingPoint)(
    configuration.expenseLevelledCost
  );

  const computeNeededQA = (productDevSum, manufacturingExpense) =>
    configuration.qaPerProductDev * productDevSum +
    configuration.qaPerManufacturingExpense * manufacturingExpense;

  const computeExpectedPrice = (sentiment) =>
    sentimentToPrice(sentiment) * configuration.industryPrice;

  const computeBoardSentiment = boardSentiment(configuration.initialStockPrice);

  const computeHireFire = (current, change) => {
    const hireFireDecision = change < 0 ?
      Math.max(change, -current) : change;
    const hireFireCost = hireFireDecision < 0 ?
      -hireFireDecision * configuration.salesFireCost :
      hireFireDecision * configuration.salesHireCost;

    return [hireFireDecision, hireFireCost];
  };

  const INIT_STATE = {
    cash: configuration.initialCash,
    lastQuarterDefects: configuration.industryDefects,
    lastQuarterDemand: Math.floor(
      configuration.demandIndex[0] * configuration.marketSize
    ),
    productionCost: 0,
    inventory: configuration.initialInventory,
    capacity: configuration.initialCapacity,
    stock: configuration.initialStockPrice,
    salespeople: configuration.initialSalespeople,
    liabilitiesBankLoan: 0, // How much loans are outstanding?
    loanPaymentDue: 0, // How much is due next quarter?
    loanPaymentApplied: 0, // How much was paid to loans last qtr?
    missedLoanPayment: false, // Have any payments been missed?
    loanLateAmount: 0, // How much overdue
    loansIndex: undefined,
    loanQuarters: 0,
    interestRate: undefined,
    interest: 0,
    expenses: 0,
    revenue: 0,
    boardSentiment: configuration.initialBoardSentiment,
    publicSentiment: configuration.initialPublicSentiment,
    qaSentiment: computeQASentiment || 0.5,
    lostSales: 0,
    valuation: configuration.initialValuation,
    advertisingHistory: [],
    productDevHistory: [],
  };

  const preventOverspend = (cash, decisions, salespeople) => {
    let cashAvail = cash + decisions.loansTaken;

    const [hireFireDecision, hireFireCost] =
      computeHireFire(salespeople, decisions.salespeople);
    salespeople += hireFireDecision;
    cashAvail -= hireFireCost;
    cashAvail -= salespeople * configuration.salesQuarterlySalaray;

    const reduceAvailableSum = (prop) => {
      decisions[prop] = Math.min(cashAvail, decisions[prop]);
      cashAvail -= decisions[prop];
    };

    reduceAvailableSum('otherExpenses');
    reduceAvailableSum('loansPaid');
    reduceAvailableSum('marketResearch');
    reduceAvailableSum('productDevelopment');
    reduceAvailableSum('qualityManagement');
    reduceAvailableSum('advertising');
    reduceAvailableSum('qualityManagement');
    reduceAvailableSum('dividends');
  };

  return (state = INIT_STATE, action) => {
    if (action === undefined) {
      return state;
    }
    switch (action.type) {
      case TICK: {
        const currentDecisions =
          action.payload.decisionHistory[
            action.payload.decisionHistory.length - 1
          ];

        preventOverspend(state.cash, currentDecisions, state.salespeople);

        // Below we are adding in the minicase outputs into our currentDecisions object prior to the rest of the calculations getting going
        let outputs = [];
        if (currentDecisions.selectedMinicase === undefined) {
          outputs = [];
        } else if (currentDecisions.selectedMinicase === {}) {
          outputs = [];
        } else {
          outputs = currentDecisions.selectedMinicase.outputs;
        }

        outputs.map((output) => {
          for (const value in currentDecisions) {
            if (value === output.outputMetric) {
              if (value === 'otherExpenses') {
                // might need to check and see what happens if there are no otherExpenses listed in Minicase decisions
                currentDecisions.otherExpenses = output.outputQuantity;
              } else {
                currentDecisions[output.outputMetric] = currentDecisions[output.outputMetric] + output.outputQuantity;
              }
            }
          }
          return '';
        });

        let liabilitiesBankLoan = state.liabilitiesBankLoan &&
          roundDownCent( state.liabilitiesBankLoan * (state.interestRate / 4)) +
          state.liabilitiesBankLoan;

        const loansIndex = (currentDecisions.loanConfirmed && !state.liabilitiesBankLoan) ?
          currentDecisions.loansIndex :
          state.loansIndex;

        const loanObj = configuration.loanTypes[loansIndex]; // loanObj undefined when loansIndex undefined

        const newLiability = currentDecisions.loanConfirmed ?
          currentDecisions.loansTaken * (1 + loanObj.servicingRate) : 0;

        const loanQuarters = (currentDecisions.loanConfirmed || !liabilitiesBankLoan) ? 1 :
          state.loanQuarters + 1;

        // cannot pay loan at the same time we take it
        const loanPaymentApplied = Math.min(currentDecisions.loansPaid, liabilitiesBankLoan);
        liabilitiesBankLoan = liabilitiesBankLoan - loanPaymentApplied + newLiability;

        const quartersRemaining = loanObj ? loanObj.term - loanQuarters + 1 : 0;
        const loanPaymentsRemaining = loanObj ?
          Math.ceil(quartersRemaining / loanObj.payperiod) :
          0;
        const missedLoanPayment = loanPaymentApplied < state.loanPaymentDue;
        const loanLateAmount = missedLoanPayment ?
          state.loanPaymentDue - loanPaymentApplied + state.loanLateAmount :
          state.loanLateAmount;
        const interestRate = missedLoanPayment ?
          loanObj.interestPenalty :
          currentDecisions.loanConfirmed ? loanObj.interest : state.interest;

        let loanPaymentDue = 0;
        if (loanObj) {
          loanPaymentDue = loanLateAmount +
            (!loanQuarters || (loanQuarters % loanObj.payperiod) ? 0 :
              interestRate ?
                (loanObj.payperiod *
                roundUpCent((liabilitiesBankLoan - loanLateAmount) /
                discountFactor(interestRate / 4)(loanPaymentsRemaining))) :
                roundUpCent((liabilitiesBankLoan - loanLateAmount) / loanPaymentsRemaining)
            );
        }
        const cash = state.cash + currentDecisions.loansTaken;

        const [hireFireDecision, hireFireCost] = computeHireFire(state.salespeople, currentDecisions.salespeople);
        const salespeople = state.salespeople + hireFireDecision;

        const nonProductionExpenses =
          currentDecisions.productDevelopment +
          currentDecisions.marketResearch +
          currentDecisions.advertising +
          currentDecisions.qualityManagement +
          currentDecisions.dividends +
          currentDecisions.otherExpenses +
          hireFireCost +
          salespeople * configuration.salesQuarterlySalaray +
          loanPaymentApplied;
        const advertisingHistory = [...state.advertisingHistory,
          currentDecisions.advertising + salespeople * configuration.salesAdvertisingOutput];

        const productDevHistory = [...state.productDevHistory,
          currentDecisions.productDevelopment]; // TODO: calculate other product dev effects

        // TODO: figure out how to divvy budget overruns
        // TODO: other expenses (salespeople, dividends, loan payments, minicases, taxes)
        const cashForProduction = Math.max(
          0,
          cash - nonProductionExpenses
        );

        const maxProduce = computeUnitsForExpense(cashForProduction);

        const unitsProduced = Math.min(
          currentDecisions.unitsProduced,
          state.capacity,
          maxProduce
        );

        const productionCost = Number(computeExpense(unitsProduced).toFixed(2));
        const neededQA = computeNeededQA(
          sum(action.payload.decisionHistory.map((v) => v.productDevelopment)),
          productionCost
        );

        const defectRate = computeDefectRate(neededQA)(
          action.payload.decisionHistory.map((v) => v.qualityManagement)
        );

        const expectedDefectRate = computeExpectedDefectRate(
          currentDecisions.price
        );

        const qaSentiment = computeQASentiment(expectedDefectRate)(defectRate);
        const devSentiment = computeProductDevelopmentSentiment(
          configuration.expectedProductDevPerQuarter *
            action.payload.decisionHistory.length
        )(advertisingHistory)(productDevHistory);

        const combinedSentiment = computeCombinedSentiment(
          qaSentiment,
          devSentiment
        );

        const expectedPrice = computeExpectedPrice(combinedSentiment);

        const maxSales = priceVolume(
          configuration.marketSize *
            configuration.demandIndex[action.payload.decisionHistory.length - 1]
        )(expectedPrice)(currentDecisions.price);

        const sales = Math.min(maxSales, state.inventory);
        const revenue = sales * currentDecisions.price;
        const expenses = nonProductionExpenses + productionCost;

        const totalDefects = Math.floor((defectRate * unitsProduced) / 1000);

        const newCash = cash + revenue - expenses;
        const inventory =
          state.inventory - sales + unitsProduced - totalDefects;

        // TODO: be a bit smarter wrt projected revenue
        // basing valuation from https://smallbusiness.chron.com/calculate-valuation-company-23616.html
        const revenueValuation = Math.max(
          0,
          Math.max(0, revenue) * 4 * configuration.revenueMultiplier +
            newCash +
            inventory * expectedPrice -
            state.liabilitiesBankLoan
        );

        // TODO: https://www.money-zine.com/investing/investing/understanding-financial-ratios/
        // https://www.money-zine.com/investing/stocks/calculating-stock-prices/
        // TODO: factor dividends
        const stock = revenueValuation / configuration.stocks;

        // TODO: get a real sentiment valuations together
        const boardSentiment = computeBoardSentiment(
          (revenueValuation * configuration.industryDividentYeild) / 4
        )(currentDecisions.dividends)(stock);

        return {
          ...state,
          cash: newCash,
          inventory: state.inventory - sales + unitsProduced - totalDefects,
          lostSales: maxSales - sales,
          lastQuarterDefects: totalDefects,
          lastQuarterDemand: maxSales,
          productionCost,
          publicSentiment: combinedSentiment,
          stock,
          boardSentiment,
          expenses,
          revenue,
          valuation: revenueValuation,
          salespeople,
          advertisingHistory,
          productDevHistory,
          liabilitiesBankLoan,
          loanPaymentDue,
          loanPaymentApplied,
          loanQuarters,
          loanLateAmount,
          loansIndex,
          missedLoanPayment,
          interestRate,
        };
      }
      default:
        return state;
    }
  };
};

export function getVitalsTickAction(decisionHistory) {
  return {
    type: TICK,
    payload: {
      decisionHistory,
    },
  };
}
