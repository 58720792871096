import { useReducer } from 'react';

export function useFormReducer(
  initializerArgs,
  initializer
) {
  const [form, formDispath] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'UPDATE':
          if (!action.payload) {
            return state;
          }

          return { ...state, [action.payload.field]: action.payload.value };
        case 'RESET':
          return initializer(initializerArgs);
        default:
          return state;
      }
    },
    initializerArgs,
    initializer
  );

  const getUpdateHandler = (field) => (
    value
  ) => {
    formDispath({
      payload: { field, value: value},
      type: 'UPDATE'
    });
  };

  const getResetHandler = (e) => {
    if (e) {
      e.preventDefault();
    }

    formDispath({
      type: 'RESET'
    });
  };

  const result = [
    form,
    getUpdateHandler,
    getResetHandler
  ];

  return result;
}
