import React, {useState} from 'react';
import TeamList from './TeamList';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateTeam from './CreateTeam';
import EditTeam from './EditTeam';
import StackPanel from '../../routing/StackPanel';
import PropTypes from 'prop-types';

const TeamAdmin = ({isSelect}) => {
  const [reload, setReload] = useState(new Date());
  const match = useRouteMatch();

  const onReload = () => {
    setReload(new Date());
  };

  return (
    <AdminPanel root={!isSelect} label='Teams' >
      <TeamList reload={reload} isSelect={isSelect} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create Team'
        >
          <StackPanel>
            <CreateTeam onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit Team'
        >
          <StackPanel>
            <EditTeam onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  )
};

TeamAdmin.propTypes = {
  isSelect: PropTypes.bool,
};

export default TeamAdmin;
