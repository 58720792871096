import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {VictoryChart, VictoryLine, VictoryAxis, VictoryTheme, VictoryLabel, VictoryPortal, VictoryContainer} from 'victory';
import PercentDiff from '../../components/PercentDiff';
import PropTypes from 'prop-types';

const graphWidth = '80%';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    margin: 'auto',
    marginTop: '30px',
  },
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
  },
  noDataTitle: {
    color: '#aeaeae',
    position: 'absolute',
    top: '25%',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      top: '15%',
    },
  },
}));

const PublicSentiment = (props) => {
  const classes = useStyles();
  const vitalsHistory = props.coresim[props.teamIndex].vitalsHistory;
  const latest = vitalsHistory[vitalsHistory.length - 1].publicSentiment;

  const stockData = [];

  vitalsHistory.map((vital, i) => {
    if (i === 0) {
      return '';
    }
    const amount = vital.publicSentiment * 100;
    stockData.push({x: `Q${i}`, y: amount});
    return '';
  });

  const previous = vitalsHistory.length > 1 ? vitalsHistory[vitalsHistory.length - 2].publicSentiment : latest;

  return (
    (stockData.length === 0 ?
      <div className={(classes.divStyle, classes.class)} style={{position: 'relative'}}>

        <Typography className={classes.titleStyle}>Public Sentiment</Typography>
        <Typography align='center' className={classes.noDataTitle}>
          Charts Available after Q1
        </Typography>

        <svg style={{width: '100%', height: 'auto', color: 'rgba(0,0,0,.1)'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
        </svg>
      </div> :
      <div className={(classes.divStyle, classes.class)}>
        <Typography className={classes.titleStyle}>Public Sentiment</Typography>
        <Typography>

          {(100 * vitalsHistory[vitalsHistory.length - 1].publicSentiment).toFixed(1)}%
        </Typography>
        <PercentDiff previous={previous} latest={latest} />

        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={30}
          containerComponent={<VictoryContainer maxWidth={graphWidth} />}
        >
          <VictoryPortal>
            <VictoryAxis
              tickValues={stockData.map((x, i) => i + 1)}
              tickFormat={stockData.map((x) => x.x)}
            />
          </VictoryPortal>
          <VictoryAxis
            dependentAxis
            tickFormat={(x) => (x.toString())}
          />

          <VictoryLine
            animate={{
              duration: 2000,
              onLoad: {duration: 1000},
            }}
            style={{
              data: {
                stroke: '#ff9900',
                strokeWidth: 6,
                borderRadius: '3px',
              },
              labels: {
                fill: '#0a0a0a',
              },
            }}
            data={stockData}
            domain={{x: [0, stockData.length], y: [0, 100]}}
            labels={({datum}) => datum.y.toFixed(1)}
            labelComponent={<VictoryLabel verticalAnchor='end' />}
            x='x'
            y='y'
          />
        </VictoryChart>
      </div>
    )
  );
};

PublicSentiment.propTypes = {
  coresim: PropTypes.array,
  classes: PropTypes.object,
  teamIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim.teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicSentiment);
