import React from 'react';
import {Box, Typography, Card, Link} from '@material-ui/core';
import formatCurrency from '../../helpers/formatCurrency';
import formatNumber from '../../helpers/formatNumber';
import PropTypes from 'prop-types';
import {simSubmitState} from '../../helpers/canSubmitDecisions';
import WarningBox from '../WarningBox';

const Value = ({label, value}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" >
      <Box pr={1} style={{textDecoration: 'none'}}>
        <Typography variant="h6">
          {label}
        </Typography>
      </Box>
      <Box style={{backgroundColor: '#eee', borderRadius: '3px'}} px={2} py={.5}>
        {value}
      </Box>
    </Box>

  );
};

Value.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SimStatusCard = ({
  decisions,
  showError,
  expendatures,
  loanBalanceDue,
  latest,
  quarterNum,
  activeQuarter,
  user,
  completed,
  decisionsSubmitted
}) => {
  const cashOnHand = latest.cash +
    (decisions.loanConfirmed ? Number(decisions.loansTaken) : 0);
  const error = cashOnHand < expendatures;
  const submittableState = simSubmitState({user, activeQuarter, completed, decisionsSubmitted});
  return (
    <Box component="header">
      <Card style={{padding: '10px'}}>
        {error && showError &&
        <WarningBox>
          Available cash exceeded!
          {loanBalanceDue === 0 ?
            <Link href='#loansTaken' style={{marginLeft: '2rem', color: 'white'}}>Loan options...</Link> :
            <Typography>Please make changes to current decisions. The Bank will not loan you any more money.</Typography>}
        </WarningBox>
        }
        {!submittableState.canSubmit() &&  <WarningBox>{submittableState.closedReason()}</WarningBox>}
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', textAlign: 'left', alignItems: 'center', flexDirection: 'row'}}>
          <Value label="Cash Avail." value={formatCurrency(cashOnHand - expendatures)} />
          <Value label="Inventory" value={formatNumber(latest.inventory)} />
          <Value
            label={`Units Sold Q${quarterNum}`}
            value={formatNumber(latest.lastQuarterDemand - latest.lostSales)} />
        </div>
      </Card>
    </Box>
  );
};

SimStatusCard.propTypes = {
  decisions: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired,
  expendatures: PropTypes.number.isRequired,
  latest: PropTypes.object.isRequired,
  quarterNum: PropTypes.number.isRequired,
  loanBalanceDue: PropTypes.number.isRequired,
  activeQuarter: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  completed: PropTypes.object.isRequired,
  decisionsSubmitted: PropTypes.bool
}

export default SimStatusCard;
