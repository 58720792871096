import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import formatCurrency from './../helpers/formatCurrency';
import PropTypes from 'prop-types';
import {Grid, Typography, Divider} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tally: {
    width: '100%',
    minWidth: '6rem',
    textAlign: 'right',
    margin: '.5rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },

  rowTitle: {
    paddingLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
    }
  },
  titleStyle: {
    fontWeight: 'bold',
  },
  row: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: '10px'
    }
  },
}));

const Row = ({title, last, current}) => {
  const classes = useStyles();
  const lastQuarter = last === undefined ? false : true;

  return (
    <div>
      <Grid container className={classes.row} direction='row' alignItems='center'>
        <Grid className={classes.rowTitle} item xs={12} sm={12} md='auto' lg='auto' style={{display: 'flex'}}>
          <Typography className={classes.titleStyle}>{title}</Typography>
        </Grid>
        <Grid item xs='auto' sm='auto' md='auto' lg='auto' style={{display: 'flex'}}>
          {lastQuarter && <Typography style={{color: (last < 0 ? 'red': 'black')}} className={classes.tally}>{formatCurrency(last)}</Typography>}
          <Typography style={{color: (current < 0 ? 'red': 'black')}} className={classes.tally}>{formatCurrency(current)}</Typography>
        </Grid>
      </Grid>
      <Divider/>
    </div>
  )
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  last: PropTypes.any,
  current: PropTypes.any.isRequired,
}

export default Row;