import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import _orderby from 'lodash.orderby';
import PropTypes from 'prop-types';
import LeadBoard from '../containers/Graphs/LeadBoard';
import formatCurrency from '../helpers/formatCurrency'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }   
  },
  table: {
    minWidth: 650,
  },
}));

function Leaderboard(props) {
  const classes = useStyles();
  const newTeams = [];

  props.coresim.teams.map((team) => {

    const newName = team.vitalsHistory !== undefined ? team.vitalsHistory.filter(vital => vital.businessAs) : undefined;

    const t = {
      teamName: (newName.length > 0 && newName[newName.length - 1].businessAs) || team.teamName,
      vitalsHistory: team.vitalsHistory[team.vitalsHistory.length - 1],
      valuation: team.vitalsHistory[team.vitalsHistory.length - 1].valuation,
    };
    if (t.valuation !== null) {
      newTeams.push(t);
    }
    return '';
  });

  const orderedTeams = _orderby(newTeams, ['valuation'], ['desc']);

  return (
    <div className={classes.root}>
      {orderedTeams.length > 0 && <LeadBoard orderedTeams={orderedTeams}/>}
      <Paper className={classes.paper}>
        <Table
          stickyHeader
          className={classes.table}
          size='small'
          aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Valuation</TableCell>
              <TableCell>Stock</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {orderedTeams.map((row, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell >{row.teamName}</TableCell>
                  <TableCell>{formatCurrency(row.valuation)}</TableCell>
                  <TableCell>{formatCurrency(row.vitalsHistory.stock)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let coresim = (ownProps && ownProps.coresim) || state.coresim;
  return {
    quarter: coresim.quarter.counter,
    coresim: coresim,
  };
};

export default connect(mapStateToProps)(Leaderboard);

Leaderboard.propTypes = {
  coresim: PropTypes.object,
};
