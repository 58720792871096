import React from "react";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

export default function RouteMenuItem(props) {
  return(
    <div className="block" style={{margin: '5px', textAlign: 'center', height: 100, width: '48%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Link to={props.route} style={{color: 'black', textDecoration: 'none'}}>
        <Typography>
          {props.title}
        </Typography>
      </Link>
    </div>
  )
}

RouteMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
}