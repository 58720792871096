import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import awsmobile from './aws-exports.js';
import Amplify, {API, Auth} from 'aws-amplify';
import {PersistGate} from 'redux-persist/lib/integration/react';
import store, {persistor} from '../src/coresim/index';
import LoadingModal from './components/LoadingModal';

Amplify.configure(awsmobile);

const defaultConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  custom_header: async () => {
    return {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  }
};

API.configure({
  API: {
    endpoints: [
      {
        name: 'mongo',
        endpoint: process.env.REACT_APP_MONGO_ENDPOINT, // wbw-backend
        ...defaultConfig,
      },
      {
        name: 'state',
        endpoint: process.env.REACT_APP_STATE_ENDPOINT, // wbw-messaging
        ...defaultConfig,
      },
      {
        name: 'scheduler',
        endpoint: process.env.REACT_APP_TICK_SCHEDULER,
        ...defaultConfig,
      },
      {
        name: 'blackboard',
        endpoint: process.env.REACT_APP_BB_ENDPOINT,
        ...defaultConfig,
      },
      {
        name: 'zoom',
        endpoint: process.env.REACT_APP_ZOOM_ENDPOINT,
        ...defaultConfig,
      }
    ],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingModal />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
