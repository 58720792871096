import {
  Table,
  TableBody,
  TableRow,
  Typography,
  TableHead,
  TablePagination,
  makeStyles,
  Box,
} from '@material-ui/core';
import React, {useMemo, useState} from 'react';
import renderHeader from './renderHeader';
import renderRow from './renderRow';
import Progress from '../Progress';
import stableSort from './stableSort';
import PropTypes from 'prop-types';

const defaultNoData = (className) => (

  <div className={className}>
    <strong>No Results to Display</strong>
  </div>
);

const dataCompare = (sortAscending) => (first, second) => {
  if (first === second) {
    return 0;
  }

  let cmp = 1;
  if (first < second) {
    cmp = -1;
  }

  if (!sortAscending) {
    cmp *= -1;
  }

  return cmp;
};

export default (row, header, noData = defaultNoData) => {
  const DecoratedTable = (props) => {
    const {
      data,
      title,
      loading,
      onDataChange,
      page = 0,
      rowsPerPage = 10,
      sortField,
      sortAscending,
      ...remaining
    } = props;

    DecoratedTable.propTypes = {
      data: PropTypes.array.isRequired,
      title: PropTypes.string,
      loading: PropTypes.bool.isRequired,
      onDataChange: PropTypes.func,
      page: PropTypes.number,
      rowsPerPage: PropTypes.number,
      sortField: PropTypes.string.isRequired,
      sortAscending: PropTypes.bool.isRequired,
    }

    const [curPage, setCurPage] = useState(page);
    const [curRowsPerPage, setCurRowsPerPage] = useState(rowsPerPage);
    const [curSortAscending, setCurSortAscending] = useState(sortAscending);
    const [curSortField, setCurSortField] = useState(sortField);

    const classes = useStyles();
    const cols = header ? header(data) : undefined;

    const handleChangeRowsPerPage = (e) => {
      const newRowsPerPage = Number(e.target.value);
      setCurRowsPerPage(newRowsPerPage);
      if (data && newRowsPerPage * curPage > data.length) {
        setCurPage(Math.floor(data.length / newRowsPerPage));
      }

      if (onDataChange) {
        onDataChange(
          curPage,
          newRowsPerPage,
          curSortField,
          curSortAscending ? 'asc' : 'desc'
        );
      }
    };

    const handleChangePage = (e, newPage) => {
      setCurPage(newPage);
      if (onDataChange) {
        onDataChange(
          newPage,
          curRowsPerPage,
          curSortField,
          curSortAscending ? 'asc' : 'desc'
        );
      }
    };

    const getDataSlice = () => {
      if (onDataChange || !data) {
        return data;
      }

      let result = data;

      if (curSortField) {
        result = stableSort(
          result,
          curSortField,
          dataCompare(curSortAscending)
        );
      } else if (!curSortAscending) {
        result = result.map((i) => i).reverse();
      }

      return result.slice(
        curPage * curRowsPerPage,
        (curPage + 1) * curRowsPerPage
      );
    };

    const slicedData = getDataSlice();

    const headerItems = useMemo(() => {
      const getSortChangeHandler = (name, defaultDirection) => {
        return () => {
          setCurSortField(name);
          setCurSortAscending(
            curSortField !== name ?
              defaultDirection !== 'desc' :
              !curSortAscending
          );
        };
      };

      return (
        cols &&
        cols.map(
          renderHeader(
            getSortChangeHandler,
            classes.headerItem,
            classes.icon,
            classes.headerActiveItem,
            curSortField,
            curSortAscending
          )
        )
      );
    }, [
      classes.headerActiveItem,
      classes.headerItem,
      classes.icon,
      cols,
      curSortField,
      curSortAscending,
    ]);

    return (
      <React.Fragment>
        <div className={classes.layout}>
          {title && (
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              {title}
            </Typography>
          )}
          <Table {...remaining}>
            {headerItems && (
              <TableHead>
                <TableRow className={classes.header}>{headerItems}</TableRow>
              </TableHead>
            )}
            <TableBody>
              {!loading &&
                slicedData &&
                slicedData.map(renderRow(row, cols, classes.cellBody))}
            </TableBody>
          </Table>
          {!loading &&
            (!slicedData || !slicedData.length) &&
            noData(classes.noData)}
          {loading && (
            <Box py={1}>
              <Progress color="secondary">
                <Typography>loading...</Typography>
              </Progress>
            </Box>

          )}
        </div>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data ? data.length : 0}
          rowsPerPage={curRowsPerPage}
          page={curPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  };

  return DecoratedTable;

};

const useStyles = makeStyles((theme) => ({
  cellBody: {
    border: 'none',
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(4),
    },
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  headerActiveItem: {
    color: `${theme.palette.secondary.contrastText} !important`,
  },
  headerItem: {
    '&:hover': {
      color: theme.palette.secondary.contrastText,
    },
    'color': theme.palette.secondary.contrastText,
    'width': '100%',
  },
  icon: {
    color: `${theme.palette.secondary.contrastText} !important`,
  },
  layout: {
    borderColor: theme.palette.secondary.main,
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 0.6,
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
  },
  noData: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.default,
    color: 'black',
    textAlign: 'center',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0.25),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  tablePagination: {
    '& > .MuiTablePagination-toolbar': {
      marginLeft: 0,
    },
  }
}));
