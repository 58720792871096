import React, { useRef } from 'react';
import {
  Button,
  makeStyles,
  Portal} from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import { useArrayReducer } from '../../helpers/useArrayReducer';
import Users from './Users'

const useStyles = makeStyles(() => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
});


const TeamForm = ({onSubmit, initialValue, disabled}) => {

  const classes = useStyles();
  const usersRef = useRef();

  const [form, updateHandler] =
    useFormReducer(initialValue,
      (init) => ({
        ...init,
      })
    );

  const [users, usersHandlers] =
  useArrayReducer(
    initialValue.members,
    (initUsers) => [...initUsers]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit({
        ...form,
        members: users
      });
    }
  }

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={handleSubmit}>

        <fieldset disabled={disabled} className={classes.fieldset}>
          <Group stack>
            <Field
              {...getProps('teamName', form, updateHandler)}
              label="Team Name"
              onChange={(e) =>updateHandler('teamName')(e.target.value)}
              disabled={disabled}
              autoFocus />
          </Group>
          <Group title="Users">
            <div ref={usersRef} />
          </Group>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled}
          >
            Save
          </Button>
        </fieldset>

      </ValidatorForm>
      <Portal container={usersRef.current}>
        <Users
          users={users}
          onUsersChanged={usersHandlers.init}
          onRemove={usersHandlers.remove}
          disabled={disabled}
        />
      </Portal>
    </React.Fragment>
  )
}

TeamForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default TeamForm;
