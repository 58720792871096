import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import SimulationForm from './SimulationForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const EditSimulation = ({onReload}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialSimulation, setInitialSimulation] = useState();
  const [error, setError] = useState();
  const breadcrumbActions = useBreadcrumbActions();

  let { id } = useParams();

  useEffect(() => {
    let aborted = false;
    const load = async () => {
      try {
        const result = await API.get('mongo', `/sims/${id}`);
        if (!aborted) {
          setInitialSimulation(result);
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setError("Simulation not found");
        } else {
          throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    load();
    return () => aborted = true;
  }, [id]);

  const onSubmit = async (form) => {
    setSaving(true);

    form.config.map((conf, i) => {
      if (conf.widgetImage.length > 0) {

        API.post('mongo', `/util/photo`, {
          body: {
            img: conf.widgetImage[0],
            name: conf.widgetId
          }
        })
      }
      // setting to empty array to no longer store base64 string
      conf.widgetImage = []
    })

    let body = {
      ...form
    }
    try {
      await API.put('mongo', `/sims/${id}`, {body});
      toast.success('Success!');
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);
    }
  }

  const started = !loading && (initialSimulation.simStarted);
  return (
    <Box>
      <Typography variant="h4">
        Edit Simulation
      </Typography>

      {initialSimulation === undefined ? null :
        <Button onClick={() => history.push(`${match.url}/view`)}>
          View Sim
        </Button>
      }
      {
        error
      }
      { // TODO: loading screen
        !loading && !error &&
        <SimulationForm
          onSubmit={onSubmit}
          initialValue={initialSimulation}
          disabled={saving || started}
          message={started ? 'Cannot edit, sim has already started.' : undefined}
        />
      }
    </Box>
  );
}

EditSimulation.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default EditSimulation;
