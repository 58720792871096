import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ComputerIcon from '@material-ui/icons/Computer';
import {useCurriculumContext} from '../providers/curriculumProvider';

// 'to' in this case is not so much link to route, more reference to which component in modal to display, if any
export default (sim, teamIndex) => {
  const activeModuleIds = useCurriculumContext();
  return [
    {
      id: 'mobile-nav-sim',
      label: 'Decisions',
      Icon: ComputerIcon,
      to: 'sim',
    },
    {
      id: 'mobile-nav-curriculum',
      label: 'Curriculum',
      Icon: LibraryBooksIcon,
      displayBadge: activeModuleIds.length > 0,
      badgeValue: activeModuleIds.length,
      to: 'curriculum'
    },
    {
      id: 'mobile-nav-notifications',
      label: 'Notifications', // announcements + schedule
      Icon: AnnouncementIcon,
      to: 'notifications'
    },
  ]
}
