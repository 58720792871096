import React, { useMemo, useCallback } from 'react';
import tableHoc from './tableHoc';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

const selectField = '_selected';
const indexField = '_index';

const selectableTableHoc = (rowDefinition, headerDefinition, noData) => {

  const selectHeader = (onSelectAllClick) => (data) => {
    const selectCount = data.reduce((count, v) => v[selectField] ? count + 1 : count, 0);

    return [
      {
        isSortable: true,
        label: <Checkbox
          color="primary"
          indeterminate={selectCount > 0 && selectCount < data.length}
          checked={data.length > 0 && selectCount === data.length}
          onChange={onSelectAllClick}
          onClick={(e) => {
            e.stopPropagation();
          }}
          inputProps={{ 'aria-label': 'select all' }}
        />
        ,
        name: selectField
      },
      ...headerDefinition(data),
    ];
  };

  const selectRow = (onSelectChange) => (row, index) => [
    <Checkbox
      key={selectField}
      checked={row[selectField]}
      onChange={onSelectChange(row)}
      inputProps={{ 'aria-label': 'select item' }}
    />,
    ...rowDefinition(row, index)
  ];

  const SelectTable = ({
    onSelectAdd,
    onSelectRemove,
    onSelectAll,
    selected,
    data,
    ...remaining
  }) => {

    const onSelectAllClick = useCallback(
      (e) => {
        e.preventDefault();

        const newSelectItems = (e.target.checked && data) ?
          [...data] : [];

        if (onSelectAll) {
          onSelectAll(newSelectItems);
        }
      }
      , [data]
    );

    const onSelectClick = useCallback(
      (row) => (e) => {
        if (e.target.checked) {
          onSelectAdd && onSelectAdd(data[row[indexField]]);
        } else {
          onSelectRemove && onSelectRemove(data[row[indexField]]);
        }
      }
      , [data]
    );

    const selectSet = useMemo(
      () => new Set(selected) // use a Set for quick lookup
      , [selected]);

    const selectableData = useMemo(() =>
      data.map((v, i) => ({
        [selectField]: selectSet.has(v._id), // O(1) lookup
        [indexField]: i,
        ...v
      }))
    , [data, selectSet]);

    const Table = useMemo(
      () => tableHoc(
        selectRow(onSelectClick),
        selectHeader(onSelectAllClick),
        noData),
      [selectRow, selectHeader, noData, onSelectClick, onSelectAllClick]
    );

    return (
      <Table {...remaining} data={selectableData} />
    );
  };

  SelectTable.propTypes = {
    onSelectAdd: PropTypes.func.isRequired,
    onSelectRemove: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    selected: PropTypes.array,
    data: PropTypes.any,
  };

  return SelectTable;
};

export default selectableTableHoc;
