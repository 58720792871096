import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import DefaultAction from './DefaultAction';
import PropTypes from 'prop-types';
import numberFilterHoc from '../../helpers/numberFilterHoc';
import Field from '../../admin/Forms/Field';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    wordWrap: 'normal'
  },
  action: {
    paddingTop: '.6rem',
    paddingLeft: '3.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '95%'
  },
  title: {
    wordWrap: 'normal'
  },
  subheader: {
    maxWidth: 'fit-content',
    backgroundColor: '#ffe066',
    padding: '.4vw',
    fontSize: '1em',
    display: 'flex',
    flexWrap: 'wrap',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  }

}));

const DefaultInput = numberFilterHoc(
  Field,
  {
    allowNegative: false,
    decimal: 0,
  }
);


const Header = ({
  businessData,
  coresim,
  teamIndex,
  decisions,
  expenses,
  onToolTipToggle,
  handleChange,
  handleUpdate}) => {
  const classes = useStyles();
  const Action = businessData.action || DefaultAction;
  const actionNode =
    <Action
      coresim={coresim}
      teamIndex={teamIndex}
      businessData={businessData}
      decisions={decisions}
      onToolTipToggle={onToolTipToggle}
      handleChange={handleChange}
      handleUpdate={handleUpdate}
      expenses={expenses}
      Component={businessData.component || DefaultInput}
    />;

  return (
    <CardHeader
      classes={{
        root: classes.root,
        action: classes.action,
        title: classes.title
      }}
      disableTypography={true}
      avatar={
        <img
          alt={`${businessData.icon}`}
          src={`./images/${businessData.icon}.png`}
        />
      }
      action={
        actionNode
      }
      title={
        <Title title={businessData.title} />
      }
      subheader={
        <Typography
          variant='h5'
          className={classes.subheader}>
          {businessData.subtitle}
        </Typography>
      }>
    </CardHeader>
  );
};

Header.propTypes = {
  action: PropTypes.node,
  businessData: PropTypes.object,
  coresim: PropTypes.object,
  teamIndex: PropTypes.number,
  expenses: PropTypes.number,
  decisions: PropTypes.object,
  onToolTipToggle: PropTypes.func,
  handleChange: PropTypes.func,
  handleUpdate: PropTypes.func,
};

export default Header;
