import React, {useState, useEffect, useMemo, useCallback} from 'react';
import tableHoc from '../../components/Tables/tableHoc';
import {API} from 'aws-amplify';
import { Box, Button, Typography, Grid, Dialog } from '@material-ui/core';
import IconButton from '../../components/widgets/IconButton';
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import Transition from '../../components/ModalTransition';
import DeleteModal from '../../components/DeleteModal';

const header = () => [
  {
    isSortable: true,
    label: 'Headline',
    name: 'headline',
  },
  {
    isSortable: true,
    label: 'Last Modified',
    name: 'lastModifiedDate',
  },
  {
    isSortable: true,
    label: 'Created',
    name: 'createdDate',
  },
  {
    label: ''
  }
];

const rowDefinition = (deleteArticle, editArticle) => (row, index) => [
  row.headline,
  row.lastModifiedDate ? new Date(row.lastModifiedDate).toLocaleString() : "",
  row.createdDate ? new Date(row.createdDate).toLocaleString() : "",
  <Grid key="actions" container justify="flex-end">
    {editArticle &&
      <Grid item>
        <IconButton size="small"
          variant="text"
          color="primary"
          icon={CreateIcon}
          onClick= { (e) => {
            e.preventDefault();
            editArticle(row._id);
          }}
        >
          edit
        </IconButton>
      </Grid>
    }
    {deleteArticle &&
      <Grid item>
        <IconButton
          size="small"
          variant="text"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            deleteArticle(row);
          }}
          icon={DeleteIcon}
        >
          delete
        </IconButton>
      </Grid>}
  </Grid>
];

const NewArticlesList = ({reload}) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const match = useRouteMatch();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    const abortObj = {
      aborted: false
    };

    load(abortObj);
    return () => {
      abortObj.aborted = true;
    }

  }, [reload]);

  const load = async (abortObj) => {
    try {
      const news = await API.get('mongo', '/newsarticles');
      if (!abortObj || !abortObj.aborted)
      {
        setArticles(news.filter((a) => a.active !== false));
      }
    } catch (e) {
      toast.error('Error retrieving news articles.');
    } finally {
      setLoading(false);
    }
  }

  const handleConfirmDelete = useCallback(async (id) => {
    try {
      setLoading(true);
      await API.put('mongo', `/newsarticles/${id}`,
        {
          body: {
            active: false
          }
        }
      );
      toast.success('News article deleted');
    } catch(e) {
      toast.error('Error deleting news article');
    } finally {
      setDeleting()
      load();
    }

  }, []);

  const handleDelete = useCallback((row) => {
    setDeleting(row);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setDeleting();
  }, []);

  const onEdit = useCallback((id) => {
    history.push(`${match.url}/${id}`);
  }, []);

  const Table = useMemo(() =>
    tableHoc(rowDefinition(handleDelete, onEdit), header),
  [handleDelete, onEdit]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography
        variant="h4">
            News Articles
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        py={2}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={
            () => history.push(`${match.url}/create`)
          }
        >
          + new
        </Button>
      </Box>
      <Table data={articles}
        sortField='lastModifiedDate'
        sortAscending={false}
        loading={loading}
      />

      <Dialog
        style={{
          margin: 'auto',
        }}
        open={Boolean(deleting)}
        TransitionComponent={Transition}
        keepMounted
        disableEscapeKeyDown={true}
        fullScreen={false}
        fullWidth={false}
        disableBackdropClick={true}
        hideBackdrop={false}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'>
        <DeleteModal handleConfirm={() => handleConfirmDelete(deleting._id)} handleCancel={handleCancelDelete} what={`News Article "${(deleting && deleting.headline) || ''}"`}/>
      </Dialog>
    </Box>

  );
};

NewArticlesList.propTypes = {
  reload: PropTypes.instanceOf(Date)
}

export default NewArticlesList;
