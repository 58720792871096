/**
 * A function to determine board sentiment based from dividends and stock price
 * 70% of the sentiment is stock price, 30% is dividends
 *
 * @param {number} initialStockPrice - The starting stock price
 */
const boardSentiment = (initialStockPrice) =>
  /**
   * @param {number} expectedDividends - The dividends expected by the board
   */
  (expectedDividends) =>
    /**
     * @param {number} dividends - The divends paid
     */
    (dividends) =>
      /**
       * @param {number} stockPrice - The current stock price
       * @returns {number} - The board sentiment as a number from 0 to 1
       */
      (stockPrice) =>
        (7 * Math.min(stockPrice / (2 * initialStockPrice), 1) +
          3 * (dividends > expectedDividends ? 1 : 0)) /
        10;

export default boardSentiment;
