import React, {useRef, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import formatCurrency from '../../helpers/formatCurrency';
import { connect } from "react-redux";
import { marketResearch } from "../../coresim/reducers/decisions";
import { bindActionCreators } from "redux";
import {Balloon, Clock} from 'mdi-material-ui';
import research from "../../config/research"

const useStyles = makeStyles((theme) => ({
  infoTip: {
    margin: theme.spacing(0, .5),
    padding: theme.spacing(.2, .6),
    borderRadius: 5,
    color: 'white',
    cursor: 'help',
  },
  positiveTip: {
    backgroundColor: 'green',
  },
  negativeTip: {
    backgroundColor: 'red',
  },
  valueLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(.5),
  },
  loanBox: {
    '&:hover': {
      backgroundColor: 'rgba(250, 0, 250, 0.08)',
    },
  },
  boxRow: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '95%',
      justifyContent: 'space-around',
    }
  },
  confirmButton: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    },
  }
}));

const Option = ({ loan, onSelect }) => {
  const classes = useStyles();
  const Icon = loan.icon === 'Balloon' ? Balloon : Clock;
  const optClasses = classnames(classes.loanBox, classes.boxRow)
  return (
    <Box
      p={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      border="1px solid #ccc"
      borderRadius={5}
      style={{ marginBottom: 10 }}

      className={optClasses}
      onClick={onSelect}
    >
      <Icon />

      <Typography component="header" variant="h6">
        {loan.type}
      </Typography>

      <Button color="secondary">
        More Info
      </Button>
    </Box>
  );
};

Option.propTypes = {
  loan: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

const OptionDetails = ({
  confirmed,
  loan,
  onCancel,
  onSave,
  value,
  cash,
  marketResearch
}) => {
  const classes = useStyles();
  const Icon = loan.icon === 'Balloon' ? Balloon : Clock;
  const hasError = Boolean(value && Number(value) > cash);

  return (
    <Box maxWidth={400} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Icon />
        <Typography component="header" variant="h6">
          {loan.type}
        </Typography>
      </Box>
      <Typography variant="body1">
        {loan.description}
      </Typography>

      {confirmed ?
        <Typography variant="body2">
          Amount Taken: {formatCurrency(value)}
        </Typography> : null
      }

      <Box
        pt={2}
        display="flex"
        flexDirection={{xs: "column-reverse", md: "row"}}
        justifyContent="space-evenly"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            marketResearch(0);
            onCancel && onCancel()
          }}
        >
          Cancel
        </Button>
        {!confirmed &&
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            disabled={hasError}
            onClick={() => {
              marketResearch(10000)
              onSave && onSave() }}
          >
            Purchase Report $10,000
          </Button>
        }
      </Box>

    </Box>
  );
};


OptionDetails.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  loan: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  inputRef: PropTypes.object.isRequired,
  cash: PropTypes.number,
  marketResearch: PropTypes.func.isRequired,
};

const MakeOptionDecision = ({
  coresim,
  decisions,
  teamIndex,
  handleUpdate,
  handleChange,
  marketResearch
}) => {
  const vitalsHistory = coresim.teams[teamIndex].vitalsHistory;
  const cash = vitalsHistory[vitalsHistory.length - 1].cash;
  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    let aborted = false;
    if (ref.current && Number.isFinite(decisions.researchIndex) && !aborted) {
      ref.current.focus();
    }
    return () => aborted = true;
  }, [ref.current, decisions.researchIndex]);

  return (
    !Number.isFinite(decisions.researchIndex) ?
      <Box className={classes.loanRow} display="flex" style={{ marginTop: 10 }} flexWrap='wrap' flexDirection="row" justifyContent="end">
        {
          research.map((loan, i) =>
            <Option
              loan={loan}
              key={i}
              onSelect={() => handleUpdate({name: 'researchIndex', value: i})}
            />)
        }
      </Box> :
      <OptionDetails
        loan={research[decisions.researchIndex]}
        onCancel={() => {
          handleUpdate({name: 'researchIndex', value: undefined});
          handleUpdate({name: 'researchConfirmed', value: false});
        }}
        handleChange={handleChange}
        onSave={() => handleUpdate({ name: 'researchConfirmed', value: true })}
        value={decisions.marketResearch}
        confirmed={decisions.researchConfirmed}
        inputRef={ref}
        cash={cash}
        marketResearch={marketResearch}
      />

  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      marketResearch: (units) => marketResearch(units),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeOptionDecision);

MakeOptionDecision.propTypes = {
  coresim: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  marketResearch: PropTypes.func.isRequired
};
