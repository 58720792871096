import React, { useMemo } from 'react';
import {API} from 'aws-amplify';
import {startOver} from '../coresim/reducers/decisions';
import {getTickAction} from '../coresim/state';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from '@material-ui/core';
import {getPreload} from '../coresim/state/index';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {initState, initVitals} from '../coresim/state/configuration';
import {initState as initDecisions} from '../coresim/state/team/decisions';

const NextQuarter = (props) => {
  async function moveQuarter() {
    try {
      props.startOver();
      await API.put('state',
        `/simulations/${
          props.coresim.id
        }/tick/quarter/${props.quarter + 1}`
      );

      if (props.admin) {
        props.reloadAdmin()
      }
      toast.success('Successfully started next quarter.');
    } catch (e) {
      toast.error(e.toString());
    }
  }

  async function reset() {
    const updatedSim = Object.assign(props.coresim);

    const updatedTeams = updatedSim.teams;
    updatedTeams.map((team) => {
      team.configuration = initState;
      team.vitalsHistory = [initVitals];
      team.decisionHistory = [];
      team.decisions = initDecisions;
      return '';
    });

    const newSimulation = Object.assign(props.coresim);
    newSimulation.quarter.counter = 0;
    newSimulation.simStarted = false;
    newSimulation.completed = false;

    try {
      props.startOver();
      await API.put('mongo', `/sims/${props.coresim._id}`, {body: newSimulation});
      if (props.admin) {
        props.reloadAdmin()
      }
      toast.success('Successfully restarted sim');
    } catch (e) {
      toast.error(e.toString());
    }
  }

  const [exportName, exportUrl, exportFile] = useMemo( () => {
    let exportText = 'Pre-assign Room Name,Email Address\n';
    const teams = props.coresim.teams;

    for(const team of teams) {
      for(const member of team.members) {
        exportText += `${team.teamName},${member.email}\n`;
      }
    }

    const file = new Blob([exportText], {type: 'text/csv'});
    const url = URL.createObjectURL(file);
    const name = `${props.coresim.name}-zoom-export.csv`;

    return [name, url, file];
  }, [props.coresim]);

  const lastQuarter = props.coresim.quarter.counter > props.coresim.quarters.length - 1 ? true : false;

  return (
    <React.Fragment>
      <div>
        <Button disabled={lastQuarter} variant="contained" color="primary" onClick={moveQuarter}>Next Quarter</Button>
        <Button style={{marginLeft: 20}} variant="contained" color="primary" href={exportUrl} download={exportName}>Zoom Export</Button>
        <Button  style={{marginLeft: 20, display: props.admin ? 'inline-block' : 'none'}} variant="contained" color="primary" onClick={reset}>Reset</Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // quarter: state.coresim.quarter.counter,
    // coresim: state.coresim,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateQuarter: () => getTickAction(),
      startOver: (state) => startOver(state),
      getPreload: (data) => getPreload(data),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NextQuarter);

NextQuarter.propTypes = {
  sim: PropTypes.object,
  updateQuarter: PropTypes.func,
  startOver: PropTypes.func,
  admin: PropTypes.bool,
  reloadAdmin: PropTypes.func,
  coresim: PropTypes.object,
  quarter: PropTypes.number
};
