import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, Divider, CardActions, Button, makeStyles, CircularProgress} from '@material-ui/core';
import AnnouncementItem from "./AnnouncementItem";
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  viewLink: {
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
});

const AnnouncementList = (props) => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let abortObj = {aborted: false};
    onLoad(abortObj);
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  //TODO: review - have to wait for load every time component is mounted - should this be earlier?
  async function onLoad(abortObj) {
    try {
      if (props.courseId && props.courseId.length > 0) {
        const announcements = await API.get('blackboard', `/courses/${props.courseId}/announcements`);
        if (!abortObj || !abortObj.aborted) {
          setAnnouncements(announcements);
        }
      }
    } catch (e) {
      if (e !== 'No current team') {
        toast.error('Unable to retrieve announcements from Blackboard');
      }
    } finally {
      setLoading(false);
    }
  }

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        {props.title}
        <Box mt={2}>
          {
            loading ? <Box textAlign="center"><CircularProgress /></Box> : announcements.length > 0 ?
              announcements.map((announcement, i) => {
                return (<AnnouncementItem key={`announcement-${i}`} item={announcement}/>)
              }):
              <p>There are no announcements at this time.</p>
          }
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.viewLink}>
        {announcements.length > 0 ? <Button
          size="small"
          href={announcements[0] ? announcements[0].externalUrl : ''}
          target="_blank"
          rel="noopener noreferrer">View All</Button> : null}
      </CardActions>
    </Card>
  )
}

AnnouncementList.propTypes = {
  /** Title component to be displayed over the data elements */
  title: PropTypes.element,
  courseId: PropTypes.string
};

export default AnnouncementList
