import React from 'react';
import {
  Button,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Radio,
  Tooltip
} from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import { useArrayReducer } from '../../helpers/useArrayReducer';
import MinicaseOption from './MinicaseOption';
import StackRoute from '../../routing/StackRoute';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
  Redirect
} from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
  item: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0,.05)',
    }
  }
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
  onChange: (e) =>updateHandler(name)(e.target.value),
});

const newOption = {
  optionTitle: '',
  optionDescription: '',
  optionOutputs: [],
  optionResult: '',
};

const OptionsAdmin = ({options, optionsHandlers, disabled}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const handleSave = (delegateHandler) => (value) => {
    delegateHandler(value);
    history.push(match.url);
  }

  return (
    <Switch>
      <StackRoute
        path={`${match.url}/options/create/`}
        label='Create Option'
      >
        <Typography variant="h4">
          Create Option
        </Typography>
        <MinicaseOption
          initialOption={newOption}
          onSave={handleSave(optionsHandlers.add)}
          disabled={disabled}
        />
      </StackRoute>

      {options && options.map((o, i) => (
        <StackRoute
          key={i}
          path={`${match.url}/options/${i}/`}
          label='Edit Option'
        >
          <Typography variant="h4">
            Edit Option
          </Typography>
          <MinicaseOption
            initialOption={o}
            onSave={handleSave(optionsHandlers.replace(i))}
            disabled={disabled}
          />
        </StackRoute>
      ))
      }
      <Redirect path={`${match.url}/`} to={match.url} />
    </Switch>
  )
}

OptionsAdmin.propTypes = {
  options: PropTypes.array.isRequired,
  optionsHandlers: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired
}

const MinicaseForm = ({onSubmit, initialValue, disabled}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const [form, updateHandler] =
    useFormReducer(initialValue,
      (init) => ({
        ...init,
      })
    );

  const [options, optionsHandlers] =
  useArrayReducer(
    initialValue.options,
    (initOptions) => [...initOptions]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit({
        ...form,
        options,
      });
    }
  }

  return (
    <React.Fragment>
      <OptionsAdmin
        options={options}
        optionsHandlers={optionsHandlers}
        disabled={disabled}
      />
      <ValidatorForm onSubmit={handleSubmit}>
        <fieldset disabled={disabled} className={classes.fieldset}>
          <Group stack>
            <Field
              {...getProps('miniCase', form, updateHandler)}
              label="Title"
              disabled={disabled}
              autoFocus />
            <Field
              {...getProps('description', form, updateHandler)}
              label="Description"
              disabled={disabled}
              multiline
              rows={4}
              fullWidth
            />
          </Group>
          <Group
            stack
            title='Options'
          >
            {
              <List>
                {options.map((o,i) =>
                  <ListItem
                    classes={{container: classes.item}}
                    key={i}
                  >
                    <ListItemIcon>
                      <Tooltip title="Set default" aria-label="Set default">
                        <Radio
                          checked={form.defaultOption === i}
                          value={i}
                          onChange={(e) => {
                            updateHandler('defaultOption')(Number(e.target.value));
                          }}
                        />
                      </Tooltip>

                    </ListItemIcon>
                    <ListItemText
                      primary={o.optionTitle}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => history.push(`${location.pathname}/options/${i}`)}
                        edge="end"
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => optionsHandlers.remove(i)}
                        edge="end"
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            }
            <Button
              style={{alignSelf: 'flex-start'}}
              variant="text"
              color="primary"
              onClick={() => history.push(`${location.pathname}/options/create`) }
              disabled={disabled}
            >
          + Add
            </Button>
          </Group>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled}
          >
          Save
          </Button>
        </fieldset>

      </ValidatorForm>
    </React.Fragment>
  )
}

MinicaseForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MinicaseForm;
