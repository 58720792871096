import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import ConfigurationForm from './ConfigurationForm';
import loans from '../../config/loans';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const InitialConfiguration = {
  qaSensitivity: 2,
  combinedQASensitivity: 1,
  productDevelopmentSensitivity: 0.75,
  demandIndex: [0.4, 0.5, 0.25, 0.85, 0.35, 0.6, 0.3, 0.75],
  stocks: 100000,
  loanTypes: loans,
  plantWorth: 500000,
  initialValuation: 2180000, //TODO: auto calculate
  initialStockPrice: 21.8,
};

const CreateConfiguration = ({onReload}) => {
  const [loading, setLoading] = useState(false);
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);
    try {
      await API.post('mongo', '/configurations', {body: form});
      toast.success('Success!');
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create Configuration
      </Typography>
      <ConfigurationForm
        onSubmit={onSubmit}
        initialValue={InitialConfiguration}
        disabled={loading}
      />
    </Box>
  );
}

CreateConfiguration.propTypes = {
  onReload: PropTypes.string.isRequired
}
export default CreateConfiguration;
