import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import UserForm from './UserForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {useBreadcrumbActions} from '../../providers/breadcrumbProvider';

const getInitialUser = () => ({
  firstName: '',
  lastName: '',
  email: '',
  teams: []
});

const CreateUser = ({onReload}) => {

  const [loading, setLoading] = useState(false);
  const [initialUser, setInitialUser] =
    useState(getInitialUser());
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);

    try {
      const createdUser = await API.post('mongo', '/users', {body: {
        ...form
      }});

      toast.success(`Successfully created user (${createdUser.firstName} ${createdUser.lastName})`);
      setInitialUser(getInitialUser());
      if (onReload) {
        onReload();
      }
      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create User
      </Typography>
      <UserForm
        onSubmit={onSubmit}
        initialValue={initialUser}
        disabled={loading}
      />
    </Box>
  );
}

CreateUser.propTypes = {
  onReload: PropTypes.string.isRequired
}

export default CreateUser;
