import React, {useState} from 'react';
import {
  Button,
  makeStyles,
  IconButton} from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import 'moment-timezone';
import {PhotoCamera} from '@material-ui/icons';
import RichTextEditor from 'react-rte';
import placeholderImage from '../../assets/image-placeholder.svg'

const useStyles = makeStyles(() => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
  input: {
    display: 'none'
  }
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withRequiredValidator: true,
  validators: ['required'],
  errorMessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
});

const NewsArticleForm = ({onSubmit, initialValue, disabled}) => {
  const classes = useStyles();
  const [editImage, setEditImage] = useState(false);

  const [form, updateHandler] =
    useFormReducer(initialValue,
      (init) => ({
        ...init,
        format: init.format || 'markdown',
        image: init.image || placeholderImage,
        headline: init.headline,
        story: RichTextEditor.createValueFromString(init.story, 'markdown')
      })
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({
        ...form,
        story: form.story.toString('markdown'),
        newImage: editImage // if this is true, we will actually update the image in s3
      });
    }
  }

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);

    fileReader.onload = (e) => {
      updateHandler('image')(e.target.result);
      setEditImage(true);
    };
  };

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Strikethrough', style: 'STRIKETHROUGH'},
      {label: 'Monospace', style: 'CODE'},
      {label: 'Underline', style: 'UNDERLINE'},

    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'},
      {label: 'Blockquote', style: 'blockquote'},
    ]
  }

  return (

    <ValidatorForm onSubmit={handleSubmit}>
      <fieldset disabled={disabled} className={classes.fieldset}>
        <Group stack>

          <Field
            {...getProps('headline', form, updateHandler)}
            label='Headline'
            onChange={(e) =>updateHandler('headline')(e.target.value)}
            disabled={disabled}
            autoFocus />

          <RichTextEditor
            id='story'
            name='story'
            toolbarConfig={toolbarConfig}
            value={form.story}
            placeholder='Story'
            onChange={updateHandler('story')}
          />

          <input
            accept=".jpg,.png,.jpeg"
            className={classes.input}
            id="icon-button-photo"
            onChange={handleCapture}
            type="file"
          />
          {editImage ? (
            <img src={form.image} width={250} height="auto" style={{color: '#eeeeee'}} />
          ) : (
            <object data={`${process.env.REACT_APP_S3_IMAGES}/${form._id}`} width={250} height="auto" type="image/png">
              <img src={form.image} alt="placeholder image" width={250} height="auto" style={{color: '#eeeeee'}} />
            </object>
          )}
          <label htmlFor="icon-button-photo">
            <IconButton color="primary" component="span">
              <PhotoCamera />
              <p>Select Image</p>
            </IconButton>
          </label>

        </Group>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
        Save
        </Button>

      </fieldset>

    </ValidatorForm>
  )
}

NewsArticleForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
};

export default NewsArticleForm;
