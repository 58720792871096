import React, { useMemo } from 'react';
import { Slider, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const ValueSlider = ({
  id,
  label,
  items,
  onChangeValue,
  defaultValue,
  value,
  ...remaining}) => {
  const lookupValue = (value) => {
    const index = items.findIndex((i) => i.value === value);
    return index === -1 ? undefined : index;
  };

  const computedDefaultValue = useMemo(
    () => lookupValue(defaultValue)
    , []
  );

  const computedValue = useMemo(
    () => lookupValue(value)
    , [value]
  );

  const marks = items.map((v, i) => ({
    value: i,
    label: v.label,
  }));

  return (
    <FormControl style={{minWidth: 226.667}}>
      {label &&
      <FormLabel htmlFor='id'>
        {label}
      </FormLabel>
      }
      <Slider
        {...remaining}
        id='id'
        marks={marks}
        valueLabelFormat={(n) => {
          return isNaN(n) ? '' : marks[n].label
        }}
        min={0}
        max={marks.length - 1}
        step={null}
        valueLabelDisplay='auto'
        onChangeCommitted={ (e, v) => onChangeValue &&
          onChangeValue(items[v].value)
        }
        defaultValue={computedDefaultValue}
        value={computedValue}
      />
    </FormControl>

  );
}

ValueSlider.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onChangeValue: PropTypes.func,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
};

export default ValueSlider;
