import * as React from 'react';
import PropTypes from 'prop-types';
import CurriculumContext from './CurriculumContext';
import useCurriculumContext from './useCurriculumContext';

const CurriculumContextProvider = ({children, curriculum}) => {
  return (
    <CurriculumContext.Provider value={curriculum}>
      {children}
    </CurriculumContext.Provider>
  );
};

CurriculumContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  curriculum: PropTypes.arrayOf(PropTypes.string),
};

export default CurriculumContextProvider;
export {CurriculumContext, useCurriculumContext};
