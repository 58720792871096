import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import {API} from 'aws-amplify';
import { toast } from 'react-toastify';
import _orderby from 'lodash.orderby';
import SelectField from '../Forms/SelectField';
import PropTypes from 'prop-types';
import ClearButton from '../Forms/ClearButton';
import SelectFlexBox from '../Forms/SelectFlexBox';
import UserRoutes from '../User';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router';
import SelectionContextProvider from '../../providers/selectionProvider';
import BuildIcon from '@material-ui/icons/Build';

const NoChairs = () => (
  <Typography>
    <em>No chairs selected</em>
  </Typography>
);

const ChairDisplay = ({chair, onRemove, disabled}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <Typography
        variant="body1">
        {chair.firstName} {chair.lastName}
      </Typography>
      <ClearButton
        onRemove={onRemove}
        disabled={disabled}
        tooltipTitle='Remove Chair'/>
    </Box>
  );
}

ChairDisplay.propTypes = {
  chair: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const ChairsDisplay = ({chairs, onRemoveChair, disabled}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    {
      chairs.map((chair, i) => (
        <ChairDisplay
          key={i}
          chair={chair}
          onRemove={() => onRemoveChair(i)}
          disabled={disabled}
        />
      ))
    }
  </Box>
);

ChairsDisplay.propTypes = {
  chairs: PropTypes.array.isRequired,
  onRemoveChair: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const ChairsList = ({chairs, onRemoveChair, disabled}) => {
  return (
    <Box py={1}>
      {
        chairs && chairs.length ?
          <ChairsDisplay
            chairs={chairs}
            onRemoveChair={onRemoveChair}
            disabled={disabled}
          /> : <NoChairs />
      }
    </Box>
  );
};

ChairsList.propTypes = {
  chairs: PropTypes.array.isRequired,
  onRemoveChair: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const SelectChair = ({chairs, onCancel, onSelected}) => {
  const [allChairs, setAllChairs] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    let aborted = false;
    async function load() {
      let chairsArray = [];
      try {
        // this is a hack, needs its own endpoint
        const loadedUsers = await API.get('mongo', '/users');
        loadedUsers.map((user, i) => {
          if (user.role === 'chair') {
            chairsArray.push(user);
          }
        })
        if (!aborted) {
          const newAllChairs =  _orderby(chairsArray, ['firstName']);
          setAllChairs(newAllChairs);
        }
      } catch(e) {
        toast.error()
      }
    }

    load();

    return () => aborted = true;
  }, []);

  useEffect(() => {
    setSelected();
  }, [chairs, allChairs]);

  const filteredChairs = useMemo( () => {
    const lookup = new Set(chairs.map((t) => t._id));
    return allChairs.filter((v) => !lookup.has(v._id));
  }, [allChairs, chairs]); // will be ordered, since allChairs is ordered

  const selectItems = useMemo( () => filteredChairs.map((t) => t.firstName),
    [filteredChairs]);

  return (
    <SelectFlexBox>
      <SelectField
        id="selectChair"
        style={{minWidth: 150}}
        label="Select Chair"
        items={selectItems}
        value={selected}
        onChange={ (e) => setSelected(e.target.value)}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={selected === undefined}
        onClick={ () => onSelected(filteredChairs[selected]) }
      >
        Select
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </SelectFlexBox>
  );
};

SelectChair.propTypes = {
  chairs: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired
}

const Chairs = ({chairs, onChairsChanged, onRemove, disabled}) => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <Box>
      <ChairsList
        chairs={chairs}
        onRemoveChair={onRemove}
        disabled={disabled}
      />
      <Switch>
        <Route
          path={`${match.url}/chairs`}
        >
          <SelectionContextProvider
            selection={chairs}
            onSelectionChanged={onChairsChanged}
          >
            <UserRoutes
              title="Chairs"
              isSelect
              filter={(user) => user.role === 'chair'}
            />
          </SelectionContextProvider>
        </Route>
      </Switch>
      <Button
        variant="text"
        color="primary"
        onClick={() => history.push(`${match.url}/chairs`)}
        disabled={disabled}
      >
        <BuildIcon style={{marginRight: '5px'}}/> Manage 
      </Button>
    </Box>
  );

};

Chairs.propTypes = {
  chairs: PropTypes.array.isRequired,
  onChairsChanged: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}
export default Chairs;
