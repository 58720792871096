import React, { useMemo } from 'react';
import {connect} from 'react-redux';
import HighlightContextProvider, { useHighlightContext } from './highlightProvider';
import CurriculumContextProvider from './curriculumProvider';
import PropTypes from "prop-types";
import highlights from './highlights';
import { useRouteMatch } from 'react-router';

const getHighlights = (coresim, settings, teamIndex, match) => {
  const result = [];
  for(let prop in highlights) {
    const highlight = highlights[prop];

    if (highlight.test(coresim, settings, teamIndex, match)) {
      result.push(highlight.tag);
    }
  }

  return result;
};

const getCurriculumForHighlights = (coresim, highlights) => {
  if (!coresim.bbCourse || !coresim.bbCourse.curriculum) {
    return []
  }
  return highlights.reduce((curriculum, highlight) => {
    const moduleId = coresim.bbCourse.curriculum[highlight];
    if (moduleId && curriculum.indexOf(moduleId) === -1) {
      curriculum.push(moduleId);
    }
    return curriculum
  }, []);
};

const EmbeddedCurriculumContext = ({
  children, coresim
}) => {
  const highlights = useHighlightContext();
  const curriculum = useMemo(
    () => getCurriculumForHighlights(coresim, highlights),
    [highlights]
  );

  // only update curriculum when the actual values change
  // otherwise we may cause downstream re-rendering
  // when highlights change, but curriculum mapping doesn't
  // (perhaps the changed highlight wasn't mapped for the sim)
  const stableCurriculum = useMemo(
    () => curriculum,
    [curriculum.reduce((result,c) => `${result}+${c}`, '')]
  );

  return (
    <CurriculumContextProvider
      curriculum={stableCurriculum}>
      {children}
    </CurriculumContextProvider>
  );
};

EmbeddedCurriculumContext.propTypes = {
  children: PropTypes.node.isRequired,
  coresim: PropTypes.object.isRequired,
};

const HighlightCurriculumContext =
  ({settings, coresim, teamIndex, children}) => {
    const match = useRouteMatch();
    const highlights = useMemo(
      () => getHighlights(coresim, settings, teamIndex, match),
      [settings, coresim, match.url]
    );

    // only update highlights when the actual values change
    // otherwise we may cause downstream re-rendering
    // when settings or coresim changes, but highlights don't
    const stableHighlights = useMemo(
      () => highlights,
      [highlights.reduce((result, h) => `${result}+${h}` , '')]
    );

    return (
      <HighlightContextProvider highlights={stableHighlights}>
        <EmbeddedCurriculumContext coresim={coresim}>
          {children}
        </EmbeddedCurriculumContext>
      </HighlightContextProvider>
    );
  };

HighlightCurriculumContext.propTypes = {
  settings: PropTypes.object.isRequired,
  coresim: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  teamIndex: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  coresim: state.coresim,
  settings: state.settings,
  teamIndex: state.teamIndex.teamIndex,
});

export default connect(mapStateToProps)(HighlightCurriculumContext);
