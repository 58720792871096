import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import SelectField from './SelectField';
import {toast} from 'react-toastify';
 
class SelectValidator extends ValidatorComponent {
 
  render() {
    const { isValid } = this.state;
    const { value, ...rest} = this.props;
    
    if (!isValid){
      toast.error(`${this.props.label} Configuration Selection Required.`);
    }

    return (
      <div>
        <SelectField
          error={!isValid}
          helperText={this.getErrorMessage()}
          value={value}
          {...rest}
          ref={(r)=> { this.input = r;}}
        />
      </div>
    );
  }
}
 
export default SelectValidator;