import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Setting from './containers/Setting';
import ChairHome from "./chair/ChairHome";
import Login from './containers/Login';
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import Sandbox from './containers/Sandbox';
import Ending from './containers/Ending'
import PropTypes from 'prop-types';

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({component: C, props: cProps, ...rest}) => {
  return (<Route
    {...rest}
    render={(props) =>
      cProps.isAuthenticated === true ?
        <MainLayout childProps={cProps}>
          <C {...props} {...cProps} />
        </MainLayout> :
        <Login {...props} {...cProps} />
    }
  />
  );
};


const EmptyRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const Routes = ({childProps}) => (
  <Switch>
    <DashboardRoute exact
      path='/'
      component={ChairHome}
      props={childProps} />

    <DashboardRoute
      path='/leaderboard'
      component={Ending}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/login'
      component={Login}
      props={childProps}
      exact />

    <DashboardRoute
      path='/setting'
      component={Setting}
      exact
      props={childProps}
    />
    <DashboardRoute
      path='/sandbox'
      component={Sandbox}
      exact
      props={childProps}
    />

    <EmptyRoute component={NotFound} />
  </Switch>
);

export default Routes;

Routes.propTypes = {
  childProps: PropTypes.object
}

DashboardRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object
}

EmptyRoute.propTypes = {
  component: PropTypes.elementType
}
