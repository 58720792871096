import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import logo from '../assets/wbw-logo.png';
import {Auth} from 'aws-amplify';

const useStyles = makeStyles((theme) =>( {
  title: {
    textAlign: 'center'
  },
  container: {
    margin: '10% auto'
  },
  buttonStyle: {
    margin: '1rem'
  }
}))

const ErrorPage = (props) => {
  const classes = useStyles()

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid className={classes.title} item xs={12}>
        <img src={logo} alt='washington business week logo'/>
      </Grid>
      <Grid className={classes.title} item xs={12}>
        <Typography>
          {props.errorData}
        </Typography>
        <br/>
        <Typography>If you believe you have received this in error, please make contact with your Administrator to get you set up for WBW.</Typography>

        <Button
          className={classes.buttonStyle}
          variant='contained'
          onClick={props.onLogin}
          color="primary">
          Retry
        </Button>

        <Button
          className={classes.buttonStyle}
          variant='contained'
          onClick={async () => {
            await Auth.signOut();
            props.childProps.handleLogout();
          }} color="primary">
          Logout
        </Button>

      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  childProps: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  errorData: PropTypes.string.isRequired
}

export default ErrorPage;
