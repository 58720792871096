import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const SelectFlexBox = ({children, ...props}) => {
  const classes = useStyles();
  return (<Box display="flex"
    className={classes.root}
    flexDirection="row"
    alignItems="center"
    flexWrap="wrap"
    {...props}>{children}</Box>)
}

/* Box wrapper that turns it into flex and adds left margin to all children */
SelectFlexBox.propTypes = {
  children: PropTypes.node.isRequired
}


export default SelectFlexBox;
