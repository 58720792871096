import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Divider, CardActions, Button, makeStyles, CircularProgress} from '@material-ui/core';
import CalendarItem from './CalendarItem';
import {API} from 'aws-amplify';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import 'moment-timezone';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  viewLink: {
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }
}));

const CalendarList = (props) => {
  const [calendar, setCalendar] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let abortObj = {aborted: false};
    onLoad(abortObj);
    return () => {
      abortObj.aborted = true;
    }
  }, []);

  useEffect(() => {
    moment.tz.setDefault(props.timezone); // consider putting this somewhere central
  }, [props.timezone]);

  //TODO: review - have to wait for load every time component is mounted - should this be earlier?
  async function onLoad(abortObj) {
    try {
      if (props.courseId && props.courseId.length > 0) {
        const calendar = await API.get('blackboard', `/courses/${props.courseId}/calendar`);
        if (!abortObj || !abortObj.aborted) {
          setCalendar(calendar);
        }
      }
    } catch (e) {
      if (e !== 'No current team') {
        toast.error('Unable to retrieve schedule from Blackboard');
      }
    } finally {
      if (!abortObj || !abortObj.aborted) {
        setLoading(false);
      }
    }
  }
  const calendarViewUrl = calendar[0] ? calendar[0].externalUrl : '';
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        {props.title}
        <Box mt={props.title ? 3 : 0}>
          {
            loading ? <Box textAlign="center"><CircularProgress /></Box> : calendar.length > 0 ?
              calendar.map((calendarItem, i) => {
                let separator;
                if (i !== 0) {
                  separator = <Divider classes={{root: classes.divider}}/>
                }
                return (<div key={i}>{separator}<CalendarItem first={i === 0} key={`calendar-item-${i}`} item={calendarItem}/></div>)
              }) :
              <p>There is no schedule at this time</p>
          }
        </Box>
      </CardContent>
      <Divider />
      <CardActions className={classes.viewLink}>
        {calendar.length > 0 ? <Button size="small" href={calendarViewUrl} target="_blank" rel="noopener noreferrer">View All</Button>
          : null}
      </CardActions>
    </Card>
  )
}

CalendarList.propTypes = {
  /** Title component to be displayed over the data elements - not used in mobile view */
  title: PropTypes.element,
  courseId: PropTypes.string,
  timezone: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    timezone: state.coresim.timezone,
  };
};

export default connect(mapStateToProps)(CalendarList);
