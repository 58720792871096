import {HammerWrench, Settings} from 'mdi-material-ui';

export default [
  {
    type: 'Build Annex',
    description: 'Planning ahead, you decide to invest a large amount of capital into increasing your production capacity next quarter. However, this will result in a large increase in defect rate as you struggle to scale your quality assurance.',
    term: 1,
    productionCapacityFactor: 2.0,
    defectRateFactor: 1.4,
    icon: HammerWrench,
    price: 125000
  },
  {
    type: 'Machinery Upgrades',
    description: 'You are considering ordering top-of-the-line manufacturing equipment from Shenzhen - though expensive up front, it will result in decreased defects and a small improvement in production capacity.',
    term: 1,
    productionCapacityFactor: 1.25,
    defectRateFactor: 0.85,
    icon: Settings,
    price: 75000
  },
];
