import LoanDecision from '../../components/InputCards/LoanDecision';
import LoanPayment from '../../components/InputCards/LoanPayment';
import ProductionPlant from '../../components/InputCards/ProductionPlant';
import MarketResearch from "../../components/InputCards/MarketResearch";
import ChangeBusinessName from "../../components/InputCards/ChangeBusinessName";
import numberFilterHoc from '../../helpers/numberFilterHoc';
import numberBoundsHoc from '../../helpers/numberBoundsHoc';
import DecisionsField from '../simulation/DecisionsField';
import numberCurrencyHoc from "../../helpers/numberCurrencyHoc";

const decimals = (
  decimal,
  options = {
    minValue: undefined,
    maxValue: undefined,
    allowNegative: false,
    currency: false,
  }
) => {
  const { minValue, maxValue, allowNegative, currency } = options;
  let Component = numberFilterHoc(DecisionsField, {
    allowNegative,
    decimal,
  });

  if (minValue !== undefined || maxValue !== undefined) {
    Component = numberBoundsHoc(Component, {
      minValue, maxValue
    });
  }

  if (currency) {
    Component = numberCurrencyHoc(Component, { dollarOnly: decimal !== 2 });
  }

  return Component;
};

const businessVariableData = [
  {
    value: 'Dollar Amount',
    title: 'Total Quality Control',
    color: '#fce4ec',
    subtitle: 'Enter the quarterly dollar amount to invest',
    definition: `<p>Total quality management (TQM) is the continual process of detecting and reducing or eliminating in manufacturing, streamlining supply chain management, improving the customer experience, and ensuring that employees are up to speed with training. Total quality management aims to hold all parties involved in the production process accountable for the overall quality of the final product or service.</p>
    <br/>
    <p><b>HINT:</b> Think about how much you would like to spend per unit on quality control to avoid defective units and multiply this by the number of units you are producing.</p>`,
    icon: 'qualityManagement',
    task: 'qualityManagement',
    video: 'https://www.youtube.com/embed/os2M7hHGOHc',
    component: decimals(0, { currency: true }),
  },
  {
    value: 'Unit',
    title: 'Production of Units',
    color: 'white',
    subtitle: 'Products become available for sale next quarter.',
    definition: 'How many units would you like to produce next quarter?',
    icon: 'unitsProduced',
    task: 'unitsProduced',
    video: 'https://www.youtube.com/embed/xbI6Pe0xkLw',
    component: decimals(0),
  },
  {
    value: 'Plants',
    title: 'Production Plant Size',
    color: '#fce4ec',
    subtitle: 'Expand your manufacturing.',
    definition: 'Your ability to manufacture new units is dependent on the capabilities of your manufacturing facility.',
    icon: 'addedPlantCapacity',
    task: 'addedPlantCapacity',
    video: 'https://www.youtube.com/embed/LClUHTWV38Y',
    // disabled: true,
    action: ProductionPlant,
    component: decimals(0),
  },
  {
    value: 'ea',
    title: 'Price of Units',
    color: 'white',
    subtitle: 'Enter the next quarters MSRP for the product.',
    definition: 'The price of your product is one of the most important decisions you will make as a business.',
    icon: 'price',
    task: 'price',
    video: 'https://www.youtube.com/embed/y6xmCTohYaY',
    component: decimals(2, { currency: true }),
  },
  {
    value: 'ea',
    title: 'Advertising',
    color: '#d1c4e9',
    subtitle: 'Enter the quarterly dollar amount.',
    definition: 'If you do not spend any money on advertising, it will be very difficult for anyone to even know you exist.',
    icon: 'megaphone',
    task: 'advertising',
    video: 'https://www.youtube.com/embed/Q0If3O-j8S0',
    component: decimals(0, { currency: true }),
  },
  {
    value: 'ea',
    title: 'Salespeople',
    color: 'white',
    subtitle: `Salespeople make an immediate impact, starting this quarter. \nUse a negative number to fire salespeople.`,
    definition: 'Investing in salespeople is a proven way of increasing velocity in growing your business.',
    icon: 'salespeople',
    task: 'salespeople',
    video: 'https://www.youtube.com/embed/jcFyAQxhp_M',
    component: decimals(0, { allowNegative: true }),
  },
  {
    value: 'ea',
    title: 'Product Development',
    color: 'white',
    subtitle: 'Enter the quarterly dollar amount.',
    definition: 'Investing in product development results in a better overall product, with fewer defects, and a more favorable outlook from the public due to your innovative process.',
    icon: 'productDevelopment',
    task: 'productDevelopment',
    video: 'https://www.youtube.com/embed/iSQtonU92zo',
    component: decimals(0, { currency: true }),
  },
  {
    value: 'ea',
    title: 'Market Research',
    color: '#d1c4e9',
    subtitle: 'Understand your competitors.',
    definition: 'Market research is where you can gain insights into your competition, and how they are targeting the market. You can learn from their mistakes and their successes.',
    icon: 'marketResearch',
    task: 'marketResearch',
    video: 'https://www.youtube.com/embed/kqxYtVtqvtk',
    action: MarketResearch,
    component: decimals(0),
  },
  {
    value: 'ea',
    title: 'Dividends',
    color: 'white',
    subtitle: 'Enter the quarterly dollar payout amount.',
    definition: `
    <p>Shareholders expect a return on investment - dividends are crucial to keeping good relationships with your investors and the Board of Directors.</p>
    <br/>
    <p>Most companies experiencing WBW for the first time have questions about dividends. Consider the following examples to help your team with this input.</p>
    <br/>
    <p><b>Qtr. 1 ONLY</b></p>
    <p>A company has started the simulation with $500,000 cash on hand. The company would use the following equation to determine dividends:</p>
    <ul style="padding-inline-start: 40px;">
      <li>Cash on hand x Industry Dividend Yield (0.025) / 4 quarters in a year</li>
      <li>$500,000 x 0.025 / 4 = $3,125</li>
    </ul>
    <br/>
    <p><b>FOR THE REMAINING QUARTERS</b></p>
    <p>A company refers to the balance sheet report to find that their business valuation is $1,200,000. They would use the following calculation to determine dividends:</p>
    <ul style="padding-inline-start: 40px;">
      <li>Business Valuation x Industry Dividend Yield (0.025) / 4 quarters in a year</li>
      <li>$1,200,000 x 0.025 / 4 = $7500</li>
    </ul>`,
    icon: 'dividends',
    task: 'dividends',
    video: 'https://www.youtube.com/embed/pPxqjR-lNsk',
    component: decimals(0, { currency: true }),
  },
  {
    value: 'ea',
    color: '#b2dfdb',
    title: 'Take Loans',
    subtitle: 'Enter the dollar amount to take out.',
    definition: 'Every business needs some liquidity from time to time. How much do you want to take?',
    icon: 'loans',
    task: 'loansTaken',
    video: 'https://www.youtube.com/embed/oAcZH-9SNSE',
    action: LoanDecision,
    component: decimals(2, { currency: true }),
  },
  {
    value: 'ea',
    title: 'Pay Loans',
    subtitle: 'Enter the dollar amount to pay to loans',
    definition: 'You need to be sure to pay back your loans. How much do you want to pay back?',
    icon: 'loans',
    task: 'loansPaid',
    video: 'https://www.youtube.com/embed/-E0rtJrmUsw',
    action: LoanPayment,
    component: decimals(2, { currency: true }),
  },
  {
    value: 'ea',
    title: 'Doing Business As',
    subtitle: 'Rebranding your company.',
    definition: 'Change the operating name of your company',
    icon: 'swap-horizontal-circle-outline',
    task: 'businessAs',
    video: 'https://www.youtube.com/embed/sVhFvZfApEo',
    action: ChangeBusinessName,
    component: decimals(2),
  },
]

export default businessVariableData;
