/**
 *
 *
 * Handles the caching of AWS Amplify API get requests, using Amplify's Cache.
 * If the request, keyed by the API name, path, and init parameters, exists in the browser cache, that response will be
 * returned. Responses that haven't been stored will be cached before the response is returned.
 *
 * https://docs.amplify.aws/lib/utilities/cache/q/platform/js#api-reference
 *
 */
import {Cache,API} from 'aws-amplify';

// https://docs.amplify.aws/lib/utilities/cache/q/platform/js#configuration-parameters
const config = {
  keyPrefix: 'wbw-frontend-get'
};

const cache = Cache.createInstance(config);

/**
 * @function buildCachedAPIGet
 *
 * @param {Object} cache - instantiated AWS Amplify Cache object
 * @return {function(apiName: string, path: string, init: Object=, expiresIn: number=)}
 *
 * @example
 *
 *  const cache = Cache.createInstance({keyPrefix: 'wbw-frontend-sims', storage: 'SessionStorage'});
 *  const sessionCachedGet = buildCachedAPIGetRequest(cache);
 *  const courses = await sessionCachedGet('blackboard', '/courses');
 *  console.log(courses) // [{"id":"_4_1","name":"WBW Simulation 1"},{"id":"_3_1","name":"Microeconomics 101"}]
 */
const buildCachedAPIGet = (cache) => async (apiName, path, init, expiresIn) => {
  let cacheKey = `${apiName}::${path}`;
  if (init) {
    cacheKey = `${cacheKey}::${JSON.stringify(init)}`;
  }
  const cachedRequest = cache.getItem(cacheKey);
  if (cachedRequest) {
    return cachedRequest;
  }

  const liveResponse = await API.get(apiName, path, init);
  // There are two parameters that can also be added to the set item call --
  //  priority and expiration time. Expiration time is configurable in this section, if none is passed to the
  //  function it will be the default TTL of 72 hours.
  // Priority, if we want to add it later, will determine what gets booted out of the cache first if it starts to run
  //  out of memory. RN it'll use the default of 5. (https://docs.amplify.aws/lib/utilities/cache/q/platform/js#setitem)
  let cacheSetOpts = {};
  if (expiresIn && expiresIn > 0) {
    let expiration = Date.now() + expiresIn * 1000;
    cacheSetOpts = { expires: expiration };
  }
  cache.setItem(cacheKey, liveResponse, cacheSetOpts)
  return liveResponse
}

/**
 * @function cachedAPIGet
 * Retrieve an api response (keyed by its AWS Amplify api name, path, and init object) from the wbw-frontend-get-* cache.
 *   If there is no value found in the cache, the value will be retrieved from the live endpoint using API.get()
 *   and its resulting value will stored in the cache, with the expiration time set to either the default 72 hours or
 *   the time set by the expiresIn parameter.
 *
 * @param {string} apiName -
 * @param {string} path - API Path
 * @param {Object} [init] - AWS Amplify Init object - commonly used for query params.
 * @param {number} [expireIn] - When the cached response will expire. Only used if the request is not yet in the cache.
 *
 * @example
 *
 *  const courses = await cachedAPIGetRequest('blackboard', '/courses', null, 3600); // expires in 1 hr, not default 72
 *  console.log(courses) // [{"id":"_4_1","name":"WBW Simulation 1"},{"id":"_3_1","name":"Microeconomics 101"}]
 */
export const cachedAPIGet = buildCachedAPIGet(cache);

/**
 * @function clearDefaultCache
 *
 * Clear all items from the cache prefixed by wbw-frontend-get
 */
export const clearDefaultCache = () => (cache.clear())
