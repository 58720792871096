import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Setting from './containers/Setting';
import ResetPassword from "./containers/ResetPassword";
import Login from './containers/Login';
import EmptyLayout from './layouts/EmptyLayout';
import Sandbox from './containers/Sandbox';
import PropTypes from 'prop-types';

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({component: C, props: cProps, ...rest}) => {
  return (<Route
    {...rest}
    render={(props) =>
      <EmptyLayout childProps={cProps}>
        <C {...props} {...cProps} />
      </EmptyLayout> 
    }
  />
  );
};


const EmptyRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const AuthRoutes = ({childProps}) => (
  <Switch>
    <DashboardRoute exact 
      path='/' 
      component={Login} 
      props={childProps} />

    <DashboardRoute
      path='/reset'
      component={ResetPassword}
      props={childProps}
      exact
    />

    <DashboardRoute
      path='/login'
      component={Login}
      props={childProps}
      exact />

    <DashboardRoute
      path='/setting'
      component={Setting}
      exact
      props={childProps}
    />
    <DashboardRoute
      path='/sandbox'
      component={Sandbox}
      exact
      props={childProps}
    />

    <EmptyRoute component={NotFound} />
  </Switch>
);

export default AuthRoutes;

AuthRoutes.propTypes = {
  childProps: PropTypes.object
}

DashboardRoute.propTypes = {
  component: PropTypes.elementType,
  props: PropTypes.object
}

EmptyRoute.propTypes = {
  component: PropTypes.elementType
}
