import React, {Component} from 'react';
import {API} from 'aws-amplify';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class Sandbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cases: [],
      simId: 'a3360b1b-e4ec-41b2-a0bb-4d697f42611f'
    };
  }
  handleSubmit = async event => {
    event.preventDefault();

    let body = {
      coresimId: this.state.simId,
      quarter: 2,
      dueDate: new Date()
    }
    try {
      await API.post('scheduler', '/ticks', {body})
    } catch(e) {
      console.log('Error: ', e);
    }
  }
  render() {
    return (
      <div>
        <button onClick={this.handleSubmit}>Schedule Tick</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    everything: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // increment: () => increment(),
      // decrement: () => decrement()
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sandbox);
