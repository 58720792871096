import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {Checkbox, makeStyles, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'moment-timezone';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  checked: {
    'fontSize': '3.5em',
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },

  },
}));

function Checks(props) {
  const classes = useStyles();
  const quarter = props.sim.quarters[props.sim.quarter.counter];
  const [endDate, setEndDate] = useState();

  useEffect(
    () => {
      moment.tz.setDefault(props.sim.timezone);
      setEndDate(quarter.end && moment(quarter.end));
    }
    , [props.sim.timezone, quarter]
  );

  return (
    <div className="block" style={{flexGrow: 1, paddingLeft: 12, paddingBottom: 20, display: 'flex', flexDirection: 'column'}}>
      <Typography style={{fontWeight: 'bolder', fontSize: '1.5em', paddingTop: 12}}>
              Quarterly Decisions
      </Typography>
      {
        quarter && endDate &&
          <Typography
            color="error"
            style={{
              fontSize: '1em',
              textAlign: 'left',
            }}
          >
            Responses due {endDate.calendar()}
          </Typography>
      }
      <Grid container justify='space-between' alignItems='center' style={{paddingTop: 20}}>
        <Grid item xs={8}>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/vitals`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bolder', fontSize: '1.0em' }}>
              Quarterly Decisions
              </Typography>
              <p>
              Your team&apos;s quarterly decisions are what determine your company&apos;s financial success.
              </p>
            </div>
          </Link>
        </Grid>
        <Grid item xs={3} style={{marginRight: '10px'}}>
          <Link style={{ textDecoration: 'none', color: 'inherit'}} to={`/vitals`}>
            <Checkbox
              className={classes.checked}
              checked={
                props.sim.teams[props.teamIndex].decisions.decisionsComplete === true
              }
              value="decisionsComplete"
              disabled={true}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
          </Link>
        </Grid>
      </Grid>
      <Grid container justify='space-between' alignItems='center' style={{paddingTop: 20}}>
        <Grid item xs={8}>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/case/details`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bolder', fontSize: '1.0em' }}>
              Mini-Case
              </Typography>
              <p>
              Business is dynamic and fluid - react in real-time to seize opportunities, or put out fires.
              </p>
            </div>
          </Link>
        </Grid>
        <Grid item xs={3} style={{marginRight: '10px'}}>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/case/details`}>
            <Checkbox
              className={classes.checked}
              checked={
                quarter === undefined || !quarter.minicase ||
                props.sim.teams[props.teamIndex].decisions.minicaseComplete === true
              }
              disabled={true}
              value="minicaseComplete"
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    sim: state.coresim,
    teamIndex: state.teamIndex.teamIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Checks);

Checks.propTypes = {
  decisions: PropTypes.object,
  sim: PropTypes.object,
  teamIndex: PropTypes.number,
};

