import React from "react";
import RouteMenuItem from "./RouteMenuItem";
import { adminRoutes } from "../containers/data/adminRoutes";
import { version } from "../../package.json";

const Admin = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        {adminRoutes.map((route, i) => {
          return (
            <RouteMenuItem route={route.route} title={route.title} key={i} />
          );
        })}
      </div>
      <p style={{ marginTop: "2rem" }}>
        <b>WBW Version:</b> {"V" + version}
      </p>
    </>
  );
};

export default Admin;
