import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const Title = ({ title }) => {
  return (
    <React.Fragment>
      <Typography
        variant="h5"
        style={{ display: "block", fontWeight: "bolder" }}
      >
        {title}
      </Typography>
    </React.Fragment>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
