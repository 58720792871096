const MiniNavRoutes = {
  vitals: [
    {
      title: 'Case Vitals',
      page: 'Mini Case',
      previous: '/dashboard',
      next: '/vitals/board',
    },
    {
      title: 'Case Vitals',
      page: 'Case board',
      previous: '/vitals',
      next: '/vitals/history',
    },
    {
      title: 'Case Vitals',
      page: 'Case History',
      previous: '/vitals/board',
      next: '',
    },
  ],
  setup: [
    {
      title: 'Setup Options',
      page: 'Quantity',
      previous: '',
      next: '/setup/quality',
    },
    {
      title: 'Setup Options',
      page: 'Quality',
      previous: '/setup/quantity',
      next: '/setup/undecided',
    },
    {
      title: 'Setup Options',
      page: 'Standard',
      previous: '/setup/quality',
      next: '/setup/undecided',
    },
  ],
  teams: [
    {
      title: 'Teams Admin Panel',
      page: 'Team List',
      previous: '/dashboard',
      next: '/admin/teams/create',
    },
    {
      title: 'Teams Admin Panel',
      page: 'Create Teams',
      previous: '/admin/teams',
      next: '',
    },
  ],
  users: [
    {
      title: 'Users Admin Panel',
      page: 'Users List',
      previous: '/dashboard',
      next: '/admin/users/create',
    },
    {
      title: 'Users Admin Panel',
      page: 'Create Users',
      previous: '/admin/users',
      next: '',
    },
  ],
  simulations: [
    {
      title: 'Simulation Admin Panel',
      page: 'Simulations List',
      previous: '/dashboard',
      next: '/admin/simulations/create',
    },
    {
      title: 'Simulation Admin Panel',
      page: 'Create Simulation',
      previous: '/admin/simulations',
      next: '',
    },
  ],
  minicases: [
    {
      title: 'Mini-Case Admin Panel',
      page: 'Mini-Case List',
      previous: '/dashboard',
      next: '/admin/mini/create',
    },
    {
      title: 'Mini-Case Admin Panel',
      page: 'Mini-Case List',
      previous: '/admin/mini/',
      next: '',
    },
  ],
  case: [
    {
      title: 'Mini-Case',
      page: 'Mini-Case Details',
      previous: '/dashboard',
      next: '/case/justification',
    },
    {
      title: 'Mini-Case',
      page: 'Mini-Case Justification',
      previous: '/case/details',
      next: '',
    },
  ],
};

export default MiniNavRoutes;
