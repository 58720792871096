import SettingsIcon from '@material-ui/icons/Settings';
import BookIcon from '@material-ui/icons/Book';
import VideoCallIcon from '@material-ui/icons/VideoCall';

export default (sim, teamIndex) => {

  return [
    {
      label: 'Home',
      Icon: SettingsIcon,
      to: '/',
    },
    {
      label: 'Leaderboard',
      Icon: BookIcon,
      to: '/leaderboard',
    },
  ];
}
