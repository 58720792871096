// takes in a number and returns a formatted string.
const formatter = (value, decPoint) => {
  let tempX = value;
  let switchX = false;


  if ( tempX < 0 ) {
    tempX *= -1;
    switchX = true;
  }


  const depth = Math.floor(Math.log10(tempX));

  let newX = 0;

  if (switchX) {
    newX *= -1;
  }

  const depthModus = depth % 3;

  if ( depthModus === 2 ) {
    newX = (Math.ceil(value) / (Math.pow(10, Math.floor(depth)-2))).toFixed(decPoint);
  } else if ( depthModus === 1 ) {
    newX = (Math.ceil(value) / (Math.pow(10, Math.floor(depth)-1))).toFixed(decPoint);
  } else if ( depthModus === 0 ) {
    newX = (Math.ceil(value) / (Math.pow(10, Math.floor(depth)))).toFixed(decPoint);
  }

  if ( depth >= 12) {
    return `$${newX}T`;
  }
  if ( depth >= 9) {
    return `$${newX}B`;
  }
  if ( depth >= 6) {
    return `$${newX}M`;
  }
  if ( depth >= 3) {
    return `$${newX}K`;
  }
  if ( depth >= 0) {
    return `$${newX}`;
  }
};

export default formatter;
