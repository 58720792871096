import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import PaletteIcon from '@material-ui/icons/Palette';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toggleThemeMode, swapThemeColors} from '../coresim/reducers/settings';

function Settings(props) {
  const [value, setValue] = React.useState('female');
  return (
    <div>
      <Typography variant="subtitle1">Color Palette Settings</Typography>
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemIcon>
                <PaletteIcon />
              </ListItemIcon>
              <ListItemText primary="Dark Mode" />
              <ListItemSecondaryAction>
                <Switch
                  onChange={(e, checked) => props.toggleThemeMode(checked)}
                  checked={props.settings.darkMode}
                />
              </ListItemSecondaryAction>
            </ListItem>


          </List>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Swap Colors</FormLabel>
            <RadioGroup aria-label="color" name="color1"
              value={value}
              onChange={(event, checked) => {
                setValue(event.target.value); props.swapThemeColors(checked);
              }}
            >

              <FormControlLabel value="secondary" control={<Radio />} label="Second Option" />
              <FormControlLabel value="tertiary" control={<Radio />} label="Third Option" />
              <FormControlLabel value="primary" control={<Radio />} label="Default" />

              <FormControlLabel value="fourth" control={<Radio />} label="Fourth" />
              <FormControlLabel value="fifth" control={<Radio />} label="Fifth" />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      swapThemeColors: (checked) => swapThemeColors(checked),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
