import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  link: {
    color: 'black',
    textDecoration: 'none',
    backgroundColor: 'grey',
  },
}));

const SmartLink = ({external, to, ...remaining}) => {
  return external ?
    <a
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      {...remaining}
    /> :
    <Link to={to} {...remaining} />;
};

SmartLink.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string,
};

const MenuLink = ({entry, onClick}) => {
  const EntryIcon = entry.Icon;
  const classes = useStyles();
  const text = <ListItemText primary={entry.label} />;
  const Badge = entry.badge;

  return entry.display !== false ? (
    <SmartLink
      id={entry.id}
      external={entry.external}
      to={entry.to}
      className={classes.link}
      onClick={() => {
        if (entry.action) {
          entry.action();
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <EntryIcon />
        </ListItemIcon>
        {
          Badge && entry.displayBadge ?
            <Badge>
              {text}
            </Badge> :
            text
        }
      </ListItem>
    </SmartLink>
  ) : null;
};

MenuLink.propTypes = {
  entry: PropTypes.object.isRequired,
  onClick: PropTypes.func, // additional onClick functionality for the link, such as closing a menu drawer
}

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim,
    teamIndex: state.teamIndex.teamIndex,
  };
};

export default connect(mapStateToProps)(MenuLink);
