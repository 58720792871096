import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button } from '@material-ui/core';
import SimulationForm from './SimulationForm';
import moment from 'moment';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import uuidv4 from 'uuid/v4';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DateTimePicker from '../../components/widgets/DateTimePicker';

const useStyles = makeStyles((theme) => ({
  minDialogHeight: {
    minHeight: '65vh'
  },
  oldStartDate: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1)
  }
}));

const StartDateModal = ({open, closeModal, oldStartDate, saveStartDate}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (loading) {
      saveStartDate(startDate);
    }
  }, [loading]);


  return (<Dialog open={open} onClose={closeModal} classes={{'paper': classes.minDialogHeight}} fullWidth={true} maxWidth="md">
    <DialogContent>
      <Box textAlign="center" mt={2}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Typography>Previous simulation start date: </Typography>
          <Typography variant="body2" className={classes.oldStartDate}>{oldStartDate ? moment(oldStartDate).format('lll') : ''}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" mt={2} alignItems="center">
          <Typography>New simulation start date:</Typography>
          <DateTimePicker
            disabled={loading}
            onChange={setStartDate}
            value={startDate.getTime()}/>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => setLoading(true)}
        disabled={loading}
        variant="contained"
        color="secondary">
        Save
      </Button>
    </DialogActions>
  </Dialog>)
}

StartDateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveStartDate: PropTypes.func.isRequired,
  oldStartDate: PropTypes.number,
}

const CloneSimulation = ({onReload}) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [initialSimulation, setInitialSimulation] = useState();
  const [simulation, setSimulation] = useState();
  const [dateModal, setDateModal] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    let aborted = false;
    const load = async () => {
      try {
        const result = await API.get('mongo', `/sims/${id}`);
        if (!aborted) {

          //unset properties before cloning
          result.id = uuidv4();
          result.name = result.name + ' (copy)';
          delete result._id;
          result.quarter = {
            counter: 0
          }
          result.teams = [];
          result.advisors = [];
          result.completed = false;
          result.simStarted = false;
          result.active = true;

          //if Q1 has a start date, toggle start date modal
          if (result.quarters[0].start) {
            setDateModal(true);
            setInitialSimulation(result);
          } else {
            setSimulation(result);
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setError("Simulation not found");
        } else {
          throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    load();
    return () => aborted = true;
  }, [id]);

  const saveNewStart = (newStart) => {
    const quarters = [];
    const newStartMillis = newStart.getTime();
    let timeDiff = newStartMillis - initialSimulation.quarters[0].start ;
    for (let i = 0; i < initialSimulation.quarters.length; i++) {
      let oldQuarter = initialSimulation.quarters[i];

      let currentQuarter = {
        start: oldQuarter.start,
        end: oldQuarter.end,
        article: oldQuarter.article,
        minicase: oldQuarter.minicase,
        demand: oldQuarter.demand,
      }

      quarters.push(currentQuarter);

      if (!currentQuarter.start && !currentQuarter.end) {
        continue;
      }

      currentQuarter.start = currentQuarter.start ? currentQuarter.start + timeDiff : currentQuarter.start;
      currentQuarter.end = currentQuarter.end ? currentQuarter.end + timeDiff : currentQuarter.end;
    }

    var updatedSim = {...initialSimulation};
    updatedSim.quarters = quarters;

    setSimulation(updatedSim);
    setDateModal(false);
  }

  const onSubmit = async (form) => {
    setSaving(true);
    try {
      await API.post('mongo', `/sims`, {body: form});
      toast.success('Successfully cloned simulation!');
      if (onReload) {
        onReload();
      }
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);

      history.push('/admin/simulations');
    }
  }

  const notLoadingNoError = !loading && !error;

  return (
    <Box>
      <Typography variant="h4">
        Clone Simulation
      </Typography>
      {
        error
      }
      { notLoadingNoError && <StartDateModal
        open={dateModal}
        closeModal={() => {setDateModal(false)}}
        oldStartDate={initialSimulation && initialSimulation.quarters && initialSimulation.quarters[0].start}
        saveStartDate={saveNewStart}
      />}
      { notLoadingNoError && simulation &&
        <SimulationForm
          onSubmit={onSubmit}
          initialValue={simulation}
          disabled={saving || loading}
        />
      }
    </Box>
  );
}

CloneSimulation.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default CloneSimulation;
