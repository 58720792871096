import {combineReducers} from 'redux';
import decisions from './decisions';
import vitals, {getVitalsTickAction} from './vitals';
import combineReducerArray, {getAddAction} from '../combineReducerArray';
import configuration from '../configuration';

const NAME_TEAM = 'team/name';
const TICK = 'team/tick';

const decisionsPrefix = 'decisions';
const vitalsPrefix = 'vitals';

const combineDecisionsArraryReducer = combineReducerArray(decisionsPrefix);
const combineVitalsArrayReducer = combineReducerArray(vitalsPrefix);

const initState = {
  name: '',
};

const decisionHistory = combineDecisionsArraryReducer(decisions);
const vitalsHistory = combineVitalsArrayReducer(
  vitals(configuration(undefined, {}))
); // HACK: this won't work once we allow for config changes

const combined = combineReducers({
  configuration,
  decisions,
  decisionHistory,
  vitalsHistory,
});

export default (state = getTeamForConfig(), action) => {
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case NAME_TEAM:
      return {
        ...state,
        name: action.payload,
      };
    case TICK: {
      const newHistory = decisionHistory(
        state.decisionHistory,
        getAddAction(decisionsPrefix)(state.decisions)
      );
      const newVitals = vitalsHistory(
        state.vitalsHistory,
        getAddAction(vitalsPrefix)(
          vitals(state.configuration)(
            state.vitalsHistory[state.vitalsHistory.length - 1],
            getVitalsTickAction(newHistory)
          )
        )
      );
      return {
        ...state,
        decisions: decisions(undefined, {}),
        decisionHistory: newHistory,
        vitalsHistory: newVitals,
      };
    }
    default:
      return {
        ...state,
        ...combined(state, action),
      };
  }
};

export function getTeamForConfig(config = configuration(undefined, {})) {
  const delegatedReducers = combined(undefined, {});

  return {
    ...initState,
    ...delegatedReducers,
    configuration: config,
    vitalsHistory: vitalsHistory(
      delegatedReducers.vitalsHistory,
      getAddAction(vitals(config)(undefined, {})) // init vitals
    ),
  };
}

export function getTeamTickAction() {
  return {
    type: TICK,
  };
}

export function getTeamNameAction(name) {
  return {
    type: NAME_TEAM,
    payload: name,
  };
}
