import React from "react";
import Table from "../components/Table";

const Research = (props) => {
  return(
    <div>
      <Table {...props} />
    </div>
  )
}

export default Research;