
import convertNumberInput from './convertNumberInput';
import expenses from '../formulas/expenses';

const getExpenses = (salesStaff) => (state, config) => {
  let salesPeople = convertNumberInput(state.salespeople);
  if (salesPeople < 0) {
    salesPeople = Math.max(salesPeople, -salesStaff);
  }

  return convertNumberInput(state.qualityManagement) +
         convertNumberInput(state.advertising) +
         convertNumberInput(state.productDevelopment) +
         convertNumberInput(state.marketResearch) +
         convertNumberInput(state.changeName) +
         convertNumberInput(state.dividends) + 
         convertNumberInput(state.addedPlantCapacity) +
         convertNumberInput(state.loansPaid) +
         expenses(config.expenseLevellingPoint)(config.expenseLevelledCost)(
           convertNumberInput(state.unitsProduced)
         ) +
         (salesPeople > 0 ? salesPeople * config.salesHireCost :
           salesPeople * config.salesFireCost) +
         (salesStaff + salesPeople) * config.salesQuarterlySalaray;
};

export default getExpenses;
