import React, {useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Auth} from 'aws-amplify';
import {toast} from 'react-toastify';
import Progress from '../components/Progress';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  'paper': {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'avatar': {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  'form': {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  'submit': {
    margin: theme.spacing(2, 0, 1),
  },
}));

const ResetPassword = (props) => {
  const [resetCodeScreen, setResetCodeScreen] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const emailRef = useRef();

  const classes = useStyles();

  const handleSubmitEmail = async (event) => {
    event.preventDefault();

    if (!email) { // TODO switch to using a validated control library
      setError(true);
      setErrorText('Email is required');
      return;
    }

    setError(false);
    setErrorText('');

    try {
      setLoading(true);
      await Auth.forgotPassword(email);
    } catch (e) {

      // only checking this error to obfuscate information given to the user.
      if (e.name === 'LimitExceededException'){

        toast.error(`Error generating reset code: ${e.message}`);
        props.history.push('/');

      } 
          
    } finally {
      setLoading(false);
      emailRef.current && emailRef.current.focus();
      setResetCodeScreen(true);
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(false);
    setErrorText('');

    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      toast.success('Password changed');
    } catch (e) {
      if (e) {
        toast.error('Incorrect Code Enter');
        throw e;
      }
      return;
    } finally {
      setEmail('');
      setPassword('');
      setLoading(false);
      emailRef.current && emailRef.current.focus();
      props.history.push('/');
    }

  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {resetCodeScreen === false ? (
        <div className={classes.paper} style={{marginTop: 200}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
            Reset Your Password
            </Typography>
          </div>
          <form className={classes.form} noValidate onSubmit={handleSubmitEmail}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={error}
              helperText={errorText}
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="username"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              inputRef={emailRef}
            />
            <Button
              onClick={handleSubmitEmail}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              Request Reset Code
              {loading && <Progress variant="overlay" size='1.5em' />}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" to="/">
                Back to Login?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      ) : (

        <div className={classes.paper} style={{marginTop: 200}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography>
            If WBW has an account for your email, a reset code will be sent. Enter it here to create a new password. 
            </Typography>
          </div>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={error}
              helperText={errorText}
              id="code"
              label="One Time Code"
              name="code"
              type="code"
              autoComplete="username"
              autoFocus
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Your Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <Button
              onClick={handleSubmit}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!(code.length > 0 &&
                password.length > 7 &&
                password === confirmPassword)}
            >
            Change Password
              {loading && <Progress variant="overlay" size='1.5em' />}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" to="/">
                Back to Login?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Container>
  );
};

ResetPassword.propTypes = {
  backToLoading: PropTypes.func,
  history: PropTypes.object.isRequired
};

export default ResetPassword;
