import React, {useRef, useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Box, Button, Typography, makeStyles, Tooltip} from '@material-ui/core';
import formatCurrency from '../../helpers/formatCurrency';
import HourglassFull from '@material-ui/icons/HourglassFull';
import {Balloon, Clock} from 'mdi-material-ui';

const minLoanAmount = 400000;

const useStyles = makeStyles((theme) => ({
  infoTip: {
    margin: theme.spacing(0, .5),
    padding: theme.spacing(.2, .6),
    borderRadius: 5,
    color: 'white',
    cursor: 'help',
  },
  positiveTip: {
    backgroundColor: 'green',
  },
  negativeTip: {
    backgroundColor: 'red',
  },
  valueLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(.5),
  },
  loanBox: {
    '&:hover': {
      backgroundColor: 'rgba(250, 0, 250, 0.08)',
    },
  },
  boxRow: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '95%',
      justifyContent: 'space-around',
    }
  },
  loanRow: {
    [theme.breakpoints.up('md')]: {
      '& div:not(:last-child)': {
        marginRight: theme.spacing(1)
      }
    }
  }
}));

const Loan = ({ loan, onSelect }) => {
  const classes = useStyles();
  const Icon = loan.icon === 'Balloon' ? Balloon : Clock;
  return (
    <Box
      className={classnames(classes.loanBox, classes.boxRow)}
      p={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      border="1px solid #ccc"
      borderRadius={5}
      style={{ marginBottom: 10 }}
      onClick={onSelect}
    >
      <Icon />

      <Typography component="header" variant="h6">
        {loan.type}
      </Typography>

      <Button color="secondary">
        More Info
      </Button>
    </Box>
  );
};

Loan.propTypes = {
  loan: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

const LoanDetails = ({
  confirmed,
  loan,
  onCancel,
  onSave,
  maxLoan,
  handleChange,
  value,
  inputRef,
  Component
}) => {
  const classes = useStyles();
  const Icon = loan.icon === 'Balloon' ? Balloon : Clock;
  const interest = `${(loan.interest * 100).toFixed(1)}%`;
  const servicing = `${(loan.servicingRate * 100).toFixed(1)}%`;
  const interestPenalty = `${(loan.interestPenalty * 100).toFixed(1)}%`;

  const hasError = Boolean(value && Number(value) > maxLoan);

  // calcluation from https://www.thebalance.com/loan-payment-calculations-315564
  const discountFactor = (rate) => (payments) => {
    const temp = Math.pow(1 + rate, payments);
    return (temp - 1) / (rate * temp);
  };

  const interestRate = loan.interest;
  const loanTerm = loan.term
  const loanAmount = value || 0;
  const rate = interestRate / 4;
  let quarterlyPayment = (loanAmount) / discountFactor(rate)(loanTerm);
  const showEstPayment = (!hasError && Number.isFinite(value) );

  return (
    <Box maxWidth={400} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Icon />
        <Typography component="header" variant="h6">
          {loan.type}
        </Typography>
      </Box>
      <Typography variant="body1">
        {loan.description}
      </Typography>
      <Box py={.5} display="flex" flexDirection="row" alignItems="center">
        <Box display='flex' flexDirection='column' justifyContent='flex-start'>
          <Typography variant="caption">
          Max Avail. <strong>{formatCurrency(maxLoan)}</strong>
          </Typography>
          {showEstPayment?
            <Tooltip title={`Estimated Quarterly Payment`}>
              <Typography variant="caption">
          Est. Pmt. <strong>{formatCurrency(quarterlyPayment)}</strong>
              </Typography>
            </Tooltip>
            : null }
        </Box>
        <Tooltip title={`${interest} annual interest, compounded quarterly`}>
          <Typography
            variant="caption"
            className={classnames(classes.infoTip, classes.positiveTip)}
          >
            {interest}
          </Typography>
        </Tooltip>
        <Tooltip title={`term: ${loan.term} quarters`}>
          <Typography
            variant="caption"
            className={classnames(classes.infoTip, classes.positiveTip)}
          >
            <HourglassFull style={{ fontSize: 'inherit' }} />{loan.term}
          </Typography>
        </Tooltip>
        { loan.payoff &&
          <Tooltip title={`ballon becomes due after ${loan.payoff} quarters`}>
            <Typography
              variant="caption"
              className={classnames(classes.infoTip, classes.positiveTip)}
            >
              <HourglassFull style={{ fontSize: 'inherit' }} />{loan.payoff}
            </Typography>
          </Tooltip>
        }
        <Tooltip title={`initial servicing rate: ${servicing}`}>
          <Typography
            variant="caption"
            className={classnames(classes.infoTip, classes.positiveTip)}
          >
            {servicing}
          </Typography>
        </Tooltip>

        <Tooltip title={`${interestPenalty} penalty interest after paying late`}>
          <Typography
            variant="caption"
            className={classnames(classes.infoTip, classes.negativeTip)}
          >
            {interestPenalty}
          </Typography>
        </Tooltip>
      </Box>
      {confirmed ?
        <Typography variant="body2">
          Amount Taken: {formatCurrency(value)}
        </Typography> :
        <Component
          id="loansTaken"
          name="loansTaken"
          label="Loan Amount"
          onNumberChange={(value) => handleChange('loansTaken')(value)}
          value={value}
          error={hasError}
          helperText={hasError && 'Loan amount is greater than maximum available.'}
          inputRef={inputRef}
          fullWidth
        />
      }

      <Box
        pt={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onCancel && onCancel()}
        >
          Cancel
        </Button>
        {!confirmed &&
          <Button
            variant="contained"
            color="primary"
            disabled={hasError || !Number.isFinite(value)}
            onClick={() => onSave && onSave()}
          >
            Take Loan
          </Button>
        }
      </Box>

    </Box>
  );
};

LoanDetails.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  loan: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  maxLoan: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  inputRef: PropTypes.object.isRequired,
  Component: PropTypes.elementType.isRequired,
};

const MakeLoanDecision = ({
  coresim,
  decisions,
  teamIndex,
  handleUpdate,
  handleChange,
  Component
}) => {
  const vitalsHistory = coresim.teams[teamIndex].vitalsHistory;
  const valuation = vitalsHistory[vitalsHistory.length - 1].valuation;
  const config = coresim.teams[teamIndex].configuration;
  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    let aborted = false;
    if (ref.current && Number.isFinite(decisions.loansIndex) && !aborted) {
      ref.current.focus();
    }
    return () => aborted = true;
  }, [ref.current, decisions.loansIndex]);

  return (
    !Number.isFinite(decisions.loansIndex) ?
      <Box className={classes.loanRow} display="flex" style={{ marginTop: 10 }} flexWrap='wrap' flexDirection="row" justifyContent="end">
        {
          config.loanTypes.map((loan, i) =>
            <Loan
              loan={loan}
              key={i}
              onSelect={() => handleUpdate({name: 'loansIndex', value: i})}
            />)
        }
      </Box> :
      <LoanDetails
        maxLoan={Math.max(minLoanAmount,
          Math.max(0, valuation) * config.loanTypes[decisions.loansIndex].maxLoanToValuation
        )
        }
        loan={config.loanTypes[decisions.loansIndex]}
        onCancel={() => {
          handleUpdate({name: 'loansIndex', value: undefined});
          handleUpdate({name: 'loanConfirmed', value: false});
          handleChange('loansTaken')(0);
        }}
        handleChange={handleChange}
        handleUpdate={handleUpdate}
        onSave={() => handleUpdate({ name: 'loanConfirmed', value: true })}
        value={decisions.loansTaken}
        confirmed={decisions.loanConfirmed}
        inputRef={ref}
        Component={Component}
      />

  );
};

MakeLoanDecision.propTypes = {
  coresim: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  Component: PropTypes.elementType.isRequired,
};

const Value = ({ name, value }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row">
      <Typography className={classes.valueLabel} variant="body1">
        {name}:
      </Typography>
      <Typography variant="body1">
        {value}
      </Typography>
    </Box>
  );
};

Value.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const ActiveLoan = ({ loan, liability, minimumDue, term, interest }) => {
  return (
    <Box py={1} px={2}>
      <Value name="Type" value={loan.type} />
      <Value name="Balance" value={formatCurrency(liability)} />
      <Value name="Due" value={formatCurrency(minimumDue)} />
      <Value name="Quarters" value={term >= 0 ? term : 'Expired'} />
      <Value name="Interest" value={`${(interest * 100).toFixed(2)}%`} />
    </Box>
  );
};

ActiveLoan.propTypes = {
  loan: PropTypes.object.isRequired,
  liability: PropTypes.number.isRequired,
  minimumDue: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  interest: PropTypes.number.isRequired
};

const LoanDecision = (props) => {
  const vitalsHistory = props.coresim.teams[props.teamIndex].vitalsHistory;
  const latest = vitalsHistory[vitalsHistory.length - 1];
  const hasLoan = latest.liabilitiesBankLoan > 0;
  const loan = props.coresim.teams[props.teamIndex].configuration.loanTypes[latest.loansIndex];
  const quartersRemaining = loan ? loan.term - latest.loanQuarters : 0;

  return hasLoan ?
    <ActiveLoan
      liability={latest.liabilitiesBankLoan}
      minimumDue={latest.loanPaymentDue}
      loan={loan}
      term={quartersRemaining}
      interest={latest.interestRate}
    /> :
    <MakeLoanDecision {...props} />;
};

LoanDecision.propTypes = {
  coresim: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
};

export default LoanDecision;
