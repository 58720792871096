import stepCounter from './stepCounter';
import settings from './settings';
import decisions from './decisions';
import {combineReducers} from 'redux';
import teamIndex from './teamIndex';
import coresim from '../state';
import getExpenses from './getExpenses';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const RESET_STATE = 'reset';

const coresimConfig = {
  key: 'coresim',
  storage: storage,
  whitelist: ['quarter']
};

const combined = combineReducers({
  stepCounter,
  settings,
  decisions,
  coresim: persistReducer(coresimConfig, coresim),
  teamIndex,
  expenses: (state = 0) => state
});

const initState = {
  ...combined(undefined, {}),
};

export default (state = initState, action) => {
  if (action === undefined) {
    return state;
  }

  if (action.type === RESET_STATE) {
    return {...initState};
  }

  const newState = {...state, ...combined(state, action)};

  if (Number.isFinite(newState.teamIndex.teamIndex)) {
    const team = newState.coresim.teams[newState.teamIndex.teamIndex];
    const vitalsHistory = team.vitalsHistory;
    newState.expenses = vitalsHistory.length ?
      getExpenses(vitalsHistory[vitalsHistory.length - 1].salespeople)(newState.decisions, team.configuration) : 0;
  }
  return newState;
};

export function resetState() {
  return {
    type: RESET_STATE
  }
}

