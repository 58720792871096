import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {VictoryChart, VictoryLine, VictoryAxis, VictoryTheme, VictoryLabel, VictoryPortal, VictoryContainer} from 'victory';
import PercentDiff from '../../components/PercentDiff';
import PropTypes from 'prop-types';
import formatter from '../../helpers/graphNumberFormat';

const graphWidth = '80%';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    margin: 'auto',
    marginTop: '30px',
  },
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
  },
  noDataTitle: {
    color: '#aeaeae',
    position: 'absolute',
    top: '25%',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      top: '15%',
    },
  },
}));

const StockPrice = (props) => {
  const classes = useStyles();
  const vitalsHistory = props.coresim[props.teamIndex].vitalsHistory;

  const latest = vitalsHistory[vitalsHistory.length - 1];
  const stockData = [];

  vitalsHistory.map((vital, i) => {
    stockData.push({x: `Q${i}`, y: parseInt(vital.stock, 10)});
    return '';
  });

  const previous = vitalsHistory[vitalsHistory.length - 2] || latest.stock;
  const yAxisMax = Math.max.apply(null, stockData.map((data) => data.y));
  const curVal = new Intl.NumberFormat(undefined, {style: 'currency', currency: 'USD'}).format(latest.stock);

  return (
    (stockData.length === 1 ?
      <div className={(classes.divStyle, classes.class)} style={{position: 'relative'}}>

        <Typography className={classes.titleStyle}>Stock Price</Typography>
        <Typography align='center' className={classes.noDataTitle}>
        Charts Available after Q1
        </Typography>

        <svg style={{width: '100%', height: 'auto', color: 'rgba(0,0,0,.1)'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z"></path>
        </svg>
      </div> :
      <div className={(classes.divStyle, classes.class)}>
        <Typography className={classes.titleStyle}>Stock Price</Typography>
        <Typography>{curVal}</Typography>
        <PercentDiff previous={previous.stock} latest={latest.stock} />

        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={30}
          containerComponent={<VictoryContainer maxWidth={graphWidth}/>}

        >
          <VictoryPortal>
            <VictoryAxis
              tickValues={stockData.map((x, i) => i + 1)}
              tickFormat={stockData.map((x) => x.x)}
            />
          </VictoryPortal>

          <VictoryAxis
            dependentAxis
            tickFormat={(x) => (formatter(x, 0))}
          />
          <VictoryLine
            style={{
              data: {
                stroke: '#ff9900',
                strokeWidth: 6,
                borderRadius: '3px',
              },
              labels: {
                fill: '#0a0a0a',
              },
            }}

            data={stockData}
            domain={{x: [0, stockData.length], y: [0, yAxisMax]}}
            labels={({datum}) => formatter(datum.y, 2)}
            labelComponent={<VictoryLabel
              verticalAnchor='end'
            />}
            x='x'
            y='y'
          />
        </VictoryChart>
      </div>
    )
  );
};

StockPrice.propTypes = {
  coresim: PropTypes.array,
  classes: PropTypes.object,
  teamIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim.teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockPrice);
