export default [
  {
    type: 'Balloon',
    description: 'This loan type pays off like a longer period, but the entire amount will become due in a short time. It has a medium interest and servicing fee.',
    term: 6,
    payoff: 3,
    interest: 0.0525,
    interestPenalty: .1425,
    servicingRate: .075,
    maxLoanToValuation: .2,
    icon: 'Balloon',
  },
  {
    type: 'Short Term',
    description: 'This loan type has a shorter pay period, but lower interest and servicing fee.',
    term: 3,
    interest: .0425,
    interestPenalty: .1225,
    servicingRate: .05,
    maxLoanToValuation: .2,
    icon: 'Clock',
  },
  {
    type: 'Aggressive',
    description: 'This loan type has a longer payment period, but higher interest and servicing fee. It does also allow for a larger borrowing.',
    term: 6,
    interest: .0725,
    interestPenalty: .1925,
    servicingRate: .1,
    maxLoanToValuation: .4,
    icon: 'Clock',
  },
];
