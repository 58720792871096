import { useFormReducer } from './useFormReducer';
import { useMemo, useState, useCallback } from 'react';

export default (initialValue, length, initForm) => {
  const [staticLength] = useState(length);
  const result = useMemo(
    () => new Array(staticLength), []
  );

  for(let i = 0; i < staticLength; i++) {
    result[i] = useFormReducer(
      (initialValue && initialValue[i]) || initForm,
      (init) => ({...init}));
  }

  const getDispatch = useCallback(
    (quarter) => (name) => result[quarter] && result[quarter][1](name), []
  )

  return [result.map((v) => v[0]), getDispatch];
}
