import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import {
  unitsProduced,
  addedPlantCapacity,
  qualityManagement,
  price,
  advertising,
  salespeople,
  productDevelopment,
  marketResearch,
  dividends,
  loansPaid,
  loansTaken,
  loansIndex,
  loanConfirmed,
  loanPaidConfirmed,
  researchIndex,
  researchConfirmed,
  researchPaidConfirmed,
  researchPaid,
  factoryIndex,
  factoryConfirmed,
  factoryPaidConfirmed,
  factoryPaid,
  businessAs,
  nameChange,
  nameChangeConfirmed,
  nameChangeIndex
} from '../coresim/reducers/decisions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Header from './InputCards/Header';
import SimTab from './SimTab';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: '2em',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: '46%',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const SimInputCard = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (props.coresim.quarter.counter === 0) {
      setExpanded(!expanded)
    }
  }, [])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleUpdate = ({name, value}) => {
    let updateTrimValue = value;
    switch (name) {
      case 'loansIndex':
        props.loansIndex(updateTrimValue);
        break;
      case 'loanConfirmed':
        props.loanConfirmed(updateTrimValue);
        break;
      case 'loanPaidConfirmed':
        props.loanPaidConfirmed(updateTrimValue);
        break;
      case 'researchIndex':
        props.researchIndex(updateTrimValue);
        break;
      case 'researchConfirmed':
        props.researchConfirmed(updateTrimValue);
        break;
      case 'researchPaidConfirmed':
        props.researchPaidConfirmed(updateTrimValue);
        break;
      case 'factoryIndex':
        props.factoryIndex(updateTrimValue);
        break;
      case 'factoryConfirmed':
        props.factoryConfirmed(updateTrimValue);
        break;
      case 'factoryPaidConfirmed':
        props.factoryPaidConfirmed(updateTrimValue);
        break;
      case 'businessAs':
        props.businessAs(updateTrimValue);
        break;
      case 'nameChange':
        props.nameChange(updateTrimValue);
        break;
      case 'nameChangeConfirmed':
        props.nameChangeConfirmed(updateTrimValue);
        break;
      case 'nameChangeIndex':
        props.nameChangeIndex(updateTrimValue);
        break;
      default:
        return updateTrimValue;
    }
  };

  const handleChange = (name) => (value) => {
    let changeTrimValue = value;
    switch (name) {
      case 'unitsProduced':
        props.unitsProduced(changeTrimValue);
        break;
      case 'addedPlantCapacity':
        props.addedPlantCapacity(changeTrimValue);
        break;
      case 'qualityManagement':
        props.qualityManagement(changeTrimValue);
        break;
      case 'price':
        props.price(changeTrimValue);
        break;
      case 'advertising':
        props.advertising(changeTrimValue);
        break;
      case 'salespeople':
        props.salespeople(changeTrimValue);
        break;
      case 'productDevelopment':
        props.productDevelopment(changeTrimValue);
        break;
      case 'marketResearch':
        props.marketResearch(changeTrimValue);
        break;
      case 'businessAs':
        props.businessAs(changeTrimValue);
        break;
      case 'nameChange':
        props.nameChange(changeTrimValue);
        break;
      case 'dividends':
        props.dividends(changeTrimValue);
        break;
      case 'loansPaid':
        props.loansPaid(changeTrimValue);
        break;
      case 'loansTaken':
        props.loansTaken(changeTrimValue);
        break;
      default:
        return changeTrimValue;
    }
  };

  // Create different colors for different roles that are meant
  // to answer the question

  return (
    <Card id={props.businessData.task} className={classes.card}>
      <Header
        businessData={props.businessData}
        coresim={props.coresim}
        teamIndex={props.teamIndex}
        decisions={props.decisions}
        expenses={props.expenses}
        onToolTipToggle={props.onToolTipToggle}
        handleChange={handleChange}
        handleUpdate={handleUpdate}
      />
      <SimTab idx={props.index} coresim={props.coresim} teamIndex={props.teamIndex}/>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Grid container spacing={2} direction='row' alignItems='center' justify='space-evenly'>
            <Grid item  xs={12} sm={12} md={6}>
              <Grid item>
                <Typography variant='h5' style={{fontWeight: 'bolder'}}>
                    Past Decisions
                </Typography>
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  style={{marginBottom: '10px'}}>
                  {props.coresim.teams[props.teamIndex].decisionHistory.map((sim, i) => (
                    <Grid key={i} item>
                      <Typography
                        variant='subtitle1'
                        style={{
                          display: 'inline-block',
                          margin: '10px',
                          textAlign: 'center',
                        }}>
                        {`Q${i + 1}`}
                        <Typography
                          variant='subtitle2'
                          style={{
                            width: 'max-content',
                            border: '1px solid grey',
                            borderRadius: '15%',
                            boxShadow: '2px 2px #888888',
                            fontSize: '1rem',
                            padding: '3px',
                          }}>
                          {sim[props.businessData.task]}
                        </Typography>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant='subtitle2' style={{fontWeight: 'bolder', margin: '10px 0'}}>
                Definition
                </Typography>
                <Typography
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: props.businessData.definition,
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant='subtitle2'  style={{fontWeight: 'bolder', marginBottom: '10px', textAlign: 'center'}}>
                Video Explanation
              </Typography>
              <CardMedia
                style={{
                  margin: 'auto',
                  maxWidth: 426,
                  minHeight: 240,
                  width: '100%',
                  height: '100%'
                }}
                component='iframe'
                src={props.businessData.video}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                title='Paella dish'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions
        style={{backgroundColor: 'grey', height: '20px'}}
        disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'>
          <ExpandMoreIcon style={{color: 'white', margin: 'auto'}} />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    decisions: state.decisions,
    coresim: state.coresim,
    expenses: state.expenses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      unitsProduced: (units) => unitsProduced(units),
      addedPlantCapacity: (units) => addedPlantCapacity(units),
      qualityManagement: (units) => qualityManagement(units),
      price: (units) => price(units),
      advertising: (units) => advertising(units),
      salespeople: (units) => salespeople(units),
      productDevelopment: (units) => productDevelopment(units),
      marketResearch: (units) => marketResearch(units),
      dividends: (units) => dividends(units),
      loansPaid: (units) => loansPaid(units),
      loansTaken: (units) => loansTaken(units),
      loansIndex: (index) => loansIndex(index),
      loanConfirmed: (value) => loanConfirmed(value),
      loanPaidConfirmed: (value) => loanPaidConfirmed(value),
      researchIndex: (value) => researchIndex(value),
      researchConfirmed: (value) => researchConfirmed(value),
      nameChangeIndex: (value) => nameChangeIndex(value),
      nameChangeConfirmed: (value) => nameChangeConfirmed(value),
      businessAs: (value) => businessAs(value),
      nameChange: (units) => nameChange(units),
      researchPaid: (value) => researchPaid(value),
      researchPaidConfirmed: (value) => researchPaidConfirmed(value),
      factoryIndex: (value) => factoryIndex(value),
      factoryConfirmed: (value) => factoryConfirmed(value),
      factoryPaid: (value) => factoryPaid(value),
      factoryPaidConfirmed: (value) => factoryPaidConfirmed(value),

    },
    dispatch
  );
};

SimInputCard.propTypes = {
  loansIndex: PropTypes.func.isRequired,
  loanConfirmed: PropTypes.func.isRequired,
  loanPaidConfirmed: PropTypes.func.isRequired,
  unitsProduced: PropTypes.func.isRequired,
  addedPlantCapacity: PropTypes.func.isRequired,
  qualityManagement: PropTypes.func.isRequired,
  price: PropTypes.func.isRequired,
  advertising: PropTypes.func.isRequired,
  salespeople: PropTypes.func.isRequired,
  productDevelopment: PropTypes.func.isRequired,
  marketResearch: PropTypes.func.isRequired,
  dividends: PropTypes.func.isRequired,
  loansPaid: PropTypes.func.isRequired,
  loansTaken: PropTypes.func.isRequired,
  coresim: PropTypes.object.isRequired,
  businessData: PropTypes.object.isRequired,
  teamIndex: PropTypes.number.isRequired,
  decisions: PropTypes.object.isRequired,
  expenses: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onToolTipToggle: PropTypes.func.isRequired,
  researchIndex: PropTypes.func.isRequired,
  researchConfirmed: PropTypes.func.isRequired,
  researchPaidConfirmed: PropTypes.func.isRequired,
  factoryIndex: PropTypes.func.isRequired,
  factoryConfirmed: PropTypes.func.isRequired,
  factoryPaidConfirmed: PropTypes.func.isRequired,
  nameChangeIndex: PropTypes.func.isRequired,
  nameChangeConfirmed: PropTypes.func.isRequired,
  nameChange: PropTypes.func.isRequired,
  businessAs: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SimInputCard);
