import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const DeleteModal=({handleConfirm, handleCancel, what}) => {
  return (
    <div>

      <DialogTitle id="responsive-dialog-title">{`Are you sure you want to delete ${what}?`}</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm Delete
        </Button>
      </DialogActions>

    </div>
  );
}

DeleteModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  what: PropTypes.string
}
export default DeleteModal;
