import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, Typography, TextField, Grid} from '@material-ui/core';
import tableHoc from '../../components/Tables/tableHoc';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router';
import {toast} from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import bulkUploadParseAndValidate, {
  groupUploadByTeams,
  MissingColumnsError
} from "../../helpers/bulkUploadParseAndValidate";

import {useBulkUploadProvider, actionTypes} from "../../providers/bulkUploadProvider";
import Progress from "../../components/Progress";
import {API} from "aws-amplify";
import ViewIcon from "@material-ui/icons/ImportContacts";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import CopyIcon from "@material-ui/icons/FileCopy";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fileInput: {
    marginRight: theme.spacing(2),
    '& > input': {
      padding: theme.spacing(2)
    },
  },
  blurb: {
    paddingBottom: theme.spacing(2)
  },
  submitRow: {
    display: "flex",
    alignItems: "center",
    '& div': {
      marginRight: theme.spacing(2)
    }
  }
}));

const InvalidFileBlurb = ({classes, errorCsv}) => {
  const data = new Blob([errorCsv.text], {type: 'text/csv'});
  const csvURL = window.URL.createObjectURL(data);
  console.log('errorCsv', errorCsv)
  return (
    <>
      <Typography variant="h4" className={classes.header}>
        Unable to upload CSV!
      </Typography>
      <Typography variant="body1" className={classes.blurb}>
        <a href={csvURL} download={errorCsv.name}>Download your validated spreadsheet here.</a> There will be an additional column, Errors, for rows
        that need to be corrected. <br/>
        Please fix these errors and re-upload the spreadsheet below.
      </Typography>
    </>
  )
}

const UploadFileBlurb = ({classes}) => (
  <>
    <Typography variant="h4" className={classes.header}>
      Upload a CSV spreadsheet of users and teams here.
    </Typography>
    <Typography variant="body1" className={classes.blurb}>
      All teams listed in the CSV will be newly created teams. Any users that have already been created will be
      removed from their previous teams and added to the team specified in the spreadsheet.
    </Typography>
  </>
)

const UploadFile = () => {
  const classes = useStyles();
  const ref = useRef();
  const [file, setFile] = useState();
  const [mounted, setMounted] = useState(false);
  const [errorCsv, setErrorCsv] = useState(null);
  const [parsedCsv, setParsedCsv] = useState(null);
  const [loading, setLoading] = useState(false);
  const {state, dispatch} = useBulkUploadProvider();
  const history = useHistory();
  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    setMounted(true)

    const fetchData = async () => {
      const active = (u => u.active !== false);
      const allUsers = await API.get('mongo', '/users', {});
      const allTeams = await API.get('mongo', '/teams/', {});

      setUsers(allUsers.filter(active))
      setTeams(allTeams.filter(active))

      // if the flag is set to add to existing teams, then this will be used for additional information in
      // the confirmation screen
      dispatch({type: actionTypes.SET_PREEXISTING_TEAMS, payload: allTeams.filter(active)})

      setDataLoaded(true)
      console.log("fetched users and teams")
    }

    fetchData()

    return () => setMounted(false)
  }, [setUsers, setTeams])

  const clear = () => {
    ref.current.value = "";
    if (mounted) {
      setFile(null)
      setLoading(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    dispatch({type: actionTypes.ADD_USER_CSV, payload: file})
    try {
      const [hasErrors, errorCsvText, parsedCsv, rowCount] =
        bulkUploadParseAndValidate(file.text, users, state.overrideTeam ? [] : teams)

      if (rowCount > 100) {
        toast.error(`Spreadsheet has more than 100 rows. Can only upload 100 rows at a time, your sheet has ${rowCount}. Please split up your spreadsheets.`)
        clear()
        return
      }

      setErrorCsv(errorCsvText ? {text: errorCsvText, name: `${file.name.split('.csv')[0]}-Invalid.csv`} : null)
      setParsedCsv(parsedCsv)

      if (!hasErrors) {
        const teamGroupedUsers = groupUploadByTeams(parsedCsv)
        dispatch({type: actionTypes.ADD_GROUPED_TEAM_USERS, payload: teamGroupedUsers})
        history.push('/admin/bulk-upload/confirm')
      }
    } catch (e) {
      if (e instanceof MissingColumnsError) {
        toast.error(`Missing required columns in csv: ${e.fields.join(', ')}. \nPlease download the example csv and use those columns. Columns are case sensitive.`)
      } else {
        toast.error(`Unknown error occurred, please contact support with the following error and the reproduction steps: \n ${e.toString()}`)
      }
    } finally {
      clear()
    }
  }

  const loadFile = event => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      const name = event.target.files[0].name;
      reader.onload = async (e) => {
        const text = (e.target.result)
        setFile({text, name})
      };
      reader.readAsText(event.target.files[0])
    }
  };

  return (
    <Box>
      {loading && <Progress variant="overlay" size='10em' />}
      {!errorCsv && <UploadFileBlurb classes={classes} />}
      {errorCsv && <InvalidFileBlurb classes={classes} errorCsv={errorCsv}/>}
      {!loading &&
        <>
          <form onSubmit={onSubmit}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{display: 'flex'}}>
                <Button
                  variant="contained"
                  component="label"
                >
                  Upload CSV
                  <input
                    type="file"
                    onChange={loadFile}
                    accept=".csv"
                    ref={ref}
                    hidden
                  />
                </Button>
                <TextField id="standard-basic" value={file ? file.name : ''} disabled={true} style={{marginLeft: '1rem'}}/>
              </div>
            {/*<input type='file' className={classes.fileInput} onChange={loadFile} accept=".csv" ref={ref}/>*/}
              <Checkbox
                checked={state.overrideTeam}
                disableRipple={true}
                onChange={() => dispatch({type: actionTypes.SET_TEAM_OVERRIDE, payload: !state.overrideTeam})}
                inputProps={{ 'aria-label': 'primary checkbox' }}/>
              <Typography variant="body1" className={classes.header}>Check this box if you want to add users to existing teams. </Typography>
            </div>
            <div className={classes.submitRow}>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!file || !dataLoaded}
                  // onClick={onSubmit}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
              <div className={classes.submitRow} style={{visibility: dataLoaded ? 'hidden' : 'visible'}}>
                <CircularProgress size='1rem'/>
                <Typography variant="body1" className={classes.header}>Loading data to verify upload</Typography>
              </div>
            </div>
          </form>
          <Typography variant="body1" className={classes.header}>
          You can download a sample spreadsheet <a href="/examples/BulkUploadSheet.csv" download target="_blank" rel="noopener noreferrer">here</a>.
          </Typography>
        </>
      }
      {errorCsv && <ErrorsTable userRows={parsedCsv}/>}
    </Box>
  );
}

const header = () => [
  {
    label: 'First Name',
    name: 'first name',
  },
  {
    label: 'Last Name',
    name: 'last name',
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Role',
    name: 'role',
  },
  {
    label: 'Team',
    name: 'team',
  },
  {
    label: 'Error',
    name: 'Errors',
  }
];

const rowDefinition = (row, index) => {
  return [
  row['First Name'],
  row['Last Name'],
  row.Email,
  row.Role,
  row.Team,
  row.Errors
]};

const ErrorsTable = ({userRows}) => {
  const errorRows = userRows.filter(row => Boolean(row.Errors))
  const Table = tableHoc(rowDefinition, header)
  return <Table data={errorRows}/>
}

export default UploadFile;
