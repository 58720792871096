import React from 'react';
import {
  Button,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  clearButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(.5),
    borderRadius: 5,
    minWidth: 'unset'
  },
  clearIcon: {
    color: theme.palette.primary.dark,
    fontSize: '1.2rem !important',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.disabled': {
      color: '#ccc',
    },
  },
}));

const ClearButton = ({onRemove, disabled, tooltipTitle, ...props}) => {
  const classes = useStyles();
  return (<Button
    className={classes.clearButton}
    onClick={onRemove}
    disabled={disabled} {...props}>
    <Tooltip title={tooltipTitle} aria-label={tooltipTitle}>
      <Close className={`${classes.clearIcon} ${disabled && 'disabled'}`} />
    </Tooltip>
  </Button>)
}

/* Clear Button is a clickable x, used across multiple fields in the Simulation Form */
ClearButton.propTypes = {
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
};

export default ClearButton;
