import React, { useState, useCallback } from "react";
import { Dialog, makeStyles, Slide, Button, DialogTitle, DialogContent, Box, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import Field from "../admin/Forms/Field";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const SetDBA = ({simId, teamId, quarter, teamName, onClose, ...remaining}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  const submitDecision = useCallback(async (name) => {
    try {
      setSaving(true);
      await API.put('state',
        `/simulations/${
          simId
        }/teams/${teamId}/decisions/${quarter}`,
        {
          body: {
            businessAs: name,
          },
        }
      );
      setName('');
      onClose && onClose(true);
      toast.success('Success!');
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);
    }
  }, [simId, teamId, quarter]);

  return (
    <Dialog
      className={classes.root}
      TransitionComponent={Transition}
      disableBackdropClick={false}
      hideBackdrop={false}
      aria-labelledby='Chaging DBA'
      aria-describedby="Change the team's name"
      onClose={onClose}
      {...remaining}
    >
      <DialogTitle>
        <Typography>Changing name from:</Typography>
        <Typography>{teamName}</Typography> 
      </DialogTitle>
      <DialogContent>
        <ValidatorForm
          onSubmit={() => submitDecision(name)}
        >
          <Field
            autoFocus
            name="dba"
            label="DBA"
            withRequiredValidator={true}
            validators={['required']}
            errorMessages={['field is required']}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Box
            display="flex"
            justifyContent="space-between">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={saving}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setName('');
                onClose && onClose();
              }
              }
              disabled={saving}
            >
              Cancel
            </Button>
          </Box>

        </ValidatorForm>
      </DialogContent>

    </Dialog>
  )
};

SetDBA.propTypes = {
  simId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  quarter: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default SetDBA;
