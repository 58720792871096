import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {VictoryChart, VictoryLine, VictoryTheme, VictoryAxis, VictoryLabel, VictoryPortal, VictoryContainer} from 'victory';
import PercentDiff from '../../components/PercentDiff';
import PropTypes from 'prop-types';

const graphWidth = '80%';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    margin: 'auto',
    marginTop: '30px',
  },
  class: {
    background: 'white',
    color: 'black',
    paddingLeft: 10,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px 0px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
    },
    height: '100%',
    margin: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 10,
  },
  noDataTitle: {
    color: '#aeaeae',
    position: 'absolute',
    top: '25%',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      top: '15%',
    },
  },
}));

const BoardSentiment = (props) => {
  const classes = useStyles();
  const vitalsHistory = props.coresim[props.teamIndex].vitalsHistory;
  const stockChartData = [];

  vitalsHistory.map((vital, i) => {
    if (i === 0) {
      return '';
    }
    stockChartData.push({x: `Q${i}`, y: vital.boardSentiment * 100});
    return '';
  });

  const latest = vitalsHistory[vitalsHistory.length - 1].boardSentiment;
  const previous = vitalsHistory.length > 1 ? vitalsHistory[vitalsHistory.length - 2].boardSentiment : latest;

  return (
    (stockChartData.length === 0 ?
      <div className={(classes.divStyle, classes.class)} style={{position: 'relative'}}>

        <Typography className={classes.titleStyle}>Board Sentiment</Typography>
        <Typography align='center' className={classes.noDataTitle}>
          Charts Available after Q1
        </Typography>

        <svg style={{width: '100%', height: 'auto', color: 'rgba(0,0,0,.1)'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,3A4,4 0 0,1 16,7A4,4 0 0,1 12,11A4,4 0 0,1 8,7A4,4 0 0,1 12,3M16,13.54C16,14.6 15.72,17.07 13.81,19.83L13,15L13.94,13.12C13.32,13.05 12.67,13 12,13C11.33,13 10.68,13.05 10.06,13.12L11,15L10.19,19.83C8.28,17.07 8,14.6 8,13.54C5.61,14.24 4,15.5 4,17V21H10L11.09,21H12.91L14,21H20V17C20,15.5 18.4,14.24 16,13.54Z" />
        </svg>
      </div> :
      < div className={(classes.divStyle, classes.class)}>

        <Typography className={classes.titleStyle}>Board Sentiment</Typography>
        <Typography>
          {(100 * vitalsHistory[vitalsHistory.length - 1].boardSentiment).toFixed(1)}%

        </Typography>
        <PercentDiff previous={previous} latest={latest} />

        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={30}
          containerComponent={<VictoryContainer maxWidth={graphWidth} />}
        >
          <VictoryPortal>
            <VictoryAxis
              tickValues={stockChartData.map((x, i) => i + 1)}
              tickFormat={stockChartData.map((x) => x.x)}
            />
          </VictoryPortal>
          <VictoryAxis
            dependentAxis
            tickFormat={(x) => (x.toString())}
          />

          <VictoryLine
            style={{
              data: {
                stroke: '#ff9900',
                strokeWidth: 6,
                borderRadius: '3px',
              },
              labels: {
                fill: '#0a0a0a',
              },
            }}
            data={stockChartData}
            domain={{x: [0, stockChartData.length], y: [0, 100]}}
            labels={({datum}) => datum.y.toFixed(1)}
            labelComponent={<VictoryLabel verticalAnchor='end' />}
            x='x'
            y='y'
          />
        </VictoryChart>
      </div>)
  );
};

BoardSentiment.propTypes = {
  coresim: PropTypes.array,
  classes: PropTypes.object,
  teamIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    coresim: state.coresim.teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardSentiment);
