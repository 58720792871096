import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import NewsArticleForm from './NewsArticleForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const getInitialArticle = () => ({
  headline: '',
  story: '',
  image: '',
});

const CreateNewsArticle = ({onReload}) => {
  const [loading, setLoading] = useState(false);
  const [initialArticle, setInitialArticle] =
    useState(getInitialArticle());
  const breadcrumbActions = useBreadcrumbActions();

  const onSubmit = async (form) => {
    setLoading(true);
    let body = {
      ...form,
      image: ''
    }
    try {
      const story = await API.post('mongo', '/newsarticles', {body});
      if (form.image !== '/static/media/image-placeholder.1ba9cec3.svg') {
        await API.post('mongo', '/util/photo', {
          body: {
            img: form.image,
            name: story._id
          }
        })
      }
      toast.success('Success!');
      setInitialArticle(getInitialArticle());

      if (onReload) {
        onReload();
      }

      breadcrumbActions.pop();
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Create News Article
      </Typography>
      <NewsArticleForm
        onSubmit={onSubmit}
        initialValue={initialArticle}
        disabled={loading}
      />
    </Box>
  );
}

CreateNewsArticle.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default CreateNewsArticle;
