import logistic from './base/logistic';

/**
 *
 * This is a function given by https://www.desmos.com/calculator/gbtjsbj9we
 * to determine the public's expected defect rate for the product. Rates are raw
 * numbers without units.
 *
 * @param {number} deviance  - What are the bounds for deviation in the expected rate (+/- how many)
 *
 */
const expectedDefectRate = (deviance) =>
  /**
   * @param {number} industryDefectRate - The industry standard defect rate
   */
  (industryDefectRate) =>
    /**
     * @param {number} expectedPrice - The industry price
     */
    (expectedPrice) =>
      /**
       * @param {number} price - The actual price
       */
      (price) =>
        -2 * deviance * logistic(4 / expectedPrice)(price - expectedPrice) +
        deviance +
        industryDefectRate;

export default expectedDefectRate;
