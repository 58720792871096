import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Tooltip,
  makeStyles,
  Slider,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
  Divider,
  Portal,
} from '@material-ui/core';
import Group from '../Forms/Group';
import Field from '../Forms/Field';
import SelectField from '../Forms/SelectField';
import SelectValidator from '../Forms/SelectValidator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { useFormReducer } from '../../helpers/useFormReducer';
import useArrayFormReducer from '../../helpers/useArrayFormReducer';
import DateTimePicker from '../../components/widgets/DateTimePicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import uuid from 'uuid';
import 'moment-timezone';
import _orderby from 'lodash.orderby';
import {API} from 'aws-amplify';
import classnames from 'classnames';
import BBCourse from './BBCourse';
import BBCurriculum from './BBCurriculum';
import Teams from './Teams';
import Chairs from "./Chairs";
import { useArrayReducer } from '../../helpers/useArrayReducer';
import InfoBox from '../../components/widgets/InfoBox';
import {PhotoCamera} from '@material-ui/icons';
import ConfigurationTypes from '../Configuration/ConfigurationTypes';

const timezones = moment.tz.names().reduce(
  (tz, v) => {
    tz[v] = v;
    return tz;
  }, {}
);

const useStyles = makeStyles((theme) => ({
  fieldset: {
    padding: 0,
    margin: 0,
    border: 'none'
  },
  quarters: {
    marginTop: theme.spacing(3),
    width: '100%',
    '& > .active': {
      zIndex: 1,
    }
  },
  quarter: {
    borderTop: '.6px solid #aeaeae'
  },
  activePicker: {
    zIndex: 1,
  },
  input: {
    display: 'none'
  }
}));

const getProps = (name, form, updateHandler) => ({
  id: name,
  name: name,
  withrequiredvalidator: 'true',
  validators: ['required'],
  errormessages: ['field is required'],
  autoComplete: "off",
  value: form[name],
  onnumberchange: updateHandler && updateHandler(name),
});

const getSliderProps = (name, items, form, updateHandler) => ({
  id: name,
  name,
  autoComplete: 'off',
  defaultValue: form[name],
  value: form[name],
  items,
  onChangeValue: (v) => updateHandler(name)(v)
});

const SectionMap = ({title, items, form, updateHandler, disabled}) => {
  return (
    <Group title={title} items={items}>
      {
        items.map( (f, i) => {
          const Component = f.Component || Field;
          const componentProps = f.isSlider ?
            getSliderProps(f.name, f.items, form, updateHandler) :
            getProps(f.name, form, updateHandler);

          return (
            <Tooltip key={i} title={f.helpText}>
              <Component
                {...componentProps}
                label={f.label}
                disabled={disabled}
              />
            </Tooltip>
          );
        }
        )
      }
    </Group>
  );
};

SectionMap.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  form: PropTypes.object.isRequired,
  updateHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

const Quarter = ({
  number,
  quarter,
  onChange,
  active,
  onActivated,
  cases,
  news,
  disabled
}) => {
  const [activeField, setActiveField] = useState();

  const caseSelections = useMemo(() => cases.map((c) => c.miniCase),
    [cases]);

  const newsSelections = useMemo(() => news.map((c) => c.headline),
    [news]);

  const selectedCase = useMemo(() => {
    if (!cases || !quarter.minicase) {
      return undefined;
    }
    const index =  cases.findIndex((c) => c._id === quarter.minicase._id );
    return index === -1 ? undefined : index;
  }, [quarter.minicase, cases]);

  const selectedArticle = useMemo(() => {
    if (!news || !quarter.article) {
      return undefined;
    }
    const index = news.findIndex((c) => c._id === quarter.article._id );
    return index === -1 ? undefined : index;

  }, [quarter.article, news]);

  const handleEditChange = (field) => (value) => {
    setActiveField(value ? field : undefined);
    if (value && onActivated) {
      onActivated();
    }
  }

  useEffect(() => {
    if (!active && activeField) {
      setActiveField();
    }
  }, [active]);

  const handleMinicaseChange = (value) => {
    setActiveField(undefined);

    if (onActivated) {
      onActivated();
    }

    if (onChange) {
      onChange('minicase')(cases[value]);
    }
  }

  const handleNewsArticleChange = (value) => {
    setActiveField(undefined);

    if (onActivated) {
      onActivated();
    }

    if (onChange) {
      onChange('article')(news[value]);
    }
  }

  const startDate = useMemo(() => {
    return quarter.start === undefined ? quarter.start : new Date(quarter.start).getTime();
  },
  [quarter.start]);

  const endDate = useMemo(() =>
    quarter.end === undefined ? quarter.end : new Date(quarter.end).getTime(),
  [quarter.end]);

  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classnames(
        classes.quarter,
        active ? 'active' : ''
      )}
    >
      <Typography
        style={{paddingRight: 16}}
        variant="h4">
        {number}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        flexGrow="1"
      >
        <DateTimePicker
          className={activeField === 'start' ? classes.activePicker : ''}
          label="start"
          isEdit={activeField === 'start'}
          onEditChange={handleEditChange('start')}
          onChange={(value) => onChange('start')( value && value.getTime())}
          value={startDate}
          disabled={disabled}
        />
        <DateTimePicker
          className={activeField === 'end' ? classes.activePicker : ''}
          label="end"
          isEdit={activeField === 'end'}
          onEditChange={handleEditChange('end')}
          onChange={(value) => onChange('end')(value && value.getTime())}
          value={endDate}
          disabled={disabled}
        />
        <SelectField
          id='minicase'
          fullWidth={false}
          helperText=''
          placeholder=''
          error={false}
          style={{width: 150}}
          label="Minicase"
          items={caseSelections}
          value={selectedCase}
          onChange={(e) => handleMinicaseChange(e.target.value)}
          disabled={disabled}
        />
        <SelectField
          id='news-article'
          fullWidth={false}
          helperText=''
          placeholder=''
          error={false}
          style={{width: 150}}
          label="News Article"
          items={newsSelections}
          value={selectedArticle}
          onChange={(e) => handleNewsArticleChange(e.target.value)}
          disabled={disabled}
        />

        <FormControl
          disabled={disabled}
        >
          <FormLabel component="legend">
          Demand
          </FormLabel>
          <Slider
            id={`demand[${number}]`}
            name={`demand[${number}]`}
            onChange={(e,v) => onChange('demand')(v)}
            min={0}
            max={1}
            step={.01}
            valueLabelDisplay='auto'
            style={{width: 150}}
            value={quarter.demand}
            disabled={disabled}
          />
        </FormControl>
      </Box>
    </Box>
  )
};

Quarter.propTypes = {
  number: PropTypes.number.isRequired,
  quarter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  onActivated: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  cases: PropTypes.array.isRequired,
  news: PropTypes.array.isRequired,
}
export const initQuarter = {
  start: undefined,
  end: undefined,
  article: undefined,
  minicase: undefined,
  demand: 0.5
};

const ConfigSelect = ({
  idx: configTypeIndex,
  configType,
  configMap,
  updateHandler,
  initConfig,
  configurations,
  form,
  disabled}) => {

  const classes = useStyles();

  // all configurations with the same type as passed to the component (e.g. Quality, High Volume)
  const configsForType = useMemo(() =>
    configurations.filter((config) => config.type === ConfigurationTypes.indexOf(configType)),
  [configurations, configType]
  );

  const configNamesForType = useMemo(() =>
    configsForType.map((config) => config.name),
  [configsForType]
  );

  // given index of a configuration within the full list of configs, return the index of the selected config within
  // the filtered config list (configs filtered by the same config type, eg Quality, High Volume)
  const getFilteredIndex = (fullConfigIndex) => {
    const configId = configurations[fullConfigIndex]._id;
    return configsForType.findIndex((config) => config._id === configId);
  }

  const getProps = () => ({
    id: configType,
    name: configType,
    validators: ['required'],
    errorMessages: ['field is required'],
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', value => value)
  }, [])

  const handleCapture = ({ target }) => {
    if (!target.files || !target.files[0]) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(target.files[0]);

    fileReader.onload = (e) => {

      const formConfigArray = [...form.config];
      formConfigArray.splice(Number(target.id), 1, {
        ...form.config[target.id], widgetImage: [e.target.result], widgetId: uuid.v4()
      });

      updateHandler('config')(formConfigArray);

    };
  };

  return (
    <Box style={{display: 'inline-flex', marginTop: '5px', marginBottom: '5px'}}>
      <SelectValidator
        {...getProps()}
        id='configSelect'
        style={{minWidth: 150}}
        label={configType}
        items={configNamesForType}
        // considering how much getting of index etc we have, this should probably be refactored to just use a map.
        value={configMap === undefined || configMap === -1 ? undefined : getFilteredIndex(configMap)}
        onChange={ (e) => {
          const newConfig = form.config.map((setting, index) => {
            if (configTypeIndex === index){
              return configsForType[e.target.value]
            } else {
              return setting
            }
          });


          updateHandler('config')(
            newConfig
          );
        }
        }
        disabled={disabled}
      />
      {form.config[configTypeIndex] !== undefined &&
              <React.Fragment>
                <input
                  accept="image/*"
                  className={classes.input}
                  id={configTypeIndex}
                  name={configType}
                  onChange={handleCapture}
                  type="file"
                />

                <label htmlFor={configTypeIndex}>
                  <IconButton style={{ backgroundColor: 'transparent'}} disabled={disabled} color="primary" component="span">
                    <PhotoCamera style={{fontSize: '1rem'}} />
                    <p style={{fontSize: '1rem'}}>Select Image</p>
                  </IconButton>
                </label>
                {
                  form.config[configTypeIndex].widgetId && form.config[configTypeIndex].widgetImage.length > 0 ?
                    <img src={form.config[configTypeIndex].widgetImage} width={150} height="auto" alt="product" style={{color: '#eeeeee', objectFit: 'contain'}} />
                    :
                    initConfig[configTypeIndex] && initConfig[configTypeIndex].widgetId ?
                      <object data={`${process.env.REACT_APP_S3_IMAGES}/${form.config[configTypeIndex].widgetId}`} width={150} height="auto" type="image/png"/>
                      :
                      null
                }
              </React.Fragment>
      }
    </Box>)
}

ConfigSelect.propTypes = {
  idx: PropTypes.number.isRequired,
  configType: PropTypes.string.isRequired,
  configMap: PropTypes.number,
  updateHandler: PropTypes.func.isRequired,
  configurations: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  initConfig: PropTypes.array.isRequired
}

const SimulationForm = ({onSubmit, initialValue, message, disabled, lockIn}) => {
  const [activeQuarter, setActiveQuarter] = useState(0);
  const [configurations, setConfigurations] = useState([]);
  const [cases, setCases] = useState([]);
  const [articles, setArticles] = useState([]);
  const [bbCourse, setBbCourse] = useState(initialValue.bbCourse);

  const chairsRef = useRef();
  const teamsRef = useRef();

  // We need this to force a re-render of quarters so that
  // we update the display whenever timezone is altered
  // We need to be sure that this happens AFTER moment.tz.setDefault()
  const [activeTimezone, setActiveTimezone] = useState('');
  const classes = useStyles();

  const [form, updateHandler] =
  useFormReducer(initialValue,
    (init) => ({
      ...init,
      numQuarters: init.quarters ? init.quarters.length : 8,
    })
  );

  useEffect(() => {
    moment.tz.setDefault(form.timezone);
    setActiveTimezone(form.timezone);
  }, [form.timezone]);

  // configMap is a list of the indexes in the full config list of each assigned configuration in the sim
  const configMap = useMemo(() =>
    form.config.map((formConfig) =>
      formConfig &&
  configurations.findIndex((c) => c._id === formConfig._id) ),
  [form.config, configurations]);

  const loadConfigurations = async (abortObj) => {
    try {
      const configurations = await API.get('mongo', '/configurations');
      if (!abortObj || !abortObj.aborted) {
        const newConfigs = _orderby(configurations, ['name']);
        setConfigurations(newConfigs);
      }
    } catch (e) {
      toast.error('Error retrieving configurations.');
    }
  }

  const loadCases = async (abortObj) => {
    try {
      const cases = await API.get('mongo', '/cases');
      if (!abortObj || !abortObj.aborted) {
        const newCases = _orderby(cases, ['miniCase']);
        setCases(newCases);
      }
    } catch (e) {
      toast.error('Error retrieving mini-cases.');
    }
  }

  const loadNewsArticles = async (abortObj) => {
    try {
      const news = await API.get('mongo', '/newsarticles');
      if (!abortObj || !abortObj.aborted) {
        const newArticles = _orderby(news, ['headline']);
        setArticles(newArticles);
      }
    } catch (e) {
      toast.error('Error retrieving news articles.');
    }
  }

  useEffect(() => {
    let abortObj = {aborted: false};
    loadConfigurations(abortObj);
    loadCases(abortObj);
    loadNewsArticles(abortObj);

    return () => abortObj.aborted = true;
  }, []);

  const [quarters, quarterUpdateHandler] =
  useArrayFormReducer(initialValue.quarters, 48, initQuarter);

  const [teams, teamsHandlers] =
    useArrayReducer(
      initialValue.teams,
      (initTeams) => [...initTeams]
    );
  // this is some hardening for old simulations - new sims will get default empty array when they are created
  initialValue.chairs = initialValue.chairs ? initialValue.chairs : [];

  const [chairs, chairsHandlers] =
    useArrayReducer(
      initialValue.chairs,
      (initChairs) => [...initChairs]
    );


  const handleSubmit = (e) => {

    e.preventDefault();
    if (onSubmit) {
      onSubmit({
        ...form,
        quarters: quarters.slice(0, form.numQuarters),
        teams,
        chairs: chairs,
        bbCourse,
        numQuarters: undefined
      });
    }
    if (lockIn) {
      lockIn({
        ...form,
        quarters: quarters.slice(0, form.numQuarters),
        teams,
        chairs: chairs,
        bbCourse,
        numQuarters: undefined
      });
    }
  }

  return (
    <React.Fragment>
      <ValidatorForm
        onSubmit={handleSubmit}
      >
        {message &&
        <InfoBox message={message} />
        }
        <fieldset disabled={disabled} className={classes.fieldset}>
          <Group stack>

            <Field
              {...getProps('name', form, updateHandler)}
              label="Name"
              onChange={(e) =>updateHandler('name')(e.target.value)}
              disabled={disabled}
              autoFocus />
            <Field
              {...getProps('widget', form, updateHandler)}
              label="Widget"
              onChange={(e) =>updateHandler('widget')(e.target.value)}
              disabled={disabled}
            />
            <SelectField
              id='timezone'
              helperText=''
              placeholder=''
              error={false}
              style={{minWidth: 150}}
              label="Timezone"
              items={timezones}
              value={form.timezone}
              onChange={(e) => updateHandler('timezone')(e.target.value)}
              disabled={disabled}
            />
            <FormControl
              disabled={disabled}
            >
              <FormLabel component="legend">
              Game Length (Quarters)
              </FormLabel>
              <Slider
                {...getProps('numQuarters', form, updateHandler)}
                onChange={(e,v) => updateHandler('numQuarters')(v)}
                min={1}
                max={48}
                valueLabelDisplay='auto'
                disabled={disabled}
              />
            </FormControl>
          </Group>
          <Group
            title="Configurations"
            stack
          >
            {ConfigurationTypes.map((type, i) => (
              <ConfigSelect
                key={i}
                idx={i}
                configType={type}
                configMap={configMap[i]}
                updateHandler={updateHandler}
                configurations={configurations}
                form={form}
                disabled={disabled}
                initConfig={initialValue.config}
              />
            ))}
          </Group>
          <Group title="Quarters">
            <Box
              className={classes.quarters}
              display="flex"
              flexDirection="column"
            >
              {
                quarters.slice(0, form.numQuarters).map(
                  (v, i) => (
                    <Quarter
                      key={`${activeTimezone}-${i}`}
                      number={i+1}
                      quarter={v}
                      active={activeQuarter === i}
                      onActivated={() => setActiveQuarter(i)}
                      onChange={quarterUpdateHandler(i)}
                      cases={cases}
                      news={articles}
                      disabled={disabled}
                    />
                  )
                )
              }
            </Box>
          </Group>
          <Group title="Teams">
            <div ref={teamsRef} />
          </Group>
          <Group title="Integrate with Blackboard" stack>
            <BBCourse
              course={bbCourse}
              onRemove={() => setBbCourse(null)}
              onSave={(val) => {setBbCourse(val)}}
              disabled={disabled}/>
            { bbCourse && bbCourse.id && <Divider style={{marginTop: 16, marginBottom: 16}}/> }
            { bbCourse && bbCourse.id &&
            <BBCurriculum
              course={bbCourse}
              onSave={(val) => {setBbCourse(val)}}
              disabled={disabled}/> }
          </Group>
          <Group title="Chairs of the Week">
            <div ref={chairsRef} />
          </Group>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled}
          >
          Save
          </Button>
        </fieldset>
      </ValidatorForm>

      <Portal container={teamsRef.current}>
        <Teams
          teams={teams}
          onTeamsChanged={teamsHandlers.init}
          onRemove={teamsHandlers.remove}
          disabled={disabled}
        />
      </Portal>
      <Portal container={chairsRef.current}>
        <Chairs
          chairs={chairs}
          onChairsChanged={chairsHandlers.init}
          onRemove={chairsHandlers.remove}
          disabled={disabled}
        />
      </Portal>
    </React.Fragment>

  )
};

SimulationForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValue: PropTypes.object.isRequired,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  lockIn: PropTypes.func
};

export default SimulationForm;
