import {createMuiTheme} from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

const TOGGLETHEMEMODE = 'settings/TOGGLETHEMEMODE';
const SWAPTHEMECOLORS = 'settings/SWAPTHEMECOLORS';
const SWAPSECONDCOLOR = 'settings/SWAPSECONDCOLOR';
const SWAPTHIRDCOLOR = 'settings/SWAPTHIRDCOLOR';
const SWAPTFOURTHCOLOR = 'settings/SWAPFOURTHCOLOR';
const SWAPFIFTHCOLOR = 'settings/SWAPFIFTHCOLOR';
const SHOWWELCOME = 'settings/SHOWWELCOME';
const SHOWANNOUNCEMENTS = 'settings/SHOWANNOUNCEMENTS';
const SHOWCALENDAR = 'settings/SHOWCALENDAR';
const SHOWCURRICULUM = 'settings/SHOWCURRICULUM';
const SHOWQUARTERENDING = 'settings/SHOWQUARTERENDING';
const SHOWPRICEVOLUME = 'settings/SHOWPRICEVOLUME';
const EDITFIELD = 'settings/EDITFIELD';
const QENDINGDISMISSED = 'settings/QENDINGDISMISSED';

const primaryColor = { main: "#bf1d22" }; // WBW Red
const secondaryColor = indigo;
const tertiaryColor = orange;
const fourthColor = green;
const fifthColor = red;

const themeConfig = {
  palette: {
    primary: primaryColor,
    secondary: primaryColor,
    ok: green,
    tertiary: orange,
    fourth: green,
    fifth: red,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
};

// All the following keys are optional.
// We try our best to provide a great default value.
const defaultTheme = createMuiTheme(themeConfig);

const initState = {
  theme: defaultTheme,
  darkMode: false,
  colorsSwapped: 'one',
  one: true,
  two: false,
  three: false,
  //for configuring various modal display states
  showWelcome: true,
  showCurriculum: false,
  showAnnouncements: false,
  showCalendar: false,
  showQEnding: false,
  qEndingDismissed: false,
  showPriceVolume: false,
  editField: undefined,
};

export default function reducer(state = initState, action) {
  if (action === undefined) {
    return state;
  }

  switch (action.type) {
    case TOGGLETHEMEMODE:
      if (action.enableDark) {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...themeConfig.palette,
              primary: state.theme.palette.primary,
              secondary: state.theme.palette.secondary,
              type: 'dark',
            },
          }),
          darkMode: true,
        };
      }
      return {
        ...state,
        theme: createMuiTheme({
          ...themeConfig,
          palette: {
            ...themeConfig.palette,
            primary: state.theme.palette.primary,
            secondary: state.theme.palette.secondary,
          },
        }),
        darkMode: false,
      };

    case SWAPTHEMECOLORS:
      if (action.colorsSwapped === 'primary') {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...state.theme.palette,
              primary: primaryColor,
              secondary: primaryColor,
              tertiaryColor: tertiaryColor,
            },
          }),
        };
      }
      break;

    case SWAPSECONDCOLOR:
      if (action.colorsSwapped === 'secondary') {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...state.theme.palette,
              primary: secondaryColor,
              secondary: tertiaryColor,
              tertiary: tertiaryColor,
            },
          }),
        };
      }
      break;
    case SWAPTHIRDCOLOR:
      if (action.colorsSwapped === 'tertiary') {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...state.theme.palette,
              primary: tertiaryColor,
              secondary: tertiaryColor,
              tertiary: tertiaryColor,
            },
          }),
        };
      }
      break;
    case SWAPTFOURTHCOLOR:
      if (action.colorsSwapped === 'fourth') {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...state.theme.palette,
              primary: fourthColor,
              secondary: tertiaryColor,
              tertiary: tertiaryColor,
            },
          }),
        };
      }
      break;
    case SWAPFIFTHCOLOR:
      if (action.colorsSwapped === 'fifth') {
        return {
          ...state,
          theme: createMuiTheme({
            ...themeConfig,
            palette: {
              ...state.theme.palette,
              primary: fifthColor,
              secondary: tertiaryColor,
              tertiary: tertiaryColor,
            },
          }),
        };
      }
      break;
    case SHOWWELCOME:
      return {
        ...state,
        showWelcome: action.payload,
      }
    case SHOWANNOUNCEMENTS:
      return {
        ...state,
        showAnnouncements: action.payload,
      }
    case SHOWCALENDAR:
      return {
        ...state,
        showCalendar: action.payload,
      }
    case SHOWCURRICULUM:
      return {
        ...state,
        showCurriculum: action.payload,
      }
    case SHOWQUARTERENDING:
      return {
        ...state,
        showQEnding: action.payload,
      }
    case QENDINGDISMISSED:
      return {
        ...state,
        qEndingDismissed: action.payload,
      }
    case SHOWPRICEVOLUME:
      return {
        ...state,
        showPriceVolume: action.payload,
      }
    case EDITFIELD:
      return {
        ...state,
        editField: action.payload,
      }
    default:
      return state;
  }
}

export function toggleThemeMode(enableDark) {
  return {
    type: TOGGLETHEMEMODE,
    enableDark,
  };
}

export function swapThemeColors(colorsSwapped) {
  return {
    type: SWAPTHEMECOLORS,
    colorsSwapped,
  };
}

export function showWelcome(show) {
  return {
    type: SHOWWELCOME,
    payload: show,
  };
}

export function showAnnouncements(show) {
  return {
    type: SHOWANNOUNCEMENTS,
    payload: show,
  }
}

export function showCalendar(show) {
  return {
    type: SHOWCALENDAR,
    payload: show,
  }
}

export function showCurriculum(show) {
  return {
    type: SHOWCURRICULUM,
    payload: show,
  }
}

export function showQEnding(show) {
  return {
    type: SHOWQUARTERENDING,
    payload: show,
  }
}

export function QEndingDismissed(show) {
  return {
    type: QENDINGDISMISSED,
    payload: show,
  }
}

export function showPriceVolume(show) {
  return {
    type: SHOWPRICEVOLUME,
    payload: show,
  }
}

export function editField(field) {
  return {
    type: EDITFIELD,
    payload: field,
  };
}
