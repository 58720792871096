import * as React from 'react';
import PropTypes from 'prop-types';
import UserContext from './UserContext';
import useUserContext from './useUserContext';

const UserContextProvider = ({children, user}) => {
  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object, // todo: proptypes.shape?
};

export default UserContextProvider;
export {UserContextProvider, useUserContext};
