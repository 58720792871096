/**
 * This M a function given by https://www.desmos.com/calculator/8okqwzypy0
 * to determine the units that can be produced given a certain amount of money.
 * This is the exact inverse of expenses.js. It is needed to determine the
 * maximum amount of units that can be produced in a quarter.
 *
 * @param {number} levellingPoint - The inflexion point where expense / unit is mostly level
 */
const unitsForExpense = (levellingPoint) =>
  /**
 * @param {number} levelledCost - The linear asymptote of the expense graph. That is, cost / unit approaches this value as units approach infinity
 */
  (levelledCost) =>
    /**
 * @param {number} expense - The amount that can be spent
 * @returns {number} - The units that can be produced, as a whole number (truncated)
 */
    (expense) =>
      Math.floor(
        Math.pow(
          Math.pow(expense / levelledCost, 2) +
            levellingPoint * levellingPoint / 4,
          0.5
        ) -
          levellingPoint / 2
      );

export default unitsForExpense;
