import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PriceVolume from "../simulation/PriceVolume";
import Transition from '../../components/ModalTransition';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showPriceVolume} from '../../coresim/reducers/settings';
import PropTypes from 'prop-types';
import {API} from 'aws-amplify';

const StudentPriceVolumeModal = ({showPriceVolume, closeModal, childProps}) => {

  async function updateConfig (activeConfigurationOption, simId, teamId) {
    let body = {
      activeConfigurationOption,
    }

    try {
      await API.put('state',
        `/simulations/${simId}/teams/${teamId}/config`,
        {body}
      );
      closeModal()
    } catch(e) {
      console.log(e);
    }
  }

  return (<Dialog
    style={{
      zIndex:9999,
      margin: 'auto',
    }}
    open={showPriceVolume}
    TransitionComponent={Transition}
    keepMounted
    disableEscapeKeyDown={true}
    fullScreen={true}
    fullWidth={true}
    disableBackdropClick={true}
    hideBackdrop={false}
    onClose={closeModal}
    aria-labelledby='price-volume-modal'
    aria-describedby='price-volume-modal-desc'
  >
    <PriceVolume updateConfig={updateConfig} handleLogout={childProps.handleLogout}/>
  </Dialog>)
}

const mapStateToProps = (state) => {
  return {
    showPriceVolume: state.settings.showPriceVolume,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => dispatch(showPriceVolume(false)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentPriceVolumeModal);

StudentPriceVolumeModal.propTypes = {
  showPriceVolume: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  childProps: PropTypes.object.isRequired
}
