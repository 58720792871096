import React, { useState } from 'react';
import NewsArticlesList from './NewsArticlesList';
import AdminPanel from '../AdminPanel';
import { useRouteMatch, Switch, Redirect } from 'react-router';
import StackRoute from '../../routing/StackRoute';
import CreateNewsArticle from './CreateNewsArticle';
import EditNewsArticle from './EditNewsArticle';
import StackPanel from '../../routing/StackPanel';

const ArticleAdmin = () => {
  const [reload, setReload] = useState(new Date());
  const match = useRouteMatch();


  const onReload = () => {
    setReload(new Date());
  }

  return (
    <AdminPanel label='Articles' >
      <NewsArticlesList reload={reload} />
      <Switch>
        <StackRoute
          path={`${match.url}/create`}
          label='Create Article'
        >
          <StackPanel>
            <CreateNewsArticle onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <StackRoute
          path={`${match.url}/:id`}
          label='Edit Article'
        >
          <StackPanel>
            <EditNewsArticle onReload={onReload} />
          </StackPanel>
        </StackRoute>
        <Redirect path={`${match.url}/`} to={match.url} />
      </Switch>
    </AdminPanel>
  )
}

export default ArticleAdmin;
