import React from 'react';
import Box from "@material-ui/core/Box";
import { useRouteMatch, Switch, Redirect } from 'react-router';

import UploadFile from './UploadFile';
import AdminPanel from '../AdminPanel';
import StackRoute from '../../routing/StackRoute';
import StackPanel from '../../routing/StackPanel';
import {BulkUploadProvider} from "../../providers/bulkUploadProvider";
import Confirm from "./Confirm";

const BulkUploadAdmin = () => {
  const match = useRouteMatch();

  return (
    <AdminPanel root={true} label='Bulk Upload'>
      <BulkUploadProvider>
        <StackPanel>
          <UploadFile />
        </StackPanel>
        <Switch>
          <StackRoute path={`${match.url}/invalid`}
                      label='Invalid Bulk Upload File'>
            <StackPanel>
              <div>INVALID</div>
            </StackPanel>
          </StackRoute>
          <StackRoute path={`${match.url}/confirm`}
                      label='Confirm'>
            <Box>
              <Confirm />
            </Box>
          </StackRoute>
          <Redirect path={`${match.url}/`} to={match.url} />
        </Switch>
      </BulkUploadProvider>
    </AdminPanel>
  );
};

export default BulkUploadAdmin;
