import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import NewsArticleForm from './NewsArticleForm';
import {API} from 'aws-amplify';
import {toast} from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useBreadcrumbActions } from '../../providers/breadcrumbProvider';

const EditNewsArticle = ({onReload}) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialArticle, setInitialArticle] = useState();
  const [error, setError] = useState();
  const breadcrumbActions = useBreadcrumbActions();

  let { id } = useParams();

  useEffect(() => {
    let aborted = false;
    const load = async () => {
      try {
        const result = await API.get('mongo', `/newsarticles/${id}`);
        if (!aborted) {
          setInitialArticle(result);
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          setError("News Article not found");
        } else {
          throw e;
        }
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    load();
    return () => aborted = true;
  }, [id]);

  const onSubmit = async (form) => {
    setSaving(true);
    try {
      await API.put('mongo', `/newsarticles/${id}`, {body: {
        ...form,
        image: ''
      }
      });
      if (form.newImage) {
        await API.post('mongo', '/util/photo', {
          body: {
            img: form.image,
            name: form._id
          }
        })
      }
      toast.success('Success!');
      if (onReload) {
        onReload();
      }
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setSaving(false);
      breadcrumbActions.pop();
    }
  }

  return (
    <Box>
      <Typography variant="h4">
        Edit News Article
      </Typography>
      {
        error
      }
      { // TODO: loading screen
        !loading && !error &&
        <NewsArticleForm
          onSubmit={onSubmit}
          initialValue={initialArticle}
          disabled={saving}
        />
      }
    </Box>
  );
}

EditNewsArticle.propTypes = {
  onReload: PropTypes.func.isRequired
}

export default EditNewsArticle;
